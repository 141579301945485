import React from "react";
import {Container} from "reactstrap";
import CreatePurchaseOrderFormFull from "../components/CreatePurchaseOrderFormFull";

const CreatePOPage: React.FC = () => {

	return (
		<Container className="my-4 htc-container">
			<CreatePurchaseOrderFormFull/>
		</Container>
	);
};

export default CreatePOPage;
