import React from "react";
import styleHeaders from "../style/styleHeaders";
import BootstrapTable from "react-bootstrap-table-next";
import {Button, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {FiChevronDown} from "react-icons/all";
import {initialAdapter} from "adapter";
import {formatMoney, sortMoney} from "../utils/formatters";

interface ISelectCustomerPOTableProps {
	data: any[];
	filter: initialAdapter.DocumentDayRange;
}

const SelectCustomerPOTable: React.FC<ISelectCustomerPOTableProps> = (props: ISelectCustomerPOTableProps) => {

	const {data, filter} = props;

	return (
		<BootstrapTable
			bootstrap4={true}
			striped={false}
			hover={true}
			condensed={true}
			bordered={false}
			wrapperClasses="table-responsive border"
			rowStyle={{whiteSpace: "nowrap"}}
			columns={styleHeaders([
				{
					dataField: "orderNumber",
					text: "Order #",
					sort: true,
				},
				{
					dataField: "buyerName",
					text: "Customer",
					sort: true,
				},
				{
					dataField: "poDate",
					text: "PO Date",
					sort: true,
				},
				{
					dataField: "orderTotal",
					text: "Order Total",
					formatter: formatMoney,
					sortFunc: sortMoney,
					headerAlign: "right",
					sort: true,
					classes: "text-right",
				} as any,
				{
					dataField: "actions",
					text: "",
					formatter: mapButton,
				},
			])}
			keyField={"keyField"}
			data={
				filterData(data, filter).map((d, i) => {
					return {
						...d,
						keyField: i
					}
				})
			}
			noDataIndication={"No Purchase Orders available"}
			defaultSorted={[{dataField: "orderNumber", order: "asc"}]}
		/>
	);
};

/**
 * filter and only return the data that matches the filter
 *
 * @param data
 * @param filter
 */
function filterData(data: any[], filter: initialAdapter.DocumentDayRange): any[] {

	// TODO filtering logic based on field names from backend
	switch (filter) {
		// case initialAdapter.DocumentDayRange.LAST_30:
		// 	return data.filter((row) => {
		// 		return row.status === initialAdapter.DocumentDayRange.LAST_30;
		// 	});
		// case initialAdapter.DocumentDayRange.DAYS_30_TO_60:
		// 	return data.filter((row) => {
		// 		return row.status === initialAdapter.DocumentDayRange.DAYS_30_TO_60;
		// 	});
		// case initialAdapter.DocumentDayRange.DAYS_60_TO_180:
		// 	return data.filter((row) => {
		// 		return row.status === initialAdapter.DocumentDayRange.DAYS_60_TO_180;
		// 	});
		default:
			return data;
	}
}

function mapButton(cell: Array<[string, (row: any) => void]>, row: any, i: number): JSX.Element {
	if (cell.length < 1) {return null}
	function mainButtonOnClick(): void {
		cell[0][1](row);
	}

	return (
		<Button onClick={mainButtonOnClick} color="link">
			{cell[0][0]}
		</Button>
	);
}

export default SelectCustomerPOTable;
