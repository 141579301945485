"use strict";

exports.__esModule = true; // v1

exports.baseURL = process.env.REACT_APP_BASE_URL;
exports.backOffice = exports.baseURL + "backoffice/";
exports.invoice = exports.baseURL + "invoiceTransaction/";
exports.investor = exports.baseURL + "investor/";
exports.file = exports.baseURL + "file/";
exports.authentication = exports.baseURL + "authentication/"; // v2

exports.baseURLV2 = process.env.REACT_APP_BASE_URL2;
exports.backOffice2 = exports.baseURLV2 + "backoffice/";
exports.authenticationV2 = exports.baseURLV2 + "authentication/";
exports.clientV2 = exports.baseURLV2 + "client/";
exports.documentV2 = exports.baseURLV2 + "document/";
exports.tradelinkV2 = exports.baseURLV2 + "tradelink/";
exports.investorV2 = exports.baseURLV2 + "investor/";
exports.purchaseV2 = exports.baseURLV2 + "purchase/";
exports.invoiceV2 = exports.baseURLV2 + "invoice/";
exports.clientlinkV2 = exports.baseURLV2 + "clientlink/";
exports.assetV2 = exports.baseURLV2 + "asset/";
exports.pdfviewerV2 = exports.baseURLV2 + "pdfviewer/";
exports.htcPO2 = exports.baseURLV2 + "htcpo/";
exports.serviceCompany2 = exports.baseURLV2 + "servicecompany/";
exports.docmapping2 = exports.baseURLV2 + "docmapping/";
exports.businesslink2 = exports.baseURLV2 + "businesslink/";
exports.registration2 = exports.baseURLV2 + "registration/";
exports.authentication2 = exports.baseURLV2 + "authentication/";