import React from "react";
import {Nav, NavItem, NavLink} from "reactstrap";
import classnames from "classnames";

export enum AddCustomerFormFilters {
	CONTACT = "CONTACT",
	BILLING = "BILLING",
	SHIPPING = "SHIPPING",
}

interface IAddCustomerFormTabsProps {
	filter: AddCustomerFormFilters;
	onFilterChange?(filter: AddCustomerFormFilters): void;
}

const AddCustomerFormTabs: React.FC<IAddCustomerFormTabsProps> = (props: IAddCustomerFormTabsProps) => {

	const {filter, onFilterChange} = props;

	function filterChangeHelper(newFilter: AddCustomerFormFilters): () => void {
		return () => {
			onFilterChange(newFilter);
		}
	}

	return (
		<div>
			<Nav tabs className="w-100 border-bottom-0 tab-table-fix tab-scroller">
				<NavItem>
					<NavLink
						className={classnames({active: filter === AddCustomerFormFilters.CONTACT}, "cursor-pointer")}
						onClick={filterChangeHelper(AddCustomerFormFilters.CONTACT)}
					>
						Contact
					</NavLink>
				</NavItem>
				<NavItem style={{minWidth: "160px"}}>
					<NavLink
						className={classnames({active: filter === AddCustomerFormFilters.BILLING}, "cursor-pointer")}
						onClick={filterChangeHelper(AddCustomerFormFilters.BILLING)}
					>
						Billing Address
					</NavLink>
				</NavItem>
				<NavItem style={{minWidth: "180px"}}>
					<NavLink
						className={classnames({active: filter === AddCustomerFormFilters.SHIPPING}, "cursor-pointer")}
						onClick={filterChangeHelper(AddCustomerFormFilters.SHIPPING)}
					>
						Shipping Address
					</NavLink>
				</NavItem>
			</Nav>
		</div>
	);
};

export default AddCustomerFormTabs;
