import {initialAdapter} from "adapter";

export interface IAccessTypes {
	buyer: boolean;
	seller: boolean;
}

export interface IMetaStore {
	token?: initialAdapter.IAuthInfo;
	tokenV2?: initialAdapter.IAuthInfo;
	loadingCount: number;
	error?: string;
	logoURL?: string;
	accessTypeBuyer: boolean,
	accessTypeSeller: boolean,
	accessTypeInvestor: boolean,
	accessTypeAccount: boolean;
	accessTypeSelfReg: boolean;
	accessTypeInviteSupplier: boolean;
	userType?: string;
	displayName?: string;
}

export default {
	loadingCount: 0,
	accessTypeBuyer: false,
	accessTypeSeller: false,
	accessTypeInvestor: false,
	accessTypeAccount: false,
	accessTypeSelfReg: false,
	accessTypeInviteSupplier: false,
	displayName: "",
};
