import React from "react";
import {formatMoney} from "../../utils/formatters";
import {Card} from "reactstrap";

interface IProps {
	title: string;
	value: number;
	className?: string;
	showCurrency?: boolean;
}

const AccountOverviewValueDisplay: React.FC<IProps> = (props: IProps) => {

	return (
		<Card className={props.className}>
			<div className="py-2 px-3">
				<h5 className="text-hBlue font-weight-semi-bold">{props.title}</h5>
				<span className="text-hPurple number-display d-flex justify-content-end align-items-end flex-wrap">
					<h4 className="font-weight-normal d-inline mb-0" style={{fontSize: "1.45rem"}}>{`${formatMoney(props.value)} `}</h4>
					{props.showCurrency && (
						<span className="font-weight-light ml-1" style={{fontSize: "0.85rem"}}>USD</span>
					)}
				</span>
			</div>
		</Card>
	);
};

AccountOverviewValueDisplay.defaultProps = {
	className: "mb-4",
	showCurrency: true,
};

export default AccountOverviewValueDisplay;
