import React, {ReactNode, useEffect, useState} from "react";
import {Button, Modal, ModalHeader} from "reactstrap";
import "./UploadLogo.scss";
import {FiUploadCloud} from "react-icons/fi";
import {decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import DocumentListItemWithIcon from "./DocumentListItemWithIcon";
import cloneDeep from "lodash.clonedeep";
import {initialAdapter} from "adapter";
import {getFileType} from "../utils/files";

interface IUploadPOModalProps {
	tokenV2?: any;
	dispatch?: any;
	isOpen: boolean;

	closeModal(): void;

	onDone(): void;
}

const UploadPOModal: React.FC<IUploadPOModalProps> = (props: IUploadPOModalProps) => {

	const {isOpen, closeModal, onDone} = props;

	const [files, setFiles] = useState([]);
	const [urls, setURLS] = useState([]);
	const [showDoneSubmit, setShowDoneSubmit] = useState(false);

	/**
	 * Reset form to default and close modal(s)
	 *
	 */
	function resetAndClose(): void {
		setFiles([]);
		setURLS([]);
		setShowDoneSubmit(false);
		closeModal();
	}

	/**
	 * Called by the success modal
	 *
	 */
	function done(): void {
		resetAndClose();
		onDone();
	}

	/**
	 * Iterate over the chosen files, call readPOUploadURL to generate a key for each one,
	 * pass the keys as an array to requestPOMapping, and show the success modal
	 * without waiting for response from requestPOMapping.
	 *
	 * Chose not to handle errors on frontend here, because the second api will email
	 * the user the important information. It may have utility to check during iteration of the
	 * first api, however, showing errors wouldn't make sense because the user would have no way to
	 * respond to 210 errors, and 400/500s might make it look like the whole thing has failed, instead
	 * of just 1/x calls of "readPOUploadURL". Could be changed in the future if required.
	 *
	 */
	async function uploadPOs(): Promise<void> {
		props.dispatch(incrementLoading());

		const promises = files.map(async (file: File) => {
			const res = await initialAdapter.api.readPOUploadURL(
				{fileName: file.name, fileType: getFileType(file)},
				props.tokenV2,
			);

			if (res.success) {
				const uploadRes = await initialAdapter.api.uploadToSignedURL(res.data.signedURL, file);

				if (uploadRes.success) {
					return res.data.keyURL;
				}
			}
		});

		const listOfKeyURL: string[] = (await Promise.all(promises)).filter(f => typeof f === "string");

		props.dispatch(decrementLoading());
		initialAdapter.api.requestPOMapping({listOfKeyURL}, props.tokenV2).then().catch();

		setShowDoneSubmit(true);
	}

	/**
	 * Handle choosing files from the file input.
	 * Make sure to limit the total amount to 10 (compare current arr vs. new selections).
	 * Generate url for each one so it can be viewed in real time.
	 *
	 * @param e
	 */
	async function onFileChange(e: any): Promise<void> {
		const pArr: Array<Promise<string>> = [];

		const fileKeys: string[] = Object.keys(e.target.files);
		const newFiles: Array<File> = [];

		const combinedLength: number = files.length + fileKeys.length;
		let maxAmount: number = combinedLength < 10 ? fileKeys.length : 10 - files.length;
		if (maxAmount < 1) {
			return;
		}

		props.dispatch(incrementLoading());
		let i: number;
		for (i = 0; i < maxAmount; i++) {
			const _i = i;
			const p = new Promise((r: (str: string) => void) => {

				const fr = new FileReader();
				fr.onload = () => {
					r(fr.result as string);
				};

				fr.readAsDataURL(e.target.files[_i]);
			});

			newFiles.push(e.target.files[_i]);
			pArr.push(p);
		}

		const filesToSet: Array<File> = files.concat(newFiles);
		setFiles(filesToSet);

		const promiseURLS = await Promise.all(pArr);
		const urlsToSet: string[] = urls.concat(promiseURLS);
		setURLS(urlsToSet);

		props.dispatch(decrementLoading());
	}

	/**
	 * Splice the files & urls states at the selected index
	 * (when clicking "x" on one of the uploaded files).
	 *
	 * @param index
	 */
	function removeOne(index: number): void {
		const newFiles: Array<File> = cloneDeep(files);
		const newURLs: string[] = cloneDeep(urls);
		newFiles.splice(index, 1);
		newURLs.splice(index, 1);

		setFiles(newFiles);
		setURLS(newURLs);
	}

	/**
	 * Iterate over the files and create a ui component for each one.
	 *
	 * @param _files
	 * @param _urls
	 */
	function mapFiles(_files: Array<File> = [], _urls: string[] = []): ReactNode {
		return _files.map((f: File, i: number) => {

			function removeHelper(): void {
				removeOne(i);
			}

			return (
				<div className="my-1" key={`mapped-file-${i}`}>
					<DocumentListItemWithIcon
						name={f.name}
						url={urls[i]}
						onDelete={removeHelper}
					/>
				</div>
			);
		});
	}

	return (
		<React.Fragment>
			<Modal
				isOpen={showDoneSubmit}
				fade={true}
				centered={true}
				contentClassName="p-2"
				className="modal-max-500"
			>
				<div className="px-3">
					<ModalHeader toggle={done} className="mb-3"><h4 className="text-success">Purchase Order uploaded</h4></ModalHeader>

					<div className="p-3">
						Your purchase order(s) have been uploaded and will be made available for editing shortly. An email notification will be sent to you shortly.
					</div>

					<div className="d-flex justify-content-end">
						<Button color="success" onClick={done}>
							Done
						</Button>
					</div>
				</div>
			</Modal>

			<Modal
				isOpen={isOpen}
				fade={true}
				centered={true}
				contentClassName="p-2"
				toggle={resetAndClose}
			>
				<div className="px-3">
					<ModalHeader toggle={resetAndClose} className="mb-3"><h4>Upload Purchase Order(s) (up to 10)</h4>
					</ModalHeader>

					<div className="upload-btn-wrapper my-3 cursor-pointer">
						<div
							className="position-relative overflow-hidden w-100 d-inline-flex flex-column align-items-center upload-btn-wrapper-inner p-3">
							<FiUploadCloud size="4em" color="#5bc0de"/>
							<input
								type="file"
								name="myfile"
								className="cursor-pointer"
								onChange={onFileChange}
								multiple={true}
							/>
							<span className="mt-2 text-center">
								Browse or drop POs here
							</span>
						</div>
					</div>
				</div>

				<div className="pt-3 pb-0">
					<h5 className="text-center">
						{`${files.length}/10`}
					</h5>
				</div>

				{files.length > 0 && (
					<div className="p-3">
						{mapFiles(files, urls)}
					</div>
				)}

				<div className="d-flex justify-content-between">
					<Button color="link" onClick={resetAndClose}>
						Cancel
					</Button>

					<Button color="link" disabled={files.length < 1} onClick={uploadPOs}>
						Upload PO(s)
					</Button>
				</div>
			</Modal>
		</React.Fragment>
	)
};

export default connect((store: IStore, props: IUploadPOModalProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
		...props,
	}
})(UploadPOModal);
