import * as React from "react";
import {useState} from "react";
import {Button, Card, CardBody, Container, Input, Label, Modal, ModalFooter} from "reactstrap";
import {app} from "../index";
import DashboardBackgroundCard from "../components/dashboard/DashboardBackgroundCard";
import {Link} from "react-router-dom";
import HTCModalHeaderCloseButton from "../components/HTCModalHeaderCloseButton";
import SupplierSelfRegistrationForm from "../components/SupplierSelfRegistrationForm";
import {decrementLoading, handleError, incrementLoading} from "../redux/meta/MetaActions";
import {initialAdapter} from "adapter";
import ErrorList from "../components/ErrorList";
import {connect} from "react-redux";

interface IProps {
	dispatch?: any;
}

const ResetPassword: React.FC<IProps> = (props: IProps) => {

	const [loginID, updateLoginID] = useState();
	const [showModal, updateShowModal] = useState(false);
	const [errors, setErrors] = useState([]);

	// update value of email
	function onLoginIDChange(event: any): void {
		updateLoginID(event.target.value);
	}

	// called when user clicks the forgot password button
	async function sendResetPasswordRequest(): Promise<void> {
		setErrors([]);
		props.dispatch(incrementLoading());

		const res = await initialAdapter.api.resetUserPassword({email: loginID, apiKey: process.env.REACT_APP_RESET_PASSWORD_API_KEY_1}, undefined);

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		props.dispatch(decrementLoading());
		toggleModal()
	}

	function toggleModal(): void {
		updateShowModal(!showModal);
	}

	return (
		<React.Fragment>

			<Modal
				isOpen={showModal}
				fade={true}
				centered={true}
				contentClassName=""
				className="htc-modal modal-max-400"
			>
				<HTCModalHeaderCloseButton onClick={toggleModal}/>
				<DashboardBackgroundCard background="bg-hBlue">
					<CardBody>
						<h2 className="text-white text-center mb-3">Password Reset Request</h2>
						<Card>
							<CardBody>
								<p className="text-center">
									An email has been sent to you with a link to reset your password.
								</p>

								<div className="mt-4 mx-5">
									<Button color="hBlue" size="sm" className="w-100" onClick={toggleModal}>
										Dismiss
									</Button>
								</div>
							</CardBody>
						</Card>
					</CardBody>
				</DashboardBackgroundCard>
			</Modal>

			<Container className="py-5">
				<img
					src={process.env.PUBLIC_URL + "/logos/logo-only.png"}
					alt="Harbour Trade Credit Logo"
					className="header-logo"
				/>
			</Container>

			<Container className="d-flex justify-content-center" style={{marginTop: "10vh"}}>
				<div style={{maxWidth: 480}} className="w-100">
					<DashboardBackgroundCard background="bg-hBlue">
						<CardBody>
							<h1 className="text-white mb-4">Password Recovery</h1>

							<Card>
								<CardBody>
									<Label>Login ID</Label>
									<Input placeholder="Login ID..." value={loginID} onChange={onLoginIDChange}/>

									{errors.length > 0 && (
										<div className="my-3">
											<ErrorList errors={errors}/>
										</div>
									)}

									<div className="mt-4 mb-3 mx-1 mx-3 mx-md-5">
										<Button color="hBlue" size="sm" className="w-100" onClick={sendResetPasswordRequest}>
											Reset Password
										</Button>
									</div>

									<div className="mx-1 mx-3 mx-md-5 d-flex justify-content-center">
										<Link to="/">
											<Button color="link" className="text-hPurple link-button">
												Back to Login
											</Button>
										</Link>
									</div>
								</CardBody>
							</Card>
						</CardBody>
					</DashboardBackgroundCard>
				</div>
			</Container>

		</React.Fragment>
	);
};

export default connect()(ResetPassword);
