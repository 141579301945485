import React, {ReactNode, useEffect, useRef, useState} from "react";
import {Button} from "reactstrap";
import {FiMenu} from "react-icons/all";
import {useClickOffDetector} from "../utils/useClickOffDetector";

export interface ITableMenuButtonListItem {
	label: string;
	callback(): void;
}

interface IProps {
	buttons: Array<ITableMenuButtonListItem>;
}

const TableHamburgerIconButton: React.FC<IProps> = (props: IProps) => {

	const [showMenu, setShowMenu] = useState(false);
	const wrapperRef = useRef(null);
	useClickOffDetector(wrapperRef, handleOffClick);

	function toggleShowMenu(): void {
		setShowMenu(!showMenu);
	}

	function handleOffClick(): void {
		setShowMenu(false);
	}

	function mapButtons(_buttons: Array<ITableMenuButtonListItem> = []): ReactNode {
		return _buttons.map((_button: ITableMenuButtonListItem, i: number) => {

			if (!_button) {
				return null;
			}

			function clickHelper(): void {
				setShowMenu(false);
				_button.callback();
			}

			return (
				<Button color="link" className="text-hPurple mr-0" onClick={clickHelper} key={`table-menu-list-item-${i}`}>
					{_button.label}
				</Button>
			);
		});
	}

	return (
		<div ref={wrapperRef}>
			<Button color="hBlue" onClick={toggleShowMenu}>
				<FiMenu size="1.5rem"/>
			</Button>

			{showMenu && (
				<div className="position-relative">
					<div className="table-button-menu-item-list">
						<div className="menu-list-item-arrow-base menu-item-list-arrow-a"/>

						<div className="menu-list-item-arrow-base menu-item-list-arrow-b"/>

						<div className="table-menu-list-content">
							{mapButtons(props.buttons)}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default TableHamburgerIconButton;
