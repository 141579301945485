import React from "react";

interface IProps {
	icon: any;
	iconProps?: object;
	title: string;
}

const CardIconHeader: React.FC<IProps> = (props: IProps) => {

	const {icon, iconProps, title} = props;

	return (
		<div>
			<div className="d-flex justify-content-center mb-2">
				{React.cloneElement(icon, {...defaultIconProps, ...iconProps})}
			</div>
			<h2 className="text-hBlue text-center mb-3">{title}</h2>
		</div>
	);
};

const defaultIconProps = {
	size: "2.25rem",
	className: "text-hPurple",
};

export default CardIconHeader;
