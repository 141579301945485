import * as React from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {CircularProgress, Dialog} from "@material-ui/core";

interface ILoaderProps {
	show?: boolean;
}

const Loader: React.FC<ILoaderProps> = (props: ILoaderProps) => {

	return (
		<Dialog
			open={props.show === true}
			PaperProps={{className: "bg-transparent shadow-none", style: {overflow: "visible"}}}
		>
			<CircularProgress className="text-white"/>
		</Dialog>
	);
};

export default connect((store: IStore, props: ILoaderProps) => {
	return {
		show: store.metaStore.loadingCount > 0,
		...props,
	};
})(Loader);
