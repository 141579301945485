import React from "react";

interface IProps {
	name: string;
	message: string;
}

const WelcomeMessage: React.FC<IProps> = (props: IProps) => {

	const {name, message} = props;

	return (
		<div>
			<h1 className="text-hBlue font-weight-semi-bold">{`Hello, ${name}.`}</h1>
			<p className="dashboard-last-log-in-welcome-message" style={{fontSize: "0.9rem"}}>{message}</p>
		</div>
	);
};

export default WelcomeMessage;
