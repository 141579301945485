import {Column} from "react-bootstrap-table-next";

function styleHeaders(columns: Column[]): Column[] {
	columns = columns.map(c => {
		if (!c.headerStyle) {
			// c.headerStyle = {whiteSpace: "noWrap", paddingRight: 30}
		}
		return c;
	});
	return columns
}

export default styleHeaders;