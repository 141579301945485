import React, {ReactNode} from "react";
import {FiX, FiXCircle} from "react-icons/all";
import downloadIFrameGenerator from "../utils/downloadIFrameGenerator";

interface IDocumentListItemWithIconProps {
	url?: string;
	name: string;
	left?: boolean;
	onDelete(e: React.MouseEvent<HTMLAnchorElement>): void;
}

const DocumentListItemWithIcon: React.FC<IDocumentListItemWithIconProps> = (props: IDocumentListItemWithIconProps) => {

	const {url, name, left, onDelete} = props;

	function debugBase64(e){
		e.preventDefault();
		const win = window.open();
		win.document.open();
		win.document.write(downloadIFrameGenerator(name, url));
		win.document.close();
	}

	const deleteIcon: ReactNode = (
		<a href="#" className={"text-hPurple " + (left ? "mr-2" : "ml-2")} onClick={onDelete} style={{marginTop: -1}}>
			<img
				src={process.env.PUBLIC_URL + "/svg_icons/small_x_button.svg"}
				className="document-list-item-delete-icon-svg"
			/>
		</a>
	);

	return (
		<div className="d-flex">
			{left && (
				<React.Fragment>
					{deleteIcon}
				</React.Fragment>
			)}

			{url ? (
				<a onClick={debugBase64} href="#" target="_blank" className="text-break document-viewer-link">
					{name}
				</a>
			) : (
				<p className="text-break document-viewer-link">
					{name}
				</p>
			)}

			{!left && (
				<React.Fragment>
					{deleteIcon}
				</React.Fragment>
			)}
		</div>
	)
};

DocumentListItemWithIcon.defaultProps = {
	left: true,
};

export default DocumentListItemWithIcon;
