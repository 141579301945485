
import React, {ReactNode} from "react";

interface IProps {
	announcements: string[];
}

const LoginAnnouncementsDesktop: React.FC<IProps> = (props: IProps) => {

	if (!props.announcements || props.announcements.length < 1) {
		return null;
	}

	function createAnnouncements(_announcements: string[] = []): ReactNode {
		return _announcements.map((_announcement: string, i: number) => {
			return (
				<p className="text-hPurple text-center mb-3">
					{_announcement}
				</p>
			);
		})
	}

	return (
		<div className="">
			{createAnnouncements(props.announcements)}
		</div>
	)
};

export default LoginAnnouncementsDesktop;
