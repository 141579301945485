import React from "react";
import {CardBody} from "reactstrap";
import DashboardBackgroundCard from "./dashboard/DashboardBackgroundCard";
import AccountOverviewNormalTextDisplay from "./AccountOverviewNormalTextDisplay";

interface IProps {
	supplier: string;
	address: string;
	name: string;
	email: string;
	phone: string;
}

const SupplierInfoCard2: React.FC<IProps> = (props: IProps) => {

	const {supplier, address, name, email, phone} = props;

	const contactString: string = (name || email || phone) ? name + "\n" + email + "\n" + phone : "";

	return (
		<DashboardBackgroundCard background="bg-hBlue">
			<CardBody>
				<h2 className="text-white text-center mb-3">Supplier Info</h2>

				<AccountOverviewNormalTextDisplay title="Supplier" value={supplier ? supplier : "-"}/>
				<AccountOverviewNormalTextDisplay title="Address" value={address ? address : "-"}/>
				<AccountOverviewNormalTextDisplay title="Contact Info" value={contactString ? contactString : "-"} className="mb-0"/>
			</CardBody>
		</DashboardBackgroundCard>
	);
};

export default SupplierInfoCard2;
