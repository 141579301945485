export function findJsonIndexInArray(array, key, value): number {
	return array.findIndex((property) => property[key] === value);
}

export function legacyIncludes(arr: string[], search: string, startIndex: number = 0): boolean {
	let i: number;
	for (i = startIndex; i < arr.length; i++) {
		if (arr[i] === search) {
			return true;
		}
	}

	return false;
}
