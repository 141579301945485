import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {
	Button,
	ButtonGroup,
	CardBody,
	CardHeader,
	Col,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row
} from "reactstrap";
import {decrementLoading, handleError, incrementLoading} from "../redux/meta/MetaActions";
import {initialAdapter} from "adapter";
import {findJsonIndexInArray} from "../utils/arrays";
import ErrorList from "./ErrorList";
import moment from "moment";
import HTCModalHeaderCloseButton from "./HTCModalHeaderCloseButton";
import find from "lodash.find";
import {formatMoney} from "../utils/formatters";

interface IProps {
	tokenV2?: any;
	dispatch?: any;
	isOpen: boolean;
	keyURL: string;
	supplierName: string;

	onClose(): void;

	onDone(): void;
}

interface ISetupTradeLinkForm {
	currency: string;
	incoterm: string;
	supplierPaymentTerm: Partial<initialAdapter.IPaymentTermItemMsg>;
	tradingSince: string;
	harborPaymentTerm: Partial<initialAdapter.IPaymentTermItemMsg>;
}

const defaultForm: ISetupTradeLinkForm = {
	currency: "",
	incoterm: "",
	supplierPaymentTerm: {},
	tradingSince: "",
	harborPaymentTerm: {},
};

const BuyerSetupTradeLinkModal: React.FC<IProps> = (props: IProps) => {

	const {isOpen, keyURL, supplierName} = props;
	const [errors, setErrors] = useState<string[]>([]);
	const [initData, setInitData] = useState<initialAdapter.ICompanyTradeLinkInitDataMsg>();
	const [form, setForm] = useState<ISetupTradeLinkForm>(defaultForm);
	const [termCosts, setTermCosts] = useState<initialAdapter.ITradeLinkTermsCostListMsg>();

	useEffect(() => {
		if (isOpen) {
			getInitData().then().catch();
		}
	}, [isOpen]);

	/**
	 * call api for init data, save it & set form with appropriate values
	 *
	 */
	async function getInitData(): Promise<void> {
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.readCompanySupplierTLInitData({keyURL}, props.tokenV2);

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		setInitData(res.data);

		const supplierPaymentIndex: number = findJsonIndexInArray(res.data.htcCOGSPymtTermList, "term", parseInt(res.data.currentSupplierPaymentTerm));
		const htcBuyerPaymentIndex: number = findJsonIndexInArray(res.data.htcBuyerPymtTermList, "term", parseInt(res.data.currentHarborPaymentTerm));

		setForm({
			currency: res.data.currentCurrency,
			incoterm: res.data.currentIncoterm,
			supplierPaymentTerm: supplierPaymentIndex > -1 ? res.data.htcCOGSPymtTermList[supplierPaymentIndex] : {},
			tradingSince: res.data.tradingSince.length > 0 ? res.data.tradingSince : moment().get("year").toString(),
			harborPaymentTerm: htcBuyerPaymentIndex > -1 ? res.data.htcBuyerPymtTermList[htcBuyerPaymentIndex] : {},
		});

		const termsRes = await initialAdapter.api.readTermCostList(undefined, props.tokenV2);

		if (!termsRes.success) {
			setErrors(handleError(termsRes));
			return;
		}

		setTermCosts(termsRes.data);


		props.dispatch(decrementLoading());
	}

	/**
	 * onChange for the custom trading since input
	 *
	 * @param change
	 */
	function onYearsChange(change: number): () => void {
		return () => {
			let years: number = parseInt(form.tradingSince) + change;
			if (years < 1800) {
				years = 1800;
			}

			const currentYear: number = moment().get("year");
			if (years > currentYear) {
				years = currentYear;
			}

			setForm({
				...form,
				tradingSince: years.toString(),
			});
		}
	}

	/**
	 * map the currency options & onChange handling
	 *
	 * @param currencies
	 */
	function makeCurrencies(currencies: string[]): ReactNode {
		return currencies.map((currency: string, i: number) => {

			function onSelectCurrency(): void {
				setForm({
					...form,
					currency: currency,
				});
			}

			return (
				<Col xs={6} sm={4} className="mb-3">
					<Button
						key={`currency-button_${i}`}
						color={form.currency === currency ? "hPurple" : "hBlue"}
						onClick={onSelectCurrency}
						className="w-100 font-weight-bold"
						size="sm"
					>
						{currency}
					</Button>
				</Col>
			);
		});
	}

	/**
	 * map the incoterm options & onChange handling
	 *
	 * @param terms
	 */
	function makeIncoterms(terms: string[]): ReactNode {
		return terms.map((term: string, i: number) => {

			function onSelectIncoTerm(): void {
				setForm({
					...form,
					incoterm: term,
				});
			}

			return (
				<Col xs={6} sm={4} className="mb-3">
					<Button
						key={`incoterm-button_${i}`}
						color={form.incoterm === term ? "hPurple" : "hBlue"}
						onClick={onSelectIncoTerm}
						className="w-100 font-weight-bold"
						size="sm"
					>
						{term}
					</Button>
				</Col>
			);
		});
	}

	/**
	 * map the htc buyer payment term options & onChange handling
	 *
	 * @param terms
	 */
	function makeHTCBuyerPaymentTerms(terms: Array<initialAdapter.IPaymentTermItemMsg>): ReactNode {
		return terms.map((term: initialAdapter.IPaymentTermItemMsg, i: number) => {

			function onSelectBuyerPaymentTerm(): void {
				setForm({
					...form,
					harborPaymentTerm: term,
				});
			}

			return (
				<Col xs={6} sm={4} className="mb-3">
					<Button
						key={`buyer-payment-term-button_${i}`}
						color={form.harborPaymentTerm.term === term.term ? "hPurple" : "hBlue"}
						onClick={onSelectBuyerPaymentTerm}
						className="w-100 font-weight-bold text-capitalize number-display"
						size="sm"
					>
						{term.termLabel}
					</Button>
				</Col>
			);
		});
	}

	/**
	 * map the supplier payment term options & onChange handling
	 *
	 * @param terms
	 */
	function makeSupplierPaymentTerms(terms: Array<initialAdapter.IPaymentTermItemMsg>): ReactNode {
		return terms.map((term: initialAdapter.IPaymentTermItemMsg, i: number) => {

			function onSelectSupplierPaymentTerm(): void {
				setForm({
					...form,
					supplierPaymentTerm: term,
				});
			}

			return (
				<Col xs={6} sm={4} className="mb-3">
					<Button
						key={`supplier-payment-term-button_${i}`}
						color={form.supplierPaymentTerm.term === term.term ? "hPurple" : "hBlue"}
						onClick={onSelectSupplierPaymentTerm}
						className="w-100 font-weight-bold text-capitalize number-display"
						size="sm"
					>
						{term.termLabel}
					</Button>
				</Col>
			);
		});
	}

	/**
	 * submit changes
	 *
	 */
	async function submitTradeLink(): Promise<void> {
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.updateBuyerSupplierTradeLink({
			supplierKeyURL: props.keyURL,
			tradingSince: form.tradingSince,
			newTradeCurrency: form.currency,
			newIncoterm: form.incoterm,
			newSupplierPaymentTerm: (form.supplierPaymentTerm && form.supplierPaymentTerm.term !== undefined) ? form.supplierPaymentTerm.term.toString() : "",
			newHarborPaymentTerm: (form.harborPaymentTerm && form.harborPaymentTerm.term !== undefined) ? form.harborPaymentTerm.term.toString() : "",
		}, props.tokenV2);

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		props.dispatch(decrementLoading());
		props.onDone();
	}

	/**
	 * factory for the display at the top of the modal when the TradeLink already exists
	 *
	 * @param label
	 * @param value
	 * @param valueClasses
	 */
	function makeLabelValuePair(label: string, value: string, valueClasses: string = ""): ReactNode {
		return (
			<span className="d-block">
				<span className="h6 text-secondary mb-0">
					{`${label}: `}
				</span>
				<span className={`h5 text-dark mb-1 ${valueClasses}`}>
					{value}
				</span>
			</span>
		)
	}

	/**
	 * determine if the save button should be enabled
	 *
	 */
	function canSave(): boolean {
		if (
			!form.currency ||
			!form.incoterm ||
			Object.keys(form.harborPaymentTerm).length < 1 ||
			Object.keys(form.supplierPaymentTerm).length < 1
		) {
			return false;
		}

		return true;
	}

	function getHowItWorksMessage(): ReactNode {
		if (
			form.incoterm &&
			Object.keys(form.harborPaymentTerm).length > 0 &&
			Object.keys(form.supplierPaymentTerm).length > 0
		) {
			return (
				<div>
	                <Label>How it Works:</Label>
					<p>
						When issuing a Purchase Order on the system, the supplier will receive a Purchase Order with <span className="font-weight-bold text-hPurple text-capitalize number-display">{form.supplierPaymentTerm.termLabel}</span> Payment terms. The supplier will deliver the goods on a <span className="font-weight-bold text-hPurple text-capitalize">{form.incoterm}</span> basis. Once the supplier has fulfilled the order in accordance with its terms of delivery, Harbor will invoice you for <span className="font-weight-bold text-hPurple text-capitalize number-display">{form.harborPaymentTerm.termLabel}</span>.
					</p>
				</div>
			);
		} else {
			return (
				<div>
					<p>
						Please select the settings on the left to start.
					</p>
				</div>
			);
		}
	}

	function getEstimatedBuyerCost(): ReactNode {

		if (
			Object.keys(form.harborPaymentTerm).length > 0 &&
			Object.keys(form.supplierPaymentTerm).length > 0 &&
			termCosts &&
			Object.keys(termCosts).length > 0
		) {
			const baseCost: number = 10000;
			const cogs: initialAdapter.ISupplierTermListMsg = find(termCosts.termCostList, {supplierTerm: form.supplierPaymentTerm.term.toString()});
			const foundHarborTerm: initialAdapter.ITermsCostItemMsg = find(cogs.harborList, {harborTerm: form.harborPaymentTerm.term.toString()});
			const estimatedCost: number = parseFloat(foundHarborTerm ? foundHarborTerm.cost : "0") - baseCost;
			const termExtension: number = form.harborPaymentTerm.term - form.supplierPaymentTerm.term;

			return (
				<div>
					<Label>Estimated Buyer Cost:</Label>
					<p>Assuming cost of goods is <span className="font-weight-bold text-hPurple text-capitalize number-display">{formatMoney(baseCost)}</span> with a payment term extension of <span className="font-weight-bold text-hPurple text-capitalize number-display">{termExtension}</span> days. The estimated cost will be <span className="font-weight-bold text-hPurple text-capitalize number-display">{foundHarborTerm ? formatMoney(estimatedCost) : "N/A"}</span> so Harbor will invoice for <span className="font-weight-bold text-hPurple text-capitalize number-display">{foundHarborTerm ? formatMoney(foundHarborTerm.cost) : "N/A"}</span>.</p>
				</div>
			)
		} else {
			return (
				<div>
					<p>
						Please select the settings on the left to start.
					</p>
				</div>
			);
		}
	}

	return (
		<Modal
			isOpen={isOpen}
			fade={true}
			centered={true}
			className="htc-modal modal-max-900"
		>
			<HTCModalHeaderCloseButton onClick={props.onClose}/>
			<CardHeader>{`TradeLink with ${supplierName}`}</CardHeader>
			<CardBody>
				{initData && (
					<React.Fragment>
						<Row>
							<Col xs={12} md={6} className="mb-3 md-md-0">
								<Label>Tell us how you want to trade with this supplier</Label>
								<p className="font-weight-light mb-2">What year did you start trading with this supplier?</p>

								<div className="d-flex">
									<div className="w-17-5 pr-2">
										<Button color="hBlue" size="sm" className="w-100 number-display font-weight-bold" onClick={onYearsChange(-5)}>-5</Button>
									</div>

									<div className="w-17-5 pl-1 pr-1">
										<Button color="hBlue" size="sm" className="w-100 number-display font-weight-bold" onClick={onYearsChange(-1)}>-1</Button>
									</div>

									<div className="w-30">
										<h3 className="mb-0 text-center text-hPurple font-weight-bold number-display">
											{form.tradingSince}
										</h3>
									</div>

									<div className="w-17-5 pl-1 pr-1">
										<Button color="hBlue" size="sm" className="w-100 number-display font-weight-bold" onClick={onYearsChange(1)}>+1</Button>
									</div>

									<div className="w-17-5 pl-2">
										<Button color="hBlue" size="sm" className="w-100 number-display font-weight-bold" onClick={onYearsChange(5)}>+5</Button>
									</div>
								</div>
							</Col>

							<Col xs={12} md={{size: 5, offset: 1}}>
								{initData.tradelinkExisted && (
									<React.Fragment>
										<p className="mb-1"><span className="font-weight-light">Trading Since:{" "}</span><span className="number-display">{initData.tradingSince}</span></p>
										<p className="mb-1"><span className="font-weight-light">Trading Currency:{" "}</span><span>{initData.currentCurrency}</span></p>
										<p className="mb-1"><span className="font-weight-light">INCOTERM:{" "}</span><span>{initData.currentIncoterm}</span></p>
										<p className="mb-1"><span className="font-weight-light">Harbor Buyer Payment Term:{" "}</span><span className="number-display text-capitalize">{initData.htcBuyerPymtTermList[findJsonIndexInArray(initData.htcBuyerPymtTermList, "term", parseInt(initData.currentHarborPaymentTerm))].termLabel}</span></p>
										<p className="mb-1"><span className="font-weight-light">Supplier Payment Term:{" "}</span><span className="number-display text-capitalize">{initData.htcCOGSPymtTermList[findJsonIndexInArray(initData.htcCOGSPymtTermList, "term", parseInt(initData.currentSupplierPaymentTerm))].termLabel}</span></p>
									</React.Fragment>
								)}
							</Col>
						</Row>

						<hr/>

						<Row>
							<Col xs={12} md={7} className="mb-3 md-md-0">
								<div>
									<p className="font-weight-light mb-2">What Currency?</p>
									<Row>
										{makeCurrencies(initData.htcTradeCurrency)}
									</Row>
								</div>

								<div>
									<p className="font-weight-light mb-2">What is your INCOTERM?</p>
									<Row>
										{makeIncoterms(initData.htcPOIncoTermList)}
									</Row>
								</div>

								<div>
									<p className="font-weight-light mb-2">With Harbor, what would you like your payment term to look like?</p>
									<Row>
										{makeHTCBuyerPaymentTerms(initData.htcBuyerPymtTermList)}
									</Row>
								</div>
							</Col>

							<Col xs={12} md={5}>
								<div className="d-none d-md-block">
									{getHowItWorksMessage()}
								</div>
							</Col>
						</Row>

						<Row>
							<Col xs={12} md={7} className="mb-3 md-md-0">
								<div>
									<p className="font-weight-light mb-2">When do you want Harbor to pay the supplier?</p>
									<Row>
										{makeSupplierPaymentTerms(initData.htcCOGSPymtTermList)}
									</Row>
								</div>
							</Col>

							<Col xs={12} md={5}  className="d-none d-md-block">
								{getEstimatedBuyerCost()}
							</Col>
						</Row>

						<div>
							<div className="d-md-none">
								{getHowItWorksMessage()}
							</div>

							<div className="d-md-none">
								{getEstimatedBuyerCost()}
							</div>
						</div>

						<hr/>

						{errors.length > 0 && (
							<div className="my-3">
								<ErrorList errors={errors}/>
							</div>
						)}

					</React.Fragment>
				)}

				<div className="d-flex justify-content-end">
					<Button color="hBlue" className="px-3 px-sm-5" size="sm" onClick={submitTradeLink} disabled={!canSave()}>
						Save TradeLink
					</Button>
				</div>
			</CardBody>
		</Modal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
		...props,
	}
})(BuyerSetupTradeLinkModal);
