import React, {useState} from "react";
import {Button, Card, CardBody} from "reactstrap";
import CardIconHeader from "../CardIconHeader";
import {FiUploadCloud} from "react-icons/all";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import UploadLogo from "../UploadLogo";

interface IProps {
	logoURL?: string;
}

const UploadLogoCard: React.FC<IProps> = (props: IProps) => {

	const {logoURL} = props;
	const [showLogoModal, setShowLogoModal] = useState(false);

	function toggleShowLogoModal(): void {
		setShowLogoModal(!showLogoModal);
	}

	return (
		<React.Fragment>
			<UploadLogo
				isOpen={showLogoModal}
				closeModal={toggleShowLogoModal}
			/>

			<Card className="dashboard-card">
				<CardBody>
					<CardIconHeader icon={<FiUploadCloud/>} title="Upload Logo"/>

					{logoURL ? (
						<p>Current logo - upload an image to change it.</p>
					) : (
						<p>You have not uploaded a company logo yet.</p>
					)}

					{logoURL && (
						<Card>
							<CardBody className="d-flex justify-content-center">
								<img
									src={logoURL}
									style={{maxHeight: 80, maxWidth: "100%", objectFit: "contain"}}
								/>
							</CardBody>
						</Card>
					)}

					<div className="mx-3 mt-4">
						<Button color="hBlue" block size="sm" onClick={toggleShowLogoModal}>
							Upload Your Logo
						</Button>
					</div>
				</CardBody>
			</Card>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		logoURL: store.metaStore.logoURL ? store.metaStore.logoURL : null,
		...props,
	}
})(UploadLogoCard);
