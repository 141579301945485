import React from "react";
import {Card, CardBody} from "reactstrap";
import CardIconHeader from "../CardIconHeader";
import {FiAlertCircle} from "react-icons/all";

interface IProps {
	name: string;
	message: string;
}

const DashboardCardMessage: React.FC<IProps> = (props: IProps) => {

	const {name, message} = props;

	return (
		<Card className="dashboard-card">
			<CardBody>
				<CardIconHeader icon={<FiAlertCircle/>} title={`Hi, ${name}`}/>

				<Card>
					<CardBody>
						<p className="mb-0">
							{message}
						</p>
					</CardBody>
				</Card>
			</CardBody>
		</Card>
	)
};

export default DashboardCardMessage;
