import moment from "moment";

export default function downloadIFrameGenerator(fileName: string, base64: any, downloadButtonText: string = "Download"): string {

	const timestamp: string = moment().format("YYYYMMDDHHmmss");
	const realFN: string = timestamp + ".PDF";

	return (
		`
		<head><title>${realFN}</title></head>
		<body style="margin: 0; background-color: rgb(82, 86, 89);">
			<div style=""><a download="${realFN}" href="${base64}" style="float: right; padding: 10px; padding-right: 20px; color: white; font-size: 1.15rem; text-decoration: none;" onmouseover="this.style.color='lightgray'" onmouseout="this.style.color='white'">${downloadButtonText}</a></div>
			<iframe
				src="${base64}"
				frameborder="0"
				style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;"
				allowfullscreen
			>	
			</iframe>
		</body>
		`
	);
}
