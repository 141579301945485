import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Button, Input, Modal, ModalHeader} from "reactstrap";
import {decrementLoading, handleError, incrementLoading, setError} from "../redux/meta/MetaActions";
import {initialAdapter} from "adapter";
import {findJsonIndexInArray} from "../utils/arrays";
import {generic400, generic500} from "../utils/errors";
import ErrorList from "./ErrorList";

interface IPreviewAndSubmitInvoiceModalProps {
	tokenV2?: any;
	dispatch?: any;
	isOpen: boolean;
	cogs: boolean;
	invKey: string;

	closeModal(): void;

	onConfirm(): Promise<void>;
	errorsForSubmitModal: string[];
}

const PreviewAndSubmitInvoiceModal: React.FC<IPreviewAndSubmitInvoiceModalProps> = (props: IPreviewAndSubmitInvoiceModalProps) => {

	const {tokenV2, dispatch, isOpen, cogs, invKey, closeModal, onConfirm, errorsForSubmitModal} = props;
	const [legalText, setLegalText] = useState();
	const [agree, setAgree] = useState(false);
	const [showAgreeError, setShowAgreeError] = useState(false);
	const [pdf, setPDF] = useState<string | ArrayBuffer>();
	const [errors, setErrors] = useState([]);

	useEffect(() => {
		if (errorsForSubmitModal.length > 0) {
			setErrors(errorsForSubmitModal);
		}
	}, [JSON.stringify(errorsForSubmitModal)]);

	useEffect(() => {
		if (isOpen) {
			getPDF().then().catch();
		}
	}, [isOpen]);

	async function getPDF(): Promise<void> {
		setErrors([]);
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.readInvPdf({invKeyURL: invKey}, tokenV2);

		if (!res.success) {
			props.dispatch(decrementLoading());
			setErrors(handleError(res));
			return;
		}

		// let legalRes;
		// if (cogs) {
		// 	legalRes = await initialAdapter.api.readText({requestKey: "HTC_INVOICE_ACCEPTANCE_TERM"}, tokenV2);
		// 	if (!legalRes.success) {
		// 		props.dispatch(decrementLoading());
		// 		// todo error;
		// 		return;
		// 	}
		//
		// 	const legalTextIndex: number = findJsonIndexInArray(legalRes.data.textList, "key", "HTC_INVOICE_ACCEPTANCE_TERM");
		// 	setLegalText(legalRes.data.textList[legalTextIndex].value);
		// }

		props.dispatch(decrementLoading());
		const reader = new FileReader();
		reader.readAsDataURL(res.data);
		reader.onloadend = function () {
			const base64data = reader.result;
			setPDF(base64data);
		};
	}

	function onAgreeChange(): void {
		if (!agree) {
			setShowAgreeError(false);
		}
		setAgree(!agree);
	}

	function close(): void {
		setAgree(false);
		closeModal();
	}

	async function send(): Promise<void> {
		// if (cogs && !agree) {
		// 	setShowAgreeError(true);
		// 	return
		// }

		await onConfirm();
	}

	return (
		<Modal
			isOpen={isOpen}
			fade={true}
			centered={true}
			contentClassName="p-2"
			className="modal-max-1200"
		>
			<div className="px-3">
				<ModalHeader toggle={close} className="mb-3"><h4>Invoice Preview</h4></ModalHeader>
				<p>
					To print, right-click the preview and select Print. Or, click the Print icon if you see one below.
				</p>

				{pdf && (
					<div
						className="w-100 d-flex justify-content-center"
						style={{
							position: "relative",
							paddingBottom: "50%",
							paddingTop: "35px",
							height: 0,
							overflow: "hidden",
						}}
					>
						<iframe
							src={pdf.toString()}
							style={{
								position: "absolute",
								top: 0,
								left: 0,
								width: "100%",
								height: "100%",
							}}
						/>
					</div>
				)}

				{/*{cogs && legalText && (*/}
				{/*	<div className="d-flex justify-content-center mt-4">*/}
				{/*		<p>*/}
				{/*			<Input*/}
				{/*				type="checkbox"*/}
				{/*				name="pdfAgreement"*/}
				{/*				id="pdfAgreement"*/}
				{/*				checked={agree === true}*/}
				{/*				onChange={onAgreeChange}*/}
				{/*			/>*/}
				{/*			<label htmlFor="pdfAgreement">*/}
				{/*				{legalText}*/}
				{/*			</label>*/}
				{/*			{showAgreeError && (*/}
				{/*				<p className="text-danger">You must agree first.</p>*/}
				{/*			)}*/}
				{/*		</p>*/}
				{/*	</div>*/}
				{/*)}*/}

				{errors.length > 0 && (
					<div className="my-3">
						<ErrorList errors={errors}/>
					</div>
				)}

				<div className="d-flex justify-content-between mt-3">
					<Button color="link" onClick={close}>
						Close
					</Button>

					<Button color="link" onClick={send}>
						Send
					</Button>
				</div>
			</div>
		</Modal>
	)
};

export default connect((store: IStore, props: IPreviewAndSubmitInvoiceModalProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
		...props,
	}
})(PreviewAndSubmitInvoiceModal)
