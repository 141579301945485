import React from "react";
import {Card, Col, Row} from "reactstrap";
import {formatMoney} from "../utils/formatters";

interface ISalesSummaryProps {
	overdue: any;
	comingDue: any;
	pending: any;
}

const SalesSummary: React.FC<ISalesSummaryProps> = (props: ISalesSummaryProps) => {

	const {overdue, comingDue, pending} = props;

	return (
		<Card className="p-4">
			<Row>
				<Col xs={12} sm={4} className="d-flex flex-column align-items-center mb-3 mb-md-0 text-hPurple">
					<h4>
						OverDue
					</h4>
					<span className="number-display">
						<h4 className="font-weight-normal d-inline">{`${formatMoney(overdue)} `}</h4>
						<span className="font-weight-light">USD</span>
					</span>
				</Col>

				<Col xs={12} sm={4} className="d-flex flex-column align-items-center mb-3 mb-md-0 text-hBlue">
					<h4>
						Coming Due
					</h4>
					<span className="number-display">
						<h4 className="font-weight-normal d-inline">{`${formatMoney(comingDue)} `}</h4>
						<span className="font-weight-light">USD</span>
					</span>
				</Col>

				<Col xs={12} sm={4} className="d-flex flex-column align-items-center mb-3 mb-sm-0 text-hBlue">
					<h4>
						Pending
					</h4>
					<span className="number-display">
						<h4 className="font-weight-normal d-inline">{`${formatMoney(pending)} `}</h4>
						<span className="font-weight-light">USD</span>
					</span>
				</Col>
			</Row>
		</Card>
	);
};

export default SalesSummary;
