import moment from "moment";

const formatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	minimumFractionDigits: 2,
	useGrouping: true
});

function formatMoney(cell: any): string {
	return cell !== undefined && cell !== null ? formatter.format(cell) : "-";
}

function formatDate(cell: any): string {
	return cell !== undefined && cell !== null ? (moment(cell).format('ll') !== "Invalid date" ? moment(cell).format('ll') : "-") : "-";
}

function sortMoney(a, b, order, dataField): number {
	const pA = parseInt(a);
	const pB = parseInt(b);
	if (order === 'asc') {
		return pA - pB;
	}
	return pB - pA;
}

function formatMoneyForDashboardOverview(v: any): string {
	return formatMoney(v).split(",").join(" ");
}

export {formatMoney, formatDate, sortMoney, formatMoneyForDashboardOverview}
