import React from "react";
import DashboardBackgroundCard from "./dashboard/DashboardBackgroundCard";
import {CardBody} from "reactstrap";
import AccountOverviewValueDisplay from "./dashboard/AccountOverviewValueDisplay";

interface IProps {
	creditLimit: number;
	availableCredit: number;
}

const AccountOverViewCard2: React.FC<IProps> = (props: IProps) => {

	const {creditLimit, availableCredit} = props;

	return (
		<DashboardBackgroundCard background="bg-hBlue">
			<CardBody>
				<h2 className="text-white text-center mb-3">Account Overview</h2>

				<AccountOverviewValueDisplay title="Credit Limit" value={creditLimit} showCurrency={false}/>
				<AccountOverviewValueDisplay title="Available Credit" value={availableCredit} showCurrency={false} className="mb-0"/>
			</CardBody>
		</DashboardBackgroundCard>
	);
};

export default AccountOverViewCard2;
