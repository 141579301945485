import React, {useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {initialAdapter} from "adapter";
import {Button, Modal, ModalHeader} from "reactstrap";
import {decrementLoading, handleError, incrementLoading, setError} from "../redux/meta/MetaActions";
import {generic400, generic500} from "../utils/errors";

interface IConfirmDeleteDraftPOModalProps {
	tokenV2?: any;
	dispatch?: any;
	isOpen: boolean;
	po: initialAdapter.IPurchaseSummaryItemMsg
	closeModal(): void;
	finishDelete(): Promise<void>;
}

const ConfirmDeleteDraftPOModal: React.FC<IConfirmDeleteDraftPOModalProps> = (props: IConfirmDeleteDraftPOModalProps) => {

	const {tokenV2, isOpen, po} = props;
	const [errors, setErrors] = useState<string[]>([]);

	async function confirmDelete(): Promise<void> {
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.deleteDraftPO(
			{poKeyURL: po.poKeyURL, timestamp: po.timestamp},
			tokenV2
		);

		if (!res.success) {
			props.dispatch(decrementLoading());
			setErrors(handleError(res));
			return;
		}

		props.dispatch(decrementLoading());
		await props.finishDelete();
	}

	return (
		<Modal
			isOpen={isOpen}
			fade={true}
			centered={true}
			contentClassName="p-2"
		>
			<div className="px-3">
				<ModalHeader toggle={props.closeModal} className="mb-3"><h4>Confirm Draft Deletion</h4></ModalHeader>

				<div className="px-3 my-4">
					{`Are you sure you want to delete ${po.poNumber}?`}
				</div>

				<div className="d-flex justify-content-between">
					<Button color="link" onClick={props.closeModal}>
						Cancel
					</Button>

					<Button color="danger" onClick={confirmDelete}>
						Delete
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default connect((store: IStore, props: IConfirmDeleteDraftPOModalProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
		...props,
	}
})(ConfirmDeleteDraftPOModal);
