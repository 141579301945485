import React, {useState} from "react";
import {useHistory} from "react-router";
import UploadLogo from "../UploadLogo";
import AddSupplierModal from "../AddSupplierModal";
import AddCustomerModal from "../AddCustomerModal";
import InviteSellerModal from "../InviteSellerModal";
import {Button, Card, CardBody} from "reactstrap";
import {FiClipboard} from "react-icons/all";
import CardIconHeader from "../CardIconHeader";

interface IProps {
	createPO: boolean;
	addSupplier: boolean;
	createInvoice: boolean;
	addCustomer: boolean;
	canSetupLogo: boolean;
	inviteSupplier: boolean;
}

const ThingsToDo3: React.FC<IProps> = (props: IProps) => {

	const {createPO, addSupplier, createInvoice, addCustomer, canSetupLogo, inviteSupplier} = props;

	const [showLogoModal, setShowLogoModal] = useState(false);
	const [showSupplierModal, setShowSupplierModal] = useState(false);
	const [showCustomerModal, setShowCustomerModal] = useState(false);
	const [showInviteSellerModal, setShowInviteSellerModal] = useState(false);
	const history = useHistory();

	function createPurchaseOrder(): void {
		history.push("/po/create");
	}

	function toggleSupplierModal(): void {
		setShowSupplierModal(!showSupplierModal)
	}

	function onCreateInvoice(): void {
		history.push("/invoice/create");
	}

	function toggleCustomerModal(): void {
		setShowCustomerModal(!showCustomerModal)
	}

	function toggleLogoModal(): void {
		setShowLogoModal(!showLogoModal);
	}

	function toggleInviteSellerModal(): void {
		setShowInviteSellerModal(!showInviteSellerModal);
	}

	return (
		<React.Fragment>
			<UploadLogo
				isOpen={showLogoModal}
				closeModal={toggleLogoModal}
			/>

			<AddSupplierModal
				isOpen={showSupplierModal}
				closeModal={toggleSupplierModal}
			/>

			<AddCustomerModal
				isOpen={showCustomerModal}
				closeModal={toggleCustomerModal}
			/>

			<InviteSellerModal
				isOpen={showInviteSellerModal}
				closeModal={toggleInviteSellerModal}
			/>

			<Card className="dashboard-card">
				<CardBody>
					<CardIconHeader icon={<FiClipboard/>} title="Things to Do"/>

					<div className="mx-3">
						{createPO && (
							<Button color="hBlue" block size="sm" onClick={createPurchaseOrder} className="mb-3">
								Create a Purchase Order
							</Button>
						)}

						{addSupplier && (
							<Button color="hBlue" block size="sm" onClick={toggleSupplierModal} className="mb-3">
								Add a Supplier
							</Button>
						)}

						{createInvoice && (
							<Button color="hBlue" block size="sm" onClick={onCreateInvoice} className="mb-3">
								Create an Invoice
							</Button>
						)}

						{addCustomer && (
							<Button color="hBlue" block size="sm" onClick={toggleCustomerModal} className="mb-3">
								Invite a Customer for Early Payment
							</Button>
						)}

						{canSetupLogo && (
							<Button color="hBlue" block size="sm" onClick={toggleLogoModal} className="mb-3">
								Set up Your Own Logo
							</Button>
						)}

						{inviteSupplier && (
							<Button color="hBlue" block size="sm" onClick={toggleInviteSellerModal} className="mb-3">
								Invite a Supplier
							</Button>
						)}
					</div>
				</CardBody>
			</Card>
		</React.Fragment>
	);
};

export default ThingsToDo3;
