import * as React from "react";
import {Link, NavLink, useLocation} from "react-router-dom";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {handleError, logout, setClientLogo} from "../redux/meta/MetaActions";
import {initialAdapter} from "adapter";
import {
	Button,
	Collapse,
	Container,
	Nav,
	Navbar,
	NavbarToggler,
	NavItem, Spinner,
} from "reactstrap";
// import "./Header.scss";
import {FiAlignJustify} from "react-icons/fi";
import {useEffect, useState} from "react";
import UploadLogo from "./UploadLogo";
import InviteSellerModal from "./InviteSellerModal";
import ChangePasswordModal from "./ChangePasswordModal";

interface IHeaderProps {
	tokenV2?: initialAdapter.IAuthInfo;
	accessTypeBuyer?: boolean;
	accessTypeSeller?: boolean;
	accessTypeAccount?: boolean;
	accessTypeSelfReg?: boolean;
	accessTypeInviteSupplier?: boolean;
	userType?: string;
	logoURL?: string;
	dispatch?: any;
}

const Header: React.FC<IHeaderProps> = (props: IHeaderProps) => {

	const location = useLocation();

	const {tokenV2, accessTypeBuyer, accessTypeSeller, accessTypeAccount, accessTypeSelfReg, accessTypeInviteSupplier, userType, logoURL, dispatch} = props;

	const [showLogoModal, setShowLogoModal] = useState(false);
	const [isOpen, updateIsOpen] = useState(false);
	const [logoLoading, updateLogoLoading] = useState(false);
	const [showInviteSellerModal, setShowInviteSellerModal] = useState(false);
	const [pMessage, setPMessage] = useState<initialAdapter.IPersonalizedMessageRes>();
	const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

	useEffect(() => {
		if (userType !== "investor") {
			getClientLogo().then();
		}
		// if (tokenV2) {
		// 	getPersonalizedMessage().then().catch();
		// } else {
		// 	setPMessage(null);
		// }
	}, [JSON.stringify(tokenV2 ? tokenV2 : {})]);

	/**
	 * Prevent anchor tag from activating.
	 * Dispatch the logout function, which will remove the tokenV2,
	 * and route the user to /, which is the login page.
	 *
	 * @param e
	 */
	function logoutDispatcher(e: any): void {
		if (e) {
			e.preventDefault();
		}
		if (isOpen) {
			updateIsOpen(false);
		}
		dispatch(logout());
	}

	/**
	 * Toggle the mobile menu being visible
	 *
	 */
	function toggleIsOpen(): void {
		updateIsOpen(!isOpen);
	}

	function mobileCloseHandler(): void {
		if (isOpen) {
			toggleIsOpen();
		}
	}

	async function getPersonalizedMessage(): Promise<void> {
		const res = await initialAdapter.api.readPersonalizedMessage(undefined, tokenV2);

		if (!res.success) {
			handleError(res);
			return;
		}

		setPMessage(res.data);
	}

	async function getClientLogo(): Promise<void> {
		if (!props.tokenV2) {
			updateLogoLoading(false);
			dispatch(setClientLogo(null));
			return;
		}
		updateLogoLoading(true);
		const res = await initialAdapter.api.readClientLogoURL(undefined, tokenV2);
		if (!res.success) {
			// TODO: error
			dispatch(setClientLogo(null));
			updateLogoLoading(false);
			return;
		}
		dispatch(setClientLogo(res.data.signedURL));
		updateLogoLoading(false);
	}

	function toggleLogoModal(e?): void {
		if (e) {
			e.preventDefault();
		}
		setShowLogoModal(!showLogoModal);
	}

	function toggleInviteSellerModal(e?): void {
		if (e) {
			e.preventDefault();
		}
		setShowInviteSellerModal(!showInviteSellerModal);
	}

	function toggleChangePasswordModal(e?): void {
		if (e) {
			e.preventDefault();
		}
		setShowChangePasswordModal(!showChangePasswordModal);
	}

	return (
		<React.Fragment>
			{!["/", "/reset-password", "/login2"].includes(location.pathname) && (
				<React.Fragment>

					<UploadLogo
						isOpen={showLogoModal}
						closeModal={toggleLogoModal}
					/>

					<InviteSellerModal
						isOpen={showInviteSellerModal}
						closeModal={toggleInviteSellerModal}
					/>

					<ChangePasswordModal
						isOpen={showChangePasswordModal}
						onClose={toggleChangePasswordModal}
					/>

					<div className="border-bottom header-container py-2">
						<Navbar color="white" light expand="md">
							<div className="container">
								<Nav navbar>
									<NavItem>
										<NavLink exact to="/"
										         className="text-decoration-none nav-link navbar-brand p-0">
											{
												logoLoading ?
													<Spinner className="header-logo" color="primary"/>
													:
													<img
														src={logoURL}
														alt="Harbour Trade Credit Logo"
														className="header-logo"
													/>
											}
										</NavLink>
									</NavItem>
								</Nav>

								<NavbarToggler onClick={toggleIsOpen} aria-label="View Navigation"
								               className="hamburger-helper">
									<FiAlignJustify
										size={"2rem"}
									/>
								</NavbarToggler>

								<Collapse isOpen={isOpen} navbar>
									<hr/>
									<Nav className="ml-auto d-flex align-items-start align-items-md-center" navbar>
										{tokenV2 ? (
											<React.Fragment>
												{!accessTypeSelfReg && (
													<React.Fragment>

														<NavItem>
															<NavLink
																exact
																to="/home"
																className="text-decoration-none nav-link"
																onClick={mobileCloseHandler}
															>
																Home
															</NavLink>
														</NavItem>

														<React.Fragment>
															<NavItem className="dropdown d-none d-md-inline-block">
																<span
																	className="text-decoration-none nav-link dropdown-toggler">
																	Account
																</span>
																<div className="dropdown-content" style={{zIndex: 10}}>
																	<div className="dropdown-menu dropdown-menu-right show p-0">
																		{accessTypeAccount && (
																			<span className="dropdown-item">
																				<a
																					href="#"
																					className="text-decoration-none nav-link py-1"
																					onClick={toggleLogoModal}
																				>
																					Setup Logo
																				</a>
																			</span>
																		)}
																		{(accessTypeAccount && accessTypeInviteSupplier) && (
																			<span className="dropdown-item">
																				<a
																					href="#"
																					className="text-decoration-none nav-link py-1"
																					onClick={toggleInviteSellerModal}
																				>
																					Invite Supplier
																				</a>
																			</span>
																		)}
																		{accessTypeAccount && (
																			<span className="dropdown-item">
																				<NavLink
																					exact
																					to="/account/account-info"
																					className="text-decoration-none nav-link py-1"
																				>
																					Account Info
																				</NavLink>
																			</span>
																		)}
																		{(accessTypeAccount && accessTypeBuyer) && (
																			<span className="dropdown-item">
																				<NavLink
																					exact
																					to="/account/business-link"
																					className="text-decoration-none nav-link py-1"
																				>
																					BizLink
																				</NavLink>
																			</span>
																		)}
																		<span className="dropdown-item">
																			<a
																				href="#"
																				className="text-decoration-none nav-link py-1"
																				onClick={toggleChangePasswordModal}
																			>
																				Change Password
																			</a>
																		</span>
																	</div>
																</div>
															</NavItem>

															<NavItem className="d-inline-block d-md-none">
																<span className="text-decoration-none nav-link header-mobile-account-label">
																	Account
																</span>
																<ul>
																	{accessTypeAccount && (
																		<li>
																			<a
																				href="#"
																				className="text-decoration-none nav-link py-1"
																				onClick={toggleLogoModal}
																			>
																				Setup Logo
																			</a>
																		</li>
																	)}
																	{(accessTypeAccount && accessTypeInviteSupplier) && (
																		<li>
																			<a
																				href="#"
																				className="text-decoration-none nav-link py-1"
																				onClick={toggleInviteSellerModal}
																			>
																				Invite Supplier
																			</a>
																		</li>
																	)}
																	{accessTypeAccount && (
																		<li>
																			<NavLink
																				exact
																				to="/account/account-info"
																				className="text-decoration-none nav-link py-1"
																				onClick={mobileCloseHandler}
																			>
																				Account Info
																			</NavLink>
																		</li>
																	)}
																	{(accessTypeAccount && accessTypeBuyer) && (
																		<li>
																			<NavLink
																				exact
																				to="/account/business-link"
																				className="text-decoration-none nav-link py-1"
																				onClick={mobileCloseHandler}
																			>
																				BizLink
																			</NavLink>
																		</li>
																	)}
																	<li>
																		<a
																			href="#"
																			className="text-decoration-none nav-link py-1"
																			onClick={toggleChangePasswordModal}
																		>
																			Change Password
																		</a>
																	</li>
																</ul>
															</NavItem>
														</React.Fragment>

														{accessTypeBuyer && (
															<NavItem>
																<NavLink
																	exact
																	to="/purchases"
																	className="text-decoration-none nav-link"
																	onClick={mobileCloseHandler}
																>
																	Purchases
																</NavLink>
															</NavItem>
														)}

														{accessTypeSeller && (
															<NavItem>
																<NavLink
																	exact
																	to="/sales"
																	className="text-decoration-none nav-link"
																	onClick={mobileCloseHandler}
																>
																	Sales
																</NavLink>
															</NavItem>
														)}

													</React.Fragment>
												)}

												<div className="align-self-center mx-2">
													<a
														href="#"
														className="text-danger"
														onClick={logoutDispatcher}
													>
														Logout
													</a>
												</div>
											</React.Fragment>
										) : (
											<Link to="/">
												<Button color="link">
													Login
												</Button>
											</Link>
										)}
									</Nav>
								</Collapse>

							</div>
						</Navbar>

						{/*{(pMessage && pMessage.message.length > 0) && (*/}
						{/*    <Navbar className="pt-0">*/}
						{/*		<Container fluid>*/}
						{/*			<p className="mb-0" style={{fontSize: "0.9rem"}}>*/}
						{/*				{`Hi ${pMessage.userName}, ${pMessage.message}`}*/}
						{/*			</p>*/}
						{/*		</Container>*/}
						{/*    </Navbar>*/}
						{/*)}*/}
					</div>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IHeaderProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
		accessTypeBuyer: store.metaStore.accessTypeBuyer,
		accessTypeSeller: store.metaStore.accessTypeSeller,
		accessTypeAccount: store.metaStore.accessTypeAccount,
		accessTypeSelfReg: store.metaStore.accessTypeSelfReg,
		accessTypeInviteSupplier: store.metaStore.accessTypeInviteSupplier,
		userType: store.metaStore.userType,
		logoURL: store.metaStore.logoURL ? store.metaStore.logoURL : process.env.PUBLIC_URL + "/logos/logo-only.png",
		...props,
	}
})(Header);
