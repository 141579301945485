import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Button, Col, Container, Row} from "reactstrap";
import {decrementLoading, handleError, incrementLoading, setError} from "../redux/meta/MetaActions";
import {initialAdapter} from "adapter";
import PendingPOForSellerApprovalCard from "../components/PendingPOForSellerApprovalCard";
import {useHistory} from "react-router";
import {Link} from "react-router-dom";
import {generic400, generic500} from "../utils/errors";
import ErrorList from "../components/ErrorList";
import JustAcceptedPOModal from "../components/JustAcceptedPOModal";
import downloadIFrameGenerator from "../utils/downloadIFrameGenerator";

interface IViewPOsAndCreateInvoicesPageProps {
	tokenV2?: any;
	dispatch?: any;
}

const ViewPOsAndCreateInvoicesPage: React.FC<IViewPOsAndCreateInvoicesPageProps> = (props: IViewPOsAndCreateInvoicesPageProps) => {

	const history = useHistory();
	const [list, setList] = useState([]);
	const [enableEmptyMessage, setEnableEmptyMessage] = useState(false);
	const [asyncElements, setAsyncElements] = useState<Array<ReactNode>>([]);
	const [errors, setErrors] = useState([]);
	const [justAccepted, setJustAccepted] = useState<initialAdapter.ICogsPOItemMsg>();

	useEffect(() => {
		getList().then().catch();
	}, []);

	useEffect(() => {
		createPOs(list).then().catch();
	}, [JSON.stringify(list)]);

	async function getList(): Promise<void> {
		setErrors([]);
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.readSentReceivedCogsPOList(undefined, props.tokenV2);
		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		setEnableEmptyMessage(true);
		setList(res.data.items);
	}

	async function onAccept(key: string, timestamp: string, po: initialAdapter.ICogsPOItemMsg): Promise<void> {
		setErrors([]);
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.updateHTCPOtoReceived({
			keyURL: key,
			timestamp,
		}, props.tokenV2);
		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			await getList();
			return;
		}

		setJustAccepted(po);
		await getList();
	}

	function dismissJustAccepted(): void {
		setJustAccepted(null);
	}

	async function onCreate(key: string, timestamp: string): Promise<void> {
		setErrors([]);
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.createCogsInvoiceFromHTCPO({
			poKeyURL: key,
			timestamp,
		}, props.tokenV2);
		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			await getList();
			return;
		}

		history.push({pathname: "/invoice/edit", search: `?invoice=${res.data.data[1]}`});
	}

	async function onReject(key: string, timestamp: string, reason: string): Promise<void> {
		setErrors([]);
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.updateHTCPOtoVoided({
			poKeyURL: key,
			timestamp,
			reason,
		}, props.tokenV2);
		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			await getList();
			return;
		}

		await getList();
	}

	async function viewCOGSPOPdf(po: initialAdapter.ICogsPOItemMsg): Promise<void> {
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.readCOGSPOPdf({poKeyURL: po.poKeyURL}, props.tokenV2);
		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		const reader = new FileReader();
		reader.readAsDataURL(res.data);
		reader.onloadend = function () {
			const base64data = reader.result;
			const win = window.open();
			win.document.open();
			win.document.write(downloadIFrameGenerator(po.poNumber, base64data));
			win.document.close();
		};
	}

	async function createPOs(pos: Array<initialAdapter.ICogsPOItemMsg> = []): Promise<any> {
		const p = pos.map(async (po: initialAdapter.ICogsPOItemMsg, i: number) => {
			setErrors([]);

			function acceptHelper(): void {
				onAccept(po.poKeyURL, po.poTimestamp, po).then().catch();
			}

			function createHelper(): void {
				onCreate(po.poKeyURL, po.poTimestamp).then().catch();
			}

			function rejectHelper(reason: string): void {
				onReject(po.poKeyURL, po.poTimestamp, reason).then().catch();
			}

			props.dispatch(incrementLoading());
			const attachmentRes = await initialAdapter.api.readPODocs({poKeyURL: po.poKeyURL}, props.tokenV2);
			props.dispatch(decrementLoading());

			if (!attachmentRes.success) {
				setErrors(handleError(attachmentRes));
				return null;
			}

			async function onClickPONumberHelper(e: any): Promise<void> {
				e.preventDefault();
				await viewCOGSPOPdf(po);
			}

			return (
				<div className="mb-5 d-flex po-inv-card-col" key={`PendingPOForSellerApprovalCard-${i}`}>
					<PendingPOForSellerApprovalCard
						poNumber={po.poNumber}
						poDate={po.poDate}
						poAmount={po.poAmount}
						paymentTerm={po.paymentTerm}
						shippingTerm={po.shippingTerm}
						attachments={attachmentRes.data.documentList}
						status={po.status}
						shipByDate={po.shipByDate}
						poKeyURL={po.poKeyURL}
						onCreate={createHelper}
						onReject={rejectHelper}
						onAccept={acceptHelper}
					/>
				</div>
			)
		});

		const fin = await Promise.all(p);
		setAsyncElements(fin);
	}

	return (
		<Container className="my-4 htc-container">

			<JustAcceptedPOModal
				poNumber={justAccepted ? justAccepted.poNumber : ""}
				done={dismissJustAccepted}
			/>

			{errors.length > 0 && (
				<div className="my-3">
					<ErrorList errors={errors}/>
				</div>
			)}

			{(list.length < 1 && enableEmptyMessage) && (
				<div>
					<h5 className="my-3 text-center">
						There are no more purchase orders for fulfillment.
					</h5>

					<div className="d-flex justify-content-center my-4">
						<Link to="/home">
							<Button
								component={Link}
								color="hBlue"
								className="px-5"
							>
								Home
							</Button>
						</Link>
					</div>
				</div>
			)}

			<div className="po-inv-card-row">
				{asyncElements}
			</div>
		</Container>
	);
};

export default connect((store: IStore, props: IViewPOsAndCreateInvoicesPageProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
	}
})(ViewPOsAndCreateInvoicesPage);
