import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {initialAdapter} from "adapter";
import {decrementLoading, handleError, incrementLoading} from "../redux/meta/MetaActions";
import {legacyIncludes} from "../utils/arrays";
import ErrorList from "../components/ErrorList";
import InvestorUserClientPortalDashbaord from "../components/InvestorUserClientPortalDashbaord";
import BusinessInsights from "../components/dashboard/BusinessInsights";
import TradeLinkSetup from "../components/dashboard/TradeLinkSetup";
import ThingsToDo3 from "../components/dashboard/ThingsToDo3";
import UploadLogoCard from "../components/dashboard/UploadLogoCard";
import RecentActivity from "../components/dashboard/RecentActivity";
import BusinessLinkSetup from "../components/dashboard/BusinessLinkSetup";
import BuyersDashboardInvoiceAlert from "../components/dashboard/BuyersDashboardInvoiceAlert";
import SuppliersDashboardPurchaseAlert from "../components/dashboard/SuppliersDashboardPurchaseAlert";
import DashboardAccountOverview from "../components/dashboard/DashboardAccountOverview";
import DashboardAccountsPayable from "../components/dashboard/DashboardAccountsPayable";
import DashboardAccountsReceivable from "../components/dashboard/DashboardAccountsReceivable";
import DashboardCardMessage from "../components/dashboard/DashboardCardMessage";
import WelcomeMessage from "../components/dashboard/WelcomeMessage";

interface IDashboardProps {
	tokenV2?: initialAdapter.IAuthInfo;
	dispatch?: any;
	accessTypeBuyer?: boolean;
	accessTypeSeller?: boolean;
	userType?: string;
	displayName?: string;
}

const Dashboard: React.FC<IDashboardProps> = (props: IDashboardProps) => {

	const {tokenV2, accessTypeBuyer, accessTypeSeller, userType, displayName} = props;
	const [dashboardData, setDashboardData] = useState<initialAdapter.IClientDashboardMsg>();
	const [showInvoices, setShowInvoices] = useState<any>({});
	const [showPOs, setShowPOs] = useState<any>({});
	const [showBuyerConnect, setShowBuyerConnect] = useState<any>({});
	const [showBuyerTradeLinkSetup, setShowBuyerTradeLinkSetup] = useState<any>({});
	const [errors, setErrors] = useState([]);
	const [pMessage, setPMessage] = useState<initialAdapter.IPersonalizedMessageRes>();

	useEffect(() => {
		if (props.tokenV2 && userType === "client") {
			getDashboardInfo().then().catch();
		}

		if (props.tokenV2) {
			getPersonalizedMessage().then().catch();
		} else {
			setPMessage(null);
		}
	}, []);

	async function getPersonalizedMessage(): Promise<void> {
		const res = await initialAdapter.api.readPersonalizedMessage(undefined, tokenV2);

		if (!res.success) {
			handleError(res);
			return;
		}

		setPMessage(res.data);
	}

	async function getDashboardInfo(): Promise<void> {
		setErrors([]);
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.readClientDashboard(undefined, tokenV2);
		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		let _showInvoices: any = {};
		let _showPOs: any = {};
		let _showBuyerConnect: any = {};
		let _showBuyerTradeLinkSetup: any = {};
		let i: number;
		for (i = 0; i < res.data.transTypeList.length; i++) {
			if (res.data.transTypeList[i].type === "pendingHTCInv") {
				_showInvoices = res.data.transTypeList[i];
			} else if (res.data.transTypeList[i].type === "pendingCOGSPO") {
				_showPOs = res.data.transTypeList[i];
			} else if (res.data.transTypeList[i].type === "pendingBuyerConnect") {
				_showBuyerConnect = res.data.transTypeList[i];
			} else if (res.data.transTypeList[i].type === "pendingBuyerTradeLinkSetup") {
				_showBuyerTradeLinkSetup = res.data.transTypeList[i];
			}
		}

		setShowInvoices(_showInvoices);
		setShowPOs(_showPOs);
		setShowBuyerConnect(_showBuyerConnect);
		setShowBuyerTradeLinkSetup(_showBuyerTradeLinkSetup);
		setDashboardData(res.data);
	}

	// information columns
	const overview: ReactNode = accessTypeBuyer ? (
		<DashboardAccountOverview
			balance={dashboardData && parseFloat(dashboardData.balanceAmount)}
			availableCredit={dashboardData && parseFloat(dashboardData.availableCreditAmount)}
			creditLimit={dashboardData && parseFloat(dashboardData.creditLimitAmount)}
		/>
	) : null;

	const payable: ReactNode = accessTypeBuyer ? (
		<DashboardAccountsPayable
			pastDue={dashboardData && parseFloat(dashboardData.apPastDueAmount)}
			current={dashboardData && parseFloat(dashboardData.apCurrentAmount)}
			totalOutstanding={dashboardData && parseFloat(dashboardData.apTotalOutstanding)}
		/>
	) : null;

	const receivable: ReactNode = accessTypeSeller ? (
		<DashboardAccountsReceivable
			pastDue={dashboardData && parseFloat(dashboardData.arPastDueAmount)}
			current={dashboardData && parseFloat(dashboardData.arCurrentAmount)}
			totalOutstanding={dashboardData && parseFloat(dashboardData.arTotalOutstanding)}
		/>
	) : accessTypeSeller;

	const message: ReactNode = (dashboardData && dashboardData.message && dashboardData.message.length > 0) ? (
		<DashboardCardMessage name={displayName} message={dashboardData.message}/>
	) : null;

	const businessInsights: ReactNode = (<BusinessInsights/>);

	// action  columns
	const bizLinkSetup: ReactNode = Object.keys(showBuyerConnect).length > 0 ? (<BusinessLinkSetup quantity={showBuyerConnect.value}/>) : null;

	const tradeLinkSetup: ReactNode = Object.keys(showBuyerTradeLinkSetup).length > 0 ? (<TradeLinkSetup quantity={showBuyerTradeLinkSetup.value}/>) : null;

	const invoiceAlert: ReactNode = Object.keys(showInvoices).length > 0 ? (<BuyersDashboardInvoiceAlert quantity={showInvoices.value}/>) : null;

	const purchaseAlert: ReactNode = Object.keys(showPOs).length > 0 ? (<SuppliersDashboardPurchaseAlert quantity={showPOs.value}/>) : null;

	const uploadLogoCard: ReactNode = (dashboardData && legacyIncludes(dashboardData.actions, "setuplogo")) ? (<UploadLogoCard/>) : null;

	// misc column

	// make sure there is at least one "thing to do" so we know whether or not to render the column
	let howManyThingsToDo: number = 0;
	if (dashboardData && legacyIncludes(dashboardData.actions, "createpo")) {howManyThingsToDo++}
	if (dashboardData && legacyIncludes(dashboardData.actions, "addsupplier")) {howManyThingsToDo++}
	if (dashboardData && legacyIncludes(dashboardData.actions, "createinvoice")) {howManyThingsToDo++}
	if (dashboardData && legacyIncludes(dashboardData.actions, "addcustomer")) {howManyThingsToDo++}
	if (dashboardData && legacyIncludes(dashboardData.actions, "invitesupplier")) {howManyThingsToDo++}
	const thingsToDo: ReactNode = (
		<ThingsToDo3
			createPO={dashboardData && legacyIncludes(dashboardData.actions, "createpo")}
			addSupplier={dashboardData && legacyIncludes(dashboardData.actions, "addsupplier")}
			createInvoice={dashboardData && legacyIncludes(dashboardData.actions, "createinvoice")}
			addCustomer={dashboardData && legacyIncludes(dashboardData.actions, "addcustomer")}
			canSetupLogo={false}
			inviteSupplier={dashboardData && legacyIncludes(dashboardData.actions, "invitesupplier")}
		/>
	);

	const recentActivity: ReactNode = (<RecentActivity/>);

	// column counting, max column count = 5 @ largest screen size, split like 2-2-1 at maximum, with the first 2 having the possibility of being 0 or 1,
	// as of right now the misc column should always exist

	// info
	let infoColumns: number = 0;
	if (overview) {infoColumns++}
	if (payable) {infoColumns++}
	if (receivable) {infoColumns++}
	if (message) {infoColumns++}

	// actions
	let actionColumns: number = 0;
	if (bizLinkSetup) {actionColumns++}
	if (tradeLinkSetup) {actionColumns++}
	if (invoiceAlert) {actionColumns++}
	if (purchaseAlert) {actionColumns++}
	if (uploadLogoCard) {actionColumns++}

	// misc
	let miscColumns: number = 1;

	let dbColSuffix: string = "";
	if (infoColumns >= 2 && actionColumns >= 2) {
		dbColSuffix = "221"
	} else if (infoColumns >= 2 && actionColumns == 1) {
		dbColSuffix = "211";
	} else if (infoColumns == 1 && actionColumns >= 2) {
		dbColSuffix = "121";
	} else if (infoColumns == 1 && actionColumns == 1) {
		dbColSuffix = "111";
	} else if (infoColumns === 0 && actionColumns === 0) {
		dbColSuffix = "001"
	} else if (infoColumns === 0 && actionColumns === 1) {
		dbColSuffix = "011"
	} else if (infoColumns === 0 && actionColumns >= 2) {
		dbColSuffix = "021"
	} else if (infoColumns === 1 && actionColumns === 0) {
		dbColSuffix = "101"
	} else if (infoColumns >= 2 && actionColumns === 0) {
		dbColSuffix = "201"
	}

	const mainStructure: ReactNode = (
		<React.Fragment>
			<div className="db-info-col">
				{overview}
				{payable}
				{receivable}
				{message}
			</div>

			<div className="db-actions-col">
				{bizLinkSetup}
				{tradeLinkSetup}
				{invoiceAlert}
				{purchaseAlert}
				{uploadLogoCard}
			</div>

			{howManyThingsToDo > 0 && (
				<div className="db-misc-col">
					{thingsToDo}
				</div>
			)}
		</React.Fragment>
	);

	return (
		<div className="container my-4 px-2 dashboard-page">
			{(pMessage && pMessage.message.length > 0) && (
				<React.Fragment>
					<WelcomeMessage name={pMessage.userName} message={pMessage.message}/>
					<hr className="my-4"/>
				</React.Fragment>
			)}

			{userType === "client" && (
				<React.Fragment>

					{errors.length > 0 && (
						<div className="my-3">
							<ErrorList errors={errors}/>
						</div>
					)}

					<div className={"mb-4 mt-4 db-wrapper2 " + ("db-wrapper2__" + dbColSuffix)}>
						{mainStructure}
					</div>

				</React.Fragment>
			)}

			{userType === "investor" && (
				<React.Fragment>
					<InvestorUserClientPortalDashbaord/>
				</React.Fragment>
			)}

		</div>
	);
};

export default connect((store: IStore, props: IDashboardProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
		accessTypeBuyer: store.metaStore.accessTypeBuyer,
		accessTypeSeller: store.metaStore.accessTypeSeller,
		userType: store.metaStore.userType,
		displayName: store.metaStore.displayName,
	}
})(Dashboard);
