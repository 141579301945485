import React, {useEffect, useState} from "react";
import {Button, Modal, ModalHeader} from "reactstrap";
import "./UploadLogo.scss";
import {FiUploadCloud} from "react-icons/fi";
import {decrementLoading, handleError, incrementLoading, setClientLogo, setError} from "../redux/meta/MetaActions";
import {initialAdapter} from "adapter";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {generic400, generic500} from "../utils/errors";
import ErrorList from "./ErrorList";


interface IUploadLogoProps {
	isOpen: boolean;
	dispatch?: any;
	tokenV2?: initialAdapter.IAuthInfo;
	closeModal(): void;
}

const UploadLogo: React.FC<IUploadLogoProps> = (props: IUploadLogoProps) => {

	const {isOpen, closeModal} = props;
	const [logo, setLogo] = useState<File>();
	const [showNoLogoError, setShowNoLogoError] = useState(false);
	const [imageURL, setImageURL] = useState<string>();
	const [errors, setErrors] = useState([]);

	function resetAndClose(): void {
		setLogo(undefined);
		setImageURL(undefined);
		closeModal();
	}

	async function submitLogo(): Promise<void> {
		if (!logo) {
			setShowNoLogoError(true);
			return;
		}

		setErrors([]);
		props.dispatch(incrementLoading());

		// get extension
		const lastDot = logo.name.lastIndexOf('.');
		const ext = logo.name.substring(lastDot + 1);

		// get google upload url
		const uploadURLRes = await initialAdapter.api.readLogoUploadURL({
			fileType: ext,
		}, props.tokenV2 )

		if (!uploadURLRes.success) {
			props.dispatch(decrementLoading());
			setErrors(handleError(uploadURLRes));
			return;
		}

		// upload the file to signed url
		const realUpload = await initialAdapter.api.uploadToSignedURL(uploadURLRes.data.signedURL, logo);
		if (!realUpload.success) {
			props.dispatch(decrementLoading());
			setErrors(handleError(realUpload));
			return;
		}

		// set the fileid
		const assignIDRes = await initialAdapter.api.updateClientLogo({
			fileid: uploadURLRes.data.fileid
		}, props.tokenV2)

		if (!assignIDRes.success) {
			props.dispatch(decrementLoading());
			setErrors(handleError(assignIDRes));
			return;
		}

		// get the logo
		const res = await initialAdapter.api.readClientLogoURL(undefined, props.tokenV2);
		if (!res.success) {
			setErrors(handleError(res));
			props.dispatch(setClientLogo(imageURL));
			return;
		}
		props.dispatch(setClientLogo(res.data.signedURL));

		resetAndClose();
		props.dispatch(decrementLoading());
	}

	function addLogo(e): void {
		props.dispatch(incrementLoading());
		setLogo(e.target.files[0]);
		const fr = new FileReader();
		fr.onload = () => {
			setImageURL(fr.result as string);
			props.dispatch(decrementLoading());
		}
		fr.readAsDataURL(e.target.files[0]);
	}

	return (
		<Modal
			isOpen={isOpen}
			fade={true}
			centered={true}
			contentClassName="p-2"
			toggle={resetAndClose}
		>
			<div className="px-3">
				<ModalHeader toggle={resetAndClose}><h4>Your Business Logo</h4></ModalHeader>

				<div className="upload-btn-wrapper my-3 cursor-pointer">
					<div
						className="position-relative overflow-hidden w-100 d-inline-flex flex-column align-items-center upload-btn-wrapper-inner p-3">
						{!imageURL && <FiUploadCloud size="4em" color="#5bc0de"/> }
						<input type="file" name="myfile" className="cursor-pointer" onChange={addLogo}/>
						{
							imageURL ?
								<div className="d-flex overflow-hidden justify-content-center align-items-center" style={{maxHeight: 400, maxWidth: 400}}>
									<img className="flex-shrink-0 mw-100 mh-100" src={imageURL} alt="business logo"/>
								</div>
								:
								<React.Fragment>
									<span className="mt-2 text-center">
										Browse or drop your file here
									</span>
									<p className="text-secondary text-center">
										Maximum 5MB in size.<br/>
										JPG, PNG, or GIF formats.<br/>
										Recommended size: 300 x 200 pixels.
									</p>
								</React.Fragment>
						}

					</div>
				</div>

				{showNoLogoError && (
					<div className="my-2">
						<span className="text-danger">
							Please upload a file first!
						</span>
					</div>
				)}

				{errors.length > 0 && (
					<div className="my-3">
						<ErrorList errors={errors}/>
					</div>
				)}
			</div>


			<div className="d-flex justify-content-between">
				<Button color="link" onClick={resetAndClose}>
					Cancel
				</Button>

				<Button color="link" disabled={!!!logo} onClick={submitLogo}>
					Use this logo
				</Button>
			</div>
		</Modal>
	)
};

export default connect((store: IStore, props: IUploadLogoProps) => {
	return {
		...props,
		tokenV2: store.metaStore.tokenV2,
	}
})(UploadLogo);
