import * as React from "react";
import {initialAdapter} from "adapter";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import moment from "moment";
import {useState} from "react";
import {decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {Button, Card, CardBody} from "reactstrap";

interface IFileThumbnailProps {
	document: initialAdapter.IDocumentItemMsg | any;
	invoiceItem: initialAdapter.IInvoiceTransactionItem
	authInfo?: initialAdapter.IAuthInfo;
	onDelete?: (document: initialAdapter.IDocument, invoiceItem: initialAdapter.IInvoiceTransactionItem) => Promise<void>;
	dispatch?: any;
}

const FileThumbnail: React.FC<IFileThumbnailProps> = ({document, invoiceItem, authInfo, onDelete, dispatch}: IFileThumbnailProps) => {

	const [disabled, updateDisabled] = useState(false);

	function openDocument(): void {
		// const url = initialAdapter.api.generateDocumentUrl(
		// 	authInfo.signId,
		// 	authInfo.signToken,
		// 	document.documentKeyURL,
		// );
		// window.open(url, '_newtab');

		window.open(document.documentURL);
	}

	async function onDeleteWrapper() {
		dispatch(incrementLoading());
		updateDisabled(true);
		await onDelete(document, invoiceItem);
		updateDisabled(false);
		dispatch(decrementLoading());
	}

	return (
		<Card>
			<CardBody className="p-3">
				<h5 className="mb-3 text-center">
					Document
				</h5>

				<h6 className="mb-4 text-center font-weight-bold">
					{invoiceItem.invoiceNum}
				</h6>

				<p className="text-center">
					{document.documentType}
				</p>

				<p className="text-center">
					{document.documentID}
				</p>

				<p className="text-center">
					{moment(document.uploadedDateTime).format("YYYY/MM/DD")}
				</p>

				<div className="mt-4 d-flex flex-column align-items-center">
					<Button
						color="link"
						onClick={openDocument}
					>
						View Document
					</Button>

					{/*{onDelete && (*/}
					{/*	<Button*/}
					{/*		className="mt-2"*/}
					{/*		color="danger"*/}
					{/*		onClick={onDeleteWrapper}*/}
					{/*		disabled={disabled}*/}
					{/*	>*/}
					{/*		Delete*/}
					{/*	</Button>*/}
					{/*)}*/}
				</div>
			</CardBody>
		</Card>
	);
};

export default connect((store: IStore, props: IFileThumbnailProps): IFileThumbnailProps => {
	return {
		...props,
		authInfo: store.metaStore.token as initialAdapter.IAuthInfo
	}
})(FileThumbnail);
