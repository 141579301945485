import React, {ChangeEventHandler, ReactNode, useState} from "react";
import {Button, Card, CardBody, Col, Input, Label, Modal, Row} from "reactstrap";
import {formatMoney} from "../utils/formatters";
import {initialAdapter} from "adapter";
import ClientViewPDFModal from "./ClientViewPDFModal";

interface IPendingPOForSellerApprovalCardProps {
	poNumber: string;
	poDate: string;
	poAmount: string;
	paymentTerm: string;
	shippingTerm: string;
	attachments: any[];
	status: string;
	shipByDate: string;
	poKeyURL: string;

	onCreate(): void;

	onReject(reason: string): void;

	onAccept(): void;
}

const PendingPOForSellerApprovalCard: React.FC<IPendingPOForSellerApprovalCardProps> = (props: IPendingPOForSellerApprovalCardProps) => {

	const {poNumber, poDate, poAmount, paymentTerm, shippingTerm, attachments, status, shipByDate, poKeyURL} = props;
	const [showWhyReject, setShowWhyReject] = useState(false);
	const [why, setWhy] = useState("");
	const [showPDFModal, setShowPDFModal] = useState(false);

	function handleWhyOnChange(e): void {
		setWhy(e.target.value);
	}

	function toggleShowWhyReject(): void {
		setShowWhyReject(!showWhyReject);
	}

	function toggleShowPDFModal(): void {
		setShowPDFModal(!showPDFModal);
	}

	function onRejectHelper(): void {
		props.onReject(why);
		setShowWhyReject(false);
	}

	function mapFiles(files: Array<initialAdapter.IDocumentItemMsg> = []): ReactNode {
		if (files.length < 1) {
			return (
				<p className="font-italic mb-1 font-weight-light">
					No Attachments.
				</p>
			);
		}

		return files.map((file: initialAdapter.IDocumentItemMsg, i: number) => {
			return (
				<div className="mb-1" key={`mapped-file-${i}`}>
					<a className="text-hPurple" href={file.documentURL} target="_blank">
						{file.documentName}
					</a>
				</div>
			);
		});
	}

	return (
		<React.Fragment>
			<ClientViewPDFModal
				isOpen={showPDFModal}
				pdfType="Purchase Order"
				api="readCOGSPOPdf"
				docKey={poKeyURL}
				onClose={toggleShowPDFModal}
			/>

			<Modal
				isOpen={showWhyReject}
				fade={true}
				centered={true}
				contentClassName="p-3"
			>
				<h3 className="mb-3">Why was this rejected?</h3>

				<Input
					type="textarea"
					name="remarks"
					id="remarks"
					placeholder="remarks"
					value={why}
					onChange={handleWhyOnChange}
					style={{minHeight: 140}}
				/>

				<div className="d-flex justify-content-between mt-3">
					<Button
						onClick={toggleShowWhyReject}
						color="link"
					>
						Cancel
					</Button>

					<Button
						onClick={onRejectHelper}
						color="primary"
					>
						Done
					</Button>
				</div>
			</Modal>

			<Card className={"w-100 " + (status === "sent" ? "po-inv-card-base" : "po-inv-card-highlighted")}>
				<CardBody className="d-flex flex-column justify-content-between">
					<div>
						<Row className="mb-2">
							<Col xs={12} sm={6}>
								<Label>PO#:</Label>
							</Col>

							<Col xs={12} sm={6}>
								<a className="text-hPurple number-display" onClick={toggleShowPDFModal} href="#">
									{poNumber}
								</a>
							</Col>
						</Row>

						<Row className="mb-2">
							<Col xs={12} sm={6}>
								<Label>PO Date:</Label>
							</Col>

							<Col xs={12} sm={6}>
								<span className="number-display">
									{poDate}
								</span>
							</Col>
						</Row>

						<Row className="mb-2">
							<Col xs={12} sm={6}>
								<Label>PO Amount:</Label>
							</Col>

							<Col xs={12} sm={6}>
								<span className="number-display">
									{formatMoney(poAmount)}
								</span>
							</Col>
						</Row>

						<Row className="mb-2">
							<Col xs={12} sm={6}>
								<Label>Payment Term:</Label>
							</Col>

							<Col xs={12} sm={6}>
								<span className="number-display">
									{paymentTerm}
								</span>
							</Col>
						</Row>

						<Row className="mb-2">
							<Col xs={12} sm={6}>
								<Label>Shipping Term:</Label>
							</Col>

							<Col xs={12} sm={6}>
								<span>
									{shippingTerm}
								</span>
							</Col>
						</Row>

						<Row className="mb-2">
							<Col xs={12} sm={6}>
								<Label>Ship by Date:</Label>
							</Col>

							<Col xs={12} sm={6}>
								<span className="number-display">
									{shipByDate}
								</span>
							</Col>
						</Row>

						<Row className="mb-2">
							<Col xs={12}>
								<Label>Attachments:</Label>
							</Col>

							<Col xs={12}>
								<span className="number-display">
									{mapFiles(attachments)}
								</span>
							</Col>
						</Row>
					</div>

					{status === "sent" ? (
						<div className="mt-3">
							<Button color="hBlue" size="sm" className="w-100 font-weight-bold" onClick={props.onAccept}>
								Accept
							</Button>

							<Button color="hPurple" size="sm" className="w-100 font-weight-bold mt-3" onClick={toggleShowWhyReject}>
								Reject
							</Button>

						</div>
					) : (
						<div className="mt-3">
							<Label>
								Please click the button below to create an invoice for this PO.
							</Label>

							<Button color="hBlue" size="sm" className="w-100 font-weight-bold mt-3" onClick={props.onCreate}>
								Create Invoice
							</Button>
						</div>
					)}

				</CardBody>
			</Card>
		</React.Fragment>
	);
};

export default PendingPOForSellerApprovalCard;
