import React from "react";
import {Button} from "reactstrap";

interface IProps {
	showSaveDraftButton: boolean;
	showPreviewButton: boolean;
	onSaveDraft(): any;
	onPOPreview(): any;
}

const POSaveButtons: React.FC<IProps> = (props: IProps) => {

	return (
		<div className="mt-4 po-invoice-save-submit-buttons">
			<div>
				{props.showSaveDraftButton && (
					<Button color="hBlue" className="button-width-280 mb-3" onClick={props.onSaveDraft}>
						Save draft
					</Button>
				)}

				{props.showPreviewButton && (
					<Button color="hBlue" className="button-width-280 po-second-button" onClick={props.onPOPreview}>
						Preview & Submit
					</Button>
				)}
			</div>
		</div>
	)
};

export default POSaveButtons;
