import React from "react";
import {Nav, NavItem, NavLink} from "reactstrap";
import classnames from "classnames";

export enum AddSupplierFormFilters {
	GENERAL = "GENERAL",
	ADDRESS = "ADDRESS",
	// CONTACT = "CONTACT",
	// BILLING = "BILLING",
	// SHIPPING = "SHIPPING",
}

interface IAddSupplierFormTabsProps {
	filter: AddSupplierFormFilters;
	onFilterChange?(filter: AddSupplierFormFilters): void;
}

const AddSupplierFormTabs: React.FC<IAddSupplierFormTabsProps> = (props: IAddSupplierFormTabsProps) => {

	const {filter, onFilterChange} = props;

	function filterChangeHelper(newFilter: AddSupplierFormFilters): () => void {
		return () => {
			onFilterChange(newFilter);
		}
	}

	return (
		<div>
			<Nav tabs className="w-100 border-bottom-0 tab-table-fix tab-scroller">
				<NavItem>
					<NavLink
						className={classnames({active: filter === AddSupplierFormFilters.GENERAL}, "cursor-pointer")}
						onClick={filterChangeHelper(AddSupplierFormFilters.GENERAL)}
					>
						General
					</NavLink>
				</NavItem>
				<NavItem style={{minWidth: "160px"}}>
					<NavLink
						className={classnames({active: filter === AddSupplierFormFilters.ADDRESS}, "cursor-pointer")}
						onClick={filterChangeHelper(AddSupplierFormFilters.ADDRESS)}
					>
						Company Address
					</NavLink>
				</NavItem>
			</Nav>
		</div>
	);
};

export default AddSupplierFormTabs;
