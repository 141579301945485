import React, {Dispatch, SetStateAction, useState} from "react";
import styleHeaders from "../style/styleHeaders";
import {formatMoney, sortMoney} from "../utils/formatters";
import BootstrapTable, {Column} from "react-bootstrap-table-next";
import {SalesTableFilters} from "./SalesTableTabs";
import {Button, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {FiChevronDown} from "react-icons/all";
import {alignCellContentRight} from "./AlignCellContentRight";
import TableHamburgerIconButton, {ITableMenuButtonListItem} from "./TableHamburgerIconButton";
import ViewInvoicePDFModal2 from "./ViewInvoicePDFModal2";
import {useHistory} from "react-router";
import ConfirmDeleteDraftInvoiceModal from "./ConfirmDeleteDraftInvoiceModal";
import ConfirmMarkInvoiceAsVoidModal from "./ConfirmMarkInvoiceAsVoidModal";
import ConfirmMarkInvoiceAsPaidModal from "./ConfirmMarkInvoiceAsPaidModal";
import {caretFormatter, headerTableFormatter} from "../utils/headerTableFormatter";

interface ISalesTableProps {
	data: any[];
	onDoneAction(): Promise<void>;
}

const SalesTable: React.FC<ISalesTableProps> = (props: ISalesTableProps) => {

	const {data} = props;

	function makeManageButton(cell: string[], row: any): JSX.Element {
		return (<ManageSalesTableButton actions={cell} document={row} onDoneAction={props.onDoneAction}/>)
	}

	let longestName: number = 0;
	if (data && data.length > 0) {
		for (const d of data) {
			let l: number = d.customerName.length;
			if (l > longestName) {
				longestName = l;
			}
		}
	}
	longestName = Math.round((longestName * 1.1) / 2);
	const customSupplierWidth: string = longestName + "ch";

	function getColumns(): any {
		const baseColumns: Array<Column> = styleHeaders(getTableColumns());
		baseColumns.push({
			dataField: "actions",
			text: "Actions",
			formatter: makeManageButton,
			headerFormatter: headerTableFormatter,
			sortCaret: caretFormatter,
			manualHeaderClasses: "justify-content-center",
		});

		for (const c of baseColumns) {
			if (c.dataField === "customerName") {
				c.formatter = addHTCIcon(customSupplierWidth);
			}
		}

		return baseColumns;
	}

	return (
		<div className="harbor-table-container box-shadow-helper">
			<BootstrapTable
				bootstrap4={true}
				striped={false}
				hover={true}
				condensed={true}
				bordered={false}
				wrapperClasses="table-responsive border"
				rowStyle={{whiteSpace: "nowrap"}}
				columns={getColumns()}
				keyField={"keyField"}
				data={data !== undefined ? filterData(data).map((d, i) => ({...d, keyField: i})) : []}
				noDataIndication={getEmptyMessage(data)}
				defaultSorted={[{dataField: getDefaultSort(), order: "asc"}]}
			/>
		</div>
	)
};

function filterData(data: any[], filter?: SalesTableFilters): any[] {

	switch (filter) {
		default:
			return data;
	}
}

const daysPastDueCol: Column = {
	dataField: "daysPastDue",
	text: "Days Past Due",
	sort: true,
	headerFormatter: headerTableFormatter,
	sortCaret: caretFormatter,
};

const invoiceNumberCol: Column = {
	dataField: "invoiceNumber",
	text: "Invoice #",
	sort: true,
	headerFormatter: headerTableFormatter,
	sortCaret: caretFormatter,
};

const orderNumberCol: Column = {
	dataField: "orderNumber",
	text: "Order #",
	sort: true,
	headerFormatter: headerTableFormatter,
	sortCaret: caretFormatter,
};

const invoiceDateCol: Column = {
	dataField: "invoiceDate",
	text: "Invoice Date",
	sort: true,
	headerFormatter: headerTableFormatter,
	sortCaret: caretFormatter,
};

const invoiceTotalCol: Column = {
	dataField: "invoiceTotal",
	text: "Invoice Total",
	sort: true,
	formatter: alignCellContentRight(formatMoney),
	sortFunc: sortMoney,
	headerFormatter: headerTableFormatter,
	sortCaret: caretFormatter,
	classes: "number-display",
	manualHeaderClasses: "justify-content-end",
};

const customerCol: Column = {
	dataField: "customerName",
	text: "Customer",
	sort: true,
	headerFormatter: headerTableFormatter,
	sortCaret: caretFormatter,
	formatter: addHTCIcon("0ch"),
};

const paymentDueCol: Column = {
	dataField: "paymentDue",
	text: "Payment Due",
	sort: true,
	headerFormatter: headerTableFormatter,
	sortCaret: caretFormatter,
};

const statusCol: Column = {
	dataField: "status",
	text: "Status",
	sort: true,
	headerFormatter: headerTableFormatter,
	sortCaret: caretFormatter,
};

const lastUpdatedDateCol: Column = {
	dataField: "lastUpdatedDate",
	text: "Last Updated Date",
	sort: true,
	headerFormatter: headerTableFormatter,
	sortCaret: caretFormatter,
};

const invoiceAmountCol: Column = {
	dataField: "invoiceAmount",
	text: "Invoice Amount",
	sort: true,
	headerFormatter: headerTableFormatter,
	sortCaret: caretFormatter,
	sortFunc: sortMoney,
	headerAlign: "right",
};

const dueAmountCol: Column = {
	dataField: "dueAmount",
	text: "Amount Due",
	sort: true,
	formatter: alignCellContentRight(formatMoney),
	sortFunc: sortMoney,
	sortCaret: caretFormatter,
	headerFormatter: headerTableFormatter,
	classes: "number-display",
	manualHeaderClasses: "justify-content-end",
};

function getTableColumns(filter?: SalesTableFilters): Column[] {
	return [statusCol, invoiceDateCol, invoiceNumberCol, customerCol, invoiceTotalCol, dueAmountCol];
}

function getDefaultSort(filter?: SalesTableFilters): string {
	return "status";
}

function getEmptyMessage(data: any[]): string {

	if (data === undefined) {
		return "Loading...";
	}

	return "You currently have no invoices";
}

/**
 * if htc po, add the icon
 *
 * @param chMinWidth
 */
function addHTCIcon(chMinWidth: string): (cell: string, document: any) => JSX.Element {
	return (cell: string, document: any) => {
		return (
			<div className="d-flex align-items-center">
				<p className="mb-0" style={{width: chMinWidth, whiteSpace: "initial"}}>
					{cell}
				</p>

				<div style={{width: 20}}>
					{document.htcPO && (
						<img
							src={process.env.PUBLIC_URL + "/logos/harbor-o.png"}
							style={{height: 20, width: 20}}
							className="ml-2"
						/>
					)}
				</div>
			</div>
		);
	}
}

interface IManageSalesTableButtonProps {
	actions: string[];
	document: any;
	onDoneAction(): Promise<void>;
}

const ManageSalesTableButton: React.FC<IManageSalesTableButtonProps> = (props: IManageSalesTableButtonProps) => {

	const history = useHistory();
	const {actions, document} = props;
	const [showView, setShowView] = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const [showVoid, setShowVoid] = useState(false);
	const [showMarkPaid, setShowMarkPaid] = useState(false);

	function openModalHelper(toggle: Dispatch<SetStateAction<boolean>>): () => void {
		return () => {
			toggle(true);
		}
	}

	function closeModalHelper(toggle: Dispatch<SetStateAction<boolean>>): () => void {
		return () => {
			toggle(false);
		}
	}

	async function doneDelete(): Promise<void> {
		setShowDelete(false);
		await props.onDoneAction()
	}

	async function doneVoid(): Promise<void> {
		setShowVoid(false);
		await props.onDoneAction()
	}

	async function doneMarkPaid(): Promise<void> {
		setShowMarkPaid(false);
		await props.onDoneAction()
	}

	function editDraft(): void {
		history.push(`/invoice/edit?invoice=${document.invoiceKeyURL}`);
	}

	function pair(action: string): ITableMenuButtonListItem {
		switch (action) {
			case "view":
				return {label: "View", callback: openModalHelper(setShowView)};
			case "edit":
				return {label: "Edit", callback: editDraft};
			case "delete":
				return {label: "Delete", callback: openModalHelper(setShowDelete)};
			case "markAsVoid":
				return {label: "Mark as Void", callback: openModalHelper(setShowVoid)};
			case "markAsPaid":
				return {label: "Mark as Paid", callback: openModalHelper(setShowMarkPaid)};
		}
	}

	if (actions.length < 1) {return null}

	const defaultAction: ITableMenuButtonListItem = pair(actions[0]);

	return (
		<React.Fragment>
			<ViewInvoicePDFModal2
				isOpen={showView}
				invoiceKey={document.invoiceKeyURL}
				closeModal={closeModalHelper(setShowView)}
			/>

			<ConfirmDeleteDraftInvoiceModal
				isOpen={showDelete}
				invoice={document}
				closeModal={closeModalHelper(setShowDelete)}
				finishDelete={doneDelete}
			/>

			<ConfirmMarkInvoiceAsVoidModal
				isOpen={showVoid}
				invoice={document}
				closeModal={closeModalHelper(setShowVoid)}
				onDone={doneVoid}
			/>

			<ConfirmMarkInvoiceAsPaidModal
				isOpen={showMarkPaid}
				invoice={document}
				closeModal={closeModalHelper(setShowMarkPaid)}
				onDone={doneMarkPaid}
			/>

			<div className="d-flex">
				<Button color="hBlue" className="text-nowrap button-width-140" onClick={defaultAction ? defaultAction.callback : () => {}}>
					{defaultAction ? defaultAction.label : ""}
				</Button>

				{actions.length > 1 && (
					<TableHamburgerIconButton
						buttons={actions.slice(1).map(pair)}
					/>
				)}
			</div>
		</React.Fragment>
	);
};

export default SalesTable;
