import React from "react";
import {Container} from "reactstrap";
import SalesTableWithController from "../components/SalesTableWithController";

const SalesPage: React.FC = () => {

	return (
		<Container className="my-4 px-1 px-lg-4 htc-container">
			<SalesTableWithController/>
		</Container>
	)
};

export default SalesPage;
