import React, {ReactNode, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {initialAdapter} from "adapter";
import {decrementLoading, handleError, incrementLoading} from "../redux/meta/MetaActions";
import ErrorList from "./ErrorList";

interface IProps {
	tokenV2?: any;
	dispatch?: any;
	invite: initialAdapter.IPendingBuyerConnectMsg;
	onDone(): Promise<void>;
}

const BuyerInvitationForSellerConfirmationCard: React.FC<IProps> = (props: IProps) => {

	const {invite} = props;
	const [showReject, setShowReject] = useState(false);
	const [showAccept, setShowAccept] = useState(false);
	const [errors, setErrors] = useState([]);

	/**
	 * factory for the labels & information
	 *
	 * @param label
	 * @param value
	 */
	function makeLabelValuePair(label: string, value: string): ReactNode {
		return (
			<div>
				<h6 className="text-secondary mb-0">
					{`${label}:`}
				</h6>
				<h5 className="text-dark mb-1">
					{value}
				</h5>
			</div>
		)
	}

	function toggleRejectModal(): void {
		setErrors([]);
		setShowReject(!showReject)
	}

	function toggleAcceptModal(): void {
		setErrors([]);
		setShowAccept(!showAccept);
	}

	/**
	 * call api to confirm the rejection
	 *
	 */
	async function confirmReject(): Promise<void> {
		setErrors([]);
		setShowReject(false);
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.rejectBusinessLinkRequest({keyURL: invite.businessLinkRequestKeyURL, timestamp: invite.timestamp}, props.tokenV2);

		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		props.onDone().then().catch();
	}

	/**
	 * call api to confirm acceptance
	 *
	 */
	async function confirmAccept(): Promise<void> {
		setErrors([]);
		setShowAccept(false);
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.acceptBusinessLinkRequest({keyURL: invite.businessLinkRequestKeyURL, timestamp: invite.timestamp}, props.tokenV2);

		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		props.onDone().then().catch();
	}

	const errorNode: ReactNode = errors.length > 0 ? (
		<div className="my-3">
			<ErrorList errors={errors}/>
		</div>
	) : null;

	return (
		<React.Fragment>
			<Modal
				isOpen={showReject}
				fade={true}
				centered={true}
				contentClassName="p-3"
			>
				<ModalHeader>Confirm Invite Rejection</ModalHeader>
				<ModalBody>
					{`Are you sure you want to reject the invite from ${invite.inviterCompanyName}?`}
					{errorNode}
				</ModalBody>
				<ModalFooter className="d-flex justify-content-between">
					<Button color="link" onClick={toggleRejectModal}>
						Cancel
					</Button>
					<Button color="primary" onClick={confirmReject}>
						Yes, Reject
					</Button>
				</ModalFooter>
			</Modal>

			<Modal
				isOpen={showAccept}
				fade={true}
				centered={true}
				contentClassName="p-3"
			>
				<ModalHeader>Confirm Invite Acceptance</ModalHeader>
				<ModalBody>
					{`Are you sure you want to accept the invite from ${invite.inviterCompanyName}?`}
					{errorNode}
				</ModalBody>
				<ModalFooter className="d-flex justify-content-between">
					<Button color="link" onClick={toggleAcceptModal}>
						Cancel
					</Button>
					<Button color="primary" onClick={confirmAccept}>
						Yes, Accept
					</Button>
				</ModalFooter>
			</Modal>

			<Card className="w-100">
				<CardBody className="d-flex flex-column justify-content-between">
					<div>
						{makeLabelValuePair("Company", invite.inviterCompanyName)}
						{makeLabelValuePair("Contact Person", invite.inviterName)}
						{makeLabelValuePair("Contact Email", invite.inviterEmail)}
					</div>

					<div className="d-flex justify-content-between mt-4">
						<Button color="link" className="text-danger" onClick={toggleRejectModal}>
							Reject
						</Button>

						<Button color="primary" onClick={toggleAcceptModal}>
							Accept
						</Button>
					</div>
				</CardBody>
			</Card>
		</React.Fragment>
	)
};

export default connect((store: IStore, props: IProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
		...props,
	}
})(BuyerInvitationForSellerConfirmationCard);
