import {MetaType} from "./MetaReducer";
import {IAction} from "../index";
import {initialAdapter} from "adapter";
import {app} from "../../index";
import {generic400, generic500, genericError} from "../../utils/errors";
import {store} from "../index"

export function incrementLoading(): IAction<MetaType.LOADING, number> {

	return {
		type: MetaType.LOADING,
		payload: 1,
	}

}

export function decrementLoading(): IAction<MetaType.LOADING, number> {

	return {
		type: MetaType.LOADING,
		payload: -1,
	}

}

export function logout(): IAction<MetaType.LOGOUT, undefined> {

	app.auth().signOut().then().catch();

	return {
		type: MetaType.LOGOUT,
	}
}

export function login(token: initialAdapter.IAuthInfo): IAction<MetaType.LOGIN, initialAdapter.IAuthInfo> {
	return {
		type: MetaType.LOGIN,
		payload: token,
	}
}

export function loginV2(token: initialAdapter.IAuthInfo): IAction<MetaType.LOGIN_V2, initialAdapter.IAuthInfo> {
	return {
		type: MetaType.LOGIN_V2,
		payload: token,
	}
}

export function saveDisplayName(name: string): IAction<MetaType.SAVE_DISPLAY_NAME, string> {
	return {
		type: MetaType.SAVE_DISPLAY_NAME,
		payload: name,
	}
}

export function setError(error: string): IAction<MetaType.SET_ERROR, string> {

	return {
		type: MetaType.SET_ERROR,
		payload: error,
	}
}

/**
 * DO NOT DISPATCH THIS FUNCTION, it is NOT an action, but I use it as a helper that can dispatch errors when called.
 * This is so it can return values if the error is a 210 to the component that had the api error.
 *
 * updated function to handle all, or most situations where the frontend must respond graciously to errors & tell the user;
 * for 210s, return array with the business validation errors, else show modal with the error
 *
 * can be used in place of any spot where there is the same mostly copy/pasted lines of error handling in any component or page;
 * the purpose was to abstract it and stop repeating those lines
 *
 * also, decrementLoading if param is true
 *
 * @param res
 * @param decLoading
 */
export function handleError(res: initialAdapter.IAPIResponse2<any>, decLoading: boolean = true): string[] {

	if (decLoading) {
		store.dispatch(decrementLoading());
	}

	if (!res) {
		return [];
	}

	function dispatchError(): void {
		store.dispatch(setError(res.error ? res.error : genericError));
	}

	let errorMessage: string;
	switch (res.status) {
		case 400:
			errorMessage = generic400;
			break;
		case 460:
			store.dispatch(logout());
			dispatchError();
			return [];
		case 500:
			errorMessage = generic500;
			break;
		case 210:
			return (res && res.data) ? (res.data as any).errorList : [];
		default:
			dispatchError();
			return [];
	}

	store.dispatch(setError(errorMessage + res.errorShortCode));
	return [];

}

export function setClientLogo(url: string): IAction<MetaType.LOGO, string> {
	return {
		type: MetaType.LOGO,
		payload: url
	}
}

export function setAccessTypes(accessTypes: string[] = []): IAction<MetaType.ACCESS_TYPES, string[]> {
	return {
		type: MetaType.ACCESS_TYPES,
		payload: accessTypes,
	}
}

export function setUserType(userType: string): IAction<MetaType.USER_TYPE, string> {
	return {
		type: MetaType.USER_TYPE,
		payload: userType,
	}
}
