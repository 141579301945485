import React, {useEffect, useState} from "react";
import {Card, CardBody, CardHeader, Container} from "reactstrap";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {decrementLoading, handleError, incrementLoading} from "../redux/meta/MetaActions";
import {initialAdapter} from "adapter";

interface IProps {
	tokenV2?: any;
	dispatch?: any;
}

const AccountInfoPage: React.FC<IProps> = (props: IProps) => {

	const [accountInfo, setAccountInfo] = useState<initialAdapter.IClientAccountInfo>();

	useEffect(() => {
		getAccountInfo().then().catch();
	}, []);

	async function getAccountInfo(): Promise<void> {
		props.dispatch(incrementLoading());

		const res = await initialAdapter.api.readClientAccountInfo(undefined, props.tokenV2);

		props.dispatch(decrementLoading());

		if (!res.success) {
			handleError(res);
			return;
		}

		setAccountInfo(res.data);
	}

	/**
	 * iterate over the fields in an object and if at least 1 value has length greater than 0,
	 * return true to indicate that the section should be displayed, else return false
	 *
	 * @param obj
	 */
	function determineShowSection(obj: Object): boolean {
		for (const field in obj) {
			if (obj[field].length > 0) {
				return true;
			}
		}

		return false;
	}

	return (
		<Container className="my-4 px-1 px-lg-4 htc-container-narrow">
			{accountInfo && Object.keys(accountInfo).length > 0 && (
				<Card>
					<CardHeader>Account Information</CardHeader>
					<CardBody>
						<div className="mb-5">
							<h1 className="text-hPurple font-weight-bold mb-3 account-info-company-name">{accountInfo.businessName}</h1>
							{accountInfo.registrationNumber && (<h5><span className="text-secondary">Registration Number:{" "}</span><span className="number-display">{accountInfo.registrationNumber}</span></h5>)}
							{accountInfo.establishedInYear && (<h5><span className="text-secondary">Established:{" "}</span><span className="number-display">{accountInfo.establishedInYear}</span></h5>)}
							{accountInfo.companyIndustryGroup && (<h5><span className="text-secondary">Industry Group:{" "}</span>{accountInfo.companyIndustryGroup}</h5>)}
							{accountInfo.companyBusinessType && (<h5><span className="text-secondary">Business Type:{" "}</span>{accountInfo.companyBusinessType}</h5>)}
							{accountInfo.taxID && (<h5><span className="text-secondary">Tax ID:{" "}</span><span className="number-display">{accountInfo.taxID}</span></h5>)}
						</div>

						{determineShowSection(accountInfo.registeredAddress) && (
							<div className="mb-5">
								<h5 className="text-secondary">Registered Address:</h5>
								<h5>
									<p className="mb-1">
										{accountInfo.registeredAddress.addressLine1 && (<span className="mb-1">{accountInfo.registeredAddress.addressLine1}</span>)}
										{accountInfo.registeredAddress.addressLine2 && (<span className="mb-1">{" " + accountInfo.registeredAddress.addressLine2}</span>)}
									</p>

									<p className="mb-1">
										{accountInfo.registeredAddress.city && (<span className="mb-1">{accountInfo.registeredAddress.city}</span>)}
										{accountInfo.registeredAddress.stateRegion && (<span className="mb-1">{" " + accountInfo.registeredAddress.stateRegion}</span>)}
										{accountInfo.registeredAddress.zipCode && (<span className="mb-1">{" " + accountInfo.registeredAddress.zipCode}</span>)}
									</p>

									{accountInfo.registeredAddress.country && (<p className="mb-1">{accountInfo.registeredAddress.country}</p>)}
								</h5>
							</div>
						)}

						<div className="mb-5">
							<h5 className="text-secondary">Company Officers:</h5>
							{(accountInfo.companyOfficer1 || accountInfo.companyOfficer1Email) && (
								<h5 className="d-block d-sm-inline-block" style={{marginRight: "10vw"}}>
									<p className="mb-1">{accountInfo.companyOfficer1}</p>
									<p className="mb-1">{accountInfo.companyOfficer1Email}</p>
								</h5>
							)}

							{(accountInfo.companyOfficer2 || accountInfo.companyOfficer2Email) && (
								<h5 className="d-block d-sm-inline-block">
									<p className="mb-1">{accountInfo.companyOfficer2}</p>
									<p className="mb-1">{accountInfo.companyOfficer2Email}</p>
								</h5>
							)}
						</div>

						<div className="mb-5">
							<h5 className="text-secondary">Contact Person:</h5>
							{accountInfo.contactName && (<h5 className="mb-1">{accountInfo.contactName}</h5>)}
							{accountInfo.contactJobTitle && (<h5 className="mb-1">{accountInfo.contactJobTitle}</h5>)}
							{accountInfo.contactNumber && (<h5 className="mb-1">{accountInfo.contactNumber}</h5>)}
							{accountInfo.contactEmail && (<h5 className="mb-1">{accountInfo.contactEmail}</h5>)}
						</div>

						{determineShowSection(accountInfo.clientBankAccount) && (
							<div className="mb-5">
								<h6 className="text-secondary">Bank Account Details</h6>
								<h5>
									{accountInfo.clientBankAccount.beneficiaryName && (<p><span className="text-secondary h6">Beneficiary Name{" "}</span><span className="mb-1">{accountInfo.clientBankAccount.beneficiaryName}</span></p>)}
									{accountInfo.clientBankAccount.beneficiaryAddress && (<p><span className="text-secondary h6">Beneficiary Address{" "}</span><span className="mb-1">{accountInfo.clientBankAccount.beneficiaryAddress}</span></p>)}
									{accountInfo.clientBankAccount.beneficiaryBank && (<p><span className="text-secondary h6">Beneficiary Bank{" "}</span><span className="mb-1">{accountInfo.clientBankAccount.beneficiaryBank}</span></p>)}
									{accountInfo.clientBankAccount.accountNumber && (<p><span className="text-secondary h6">Account Number{" "}</span><span className="mb-1">{accountInfo.clientBankAccount.accountNumber}</span></p>)}
									{accountInfo.clientBankAccount.swiftCode && (<p><span className="text-secondary h6">Swift Code{" "}</span><span className="mb-1">{accountInfo.clientBankAccount.swiftCode}</span></p>)}
									{accountInfo.clientBankAccount.routingNumber && (<p><span className="text-secondary h6">Routing Number{" "}</span><span className="mb-1">{accountInfo.clientBankAccount.routingNumber}</span></p>)}
									{accountInfo.clientBankAccount.currency && (<p><span className="text-secondary h6">Currency{" "}</span><span className="mb-1">{accountInfo.clientBankAccount.currency}</span></p>)}
									{accountInfo.clientBankAccount.intermediaryBankName && (<p><span className="text-secondary h6">Intermediary Bank Name{" "}</span><span className="mb-1">{accountInfo.clientBankAccount.intermediaryBankName}</span></p>)}
									{accountInfo.clientBankAccount.intermediaryBankSwiftCode && (<p><span className="text-secondary h6">Intermediary Bank Swift Code{" "}</span><span className="mb-1">{accountInfo.clientBankAccount.intermediaryBankSwiftCode}</span></p>)}
								</h5>
							</div>
						)}
					</CardBody>
				</Card>
			)}
		</Container>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
	}
})(AccountInfoPage);
