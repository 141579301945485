import React, {ReactNode, useEffect, useState} from "react";
import {Button, Col, Container, Row} from "reactstrap";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {decrementLoading, handleError, incrementLoading, setError} from "../redux/meta/MetaActions";
import {initialAdapter} from "adapter";
import PendingInvoiceForBuyerApprovalCard from "../components/PendingInvoiceForBuyerApprovalCard";
import {toKey} from "react-select/src/utils";
import {Link} from "react-router-dom";
import {generic400, generic500} from "../utils/errors";
import ErrorList from "../components/ErrorList";
import downloadIFrameGenerator from "../utils/downloadIFrameGenerator";

interface IViewAndApproveInvoicesPageProps {
	tokenV2?: any;
	dispatch?: any;
}

const ViewAndApproveInvoicesPage: React.FC<IViewAndApproveInvoicesPageProps> = (props: IViewAndApproveInvoicesPageProps) => {

	const [list, setList] = useState([]);
	const [enableEmptyMessage, setEnableEmptyMessage] = useState(false);
	const [asyncElements, setAsyncElements] = useState<Array<ReactNode>>([]);
	const [lastAccepted, setLastAccepted] = useState();
	const [errors, setErrors] = useState([]);

	useEffect(() => {
		getList().then().catch();
	}, []);

	useEffect(() => {
		createPendingInvoiceCards(list).then().catch();
	}, [JSON.stringify(list)]);

	async function getList(): Promise<void> {
		setErrors([]);
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.readPendingHTCInvList(undefined, props.tokenV2);
		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		setEnableEmptyMessage(true);
		setList(res.data.items);
	}

	async function viewInvoicePDF(inv: initialAdapter.IHTCInvItemMsg): Promise<void> {
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.readInvPdf({invKeyURL: inv.htcInvKeyURL}, props.tokenV2);
		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		const reader = new FileReader();
		reader.readAsDataURL(res.data);
		reader.onloadend = function () {
			const base64data = reader.result;
			const win = window.open();
			win.document.open();
			win.document.write(downloadIFrameGenerator(inv.htcInvNumber, base64data));
			win.document.close();
		};
	}

	async function createPendingInvoiceCards(invoices: Array<initialAdapter.IHTCInvItemMsg> = []): Promise<any> {
		const p = invoices.map(async (invoice: initialAdapter.IHTCInvItemMsg, i: number) => {
			setErrors([]);
			// console.log("invoice:", invoice);

			async function finishAttachingHelper(): Promise<void> {
				await getList();
			}

			function acceptHelper(invoiceNumber): void {
				// onAccept(invoice.htcInvKeyURL, invoice.timestamp, invoice.htcInvNumber).then().catch();
				setLastAccepted(invoiceNumber);
				getList().then().catch();
			}

			props.dispatch(incrementLoading());

			const attachmentRes = await initialAdapter.api.readHTCInvDocs({keyURL: invoice.htcInvKeyURL}, props.tokenV2);
			props.dispatch(decrementLoading());

			if (!attachmentRes.success) {
				setErrors(handleError(attachmentRes));
				return null;
			}

			async function onClickInvoiceNumberHelper(e: any): Promise<void> {
				e.preventDefault();
				await viewInvoicePDF(invoice);
			}

			return (
				<div className="mb-5 d-flex po-inv-card-col" key={`PendingInvoiceForBuyerApprovalCard-${i}`}>
					<PendingInvoiceForBuyerApprovalCard
						invoiceNumber={invoice.htcInvNumber}
						invoiceDate={invoice.invDate}
						endSupplier={invoice.supplierName}
						invoiceAmount={invoice.invAmount}
						paymentTerm={invoice.paymentTerm}
						attachments={attachmentRes.data.documentList}
						legalText={invoice.legalText}
						htcInvKeyURL={invoice.htcInvKeyURL}
						timestamp={invoice.timestamp}
						poKeyURL={invoice.poKeyURL}
						invKeyURL={invoice.invKeyURL}
						onAttach={finishAttachingHelper}
						onAccept={acceptHelper}
					/>
				</div>
			)
		});

		const fin = await Promise.all(p);
		setAsyncElements(fin);
	}

	return (
		<Container className="my-4 htc-container">
			{lastAccepted && (
				<div>
					<p className="text-center font-italic my-3">
						{`HTC Invoice #${lastAccepted} was accepted.`}
					</p>
				</div>
			)}

			{errors.length > 0 && (
				<div className="my-3">
					<ErrorList errors={errors}/>
				</div>
			)}

			{(list.length < 1 && enableEmptyMessage) && (
				<div>
					<h5 className="my-3 text-center">
						There are no more invoices for acceptance.
					</h5>

					<div className="d-flex justify-content-center my-4">
						<Link to="/home">
							<Button
								component={Link}
								color="hBlue"
								className="px-5"
							>
								Home
							</Button>
						</Link>
					</div>
				</div>
			)}

			<Row>
				{asyncElements}
			</Row>
		</Container>
	);
};

export default connect((store: IStore, props: IViewAndApproveInvoicesPageProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
	}
})(ViewAndApproveInvoicesPage);

export const fakeArr: any[] = [1, 2, 3, 4, 5, 6, 7, 8, 9];
