import React from "react";
import {Button, Card, CardBody, Input, Label} from "reactstrap";
import DashboardBackgroundCard from "./dashboard/DashboardBackgroundCard";
import {Link} from "react-router-dom";
import ErrorList from "./ErrorList";

interface IProps {
	values: any;
	uniqueError?: string;
	errors?: string[];
	onChange(key: any): (e) => void;
	onLogIn(): Promise<void>;
}

const LoginForm: React.FC<IProps> = (props: IProps) => {

	return (
		<DashboardBackgroundCard background="bg-hBlue" className="w-100">
			<CardBody>
				<h2 className="text-white font-weight-semi-bold mb-3">Log In to Your Account</h2>

				<Card>
					<CardBody>
						<form onSubmit={props.onLogIn}>
							<div className="mb-3">
								<Label>Login ID</Label>
								<Input placeholder="Enter Login ID..." onChange={props.onChange("loginID")} value={props.values.loginID}/>
							</div>

							<div className="mb-3">
								<Label>Password</Label>
								<Input type="password" placeholder="Enter Password..." onChange={props.onChange("password")} value={props.values.password}/>
							</div>

							{props.uniqueError !== undefined && (
								<div className="my-3">
									<ErrorList errors={[props.uniqueError]}/>
								</div>
							)}

							{props.errors?.length > 0 && (
								<div className="my-3">
									<ErrorList errors={props.errors}/>
								</div>
							)}

							<div className="d-flex justify-content-center">
								<Button color="hBlue" size="sm" className="px-5" type="submit">
									Log In
								</Button>
							</div>
						</form>

						<hr/>

						<div className="d-flex justify-content-center">
							<Link to="/reset-password">
								<Button color="link" className="text-hPurple link-button">
									Forgot your password?
								</Button>
							</Link>
						</div>
					</CardBody>
				</Card>
			</CardBody>
		</DashboardBackgroundCard>
	);
};

export default LoginForm;
