import React, {ChangeEventHandler, useState} from "react";
import {Button, Col, Input, Label, Row} from "reactstrap";
import AddSupplierFormTabs, {AddSupplierFormFilters} from "./AddSupplierFormTabs";
import SelectOptions from "./SelectOptions";
import AddCustomerFormTabs, {AddCustomerFormFilters} from "./AddCustomerFormTabs";
import {IAddSupplierFormFields} from "./AddSupplierFormWithController";

export interface IAddCustomerFormFields {
	customerName?: string;
	contactPerson?: string;
	contactNumber?: string;
	contactEmail?: string;
	currency?: string;

	billingCountry?: string;
	billingAddress1?: string;
	billingAddress2?: string;
	billingCity?: string;
	billingState?: string;
	billingZip?: string;

	useBillingForShipping?: boolean;
	shippingCountry?: string;
	shippingAddress1?: string;
	shippingAddress2?: string;
	shippingCity?: string;
	shippingState?: string;
	shippingZip?: string;
}

export const defaultAddCustomerForm: IAddCustomerFormFields = {
	customerName: "",
	contactPerson: "",
	contactNumber: "",
	contactEmail: "",
	currency: "",
	billingCountry: "",
	billingAddress1: "",
	billingAddress2: "",
	billingCity: "",
	billingState: "",
	billingZip: "",
	useBillingForShipping: true,
	shippingCountry: "",
	shippingAddress1: "",
	shippingAddress2: "",
	shippingCity: "",
	shippingState: "",
	shippingZip: "",
};

interface IAddCustomerFormWithControllerProps {
	values: IAddCustomerFormFields;
	currencies: string[];
	countries: string[];

	onCancel?(): void;

	onChange(key: keyof IAddCustomerFormFields): ChangeEventHandler<HTMLInputElement>;

	onSave?(): void;

	onSaveAndInvite?(): void;
}

const AddCustomerFormWithController: React.FC<IAddCustomerFormWithControllerProps> = (props: IAddCustomerFormWithControllerProps) => {

	const {values, onCancel, onChange, onSave, onSaveAndInvite, currencies, countries} = props;
	const [tabFilter, setTabFilter] = useState(AddCustomerFormFilters.CONTACT);

	function handleTableFilterChange(newFilter: AddCustomerFormFilters): void {
		setTabFilter(newFilter);
	}

	return (
		<div>
			<AddCustomerFormTabs filter={tabFilter} onFilterChange={handleTableFilterChange}/>
			<hr className="mt-0"/>

			{tabFilter === AddCustomerFormFilters.CONTACT && (
				<React.Fragment>
					<Row className="mb-4">
						<Label for="customerName" xs={12} md={6} lg={4}>Customer Name</Label>
						<Col xs={12} md={6} lg={8}>
							<Input type="text" name="customerName" id="customerName"
							       placeholder="Customer Name" value={values.customerName}
							       onChange={onChange("customerName")}/>
						</Col>
					</Row>
					<Row className="mb-4">
						<Label for="contactPerson" xs={12} md={6} lg={4}>Contact Person</Label>
						<Col xs={12} md={6} lg={8}>
							<Input type="text" name="contactPerson" id="contactPerson"
							       placeholder="Contact Person" value={values.contactPerson}
							       onChange={onChange("contactPerson")}/>
						</Col>
					</Row>
					<Row className="mb-4">
						<Label for="contactNumber" xs={12} md={6} lg={4}>Contact Number</Label>
						<Col xs={12} md={6} lg={8}>
							<Input type="text" name="contactNumber" id="contactNumber"
							       placeholder="Contact Number" value={values.contactNumber}
							       onChange={onChange("contactNumber")}/>
						</Col>
					</Row>
					<Row className="mb-4">
						<Label for="contactEmail" xs={12} md={6} lg={4}>Contact Email</Label>
						<Col xs={12} md={6} lg={8}>
							<Input type="text" name="contactEmail" id="contactEmail"
							       placeholder="Contact Email" value={values.contactEmail}
							       onChange={onChange("contactEmail")}/>
						</Col>
					</Row>
					<Row className="mb-4">
						<Label for="currency" xs={12} md={6} lg={4}>Currency</Label>
						<Col xs={12} md={6} lg={8}>
							<Input type="select" name="currency" id="currency"
							       placeholder="Currency" value={values.currency}
							       onChange={onChange("currency")}
							>
								<option value="" selected className="first-select-option">Select Currency</option>
								<hr/>
								<SelectOptions strings={currencies}/>
							</Input>
						</Col>
					</Row>
				</React.Fragment>
			)}

			{tabFilter === AddCustomerFormFilters.BILLING && (
				<React.Fragment>
					<Row className="mb-4">
						<Label for="billingCountry" xs={12} md={6} lg={4}>Country/Region</Label>
						<Col xs={12} md={6} lg={8}>
							<Input type="select" name="billingCountry" id="billingCountry"
							       placeholder="Country/Region" value={values.billingCountry}
							       onChange={onChange("billingCountry")}
							>
								<option value="" selected className="first-select-option">Select Country/Region</option>
								<hr/>
								<SelectOptions strings={countries}/>
							</Input>
						</Col>
					</Row>
					<Row className="mb-4">
						<Label for="billingAddress1" xs={12} md={6} lg={4}>Address Line 1</Label>
						<Col xs={12} md={6} lg={8}>
							<Input type="text" name="billingAddress1" id="billingAddress1"
							       placeholder="Address Line 1" value={values.billingAddress1}
							       onChange={onChange("billingAddress1")}
							/>
						</Col>
					</Row>
					<Row className="mb-4">
						<Label for="billingAddress2" xs={12} md={6} lg={4}>Address Line 2</Label>
						<Col xs={12} md={6} lg={8}>
							<Input type="text" name="billingAddress2" id="billingAddress2"
							       placeholder="Address Line 2" value={values.billingAddress2}
							       onChange={onChange("billingAddress2")}
							/>
						</Col>
					</Row>
					<Row className="mb-4">
						<Label for="billingCity" xs={12} md={6} lg={4}>City</Label>
						<Col xs={12} md={6} lg={8}>
							<Input type="text" name="billingCity" id="billingCity" placeholder="City"
							       value={values.billingCity}
							       onChange={onChange("billingCity")}
							/>
						</Col>
					</Row>
					<Row className="mb-4">
						<Label for="billingState" xs={12} md={6} lg={4}>State / Province / Region</Label>
						<Col xs={12} md={6} lg={8}>
							<Input type="text" name="billingState" id="billingState"
							       placeholder="State/Province/Region" value={values.billingState}
							       onChange={onChange("billingState")}
							/>
						</Col>
					</Row>
					<Row className="mb-4">
						<Label for="billingZip" xs={12} md={6} lg={4}>Postal / Zip Code</Label>
						<Col xs={12} md={6} lg={8}>
							<Input type="text" name="billingZip" id="billingZip" placeholder="Postal/Zip Code"
							       value={values.billingZip} onChange={onChange("billingZip")}
							/>
						</Col>
					</Row>
				</React.Fragment>
			)}

			{tabFilter === AddCustomerFormFilters.SHIPPING && (
				<React.Fragment>
					<Row className="mb-3">
						<Col xs={2} lg={1}>
							<p><input type="checkbox" name="useBillingForShipping" id="useBillingForShipping"
							          checked={values.useBillingForShipping === true}
							          onChange={onChange("useBillingForShipping")}
							/><label htmlFor="useBillingForShipping"/></p>
						</Col>
						<Label for="useBillingForShipping" xs={10} lg={11}>Same as billing address</Label>
					</Row>

					{!values.useBillingForShipping && (
						<React.Fragment>
							<Row className="mb-4">
								<Label for="shippingCountry" xs={12} md={6} lg={4}>Country/Region</Label>
								<Col xs={12} md={6} lg={8}>
									<Input type="select" name="shippingCountry" id="shippingCountry"
									       placeholder="Country/Region" value={values.shippingCountry}
									       onChange={onChange("shippingCountry")}
									>
										<option value="" selected className="first-select-option">Select Country/Region</option>
										<hr/>
										<SelectOptions strings={countries}/>
									</Input>
								</Col>
							</Row>
							<Row className="mb-4">
								<Label for="shippingAddress1" xs={12} md={6} lg={4}>Address Line 1</Label>
								<Col xs={12} md={6} lg={8}>
									<Input type="text" name="shippingAddress1" id="shippingAddress1"
									       placeholder="Address Line 1" value={values.shippingAddress1}
									       onChange={onChange("shippingAddress1")}
									/>
								</Col>
							</Row>
							<Row className="mb-4">
								<Label for="shippingAddress2" xs={12} md={6} lg={4}>Address Line 2</Label>
								<Col xs={12} md={6} lg={8}>
									<Input type="text" name="shippingAddress2" id="shippingAddress2"
									       placeholder="Address Line 2" value={values.shippingAddress2}
									       onChange={onChange("shippingAddress2")}
									/>
								</Col>
							</Row>
							<Row className="mb-4">
								<Label for="shippingCity" xs={12} md={6} lg={4}>City</Label>
								<Col xs={12} md={6} lg={8}>
									<Input type="text" name="shippingCity" id="shippingCity" placeholder="City"
									       value={values.shippingCity}
									       onChange={onChange("shippingCity")}
									/>
								</Col>
							</Row>
							<Row className="mb-4">
								<Label for="shippingState" xs={12} md={6} lg={4}>State / Province / Region</Label>
								<Col xs={12} md={6} lg={8}>
									<Input type="text" name="shippingState" id="shippingState"
									       placeholder="State/Province/Region" value={values.shippingState}
									       onChange={onChange("shippingState")}
									/>
								</Col>
							</Row>
							<Row className="mb-4">
								<Label for="shippingZip" xs={12} md={6} lg={4}>Postal / Zip Code</Label>
								<Col xs={12} md={6} lg={8}>
									<Input type="text" name="shippingZip" id="shippingZip" placeholder="Postal/Zip Code"
									       value={values.shippingZip} onChange={onChange("shippingZip")}
									/>
								</Col>
							</Row>
						</React.Fragment>
					)}
				</React.Fragment>
			)}

			{/*<div className="d-flex justify-content-end mt-4">*/}
			{/*	<Button color="link" onClick={onSave}>*/}
			{/*		Save*/}
			{/*	</Button>*/}
			{/*</div>*/}

			<div className="d-flex justify-content-between mt-2">
				<Button color="link" onClick={onCancel}>
					Cancel
				</Button>

				<Button color="link" onClick={onSave}>
					Save
				</Button>
				{/*<Button color="link" onClick={onSaveAndInvite}>*/}
				{/*	Save & Invite*/}
				{/*</Button>*/}
			</div>
		</div>
	)
};

export default AddCustomerFormWithController;
