import React from "react";
import {useHistory} from "react-router";
import {useQuery} from "../utils/useQuery";
import {Container} from "reactstrap";
import CreateInvoiceFormFull from "../components/CreateInvoiceFormFull";

const EditInvPage: React.FC = () => {

	const history = useHistory();
	const query = useQuery();
	const invoice: string = query.get("invoice");

	if (!invoice) {
		history.push("/invoice/create");
		return null;
	}

	return (
		<Container className="my-4 px-1 px-lg-4 htc-container">
			<CreateInvoiceFormFull invoice={invoice}/>
		</Container>
	);
};

export default EditInvPage;
