import {IInvoiceItem} from "../components/CreateInvoiceForm";
import * as math from "mathjs";

/**
 * Calculate the base order total by iterating over the invoice items
 * & multiplying the quantities against the prices, if both exist at that index.
 *
 * Used by both the Create Purchase Order & Create Invoice forms.
 *
 * @param items
 */
export function calculateBaseOrderTotal(items: IInvoiceItem[] = []): number {
	let total = 0;
	let i: number = 0;
	for (i; i < items.length; i++) {
		if (items[i].quantity && items[i].price) {
			let sub = math.multiply(items[i].quantity, items[i].price);
			total = math.add(total, sub) as number;
		}
	}
	return total;
}
