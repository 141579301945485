/**
 * Takes an array of arrays and returns a `,` separated csv content.
 * @param {(string|number)[][]} table
 * @returns {string}
 */
function csvFormatter(table: Array<Array<string | number>>): string {
	const replacer = (key, value) => value === null ? '' : value;
	const csvContent: string = table.map((row) => {
		return row.map((cell) => {
			return JSON.stringify(cell, replacer);
		}).join(',');
	}).join('\r\n');

	return "data:text/csv;charset=utf-8," + csvContent;
}

/**
 * Download a CSV of tabular content
 * @param data
 * @param headers
 * @param filename
 */
export function csvExport(data: Array<Array<string | number>>,headers: Array<string>, filename: string) {
	const csvContent: string = csvFormatter([headers, ...data]);
	const encodedUri: string = encodeURI(csvContent);
	const link: HTMLAnchorElement = document.createElement("a");
	link.setAttribute("href", encodedUri);
	link.setAttribute("download", filename);
	document.body.appendChild(link); // Required for FF
	link.click(); // This will download the data file.
}
