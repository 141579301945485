import React from "react";
import {Button, Card, Col, Input, Label, Nav, NavItem, NavLink, Row} from "reactstrap";
import classnames from "classnames";

export enum ClientTableFilters {
	BUYER = "BUYER",
	SELLER = "SELLER",
}

export interface IClientTableFilterProps {
	filter: ClientTableFilters;
	search?: string;

	onFilterChange?(filter: ClientTableFilters): void;

	onSearchChange?(search: string): void;
	searchVal?: string;
	handleSearch?(): void;
}

const ClientTableFilter: React.FC<IClientTableFilterProps> = (props: IClientTableFilterProps) => {

	const {filter, search, onFilterChange, onSearchChange, handleSearch} = props;

	function filterChangeHelper(newFilter: ClientTableFilters): () => void {
		return () => {
			onFilterChange(newFilter);
		}
	}

	function searchChangeHelper(event: any): void {
		onSearchChange(event.target.value);
	}

	function onSubmitHelper(e): void {
		e.preventDefault();
		handleSearch();
	}

	return (
		<div>
			<Row className="no-gutters tab-table-fix">
				{/*<Col*/}
				{/*	xs={12}*/}
				{/*	sm={6}*/}
				{/*	className="d-flex align-items-end mt-3 mt-sm-0 no-gutters order-12 order-sm-0"*/}
				{/*>*/}
				{/*	<Nav tabs>*/}
				{/*		<NavItem>*/}
				{/*			<NavLink*/}
				{/*				className={classnames({active: filter === ClientTableFilters.BUYER}, "cursor-pointer")}*/}
				{/*				onClick={filterChangeHelper(ClientTableFilters.BUYER)}*/}
				{/*			>*/}
				{/*				Buyer*/}
				{/*			</NavLink>*/}
				{/*		</NavItem>*/}
				{/*		<NavItem>*/}
				{/*			<NavLink*/}
				{/*				className={classnames({active: filter === ClientTableFilters.SELLER}, "cursor-pointer")}*/}
				{/*				onClick={filterChangeHelper(ClientTableFilters.SELLER)}*/}
				{/*			>*/}
				{/*				Supplier*/}
				{/*			</NavLink>*/}
				{/*		</NavItem>*/}
				{/*		/!*<NavItem>*!/*/}
				{/*		/!*	<NavLink*!/*/}
				{/*		/!*		className={classnames({active: filter === ClientTableFilters.ALL}, "cursor-pointer")}*!/*/}
				{/*		/!*		onClick={filterChangeHelper(ClientTableFilters.ALL)}*!/*/}
				{/*		/!*	>*!/*/}
				{/*		/!*		All*!/*/}
				{/*		/!*	</NavLink>*!/*/}
				{/*		/!*</NavItem>*!/*/}
				{/*	</Nav>*/}
				{/*</Col>*/}

				<Col
					xs={12}
					sm={6}
					className="d-flex justify-content-center justify-content-sm-start align-items-center order-0 order-sm-12"
				>
					<Row className="pb-2">
						<form onSubmit={onSubmitHelper} className="d-flex">
							<Label for="tableSearch" xs={4} className="text-right">Search</Label>
							<Col xs={8}>
								<Input
									type="text"
									name="tableSearch"
									id="tableSearch"
									placeholder="Search..."
									value={search}
									onChange={searchChangeHelper}
								/>
							</Col>
						</form>
					</Row>
				</Col>
			</Row>
		</div>
	);
};

export default ClientTableFilter;
