import React from "react";
import {Nav, NavItem, NavLink} from "reactstrap";
import classnames from "classnames";

export enum InvoiceTableFilters {
	OPEN = "open",
	PAID = "paid",
}

interface IInvoiceTableTabsProps {
	filter: InvoiceTableFilters;
	onFilterChange?(filter: InvoiceTableFilters): void;
}

const InvoiceTableTabs: React.FC<IInvoiceTableTabsProps> = (props: IInvoiceTableTabsProps) => {

	const {filter, onFilterChange} = props;

	function filterChangeHelper(newFilter: InvoiceTableFilters): () => void {
		return () => {
			onFilterChange(newFilter);
		}
	}

	return (
		<div>
			<Nav tabs className="w-100 border-bottom-0 tab-table-fix tab-scroller">
				<NavItem>
					<NavLink
						className={classnames({active: filter === InvoiceTableFilters.OPEN}, "cursor-pointer")}
						onClick={filterChangeHelper(InvoiceTableFilters.OPEN)}
					>
						Open
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						className={classnames({active: filter === InvoiceTableFilters.PAID}, "cursor-pointer")}
						onClick={filterChangeHelper(InvoiceTableFilters.PAID)}
					>
						Paid
					</NavLink>
				</NavItem>
			</Nav>
		</div>
	)
};

export default  InvoiceTableTabs;
