import React, {ReactNode} from "react";
import {Card, CardBody} from "reactstrap";

interface IProps {
	background: string;
	children: ReactNode;
	className?: string;
}

const DashboardBackgroundCard: React.FC<IProps> = (props: IProps) => {

	const {background, children, className} = props;

	return (
		<Card className={"dashboard-card dashboard-background-card" + (className ? ` ${className}` : "")}>
			<div className="db-background-circle-container">
				<div className={background}/>
			</div>

			{children}
		</Card>
	);
};

export default DashboardBackgroundCard;
