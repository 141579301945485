import React, {ReactNode, useEffect, useState} from "react";
import {initialAdapter} from "adapter";
import {Button, Carousel, CarouselItem} from "reactstrap";
import {defaultCarouselImage} from "../pages/Login2";

interface IProps {
	marketingItems: Array<initialAdapter.IMarketingItemMsg>;
	contentHeight: any;
}

const LoginCarouselDesktop: React.FC<IProps> = (props: IProps) => {

	const [selectedItem, setSelectedItem] = useState(0);
	const [animating, setAnimating] = useState(false);

	// if (!props.marketingItems || props.marketingItems.length < 1) {
	// 	return null;
	// }

	const next = () => {
		if (animating) return;
		const nextIndex = selectedItem === props.marketingItems.length - 1 ? 0 : selectedItem + 1;
		setSelectedItem(nextIndex);
	};

	const previous = () => {
		if (animating) return;
		const nextIndex = selectedItem === 0 ? props.marketingItems.length - 1 : selectedItem - 1;
		setSelectedItem(nextIndex);
	};

	const goToIndex = (newIndex) => {
		if (animating) return;
		setSelectedItem(newIndex);
	};

	function makeRadioNobs(_marketingItems: Array<initialAdapter.IMarketingItemMsg> = []): ReactNode {
		return _marketingItems.map((_marketingItem: initialAdapter.IMarketingItemMsg, i: number) => {

			function selectOne(): void {
				setSelectedItem(i);
			}

			return (
				<div className={"login-carousel-selector" + (selectedItem === i ? " login-carousel-selector-selected" : "")} onClick={selectOne}/>
			);
		});
	}

	const slides = (props.marketingItems?.length > 0 ? props.marketingItems : []).map((item) => {
		return (
			<CarouselItem
				onExiting={() => setAnimating(true)}
				onExited={() => setAnimating(false)}
				key={item.imgURL}
				className="position-relative h-100"
			>
				<img src={item.imgURL} alt={item.imgURL} className="h-100 w-100" style={{objectFit: "cover"}}/>
				<div className="position-absolute login-carousel-message-board-desktop" style={{backgroundColor: `rgba(255, 255, 255, ${item.translucentLevel / 100})`}}>
					<div style={{width: "40%"}}>
					<h1 className="text-hPurple font-weight-bold mb-2">{item.msgHeader}</h1>

					<h2 className="mb-3 font-weight-semi-bold" style={{color: item.msgFontColor}}>
						{item.msgContent}
					</h2>

					<div className="mb-3">
						<div className="login-carousel-btn-container">
							<a href={item.btnLink} target="_blank" rel="noreferrer noopener">
								<Button color="hBlue" size="sm" className="w-100">
									{item.btnLabel}
								</Button>
							</a>
						</div>
					</div>

					{props.marketingItems?.length > 0 &&
						<div className="login-carousel-selector-container-desktop">
							{makeRadioNobs(props.marketingItems)}
						</div>
					}
					</div>
				</div>
			</CarouselItem>
		);
	});

	return (
		<div className="pb-4">
			<div style={{height: props.contentHeight}}>
				{props.marketingItems?.length > 0 ? (
					<Carousel
						activeIndex={selectedItem}
						next={next}
						previous={previous}
						className="h-100 reactstrap-carousel-selector-helper"
						autoPlay={false}
						interval={0}
						ride="carousel"
					>
						{slides}
					</Carousel>
				) : (
                    <Carousel
                        activeIndex={0}
                        next={() => {}}
                        previous={() => {}}
                        className="h-100"
                        autoPlay={false}
                    >
	                    {
	                    	[
			                    <CarouselItem
				                    onExiting={() => {}}
				                    onExited={() => {}}
				                    key={defaultCarouselImage}
				                    className="position-relative h-100"
			                    >
				                    <img src={defaultCarouselImage} alt="Harbor" className="h-100 w-100" style={{objectFit: "cover"}}/>
			                    </CarouselItem>
		                    ]
	                    }
                    </Carousel>
				)}
			</div>
		</div>
	);
};

export default LoginCarouselDesktop;
