import React, {ReactNode} from "react";

interface IProps {
	announcements: string[];
}

const LoginAnnouncementsMobile: React.FC<IProps> = (props: IProps) => {

	if (!props.announcements || props.announcements.length < 1) {
		return null;
	}

	function createAnnouncements(_announcements: string[] = []): ReactNode {
		return _announcements.map((_announcement: string, i: number) => {
			return (
				<p className={"text-white text-center " + ((i + 1) < _announcements.length ? "mb-3" : "mb-0")}>
					{_announcement}
				</p>
			);
		})
	}

	return (
		<div className="p-4 bg-hPurple">
			{createAnnouncements(props.announcements)}
		</div>
	)
};

export default LoginAnnouncementsMobile;
