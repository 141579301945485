import React, {useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Input, InputProps} from "reactstrap";
import moment from "moment";
import {isMobile} from 'react-device-detect';

interface ICustomDatePickerProps {
	inputProps: InputProps;
	date: string;

	onDateChange(d: string): any;
}

const CustomDatePicker: React.FC<ICustomDatePickerProps> = (props: ICustomDatePickerProps) => {

	const {inputProps} = props;
	const [mobilePlaceholder, setMobilePlaceholder] = useState(inputProps ? inputProps.placeholder : "");
	const splitDate: string[] = props.date.split("-");
	const date = splitDate.length > 1 ? new Date(parseInt(splitDate[0]), parseInt(splitDate[1]) - 1, parseInt(splitDate[2])) : null;

	function dateOnChangeHelper(d: Date): void {
		props.onDateChange(moment(d).format("YYYY-MM-DD"));
	}

	function test(e): void {
		dateOnChangeHelper(e.target.value);
	}

	function handleDateChangeRaw(e) {
		e.preventDefault();
	}

	function f(): void {
		setMobilePlaceholder("");
	}

	return (
		<div className="react-datepicker-wrapper-parent">
			{isMobile ? (
				<Input
					{...inputProps}
					onChange={test}
					onFocus={f}
					placeholder={(inputProps && inputProps.value && (inputProps.value).toString().length > 0) ? "" : mobilePlaceholder}
				/>
			) : (
				<DatePicker
					selected={props.date.length > 0 ? date : null}
					onChange={dateOnChangeHelper}
					showMonthDropdown={true}
					showYearDropdown={true}
					dropdownMode="select"
					dateFormat="yyyy-MM-dd"
					onChangeRaw={handleDateChangeRaw}
					className="w-100"
					placeholderText={inputProps.placeholder}
					customInput={
						<Input
							name={inputProps.name}
							id={inputProps.id}
							placeholder={inputProps.placeholder}
						/>
					}
				/>
			)}
		</div>
	);
};

export default CustomDatePicker;
