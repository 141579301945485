import * as React from "react";
import {Redirect, Route, RouteProps} from "react-router";
import {store} from "../redux";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {initialAdapter} from "adapter";
import {legacyIncludes} from "../utils/arrays";

interface IAuthenticatedRouteProps extends RouteProps {
	tokenV2?: initialAdapter.IAuthInfo;
	accessTypeBuyer?: boolean;
	accessTypeSeller?: boolean;
	accessTypeAccount?: boolean;
	accessTypeSelfReg?: boolean;
	// accessRestriction?: "buyer" | "seller" | "account";
	accessRestriction?: string[];
}

const AuthenticatedRoute: React.FunctionComponent<IAuthenticatedRouteProps> = ({component: Component, ...rest}: any): JSX.Element => {

	const {tokenV2, accessTypeBuyer, accessTypeSeller, accessTypeAccount, accessTypeSelfReg, accessRestriction} = rest;

	function determinePermission(): boolean {
		if (!accessRestriction) {return true}

		if (
			(legacyIncludes(accessRestriction, "buyer") && !accessTypeBuyer) ||
			(legacyIncludes(accessRestriction, "seller") && !accessTypeSeller) ||
			(legacyIncludes(accessRestriction, "account") && !accessTypeAccount)
		) {
			return false;
		}

		return true;
	}

	return (
		<React.Fragment>
			{tokenV2 ? (
				<Route
					{...rest}
					render={(props: any) =>
						(!accessTypeSelfReg && store.getState().metaStore.tokenV2 && (!accessRestriction || determinePermission())) ? (
							<Component {...props}/>
						) : (
							<Redirect
								to={{
									pathname: "/",
									state: {from: props.location}
								}}
							/>
						)
					}
				/>
			) : (
				<Redirect to="/"/>
			)}
		</React.Fragment>
	)
};

export default connect((store: IStore, props: IAuthenticatedRouteProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
		accessTypeBuyer: store.metaStore.accessTypeBuyer,
		accessTypeSeller: store.metaStore.accessTypeSeller,
		accessTypeAccount: store.metaStore.accessTypeAccount,
		accessTypeSelfReg: store.metaStore.accessTypeSelfReg,
	}
})(AuthenticatedRoute);
