import React, {useState} from "react";
import {Button, Modal, ModalHeader} from "reactstrap";
import {FiUploadCloud} from "react-icons/fi";
import {decrementLoading, handleError, incrementLoading, setError} from "../redux/meta/MetaActions";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {initialAdapter} from "adapter";
import {getFileType} from "../utils/files";
import DocumentListItemWithIcon from "./DocumentListItemWithIcon";
import {generic400, generic500} from "../utils/errors";
import ErrorList from "./ErrorList";

export interface IPendingInvoiceEditMeta {
	keyURL: string;
	timestamp: string;
}

interface IAttachDocumentsToPendingInvoiceBuyerApprovalProcessModalProps {
	tokenV2?: any;
	dispatch?: any;
	title?: string;
	isOpen: boolean;
	editingMeta: IPendingInvoiceEditMeta;

	closeModal(): void;

	onUpload(): Promise<any>;
}

const AttachDocumentsToPendingInvoiceBuyerApprovalProcessModal: React.FC<IAttachDocumentsToPendingInvoiceBuyerApprovalProcessModalProps> = (props: IAttachDocumentsToPendingInvoiceBuyerApprovalProcessModalProps) => {

	const {tokenV2, title, isOpen, editingMeta, closeModal, onUpload} = props;

	const [file, setFile] = useState<File>();
	const [fileURL, setFileURL] = useState<string | ArrayBuffer>();
	const [errors, setErrors] = useState([]);

	function resetAndClose(): void {
		setFile(null);
		setFileURL(null);
		closeModal();
	}

	function reset(e: React.MouseEvent<HTMLAnchorElement>): void {
		e.preventDefault();
		setFile(null);
		setFileURL(null);
	}

	async function uploadFiles(): Promise<void> {
		setErrors([]);
		props.dispatch(incrementLoading());
		const fileType: string = getFileType(file);

		const getURLRes = await initialAdapter.api.readHTCInvDocumentUploadURL({fileType}, tokenV2);

		if (!getURLRes.success) {
			props.dispatch(decrementLoading());
			setErrors(handleError(getURLRes));
			return;
		}

		const uploadToURLRes = await initialAdapter.api.uploadToSignedURL(getURLRes.data.signedURL, file);

		if (!uploadToURLRes.success) {
			props.dispatch(decrementLoading());
			setErrors(handleError(uploadToURLRes));
			return;
		}

		const attachRes = await initialAdapter.api.attachDocToHTCInv({
			keyURL: editingMeta ? editingMeta.keyURL : "",
			timestamp: editingMeta ? editingMeta.timestamp : "",
			documentType: fileType,
			documentName: file.name,
			documentID: getURLRes.data.fileid,
		}, tokenV2);

		if (!attachRes.success) {
			props.dispatch(decrementLoading());
			setErrors(handleError(attachRes));
			return;
		}

		props.dispatch(decrementLoading());
		// await onUpload({timestamp: attachRes.data.data[0], keyURL: attachRes.data.data[1]});
		await onUpload();
		resetAndClose();
	}

	function onFileChange(e: any): void {
		props.dispatch(incrementLoading());
		if (!e.target.files || (e.target.files && e.target.files.length < 1)) {
			props.dispatch(decrementLoading());
			return;
		}
		setFile(e.target.files[0]);
		const fr = new FileReader();
		fr.onload = () => {
			setFileURL(fr.result);
			props.dispatch(decrementLoading());
		};
		fr.readAsDataURL(e.target.files[0]);
	}

	return (
		<Modal
			isOpen={isOpen}
			fade={true}
			centered={true}
			contentClassName="p-2"
			toggle={resetAndClose}
		>
			<div className="px-3">
				<ModalHeader toggle={resetAndClose} className="mb-3"><h4>{title ? title : "Attach Document"}</h4>
				</ModalHeader>

				<div className="upload-btn-wrapper my-3 cursor-pointer">
					<div
						className="position-relative overflow-hidden w-100 d-inline-flex flex-column align-items-center upload-btn-wrapper-inner p-3">
						<FiUploadCloud size="4em" color="#5bc0de"/>
						<input
							type="file"
							name="myfile"
							className="cursor-pointer"
							onChange={onFileChange}
						/>
						<span className="mt-2 text-center">
								Browse or drop files here
							</span>
					</div>
				</div>
			</div>

			{file && (
				<div className="my-2 px-3">
					<DocumentListItemWithIcon
						name={file.name}
						onDelete={reset}
					/>
				</div>
			)}

			{errors.length > 0 && (
				<div className="my-3">
					<ErrorList errors={errors}/>
				</div>
			)}

			<div className="d-flex justify-content-between">
				<Button color="link" onClick={resetAndClose}>
					Cancel
				</Button>

				<Button color="link" disabled={!file} onClick={uploadFiles}>
					Upload File
				</Button>
			</div>
		</Modal>
	);
};

export default connect((store: IStore, props: IAttachDocumentsToPendingInvoiceBuyerApprovalProcessModalProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
		...props,
	}
})(AttachDocumentsToPendingInvoiceBuyerApprovalProcessModal);
