import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Button, CardBody, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {decrementLoading, handleError, incrementLoading} from "../redux/meta/MetaActions";
import {initialAdapter} from "adapter";
import HTCModalHeaderCloseButton from "./HTCModalHeaderCloseButton";

interface IProps {
	tokenV2?: any;
	dispatch?: any;
	isOpen: boolean;
	keyURL: string;

	onClose(): void;
}

const ViewSupplierInfoModal: React.FC<IProps> = (props: IProps) => {

	const {isOpen, keyURL} = props;
	const [errors, setErrors] = useState([]);
	const [supplierInfo, setSupplierInfo] = useState<initialAdapter.IClientAccountInfo>();

	useEffect(() => {
		if (isOpen) {
			readSupplierDetails().then().catch();
		}
	}, [isOpen]);

	async function readSupplierDetails(): Promise<void> {
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.readBusinessCompanyAccountInfo({keyURL}, props.tokenV2);

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		props.dispatch(decrementLoading());
		setSupplierInfo(res.data);
	}

	/**
	 * iterate over the fields in an object and if at least 1 value has length greater than 0,
	 * return true to indicate that the section should be displayed, else return false
	 *
	 * @param obj
	 */
	function determineShowSection(obj: Object): boolean {
		if (!obj) {
			return false;
		}

		for (const field in obj) {
			if (obj[field].length > 0) {
				return true;
			}
		}

		return false;
	}

	return (
		<Modal
			isOpen={isOpen}
			fade={true}
			centered={true}
			className="htc-modal modal-max-700"
			toggle={props.onClose}
		>
			<HTCModalHeaderCloseButton onClick={props.onClose}/>
			<CardHeader>Business Information</CardHeader>
			{supplierInfo && (
				<CardBody>
					<h1 className="text-hPurple font-weight-bold mb-3">{supplierInfo.businessName}</h1>

					<div className="mb-4">
						{supplierInfo.registrationNumber && (<p className="mb-2"><span className="font-weight-light">Registration Number:{" "}</span><span className="number-display">{supplierInfo.registrationNumber}</span></p>)}
						{supplierInfo.establishedInYear && (<p className="mb-2"><span className="font-weight-light">Established:{" "}</span><span className="number-display">{supplierInfo.establishedInYear}</span></p>)}
						{supplierInfo.companyIndustryGroup && (<p className="mb-2"><span className="font-weight-light">Industry Group:{" "}</span><span>{supplierInfo.companyIndustryGroup}</span></p>)}
						{supplierInfo.companyBusinessType && (<p className="mb-2"><span className="font-weight-light">Business Type:{" "}</span><span>{supplierInfo.companyBusinessType}</span></p>)}
						{supplierInfo.taxID && (<p className="mb-2"><span className="font-weight-light">Tax ID:{" "}</span><span className="number-display">{supplierInfo.taxID}</span></p>)}
					</div>

					{determineShowSection(supplierInfo.registeredAddress) && (
						<div className="mb-4">
							<p className="font-weight-light mb-1">Registered Address:</p>
							<p className="number-display mb-0">
								{supplierInfo.registeredAddress.addressLine1 && (
									<span>{supplierInfo.registeredAddress.addressLine1}</span>)}
								{supplierInfo.registeredAddress.addressLine2 && (
									<span>{" " + supplierInfo.registeredAddress.addressLine2}</span>)}
							</p>

							<p className="number-display mb-0">
								{supplierInfo.registeredAddress.city && (
									<span>{supplierInfo.registeredAddress.city}</span>)}
								{supplierInfo.registeredAddress.stateRegion && (
									<span>{" " + supplierInfo.registeredAddress.stateRegion}</span>)}
								{supplierInfo.registeredAddress.zipCode && (
									<span>{" " + supplierInfo.registeredAddress.zipCode}</span>)}
							</p>

							{supplierInfo.registeredAddress.country && (
								<p className="mb-0">{supplierInfo.registeredAddress.country}</p>)}
						</div>
					)}

					<p className="font-weight-light mb-1">Company Officer(s):</p>
					<Row className="mb-4">
						<Col xs={12} sm={12}>
							{(supplierInfo.companyOfficer1 || supplierInfo.companyOfficer1Email) && (
								<div>
									<p className="mb-0">{supplierInfo.companyOfficer1}</p>
									<a className="text-hPurple" target="_blank"
									   href={`mailto:${supplierInfo.companyOfficer1Email}`}>{supplierInfo.companyOfficer1Email}</a>
								</div>
							)}
						</Col>

						{(supplierInfo.companyOfficer2 || supplierInfo.companyOfficer2Email) && (
							<Col xs={12} sm={12} className="mt-2">
								<div>
									<p className="mb-0">{supplierInfo.companyOfficer2}</p>
									<a className="text-hPurple" target="_blank"
									   href={`mailto:${supplierInfo.companyOfficer2Email}`}>{supplierInfo.companyOfficer2Email}</a>
								</div>
							</Col>
						)}
					</Row>

					<div className="mb-4">
						<p className="font-weight-light mb-0">Contact Person:</p>
						{supplierInfo.contactName && (<p className="mb-0">{supplierInfo.contactName}</p>)}
						{supplierInfo.contactJobTitle && (<p className="mb-0">{supplierInfo.contactJobTitle}</p>)}
						{supplierInfo.contactNumber && (<p className="mb-0">{supplierInfo.contactNumber}</p>)}
						{supplierInfo.contactEmail && (<a className="mb-0 text-hPurple" target="_blank" href={`mailto:${supplierInfo.contactEmail}`}>{supplierInfo.contactEmail}</a>)}
					</div>

					{determineShowSection(supplierInfo.clientBankAccount) && (
						<div className="mb-4">
							<p className="font-weight-light mb-1">Bank Account Details:</p>
							<div>
								{supplierInfo.clientBankAccount.beneficiaryName && (<p className="mb-1"><span className="font-weight-light">Beneficiary Name{" "}</span><span>{supplierInfo.clientBankAccount.beneficiaryName}</span></p>)}
								{supplierInfo.clientBankAccount.beneficiaryAddress && (<p className="mb-1"><span className="font-weight-light">Beneficiary Address{" "}</span><span>{supplierInfo.clientBankAccount.beneficiaryAddress}</span></p>)}
								{supplierInfo.clientBankAccount.beneficiaryBank && (<p className="mb-1"><span className="font-weight-light">Beneficiary Bank{" "}</span><span>{supplierInfo.clientBankAccount.beneficiaryBank}</span></p>)}
								{supplierInfo.clientBankAccount.accountNumber && (<p className="mb-1"><span className="font-weight-light">Account Number / IBAN{" "}</span><span className="number-display">{supplierInfo.clientBankAccount.accountNumber}</span></p>)}
								{supplierInfo.clientBankAccount.swiftCode && (<p className="mb-1"><span className="font-weight-light">SWIFT Code{" "}</span><span className="number-display">{supplierInfo.clientBankAccount.swiftCode}</span></p>)}
								{supplierInfo.clientBankAccount.routingNumber && (<p className="mb-1"><span className="font-weight-light">Routing Number{" "}</span><span className="number-display">{supplierInfo.clientBankAccount.routingNumber}</span></p>)}
								{supplierInfo.clientBankAccount.currency && (<p className="mb-1"><span className="font-weight-light">Currency{" "}</span><span>{supplierInfo.clientBankAccount.currency}</span></p>)}
								{supplierInfo.clientBankAccount.intermediaryBankName && (<p className="mb-1"><span className="font-weight-light">Intermediary Bank Name{" "}</span><span>{supplierInfo.clientBankAccount.intermediaryBankName}</span></p>)}
								{supplierInfo.clientBankAccount.intermediaryBankSwiftCode && (<p className="mb-1"><span className="font-weight-light">Intermediary Bank Swift Code{" "}</span><span className="number-display">{supplierInfo.clientBankAccount.intermediaryBankSwiftCode}</span></p>)}
							</div>
						</div>
					)}
				</CardBody>
			)}
		</Modal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
		...props,
	}
})(ViewSupplierInfoModal);
