import React, {useEffect, useState} from "react";
import {Button, CardBody, CardHeader, Modal, ModalHeader} from "reactstrap";
import HTCModalHeaderCloseButton from "./HTCModalHeaderCloseButton";

interface IJustAcceptedPOModalProps {
	poNumber: string;
	done(): void;
}

const JustAcceptedPOModal: React.FC<IJustAcceptedPOModalProps> = (props: IJustAcceptedPOModalProps) => {

	const {poNumber} = props;
	const [dataClone, setDataClone] = useState<any>();

	useEffect(() => {
		setDataClone(poNumber);
	}, [JSON.stringify(poNumber)]);

	return (
		<Modal
			isOpen={poNumber && poNumber.length > 0}
			fade={true}
			centered={true}
			contentClassName=""
			className="htc-modal modal-max-500"
		>
			<HTCModalHeaderCloseButton onClick={props.done}/>
			<CardHeader>Purchase Order Accepted</CardHeader>

			<CardBody>
				<p className="mb-3">
					Purchase order <span className="text-hPurple font-weight-bold number-display">{dataClone}</span> has been accepted. You can now press the matching "Create Invoice" button to create an invoice from this purchase order.
				</p>

				<div className="d-flex justify-content-center">
					<Button color="hBlue" size="sm" className="px-5" onClick={props.done}>
						Done
					</Button>
				</div>
			</CardBody>
		</Modal>
	);
};

export default JustAcceptedPOModal;
