import React from "react";
import DashboardBackgroundCard from "./DashboardBackgroundCard";
import {CardBody} from "reactstrap";
import AccountOverviewValueDisplay from "./AccountOverviewValueDisplay";

interface IProps {
	pastDue: number;
	current: number;
	totalOutstanding: number;
}

const DashboardAccountsPayable: React.FC<IProps> = (props: IProps) => {

	const {pastDue, current, totalOutstanding} = props;

	return (
		<DashboardBackgroundCard background="bg-hPurple">
			<CardBody>
				<h2 className="text-white text-center mb-3">Accounts Payable</h2>

				<AccountOverviewValueDisplay title="Past Due" value={pastDue}/>
				<AccountOverviewValueDisplay title="Current" value={current}/>

				<hr className="my-0"/>

				<AccountOverviewValueDisplay title="Total AP Outstanding" value={totalOutstanding} className="mt-4"/>
			</CardBody>
		</DashboardBackgroundCard>
	);
};

export default DashboardAccountsPayable;
