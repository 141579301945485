import React, {useEffect, useState} from "react";
import SalesTableTabs, {SalesTableFilters} from "./SalesTableTabs";
import SalesTable from "./SalesTable";
import {decrementLoading, handleError, incrementLoading, logout, setError} from "../redux/meta/MetaActions";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {initialAdapter} from "adapter";
import cloneDeep from "lodash.clonedeep";
import {Button, Input, Label} from "reactstrap";
import {legacyIncludes} from "../utils/arrays";
import ViewInvoicePDFModal2 from "./ViewInvoicePDFModal2";
import {useHistory} from "react-router";
import {generic400, generic500} from "../utils/errors";
import ErrorList from "./ErrorList";
import ConfirmDeleteDraftPOModal from "./ConfirmDeleteDraftPOModal";
import ConfirmDeleteDraftInvoiceModal from "./ConfirmDeleteDraftInvoiceModal";
import SalesSummary from "./SalesSummary";
import ConfirmMarkInvoiceAsVoidModal from "./ConfirmMarkInvoiceAsVoidModal";
import ConfirmMarkInvoiceAsPaidModal from "./ConfirmMarkInvoiceAsPaidModal";
import TableFilterHelper, {defaultMainSubFilter, ITableFilterMainAndSub} from "./TableFilterHelper";
import TabSelector from "./TabSelector";

interface ISalesTableWithControllerProps {
	tokenV2?: any;
	dispatch?: any;
}

const defaultSalesData: any = {};

const SalesTableWithController: React.FC<ISalesTableWithControllerProps> = (props: ISalesTableWithControllerProps) => {

	const history = useHistory();
	const {tokenV2} = props;
	const [salesData, setSalesData] = useState<any>(defaultSalesData);
	const [errors, setErrors] = useState([]);
	const [mainSubFilter, setMainSubFilter] = useState<ITableFilterMainAndSub>(defaultMainSubFilter);
	const [pseudoSelection, setPseudoSelection] = useState(null);

	useEffect(() => {
		setPseudoSelection(null);
		getSalesTableData().then().catch();
	}, [JSON.stringify(mainSubFilter)]);

	function onCreateInvoice(): void {
		history.push("/invoice/create");
	}

	/**
	 * Call api for new sales data
	 *
	 */
	async function getSalesTableData(): Promise<void> {
		props.dispatch(incrementLoading());

		const res = await initialAdapter.api.readInvoiceSummary({ urlsafeNextCursor: "", ...mainSubFilter}, tokenV2);

		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		if (!pseudoSelection && (res.data.mainFilters && res.data.mainFilters.length > 0)) {
			setPseudoSelection(res.data.mainFilters[0]);
		}

		setSalesData(res.data);
	}

	return (
		<div>
			{(salesData && salesData.actions) && legacyIncludes(salesData.actions, "createinvoice") && (
				<div className="d-flex justify-content-end my-4">
					<Button className="px-4" color="hPurple" size="sm" onClick={onCreateInvoice}>
						Create an invoice
					</Button>
				</div>
			)}

			<div className="my-4 mb-5">
				{salesData && (
					<SalesSummary overdue={salesData.arPastDueAmount} comingDue={salesData.arCurrentAmount} pending={salesData.arPendingAmount}/>
				)}
			</div>

			{errors.length > 0 && (
				<div className="my-3">
					<ErrorList errors={errors}/>
				</div>
			)}

			<TabSelector
				tabs={[{title: "Sales", value: "Sales",}]}
				value={"Sales"}
				onClick={() => {}}
			/>

			{salesData && ((salesData.mainFilters && salesData.mainFilters.length > 0) || (salesData.subFilters && salesData.subFilters.length > 0)) && (
				<TableFilterHelper
					filterInformation={{
						mainFilters: salesData.mainFilters,
						subFilters: salesData.subFilters,
					}}
					activeFilters={mainSubFilter}
					pseudoSelection={pseudoSelection}
					onFilterChange={setMainSubFilter}
				/>
			)}

			<SalesTable data={salesData.items} onDoneAction={getSalesTableData}/>

			{salesData.urlsafeNextCursor && (
				<p className="text-hPurple font-italic mt-3">
					{salesData.urlsafeNextCursor}
				</p>
			)}
		</div>
	)
};

export default connect((store: IStore, props: ISalesTableWithControllerProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
		...props,
	}
})(SalesTableWithController);
