import React, {ReactNode, useEffect, useState} from "react";
import PurchaseOrderForm, {IPurchaseOrderFormFields} from "./PurchaseOrderForm";
import {Button, Col, Container, Row} from "reactstrap";
import AccountOverViewCard from "./AccountOverViewCard";
import SupplierInfoCard from "./SupplierInfoCard";
import OrderSummaryCard, {IOrderSummaryCardProps} from "./OrderSummaryCard";
import UploadPOModal from "./UploadPOModal";
import {calculateBaseOrderTotal} from "../utils/orderCalculations";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {initialAdapter} from "adapter";
import {decrementLoading, handleError, incrementLoading, setError} from "../redux/meta/MetaActions";
import {findJsonIndexInArray, legacyIncludes} from "../utils/arrays";
import {IInvoiceItem} from "./CreateInvoiceForm";
import {useHistory} from "react-router";
import PreviewAndSubmitPOModal from "./PreviewAndSubmitPOModal";
import {generic400, generic500} from "../utils/errors";
import AccountOverViewCard2 from "./AccountOverViewCard2";
import SupplierInfoCard2 from "./SupplierInfoCard2";
import OrderSummaryCard2 from "./OrderSummaryCard2";
import POSaveButtons from "./POSaveButtons";
import ErrorList from "./ErrorList";

export interface IPOEditingMeta {
	timestamp: string;
	poKeyURL: string;
}

interface ICreatePurchaseOrderFormFullProps {
	tokenV2?: initialAdapter.IAuthInfo;
	dispatch?: any;
	purchaseOrder?: string;
}

const CreatePurchaseOrderFormFull: React.FC<ICreatePurchaseOrderFormFullProps> = (props: ICreatePurchaseOrderFormFullProps) => {

	const {tokenV2, dispatch, purchaseOrder} = props;
	const history = useHistory();
	const [showUploadPOModal, setShowUploadPOModal] = useState(false);
	const [form, setForm] = useState<IPurchaseOrderFormFields>({
		purchaseOrderNumber: "",
		purchaseOrderDate: "",
		htcPaymentTerms: -2,
		orderItems: [{}],
		paidDeposit: undefined,
		htcPOIncoTerm: "",
		notes: "",
		shipDate: "",
		supplier: "",
		paymentDue: -1,
		shippingTerms: "",
		files: [],
	});
	const [initDataRes, setInitDataRes] = useState<initialAdapter.IReadPOInitDataRes>();
	const [selectedSupplier, setSelectedSupplier] = useState<initialAdapter.ISupplierListForPO>();
	const [fullSupplierDetails, setFullSupplierDetails] = useState<initialAdapter.IReadPOSupplierRes>();
	const [autoCompleteVal, setAutoCompleteVal] = useState("");
	const [editingMeta, setEditingMeta] = useState<IPOEditingMeta>();
	const [newPOKey, setNewPOKey] = useState<string>();
	const [showSubmitPreview, setShowSubmitPreview] = useState(false);
	const [rawExistingPO, setRawExistingPO] = useState<any>();
	const [errors, setErrors] = useState([]);
	const [errorsForSubmitModal, setErrorsForSubmitModal] = useState([]);
	const [depositIsBeingMade, setDepositIsBeingMade] = useState(true);
	const [showDepositError, setShowDepositError] = useState(false);

	useEffect(() => {
		if (!purchaseOrder) {
			getInitialPOData().then().catch();
		} else {
			getExistingPOInfo(purchaseOrder).then().catch();
		}
	}, []);

	async function getInitialPOData(ignoreForm: boolean = false): Promise<initialAdapter.IReadPOInitDataRes> {
		setErrors([]);

		props.dispatch(incrementLoading());
		// @ts-ignore
		const res = await initialAdapter.api.readPOInitData(undefined, tokenV2);
		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		setInitDataRes(res.data);
		if (!ignoreForm) {
			setForm({...form, purchaseOrderNumber: res.data.poNumber, purchaseOrderDate: res.data.poDate});
		}

		if (!purchaseOrder && !newPOKey) {
			setNewPOKey(res.data.newPOKeyURL);
		}

		props.dispatch(decrementLoading());

		return res.data;
	}

	async function getExistingPOInfo(key: string = ""): Promise<void> {
		setErrors([]);
		const initData = await getInitialPOData(true);

		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.readDraftPO({poKeyURL: key}, tokenV2);

		if (!res.success) {
			props.dispatch(decrementLoading());
			setErrors(handleError(res));
			return;
		}

		setEditingMeta({timestamp: res.data.timestamp, poKeyURL: res.data.poKeyURL});

		let supplierRes;
		if (res.data.supplierKeyURL) {
			supplierRes = await initialAdapter.api.readPOSupplierData(
				// {supplierKeyURL: res.data.supplierKeyURL, tradelink: res.data.tradelinkSupplier},
				{supplierKeyURL: res.data.supplierKeyURL, tradelink: res.data.tradelinkSupplier},
				tokenV2
			);

			if (!supplierRes.success) {
				props.dispatch(decrementLoading());
				setErrors(handleError(supplierRes));
				return;
			}
			setFullSupplierDetails(supplierRes.data);
		}

		const files: Array<initialAdapter.IDocumentItemMsg> = await attachOrGetDocuments({timestamp: res.data.timestamp, poKeyURL: res.data.poKeyURL});

		setAutoCompleteVal(res.data.supplierName);
		setSelectedSupplier({
			supplierKeyURL: res.data.supplierKeyURL,
			supplierName: res.data.supplierName,
			tradelink: res.data.tradelinkSupplier,
		});

		setRawExistingPO(res.data);

		const selectedPaymentTerm: number = supplierRes ? findJsonIndexInArray(supplierRes.data.htcBuyerPymtTermList, "term", parseInt(res.data.htcPaymentTerm)) : -1;
		const supplierPaymentDue: number = findJsonIndexInArray(initData.supplierPaymentDueList, "term", parseInt(res.data.supplierPaymentTerm));

		setForm({
			...form,
			purchaseOrderNumber: res.data.poNumber,
			purchaseOrderDate: res.data.poDate,
			htcPaymentTerms: selectedPaymentTerm,
			supplier: res.data.supplierKeyURL,
			paidDeposit: res.data.deposit,
			shipDate: res.data.shipByDate,
			shippingTerms: res.data.supplierShippingTerm,
			paymentDue: supplierPaymentDue,
			notes: res.data.notes,
			orderItems: res.data.supplierOrderItems.map((item) => {
				return {
					name: item.itemName,
					description: item.itemDesc,
					// quantity: parseFloat(item.itemQty),
					quantity: item.itemQty,
					// price: parseFloat(item.unitPrice),
					price: item.unitPrice,
				};
			}),
			htcPOIncoTerm: res.data.htcShippingTerm,
			files,
		});

		if (res.data.deposit) {
			setDepositIsBeingMade(true);
		} else {
			setDepositIsBeingMade(false);
		}

		props.dispatch(decrementLoading());
	}

	async function finishRemovingDocumentHelper(newEditingMeta: IPOEditingMeta): Promise<void> {
		await attachOrGetDocuments(newEditingMeta);
	}

	async function handleSupplierSelect(supplier: initialAdapter.ISupplierListForPO): Promise<void> {
		setErrors([]);
		if (Object.keys(supplier).length < 1) {
			setSelectedSupplier(null);
			setFullSupplierDetails(null);
			setForm({
				...form,
				supplier: "",
				htcPaymentTerms: -2,
				htcPOIncoTerm: "",
				shippingTerms: "",
				paymentDue: -1,
			})
		} else {
			props.dispatch(incrementLoading());
			await getInitialPOData(true);

			const res = await initialAdapter.api.readPOSupplierData(
				{supplierKeyURL: supplier.supplierKeyURL, tradelink: supplier.tradelink},
				tokenV2
			);

			setAutoCompleteVal(supplier.supplierName);

			props.dispatch(decrementLoading());

			if (!res.success) {
				setErrors(handleError(res));
				return;
			}

			const selectedPaymentTerm: number = findJsonIndexInArray(res.data.htcBuyerPymtTermList, "term", parseInt(res.data.defaultHTCPymtTerm));
			const supplierPaymentDue: number = findJsonIndexInArray(initDataRes.supplierPaymentDueList, "term", parseInt(res.data.defaultSupplierPymtDue));

			setSelectedSupplier(supplier);
			setFullSupplierDetails(res.data);
			setForm({
				...form,
				supplier: supplier.supplierKeyURL,
				htcPaymentTerms: selectedPaymentTerm,
				htcPOIncoTerm: res.data.htcPOIncoTerm,
				shippingTerms: res.data.defaultIncoterm,
				paymentDue: supplierPaymentDue,
			});
		}
	}

	function toggleShowUploadPOModal(): void {
		setShowUploadPOModal(!showUploadPOModal);
	}

	async function handleUploadPO(): Promise<void> {

		setShowUploadPOModal(false);
	}

	function onFormChange(key: keyof IPurchaseOrderFormFields, value: any): void {
		setForm({...form, [key]: value});
	}

	async function attachOrGetDocuments(newEditingMeta: IPOEditingMeta = editingMeta): Promise<Array<initialAdapter.IDocumentItemMsg>> {
		setErrors([]);
		props.dispatch(incrementLoading());
		setEditingMeta(newEditingMeta);
		const res = await initialAdapter.api.readPODocs({poKeyURL: newEditingMeta.poKeyURL}, tokenV2);
		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		setForm({
			...form,
			files: res.data.documentList,
		});

		return res.data.documentList;
	}

	async function toggleHTCPreviewVisible(): Promise<void> {
		// alert("todo! htc po preview & submit");
	}

	async function showPOPreviewModal(): Promise<void> {
		setErrors([]);

		if (isDepositSettingInvalid()) {
			return;
		}

		const req: any = generateSaveReqObject(true);

		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.updateDraftPO(req, tokenV2);
		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		await getExistingPOInfo(res.data.data[1]);

		// setEditingMeta({timestamp: res.data.data[0], poKeyURL: res.data.data[1]});
		setShowSubmitPreview(true);
	}

	function hidePOPreviewModal(): void {
		setShowSubmitPreview(false);
	}

	async function sendPO(): Promise<void> {
		setErrorsForSubmitModal([]);
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.updateValidatedPOToSubmitted(
			{poKeyURL: editingMeta.poKeyURL, timestamp: editingMeta.timestamp},
			tokenV2
		);
		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		history.push("/home");

	}

	/**
	 * Creates the request object for use in the updateDraftPO api.
	 * needValidation = true when clicking "Preview & Submit" button,
	 * otherwise false when creating / standard editing of the PO.
	 *
	 * @param needValidation
	 */
	function generateSaveReqObject(needValidation: boolean): any {
		return {
			needValidation,
			poNumber: form.purchaseOrderNumber,
			poDate: form.purchaseOrderDate,
			supplierName: selectedSupplier ? selectedSupplier.supplierName : "",
			supplierKeyURL: form.supplier,
			tradelinkSupplier: selectedSupplier ? selectedSupplier.tradelink : false,
			deposit: depositIsBeingMade ? (form.paidDeposit.toString().length > 0 ? form.paidDeposit : 0) : 0,
			shipByDate: form.shipDate,
			tradeCurrency: fullSupplierDetails ? fullSupplierDetails.tradeCurrency : "",
			supplierPaymentTerm: form.htcPaymentTerms < 0 ? ((form.paymentDue > -1 && initDataRes) ? initDataRes.supplierPaymentDueList[form.paymentDue].term.toString() : "") : "",
			supplierShippingTerm: form.htcPaymentTerms < 0 ? form.shippingTerms : "",
			supplierOrderItems: form.orderItems.map((item) => {
				return {
					itemName: item.name ? item.name : "",
					itemDesc: item.description ? item.description : "",
					itemQty: item.quantity ? item.quantity.toString() : "",
					unitPrice: item.price ? item.price.toString() : "",
				};
			}).filter((filterItem) => {
				if (filterItem.itemName.length > 0 || filterItem.itemDesc.length > 0 || filterItem.itemQty.length > 0 || filterItem.unitPrice.length > 0) {
					return filterItem;
				}
			}),
			htcPO: form.htcPaymentTerms > -1, // htcPO = true if a payment term is selected; payment terms gets set back to -1 when clearing selected supplier OR choosing "no finance" in the drop down when a trade-link supplier IS selected
			htcPaymentTerm: (form.htcPaymentTerms > -1 && fullSupplierDetails) ? fullSupplierDetails.htcBuyerPymtTermList[form.htcPaymentTerms].term.toString() : "",
			htcShippingTerm: form.htcPaymentTerms > -1 ? form.htcPOIncoTerm : "",
			notes: form.notes,
			timestamp: (editingMeta && editingMeta.timestamp) ? editingMeta.timestamp : "",
			poKeyURL: (editingMeta && editingMeta.poKeyURL) ? editingMeta.poKeyURL : newPOKey,
		}
	}

	function isDepositSettingInvalid(): boolean {
		const isInvalid: boolean = depositIsBeingMade && (!form.paidDeposit || form.paidDeposit as unknown as number === 0 || form.paidDeposit === "0");
		setShowDepositError(isInvalid);
		return isInvalid;
	}

	async function saveDraft(): Promise<void> {
		setErrors([]);

		if (isDepositSettingInvalid()) {
			return;
		}

		const req: any = generateSaveReqObject(false);

		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.updateDraftPO(req, tokenV2);
		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		// Originally this checked if editingMeta exists, however,
		// even when creating a new PO, editingMeta will exist after attaching a document,
		// so for now checking if this exists or not is the proper check. In the future
		// the way the editingMeta / newPOKey are handled on the frontend could be changed.
		// newPOKey is always returned when readPOInitData is called, but only saved
		// if no poKeyURL (po from url params) is present via props
		if (!newPOKey) {
			setEditingMeta({timestamp: res.data.data[0], poKeyURL: res.data.data[1]});
			await getExistingPOInfo(res.data.data[1]);
		} else {
			history.push({pathname: "/po/edit", search: `?po=${res.data.data[1]}`});
		}

	}

	function calculateOrderAdjusted(): number {
		const itemTotal: number = calculateBaseOrderTotal(form.orderItems);
		const totalMinusDeposit: number = itemTotal - (form.paidDeposit ? parseFloat(form.paidDeposit) : 0);
		const multiplier: number = (form.htcPaymentTerms > -1 && fullSupplierDetails.htcBuyerPymtTermList) ? parseFloat(fullSupplierDetails.htcBuyerPymtTermList[form.htcPaymentTerms].feeMultiple) : 1;
		return totalMinusDeposit * multiplier;
	}

	function onAutoCompleteValueChange(val: string): void {
		setAutoCompleteVal(val);
	}

	const uploadButton: ReactNode = (initDataRes && initDataRes.actions && legacyIncludes(initDataRes.actions, "uploadpo") ? (
		<Button color="hPurple" size="sm" className="px-4" onClick={toggleShowUploadPOModal}>
			Upload Purchase Order(s)
		</Button>
	) : null);

	function toggleMakingDeposit(): void {
		setForm({...form, paidDeposit: undefined});
		if (depositIsBeingMade) {
			setShowDepositError(false);
		}
		setDepositIsBeingMade(!depositIsBeingMade);
	}

	return (
		<React.Fragment>
			<UploadPOModal
				isOpen={showUploadPOModal}
				closeModal={toggleShowUploadPOModal}
				onDone={handleUploadPO}
			/>

			<PreviewAndSubmitPOModal
				isOpen={showSubmitPreview}
				htc={rawExistingPO && rawExistingPO.htcPO}
				closeModal={hidePOPreviewModal}
				onConfirm={sendPO}
				poKey={editingMeta ? editingMeta.poKeyURL : newPOKey}
				errorsForSubmitModal={errorsForSubmitModal}
			/>

			<div className="upload-po-or-invoice-button-container-a mb-3">
				{uploadButton}
			</div>

			<div className="po-invoice-form-container">
				<div className="po-invoice-main">
					<PurchaseOrderForm
						form={form}
						onChange={onFormChange}
						onAttachDocuments={attachOrGetDocuments}
						onHTCPreview={toggleHTCPreviewVisible}
						onPOPreview={showPOPreviewModal}
						onSaveDraft={saveDraft}
						supplierList={initDataRes ? initDataRes.supplierListForPO : []}
						onSupplierSelect={handleSupplierSelect}
						incoterms={initDataRes ? initDataRes.supplierIncotermList : []}
						paymentDueList={initDataRes ? initDataRes.supplierPaymentDueList : []}
						selectedSupplierIsEditable={selectedSupplier && selectedSupplier.tradelink === false}
						htcBuyerPymtTermList={fullSupplierDetails ? fullSupplierDetails.htcBuyerPymtTermList : []}
						actions={initDataRes ? initDataRes.actions : []}
						showHTCPaymentTerms={!form.supplier || (selectedSupplier && selectedSupplier.tradelink === true)}
						autoCompleteVal={autoCompleteVal}
						onAutoCompleteValueChange={onAutoCompleteValueChange}
						fullSupplierEditingDetails={fullSupplierDetails}
						selectedSupplier={selectedSupplier}
						editingMeta={editingMeta ? editingMeta : {poKeyURL: newPOKey, timestamp: ""}}
						onRemoveDocument={finishRemovingDocumentHelper}
						errors={errors}
						depositIsBeingMade={depositIsBeingMade}
						toggleMakingDeposit={toggleMakingDeposit}
						showDepositError={showDepositError}
					/>
				</div>

				<div className="po-invoice-sub">
					<div className="upload-po-or-invoice-button-container-b mb-3">
						{uploadButton}
					</div>

					<div className="po-invoice-sub-card-container">
						{initDataRes && (
							<AccountOverViewCard2
								creditLimit={parseFloat(initDataRes.creditLimitAmount)}
								availableCredit={parseFloat(initDataRes.availableCreditAmount)}
							/>
						)}
					</div>

					<div className="po-invoice-sub-card-container po-invoice-sub-container-middle">
						<SupplierInfoCard2
							supplier={selectedSupplier ? selectedSupplier.supplierName : ""}
							address={fullSupplierDetails ? fullSupplierDetails.formattedAddress : ""}
							name={fullSupplierDetails ? fullSupplierDetails.contactName : ""}
							email={fullSupplierDetails ? fullSupplierDetails.contactEmail : ""}
							phone={fullSupplierDetails ? fullSupplierDetails.contactNumber : ""}
						/>
					</div>

					<div className="po-invoice-sub-card-container">
						<OrderSummaryCard2
							orderTotal={calculateBaseOrderTotal(form.orderItems)}
							deposit={parseFloat(form.paidDeposit)}
							orderAdjusted={calculateOrderAdjusted()}
							currency={fullSupplierDetails ? fullSupplierDetails.tradeCurrency : ""}
						/>
					</div>
				</div>

			</div>

			{errors.length > 0 && (
				<div className="mb-4 px-3 po-invoice-error-helper">
					<div className="po-errors-exterior">
						<ErrorList errors={errors}/>
					</div>
				</div>
			)}

			<div className="mt-4 po-save-button-display-manager">
				<div className="po-buttons-exterior">
					<POSaveButtons
						showSaveDraftButton={(initDataRes && initDataRes.actions) && legacyIncludes(initDataRes.actions, "savedraft")}
						showPreviewButton={(initDataRes && initDataRes.actions) && legacyIncludes(initDataRes.actions, "submit")}
						onSaveDraft={saveDraft}
						onPOPreview={showPOPreviewModal}
					/>
				</div>
			</div>
		</React.Fragment>
	)
};

export default connect((store: IStore, props: ICreatePurchaseOrderFormFullProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
	}
})(CreatePurchaseOrderFormFull);
