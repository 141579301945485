import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Button, Col, Container, Row} from "reactstrap";
import {decrementLoading, handleError, incrementLoading} from "../redux/meta/MetaActions";
import {initialAdapter} from "adapter";
import {Link} from "react-router-dom";
import ErrorList from "../components/ErrorList";
import PendingBuyerTradeLinkSetupCard from "../components/PendingBuyerTradeLinkSetupCard";

interface IProps {
	tokenV2?: any;
	dispatch?: any;
}

const BuyerTradeLinkSetup: React.FC<IProps> = (props: IProps) => {

	const [list, setList] = useState<Array<initialAdapter.IBusinessLinkForTLCompany>>([]);
	const [enableEmptyMessage, setEnableEmptyMessage] = useState(false);
	const [errors, setErrors] = useState([]);

	useEffect(() => {
		readPendingSetups().then().catch();
	}, []);

	async function readPendingSetups(): Promise<void> {
		setErrors([]);
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.readPendingBuyerTradeLinkSetup(undefined, props.tokenV2);
		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		setEnableEmptyMessage(true);
		setList(res.data.items);
	}

	function makeCards(pending: Array<initialAdapter.IBusinessLinkForTLCompany>): ReactNode {
		return pending.map((p: initialAdapter.IBusinessLinkForTLCompany, i: number) => {
			return (
				<div className="mb-5 d-flex po-inv-card-col" key={`pendingBuyerTradeLinkSetup-${i}`}>
					<PendingBuyerTradeLinkSetupCard invite={p} onDone={readPendingSetups}/>
				</div>
			);
		});
	}

	return (
		<Container className="my-4 htc-container">
			{(list.length < 1 && enableEmptyMessage) && (
				<div>
					<h5 className="my-3 text-center">
						There are no accepted invitations from suppliers awaiting TradeLink setup.
					</h5>

					<div className="d-flex justify-content-center my-4">
						<Link to="/home">
							<Button
								component={Link}
								color="primary"
							>
								Home
							</Button>
						</Link>
					</div>
				</div>
			)}

			{errors.length > 0 && (
				<div className="my-3">
					<ErrorList errors={errors}/>
				</div>
			)}

			<Row className="d-flex justify-content-centers">
				{makeCards(list)}
			</Row>
		</Container>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
		...props,
	}
})(BuyerTradeLinkSetup);
