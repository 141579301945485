import * as React from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Button, Dialog, DialogActions, DialogContent} from "@material-ui/core";
import {setError} from "../redux/meta/MetaActions";

interface IErrorModalProps {
	dispatch?: any;
	error?: string;
}

const ErrorModal: React.FC<IErrorModalProps> = (props: IErrorModalProps) => {

	function closeHelper(): void {
		props.dispatch(setError(undefined));
	}

	return (
		<Dialog
			open={!!props.error}
			onClose={closeHelper}
			PaperProps={{className: "p-3 error-modal"}}
		>
			<DialogContent>
				<h2 className="mb-3 text-danger">
					Error!
				</h2>

				<p className="mb-4">
					{props.error}
				</p>
			</DialogContent>

			<DialogActions>
				<Button
					autoFocus={false}
					onClick={closeHelper}
					className="text-primary"
				>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);

};

export default connect((store: IStore, props: IErrorModalProps) => {
	return {
		error: store.metaStore.error,
		...props,
	}
})(ErrorModal);
