import React, {useEffect} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import { initialAdapter } from "adapter";
import {logout} from "../redux/meta/MetaActions";
interface IProps {
	tokenV2?: initialAdapter.IAuthInfo;
	dispatch?: any;
}

const TokenManagement: React.FC<IProps> = (props) => {

	useEffect(() => {
		checkToken().then();
		const interval = setInterval(checkToken,300000);
		return () => clearInterval(interval)
	}, [JSON.stringify(props.tokenV2)])

	async function checkToken() {
		// don't need to check on a token existing
		if (!props.tokenV2) {
			return
		}

		const res = await initialAdapter.api.tokenExpired(undefined, props.tokenV2);

		if (!res.success || res.data) {
			props.dispatch(logout());
			//TODO show session logout error
		}
	}

	return null;
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		tokenV2: store.metaStore.tokenV2
	}
})(TokenManagement);
