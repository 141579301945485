import {Container} from "reactstrap";
import React from "react";
import CreateInvoiceFormFull from "../components/CreateInvoiceFormFull";

const CreateInvPage: React.FC = () => {

	return (
		<Container className="my-4 htc-container">
			<CreateInvoiceFormFull/>
		</Container>
	)
};

export default CreateInvPage;
