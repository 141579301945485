"use strict";

exports.__esModule = true;
var DocumentDayRange;

(function (DocumentDayRange) {
  DocumentDayRange["LAST_30"] = "last30";
  DocumentDayRange["DAYS_30_TO_60"] = "30to60days";
  DocumentDayRange["DAYS_60_TO_180"] = "60to180days";
})(DocumentDayRange = exports.DocumentDayRange || (exports.DocumentDayRange = {}));

var ReadHTCPOSummaryReqType;

(function (ReadHTCPOSummaryReqType) {
  ReadHTCPOSummaryReqType["PENDING_HTC_PO"] = "pendingHTCPO";
  ReadHTCPOSummaryReqType["PENDING_COGS"] = "pendingCOGS";
  ReadHTCPOSummaryReqType["OUTSTANDING"] = "outstanding";
  ReadHTCPOSummaryReqType["PAID_VOIDED"] = "paidvoided";
})(ReadHTCPOSummaryReqType = exports.ReadHTCPOSummaryReqType || (exports.ReadHTCPOSummaryReqType = {}));

var PurchaseSummaryListFilter;

(function (PurchaseSummaryListFilter) {
  PurchaseSummaryListFilter["PAST_DUE"] = "pastdue";
  PurchaseSummaryListFilter["CURRENT"] = "current";
  PurchaseSummaryListFilter["PENDING"] = "pending";
  PurchaseSummaryListFilter["DRAFT"] = "draft";
  PurchaseSummaryListFilter["PAID_VOIDED"] = "paidvoided";
  PurchaseSummaryListFilter["INVOICES"] = "INVOICES";
  PurchaseSummaryListFilter["PURCHASE_ORDERS"] = "PURCHASE_ORDERS";
  PurchaseSummaryListFilter["TRANSACTION_HISTORY"] = "TRANSACTION_HISTORY";
})(PurchaseSummaryListFilter = exports.PurchaseSummaryListFilter || (exports.PurchaseSummaryListFilter = {}));

var BUYER_TABLE_SUMMARY_FILTERS;

(function (BUYER_TABLE_SUMMARY_FILTERS) {
  BUYER_TABLE_SUMMARY_FILTERS["ACTIVE"] = "active";
  BUYER_TABLE_SUMMARY_FILTERS["COMPLETED"] = "completed";
})(BUYER_TABLE_SUMMARY_FILTERS = exports.BUYER_TABLE_SUMMARY_FILTERS || (exports.BUYER_TABLE_SUMMARY_FILTERS = {}));