import React, {ChangeEventHandler, useEffect, useState} from "react";
import {Button, Modal, ModalHeader} from "reactstrap";
import AddSupplierFormWithController, {
	defaultAddSupplierForm,
	IAddSupplierFormFields
} from "./AddSupplierFormWithController";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {initialAdapter} from "adapter";
import {decrementLoading, handleError, incrementLoading, setError} from "../redux/meta/MetaActions";
import {generic400, generic500} from "../utils/errors";
import ErrorList from "./ErrorList";

interface IAddSupplierModalProps {
	isOpen: boolean;
	tokenV2?: initialAdapter.IAuthInfo;
	dispatch?: any;
	closeModal(): void;
	handleFinishAdding?(key: string, name: string): void;
	fullEditingDetails?: initialAdapter.IReadPOSupplierRes;
	selectedSupplier?: initialAdapter.ISupplierListForPO;
}

const AddSupplierModal: React.FC<IAddSupplierModalProps> = (props: IAddSupplierModalProps) => {

	const {isOpen, tokenV2, dispatch, closeModal, handleFinishAdding, fullEditingDetails, selectedSupplier} = props;
	const [values, setValues] = useState<IAddSupplierFormFields>(defaultAddSupplierForm);
	const [initData, setInitData] = useState<initialAdapter.IReadCLInitDataRes>();
	const [errors, setErrors] = useState([]);

	useEffect(() => {
		if (isOpen) {
			getInitialClientLinkData().then().catch();
		}
	}, [isOpen]);

	useEffect(() => {
		if (fullEditingDetails) {
			setValues({
				supplierName: selectedSupplier ? selectedSupplier.supplierName : "",
				contactPerson: fullEditingDetails.contactName,
				contactNumber: fullEditingDetails.contactNumber,
				contactEmail: fullEditingDetails.contactEmail,
				currency: fullEditingDetails.tradeCurrency,
				billingCountry: fullEditingDetails.supplierAddress && fullEditingDetails.supplierAddress.country,
				billingAddress1: fullEditingDetails.supplierAddress && fullEditingDetails.supplierAddress.addressLine1,
				billingAddress2: fullEditingDetails.supplierAddress && fullEditingDetails.supplierAddress.addressLine2,
				billingCity: fullEditingDetails.supplierAddress && fullEditingDetails.supplierAddress.city,
				billingState: fullEditingDetails.supplierAddress && fullEditingDetails.supplierAddress.stateRegion,
				billingZip: fullEditingDetails.supplierAddress && fullEditingDetails.supplierAddress.zipCode,
			})
		}
	}, [JSON.stringify(fullEditingDetails), isOpen]);

	async function getInitialClientLinkData(): Promise<void> {
		setErrors([]);
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.readClientLinkInitData(undefined, tokenV2);
		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			closeModal();
			return;
		}

		setInitData(res.data);
	}

	function resetAndClose(): void {
		setValues(defaultAddSupplierForm);
		closeModal();
	}

	function supplierFormOnChange(key: keyof IAddSupplierFormFields): any {
		return (e: any) => {
			const vals = {...values};
			let val = e.target.value;
			if (key === "useBillingForShipping") {
				val = !vals.useBillingForShipping;
			}

			setValues({...values, [key]: val});
		}
	}

	async function onSave(): Promise<void> {
		setErrors([]);
		const req: any = {
			businessName: values.supplierName,
			contactName: values.contactPerson,
			contactNumber: values.contactNumber,
			contactEmail: values.contactEmail,
			tradeCurrency: values.currency,
			registeredAddress: {
				country: values.billingCountry,
				addressLine1: values.billingAddress1,
				addressLine2: values.billingAddress2,
				city: values.billingCity,
				stateRegion: values.billingState,
				zipCode: values.billingZip,
			}
		};

		if (fullEditingDetails) {
			req.supplierKeyURL = selectedSupplier.supplierKeyURL;
			req.timestamp = fullEditingDetails.timestamp;

			props.dispatch(incrementLoading());
			const editRes = await initialAdapter.api.updateSupplier(req, tokenV2);
			props.dispatch(decrementLoading());

			if (!editRes.success) {
				setErrors(handleError(editRes));
				return;
			}

			if (handleFinishAdding) {
				handleFinishAdding(editRes.data.data[1], values.supplierName);
			}

			resetAndClose();

		} else {
			props.dispatch(incrementLoading());
			const res = await initialAdapter.api.createSupplier(req, tokenV2);
			props.dispatch(decrementLoading());

			if (!res.success) {
				setErrors(handleError(res));
				return;
			}

			if (handleFinishAdding) {
				handleFinishAdding(res.data.data[1], values.supplierName);
			}

			resetAndClose();
		}

	}

	return (
		<Modal
			isOpen={isOpen}
			fade={true}
			centered={true}
			contentClassName="p-2"
			toggle={resetAndClose}
		>
			<div className="px-3">
				<ModalHeader toggle={resetAndClose} className="mb-3"><h4>Add a supplier</h4></ModalHeader>

				<AddSupplierFormWithController
					values={values}
					onCancel={resetAndClose}
					onChange={supplierFormOnChange}
					onSave={onSave}
					currencies={initData ? initData.supportedCurrencies : []}
					countries={initData ? initData.countryList : []}
				/>

				{errors.length > 0 && (
					<div className="my-3">
						<ErrorList errors={errors}/>
					</div>
				)}
			</div>
		</Modal>
	);
};

export default connect((store: IStore, props: IAddSupplierModalProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
	}
})(AddSupplierModal);
