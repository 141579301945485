import React, {useEffect, useState} from "react";
import PurchasesTable from "./PurchasesTable";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {decrementLoading, handleError, incrementLoading} from "../redux/meta/MetaActions";
import {initialAdapter} from "adapter";
import ErrorList from "./ErrorList";
import PurchaseSummary from "./PurchaseSummary";
import TableFilterHelper, {
	defaultMainSubFilter,
	ITableFilterMainAndSub
} from "./TableFilterHelper";
import TabSelector from "./TabSelector";
import {Button} from "reactstrap";
import {useHistory} from "react-router";
import isEqual from "lodash.isequal";

interface IPurchaseTableWithControllerProps {
	tokenV2?: any;
	dispatch?: any;
}

const defaultPurchaseData: any = {};

interface IPseudSelectionHelper {
	selection?: any;
	secondCheck?: boolean;
	key: number;
}

const PurchaseTableWithController: React.FC<IPurchaseTableWithControllerProps> = (props: IPurchaseTableWithControllerProps) => {

	const history = useHistory();
	const {tokenV2} = props;
	const [tableFilter, setTableFilter] = useState(initialAdapter.PurchaseSummaryListFilter.INVOICES);
	const [purchaseData, setPurchaseData] = useState<any>(defaultPurchaseData);
	const [errors, setErrors] = useState([]);
	const [mainSubFilter, setMainSubFilter] = useState<ITableFilterMainAndSub>(defaultMainSubFilter);
	const [pseudoSelection, setPseudoSelection] = useState<IPseudSelectionHelper>({selection: null, secondCheck: false, key: 1});
	const [preventDoubleApiCallOnRenderLock, setPreventDoubleApiCallOnRenderLock] = useState(true);

	useEffect(() => {
		if (preventDoubleApiCallOnRenderLock) {
			setPreventDoubleApiCallOnRenderLock(false);
		}
		setPseudoSelection({selection: null, secondCheck: false, key: pseudoSelection.key + 1});
	}, [JSON.stringify(mainSubFilter)]);

	useEffect(() => {
		if (preventDoubleApiCallOnRenderLock) {
			return;
		}
		setPseudoSelection({selection: null, secondCheck: true, key: pseudoSelection.key + 1});
	}, [tableFilter]);

	useEffect(() => {
		if (pseudoSelection.selection === null) {
			if (pseudoSelection.secondCheck) {
				if (isEqual(mainSubFilter, defaultMainSubFilter)) {
					getPurchaseTableData().then().catch();
				} else {
					setMainSubFilter(defaultMainSubFilter);
				}
			} else {
				getPurchaseTableData().then().catch();
			}
		}
	}, [JSON.stringify(pseudoSelection)]);

	function onCreatePurchaseOrder(): void {
		history.push("/po/create")
	}

	/**
	 * When user changes the filter type,
	 * clear data from the api before the next call is prompted
	 *
	 * @param newFilter
	 */
	function handleTableFilterChange(newFilter: initialAdapter.PurchaseSummaryListFilter): void {
		if (newFilter !== tableFilter) {
			setPurchaseData(defaultPurchaseData);
			setTableFilter(newFilter);
		}
	}

	/**
	 * Call api for new purchase data
	 *
	 */
	async function getPurchaseTableData(): Promise<void> {
		props.dispatch(incrementLoading());

		let res;

		switch (tableFilter) {
			case initialAdapter.PurchaseSummaryListFilter.INVOICES:
				res = await initialAdapter.api.readBuyerInvoiceSummary({urlsafeNextCursor: "", ...mainSubFilter}, tokenV2);
				break;
			case initialAdapter.PurchaseSummaryListFilter.PURCHASE_ORDERS:
				res = await initialAdapter.api.readPurchaseOrderSummary({urlsafeNextCursor: "", ...mainSubFilter}, tokenV2);
				break;
		}

		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			setPurchaseData(defaultPurchaseData);
			return;
		}

		if (!pseudoSelection.selection && (res.data.mainFilters && res.data.mainFilters.length > 0) && isEqual(mainSubFilter, defaultMainSubFilter)) {
			setPseudoSelection({...pseudoSelection, selection: res.data.mainFilters[0]});
		}

		setPurchaseData(res.data);
	}

	return (
		<div>
			<div className="d-flex justify-content-end my-4">
				<Button className="px-4" color="hPurple" size="sm" onClick={onCreatePurchaseOrder}>
					Create a purchase order
				</Button>
			</div>

			<div className="my-4 mb-5">
				{purchaseData && (
					<PurchaseSummary overdue={purchaseData.apPastDueAmount} comingDue={purchaseData.apCurrentAmount} pending={purchaseData.apPendingAmount}/>
				)}
			</div>

			{errors.length > 0 && (
				<div className="my-3">
					<ErrorList errors={errors}/>
				</div>
			)}

			<TabSelector
				tabs={[
					{
						title: "Bills",
						value: initialAdapter.PurchaseSummaryListFilter.INVOICES,
					},
					{
						title: "Purchase Orders",
						value: initialAdapter.PurchaseSummaryListFilter.PURCHASE_ORDERS,
					},
				]}
				value={tableFilter}
				onClick={handleTableFilterChange}
			/>

			{purchaseData && ((purchaseData.mainFilters && purchaseData.mainFilters.length > 0) || (purchaseData.subFilters && purchaseData.subFilters.length > 0)) && (
				<TableFilterHelper
					filterInformation={{
						mainFilters: purchaseData.mainFilters,
						subFilters: purchaseData.subFilters,
					}}
					activeFilters={mainSubFilter}
					pseudoSelection={pseudoSelection.selection}
					onFilterChange={setMainSubFilter}
				/>
			)}

			<PurchasesTable data={purchaseData.items} filter={tableFilter} onDoneAction={getPurchaseTableData}/>

			{purchaseData.urlsafeNextCursor && (
				<p className="text-hPurple font-italic mt-3">
					{purchaseData.urlsafeNextCursor}
				</p>
			)}
		</div>
	);
};

export default connect((store: IStore, props: IPurchaseTableWithControllerProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
	}
})(PurchaseTableWithController);
