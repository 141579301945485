import React from "react";
import {CardBody} from "reactstrap";
import DashboardBackgroundCard from "./dashboard/DashboardBackgroundCard";
import AccountOverviewValueDisplay from "./dashboard/AccountOverviewValueDisplay";
import AccountOverviewNormalTextDisplay from "./AccountOverviewNormalTextDisplay";
import {formatMoney} from "../utils/formatters";

interface IProps {
	orderTotal?: number;
	deposit?: number;
	orderAdjusted?: number;
	currency?: string;
}

const OrderSummaryCard2: React.FC<IProps> = (props: IProps) => {

	const {orderTotal, deposit, orderAdjusted, currency} = props;

	return (
		<DashboardBackgroundCard background="bg-hPurple">
			<CardBody>
				<h2 className="text-white text-center mb-3">Order Summary</h2>

				<AccountOverviewNormalTextDisplay title="Order Total" value={orderTotal ? formatMoney(orderTotal) : "-"} pClass="card-summary-mock-account-overview number-display"/>
				<AccountOverviewNormalTextDisplay title="Deposit" value={deposit ? formatMoney(deposit) : "-"} pClass="card-summary-mock-account-overview number-display"/>
				<AccountOverviewNormalTextDisplay title="Order Adjusted Total" value={orderAdjusted ? formatMoney(orderAdjusted): "-"} pClass="card-summary-mock-account-overview number-display"/>
				<AccountOverviewNormalTextDisplay title="Currency" value={currency ? currency : "-"} className="mb-0" pClass="card-summary-mock-account-overview"/>
			</CardBody>
		</DashboardBackgroundCard>
	);
};

export default OrderSummaryCard2;
