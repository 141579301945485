import React, {ChangeEventHandler, useEffect, useState} from "react";
import {Button, Modal, ModalHeader} from "reactstrap";
import AddCustomerFormWithController, {
	defaultAddCustomerForm,
	IAddCustomerFormFields
} from "./AddCustomerFormWithController";
import {initialAdapter} from "adapter";
import {decrementLoading, handleError, incrementLoading, setError} from "../redux/meta/MetaActions";
import AddSupplierFormWithController from "./AddSupplierFormWithController";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {generic400, generic500} from "../utils/errors";

interface IAddCustomerModalProps {
	isOpen: boolean;
	tokenV2?: initialAdapter.IAuthInfo;
	dispatch?: any;
	closeModal(): void;
	handleFinishAdding?(key: string, name: string): void;
	fullEditingDetails?: any;
	selectedBuyer?: any;
}

const AddCustomerModal: React.FC<IAddCustomerModalProps> = (props: IAddCustomerModalProps) => {

	const {isOpen, tokenV2, dispatch, closeModal, handleFinishAdding, fullEditingDetails, selectedBuyer} = props;
	const [values, setValues] = useState<IAddCustomerFormFields>(defaultAddCustomerForm);
	const [initData, setInitData] = useState<initialAdapter.IReadCLInitDataRes>();
	const [errors, setErrors] = useState([]);

	useEffect(() => {
		if (isOpen) {
			getInitialClientLinkData().then().catch();
		}
	}, [isOpen]);

	useEffect(() => {
		if (fullEditingDetails) {
			setValues({
				customerName: selectedBuyer ? selectedBuyer.buyerName : "",
				contactPerson: fullEditingDetails.contactName,
				contactNumber: fullEditingDetails.contactNumber,
				contactEmail: fullEditingDetails.contactEmail,
				currency: fullEditingDetails.tradeCurrency,

				billingCountry: fullEditingDetails.buyerAddress ? fullEditingDetails.buyerAddress.country : "",
				billingAddress1: fullEditingDetails.buyerAddress ? fullEditingDetails.buyerAddress.addressLine1 : "",
				billingAddress2: fullEditingDetails.buyerAddress ? fullEditingDetails.buyerAddress.addressLine2 : "",
				billingCity: fullEditingDetails.buyerAddress ? fullEditingDetails.buyerAddress.city : "",
				billingState: fullEditingDetails.buyerAddress ? fullEditingDetails.buyerAddress.stateRegion : "",
				billingZip: fullEditingDetails.buyerAddress ? fullEditingDetails.buyerAddress.zipCode : "",

				useBillingForShipping: fullEditingDetails.shippingSameAsBilling,

				shippingCountry: fullEditingDetails.shippingAddress ? fullEditingDetails.shippingAddress.country : "",
				shippingAddress1: fullEditingDetails.shippingAddress ? fullEditingDetails.shippingAddress.addressLine1 : "",
				shippingAddress2: fullEditingDetails.shippingAddress ? fullEditingDetails.shippingAddress.addressLine2 : "",
				shippingCity: fullEditingDetails.shippingAddress ? fullEditingDetails.shippingAddress.city : "",
				shippingState: fullEditingDetails.shippingAddress ? fullEditingDetails.shippingAddress.stateRegion : "",
				shippingZip: fullEditingDetails.shippingAddress ? fullEditingDetails.shippingAddress.zipCode : "",
			});
		}
	}, [JSON.stringify(fullEditingDetails), isOpen]);

	async function getInitialClientLinkData(): Promise<void> {
		setErrors([]);
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.readClientLinkInitData(undefined, tokenV2);
		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			closeModal();
			return;
		}

		setInitData(res.data);
	}

	function resetAndClose(): void {
		setValues(defaultAddCustomerForm);
		closeModal();
	}

	function supplierFormOnChange(key: keyof IAddCustomerFormFields): any {
		return (e: any) => {
			const vals = {...values};
			let val = e.target.value;
			if (key === "useBillingForShipping") {
				val = !vals.useBillingForShipping;
			}

			setValues({...values, [key]: val});
		}
	}

	async function onSave(): Promise<void> {
		setErrors([]);
		const req: any = {
			businessName: values.customerName,
			contactName: values.contactPerson,
			contactNumber: values.contactNumber,
			contactEmail: values.contactEmail,
			tradeCurrency: values.currency,
			billingAddress: {
				country: values.billingCountry,
				addressLine1: values.billingAddress1,
				addressLine2: values.billingAddress2,
				city: values.billingCity,
				stateRegion: values.billingState,
				zipCode: values.billingZip,
			},
			shippingSameAsBilling: values.useBillingForShipping,
			shippingAddress: {
				country: values.useBillingForShipping ? values.billingCountry : values.shippingCountry,
				addressLine1: values.useBillingForShipping ? values.billingAddress1 : values.shippingAddress1,
				addressLine2: values.useBillingForShipping ? values.billingAddress2 : values.shippingAddress2,
				city: values.useBillingForShipping ? values.billingCity : values.shippingCity,
				stateRegion: values.useBillingForShipping ? values.billingState : values.shippingState,
				zipCode: values.useBillingForShipping ? values.billingZip : values.shippingZip,
			}
		};

		if (fullEditingDetails) {
			req.customerKeyURL = selectedBuyer.buyerKeyURL;
			req.timestamp = fullEditingDetails.timestamp;

			props.dispatch(incrementLoading());
			const editRes = await initialAdapter.api.updateCustomer(req, tokenV2);
			props.dispatch(decrementLoading());

			if (!editRes.success) {
				setErrors(handleError(editRes));
				return;
			}

			if (handleFinishAdding) {
				handleFinishAdding(editRes.data.data[1], values.customerName);
			}

			resetAndClose();
		} else {
			props.dispatch(incrementLoading());
			const res = await initialAdapter.api.createCustomer(req, tokenV2);
			props.dispatch(decrementLoading());

			if (!res.success) {
				setErrors(handleError(res));
				return;
			}

			if (handleFinishAdding) {
				handleFinishAdding(res.data.data[1], values.customerName);
			}

			resetAndClose();
		}
	}

	async function onSaveAndInvite(): Promise<void> {
		// alert("todo + invite");
	}

	return (
		<Modal
			isOpen={isOpen}
			fade={true}
			centered={true}
			contentClassName="p-2"
			toggle={resetAndClose}
		>
			<div className="px-3">
				<ModalHeader toggle={resetAndClose} className="mb-3"><h4>Add a customer</h4></ModalHeader>

				<AddCustomerFormWithController
					values={values}
					onCancel={resetAndClose}
					onChange={supplierFormOnChange}
					onSave={onSave}
					onSaveAndInvite={onSaveAndInvite}
					currencies={initData ? initData.supportedCurrencies : []}
					countries={initData ? initData.countryList : []}
				/>
			</div>
		</Modal>
	);
};

export default connect((store: IStore, props: IAddCustomerModalProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
	}
})(AddCustomerModal);
