import React from "react";
import DashboardBackgroundCard from "./DashboardBackgroundCard";
import {CardBody} from "reactstrap";
import AccountOverviewValueDisplay from "./AccountOverviewValueDisplay";

interface IProps {
	balance: number;
	availableCredit: number;
	creditLimit: number;
}

const DashboardAccountOverview: React.FC<IProps> = (props: IProps) => {

	const {balance, availableCredit, creditLimit} = props;

	return (
		<DashboardBackgroundCard background="bg-hBlue">
			<CardBody>
				<h2 className="text-white text-center mb-3">Account Overview</h2>

				<AccountOverviewValueDisplay title="Account Balance" value={balance}/>
				<AccountOverviewValueDisplay title="Available Credit" value={availableCredit}/>

				<hr className="my-0"/>

				<AccountOverviewValueDisplay title="Credit Limit" value={creditLimit} className="mt-4"/>
			</CardBody>
		</DashboardBackgroundCard>
	);
};

export default DashboardAccountOverview;
