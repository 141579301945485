import React, {ReactNode} from "react";

export interface ISelectOptions {
	value: any;
	text: string;
}

interface ISelectOptionsProps {
	strings?: string[];
	fullOps?: ISelectOptions[];
}

const SelectOptions: React.FC<ISelectOptionsProps> = (props: ISelectOptionsProps) => {

	const {strings, fullOps} = props;

	function createOptions(options: ISelectOptions[]): ReactNode {
		return options.map((option: ISelectOptions, i: number) => {
			return (<option value={option.value} key={`op-${i}`}>{option.text}</option>)
		})
	}

	return (
		<React.Fragment>
			{strings ? (
				<React.Fragment>
					{createOptions(strings.map((s) => {return {value: s, text: s}}))}
				</React.Fragment>
			) : fullOps ? (
				<React.Fragment>
					{createOptions(fullOps)}
				</React.Fragment>
			) : null}
		</React.Fragment>
	)
};

export default SelectOptions;
