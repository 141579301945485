import React, {ReactNode} from "react";
import {IInvoiceItem} from "./CreateInvoiceForm";
import InvoiceItem from "./InvoiceItem";

interface IInvoiceItemsListProps {
	items: IInvoiceItem[];
	onDelete(i: number): void;
	onChange(i: number, key: keyof IInvoiceItem, value: any): void;
}

const InvoiceItemsList: React.FC<IInvoiceItemsListProps> = (props: IInvoiceItemsListProps) => {

	const {items, onDelete, onChange} = props;

	function createList(list: IInvoiceItem[]): ReactNode {
		return list.map((item: IInvoiceItem, i: number) => {

			function onDeleteHelper(): void {
				onDelete(i)
			}

			function onChangeHelper(key: keyof IInvoiceItem, value: any): void {
				onChange(i, key, value);
			}

			return (
				<div key={`list-item-${i}`} className="mb-2">
					<InvoiceItem {...item} onDelete={onDeleteHelper} onChange={onChangeHelper}/>
				</div>
			)
		});
	}

	return (
		<div>
			{createList(items)}
		</div>
	)
};

export default InvoiceItemsList;
