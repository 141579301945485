import React, {ChangeEventHandler, ReactNode, useState} from "react";
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Input, Label, Row} from "reactstrap";
import SelectOptions from "./SelectOptions";
import AddSupplierModal from "./AddSupplierModal";
import InvoiceItemsList from "./InvoiceItemsList";
import {IInvoiceItem} from "./CreateInvoiceForm";
import {initialAdapter} from "adapter";
import Select from "react-select";
import NumberFormat from "react-number-format";
import {INumberFormat} from "./InvoiceItem";
import {findJsonIndexInArray, legacyIncludes} from "../utils/arrays";
import {IPOEditingMeta} from "./CreatePurchaseOrderFormFull";
import DocumentListItem from "./DocumentListItem";
import AttachDocumentsToPOModal from "./AttachDocumentsToPOModal";
import ErrorList from "./ErrorList";
import CustomDatePicker from "./CustomDatePicker";
import POSaveButtons from "./POSaveButtons";
import HTCCheckbox from "./HTCCheckbox";

export interface IPurchaseOrderFormFields {
	purchaseOrderNumber?: string;
	purchaseOrderDate?: any;
	htcPaymentTerms?: number;
	supplier?: string;
	paidDeposit?: string;
	shipDate?: any;
	shippingTerms?: any;
	paymentDue?: any;
	notes?: string;
	orderItems?: IInvoiceItem[];
	htcPOIncoTerm?: string;
	files?: Array<initialAdapter.IDocumentItemMsg>;
}

interface IPurchaseOrderFormProps {
	form: IPurchaseOrderFormFields;

	onChange(key: keyof IPurchaseOrderFormFields, value: any): void;

	onAttachDocuments(editingMeta: IPOEditingMeta): Promise<any>;

	onHTCPreview(): void;

	onPOPreview(): void;

	onSaveDraft(): void;

	supplierList: Array<initialAdapter.ISupplierListForPO>;

	onSupplierSelect(supplier: initialAdapter.ISupplierListForPO): void;

	incoterms: string[];
	paymentDueList: Array<initialAdapter.IPaymentTermItemMsg>;
	selectedSupplierIsEditable: boolean;
	htcBuyerPymtTermList: Array<initialAdapter.IPaymentTermItemMsg>;
	actions: string[];
	showHTCPaymentTerms: boolean;
	autoCompleteVal: string;

	onAutoCompleteValueChange(val: string): void;

	fullSupplierEditingDetails?: initialAdapter.IReadPOSupplierRes;
	selectedSupplier?: initialAdapter.ISupplierListForPO;
	editingMeta: IPOEditingMeta;

	onRemoveDocument(newEditingMeta: IPOEditingMeta): Promise<any>;

	errors: string[];
	depositIsBeingMade: boolean;
	toggleMakingDeposit(): void;
	showDepositError: boolean;
}

const PurchaseOrderForm: React.FC<IPurchaseOrderFormProps> = (props: IPurchaseOrderFormProps) => {

	const {
		form,
		onChange,
		onAttachDocuments,
		onHTCPreview,
		onPOPreview,
		onSaveDraft,
		supplierList,
		onSupplierSelect,
		incoterms,
		paymentDueList,
		selectedSupplierIsEditable,
		htcBuyerPymtTermList,
		actions,
		showHTCPaymentTerms,
		autoCompleteVal,
		onAutoCompleteValueChange,
		fullSupplierEditingDetails,
		selectedSupplier,
		editingMeta,
		errors,
		depositIsBeingMade,
		showDepositError,
	} = props;

	const [showAddSupplierModal, setShowAddSupplierModal] = useState(false);
	const [showAttachDocumentsModal, setShowAttachDocumentsModal] = useState(false);
	const [orderItemsKey, setOrderItemsKey] = useState(1);

	function toggleAddSupplierModal(): void {
		setShowAddSupplierModal(!showAddSupplierModal);
	}

	function toggleAttachDocumentsModal(): void {
		setShowAttachDocumentsModal(!showAttachDocumentsModal);
	}

	function createOnChange(key: keyof IPurchaseOrderFormFields): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			onChange(key, e.target.value);
		}
	}

	function createDateChange(key: keyof IPurchaseOrderFormFields): any {
		return (d: string) => {
			onChange(key, d);
		}
	}

	function addNewOrderItem(): void {
		const items: IInvoiceItem[] = form.orderItems;
		items.push({});
		onChange("orderItems", items);
	}

	function removeOrderItem(i: number): void {
		onChange("orderItems", form.orderItems.filter((item, index) => {
			return i !== index
		}));
		setOrderItemsKey(orderItemsKey + 1);
	}

	function onOrderItemChange(i: number, key: keyof IInvoiceItem, value: any): void {
		const items: IInvoiceItem[] = form.orderItems;
		// @ts-ignore
		items[i][key] = value;
		onChange("orderItems", items)
	}

	function onSupplierSelectHelper(a): void {
		onSupplierSelect(a ? a.value : {});
	}

	function finishAddingSupplier(key: string, name: string): void {
		onSupplierSelect({
			supplierName: name,
			supplierKeyURL: key,
			tradelink: false,
		});
	}

	const suppliers = supplierList.map((supplier: initialAdapter.ISupplierListForPO) => {
		return {
			value: supplier,
			label: supplier.supplierName,
		};
	});

	function onNumberFormatChange(key: keyof IPurchaseOrderFormFields): any {
		return (e: INumberFormat) => {
			onChange(key, e.floatValue);
		}
	}

	function mapDocuments(files: Array<initialAdapter.IDocumentItemMsg> = []): ReactNode {
		if (files.length < 1) {
			return (
				<span className="text-hPurple font-italic">No documents have been uploaded yet.</span>
			);
		}

		return files.map((file: initialAdapter.IDocumentItemMsg, i: number) => {
			return (
				<div key={`document-list-item-${i}`} className="my-2">
					<DocumentListItem
						file={file}
						editingMeta={editingMeta}
						showConfirmation={true}
						type="po"
						onFinishDelete={props.onRemoveDocument}
						deleteIconPositionsLeft={false}
					/>
				</div>
			);
		})
	}

	return (
		<React.Fragment>
			<AddSupplierModal
				isOpen={showAddSupplierModal}
				closeModal={toggleAddSupplierModal}
				handleFinishAdding={finishAddingSupplier}
				fullEditingDetails={fullSupplierEditingDetails}
				selectedSupplier={selectedSupplier}
			/>

			<AttachDocumentsToPOModal
				title="Attach Documents to PO"
				isOpen={showAttachDocumentsModal}
				closeModal={toggleAttachDocumentsModal}
				onUpload={onAttachDocuments}
				editingMeta={editingMeta}
			/>

			<Card>
				<CardHeader>Create Purchase Order</CardHeader>
				<CardBody>
					<Card className="mt-3 mb-4">
						<CardTitle>
							<h5 className="text-hPurple">Order Info</h5>
							<hr/>
						</CardTitle>
						<CardBody className="pt-0">
							<div className="mb-3">
								<Label for="purchaseOrderNumber">Purchase Order #*</Label>
								<Input type="text" name="purchaseOrderNumber" id="purchaseOrderNumber"
								       placeholder="Purchase Order #..." value={form.purchaseOrderNumber}
								       onChange={createOnChange("purchaseOrderNumber")}
								       className="input-max-width-300"/>
							</div>

							<div className="mb-3">
								<Label for="purchaseOrderDate">Purchase Order Date</Label>
								<div className="input-max-width-300">
									<CustomDatePicker
										inputProps={{
											type: "date",
											name: "purchaseOrderDate",
											id: "purchaseOrderDate",
											placeholder: "YYYY-MM-DD",
											value: form.purchaseOrderDate,
											onChange: createOnChange("purchaseOrderDate"),
										}}
										date={form.purchaseOrderDate}
										onDateChange={createDateChange("purchaseOrderDate")}
									/>
								</div>
							</div>

							<div className="mb-3">
								<Label for="supplier">Supplier</Label>
								<div className="add-edit-supplier-customer-button-container">
									<div className="w-100 input-max-width-600">
										<Select
											options={suppliers}
											styles={reactSelectCustomStyles}
											isClearable={true}
											onChange={onSupplierSelectHelper}
											onInputChange={onAutoCompleteValueChange}
											inputValue={autoCompleteVal}
										/>
									</div>

									<div>
										{form.supplier ? (
											<div className="ae-sc-btn-inner">
												{selectedSupplierIsEditable && (
													<Button color="hBlue" size="sm" className="text-nowrap" onClick={toggleAddSupplierModal}>
														Edit Supplier
													</Button>
												)}
											</div>
										) : (
											<div className="ae-sc-btn-inner">
												{actions && legacyIncludes(actions, "addsupplier") && (
													<Button color="hBlue" size="sm" className="text-nowrap" onClick={toggleAddSupplierModal}>
														Add a Supplier
													</Button>
												)}
											</div>
										)}
									</div>
								</div>
							</div>

						</CardBody>
					</Card>

					{showHTCPaymentTerms && (
						<Card className="mb-4">
							<CardTitle>
								<h5 className="text-hPurple">Payment Terms with Harbor</h5>
								<hr/>
							</CardTitle>
							<CardBody className="pt-0">
								<div className="mb-3">
									<Label for="htcPaymentTerms">Harbor Payment Terms</Label>

									<Input type="select" name="htcPaymentTerms" id="htcPaymentTerms"
									       placeholder="HTC Payment Terms..." value={form.htcPaymentTerms}
									       onChange={createOnChange("htcPaymentTerms")}
									       disabled={!form.supplier} className={"input-max-width-300" + (form.htcPaymentTerms < 0 ? " un-selected-drop-down" : " number-display")}
									>
										<option value={-2} selected disabled className="first-select-option">Select Terms...</option>
										<hr/>
										{actions && legacyIncludes(actions, "regularpo") && (
											<option value={-1}>No Finance for this PO</option>
										)}
										<SelectOptions fullOps={htcBuyerPymtTermList.map((item, i: number) => {
											return {
												value: i,
												text: item.termLabel,
											}
										})}/>
									</Input>
								</div>


								{form.htcPaymentTerms > -1 && (
									<div className="mb-3">
										<Label for="htcPOIncoTerm">Shipping Terms / INCOTERMS</Label>
										<Input type="text" name="htcPOIncoTerm" id="htcPOIncoTerm"
										       placeholder="Shipping Terms / INCOTERMS..."
										       value={form.htcPOIncoTerm}
										       onChange={createOnChange("htcPOIncoTerm")}
										       readOnly={true} disabled={true} className="input-max-width-300"
										/>
									</div>
								)}
							</CardBody>
						</Card>
					)}

					<Card className="mb-4">
						<CardTitle>
							<span className="text-hPurple"><h5 className="d-inline">Supplier's Terms</h5>{" "}(Terms with the end suppliers)</span>
							<hr/>
						</CardTitle>
						<CardBody className="pt-0">
								<div className="mb-3">
									<HTCCheckbox
										checked={depositIsBeingMade}
										label={"Yes, a deposit is being made" + ((selectedSupplier && selectedSupplier.supplierName) ? ` to ${selectedSupplier.supplierName}` : "")}
										onToggle={props.toggleMakingDeposit}
									/>

									{/*<Label for="paidDeposit">Deposit</Label>*/}
									{depositIsBeingMade && (
										<React.Fragment>
											<div className="input-max-width-300">
												<NumberFormat
													prefix="$"
													allowLeadingZeros={true}
													placeholder="Deposit..."
													value={form.paidDeposit}
													customInput={Input}
													thousandSeparator={true}
													decimalScale={2}
													onValueChange={onNumberFormatChange("paidDeposit")}
													className="number-display mt-1"
												/>
											</div>
											{showDepositError && (
												<p className="form-error-message">
													*Please enter a valid number or disabled the checkbox if no deposit is being made.
												</p>
											)}
										</React.Fragment>
									)}
								</div>

								<div className="mb-3">
									<Label for="shipDate">Ship by Date</Label>
									<div className="input-max-width-300">
										<CustomDatePicker
											inputProps={{
												type: "date",
												name: "shipDate",
												id: "shipDate",
												placeholder: "YYYY-MM-DD",
												value: form.shipDate,
												onChange: createOnChange("shipDate")
											}}
											date={form.shipDate}
											onDateChange={createDateChange("shipDate")}
										/>
									</div>
								</div>

								{form.htcPaymentTerms < 0 && (
									<React.Fragment>
										<div className="mb-3">
											<Label for="shippingTerms">Shipping Terms / INCOTERMS</Label>
											<Input type="select" name="shippingTerms" id="shippingTerms"
											       placeholder="Shipping Terms/INCOTERMS..." value={form.shippingTerms}
											       onChange={createOnChange("shippingTerms")} className={"input-max-width-300" + (form.shippingTerms === "" ? " un-selected-drop-down" : "")}>
												<option value="" selected className="first-select-option">Select Terms...</option>
												<hr/>
												<SelectOptions strings={incoterms}/>
											</Input>
										</div>

										<div className="mb-3">
											<Label for="paymentDue">Payment Due</Label>
											<Input type="select" name="paymentDue" id="paymentDue"
											       placeholder="Payment Due..." value={form.paymentDue}
											       onChange={createOnChange("paymentDue")} className={"input-max-width-300" + (form.paymentDue < 0 ? " un-selected-drop-down" : "")}>
												<option value={-1} selected className="first-select-option">Select Terms...</option>
												<hr/>
												<SelectOptions fullOps={paymentDueList.map((item, i: number) => {
													return {value: i, text: item.termLabel}
												})}/>
											</Input>
										</div>
									</React.Fragment>
								)}

								<div className="mb-3">
									<Label for="notes">Notes/Special Instructions</Label>
									<Input type="textarea" name="notes" id="notes"
									       placeholder="Notes/Special Instructions..." value={form.notes}
									       onChange={createOnChange("notes")} className="input-max-width-900"/>
								</div>
					    </CardBody>
					</Card>

					<Card className="mb-4">
						<CardTitle>
							<div className="d-flex align-items-center">
								<h5 className="text-hPurple d-inline mr-3 mb-0">Order Items</h5>
								<Button color="hBlue" size="sm" className="px-3 po-invoice-sub-header-button-a"
								        onClick={addNewOrderItem}>Add New Item</Button>
							</div>
							<hr/>
						</CardTitle>
						<CardBody className="pt-0 pb-0">
							<InvoiceItemsList items={form.orderItems} onDelete={removeOrderItem}
							                  onChange={onOrderItemChange} key={orderItemsKey}/>
							<div className="d-flex justify-content-center">
								<Button color="hBlue" size="sm" className="px-5 po-invoice-sub-header-button-b mb-4"
								        onClick={addNewOrderItem}>Add New Item</Button>
							</div>
						</CardBody>
					</Card>

					{actions && legacyIncludes(actions, "attachdoc") && (
						<Card className="mb-4">
							<CardTitle>
								<div className="d-flex align-items-center">
									<h5 className="text-hPurple d-inline mr-3 mb-0">Attach Documents</h5>
									<Button color="hBlue" size="sm" className="px-3 po-invoice-sub-header-button-a"
									        onClick={toggleAttachDocumentsModal}>Upload a File</Button>
								</div>
								<hr/>
							</CardTitle>
							<CardBody className="pt-0">
								{mapDocuments(form.files)}

								<div className="d-flex justify-content-center">
									<Button color="hBlue" size="sm" className="mt-4 px-5 po-invoice-sub-header-button-b"
									        onClick={toggleAttachDocumentsModal}>Upload a File</Button>
								</div>
							</CardBody>
						</Card>
					)}

					{errors.length > 0 && (
						<div className="my-4 px-3 po-invoice-error-helper">
							<div className="po-errors-interior">
								<ErrorList errors={errors}/>
							</div>
						</div>
					)}

					<div className="mt-4 po-save-button-display-manager">
						<div className="po-buttons-interior">
							<POSaveButtons
								showSaveDraftButton={actions && legacyIncludes(actions, "savedraft")}
								showPreviewButton={actions && legacyIncludes(actions, "submit")}
								onSaveDraft={onSaveDraft}
								onPOPreview={onPOPreview}
							/>
						</div>
					</div>
				</CardBody>
			</Card>
		</React.Fragment>
	);
};

const brandColor = "rgb(49, 9, 102)";

export const reactSelectCustomStyles = {
	// control: (base, state) => ({
	// 	...base,
	// 	cursor: "text",
	// 	borderWidth: 1,
	// 	boxShadow: state.isFocused ? 0 : 0,
	// 	borderColor: state.isFocused
	// 		? brandColor
	// 		: brandColor,
	// 	"&:hover": {
	// 		borderColor: state.isFocused
	// 			? brandColor
	// 			: brandColor,
	// 	}
	// })
};

export default PurchaseOrderForm;
