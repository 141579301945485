
import React, {useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Button, Card, CardBody, Input, Label, Modal} from "reactstrap";
import HTCModalHeaderCloseButton from "./HTCModalHeaderCloseButton";
import DashboardBackgroundCard from "./dashboard/DashboardBackgroundCard";
import {decrementLoading, handleError, incrementLoading} from "../redux/meta/MetaActions";
import {initialAdapter} from "adapter";
import ErrorList from "./ErrorList";
import {useQuery} from "../utils/useQuery";

interface IProps {
	dispatch?: any;
	tokenV2?: initialAdapter.IAuthInfo;
	isOpen: boolean;
	onClose(): void;
}

const ResetForgotPasswordModal: React.FC<IProps> = (props: IProps) => {

	const query = useQuery();
	const oobCode: string = query.get("oobCode");

	const {tokenV2, isOpen} = props;
	const [securityCode, setSecurityCode] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [errors, setErrors] = useState([]);
	const [showGenerateCodeSuccess, setShowGenerateCodeSuccess] = useState(false);
	const [showChangeSuccessModal, setShowChangeSuccessModal] = useState(false);

	function resetAndCloseHelper(): void {
		setSecurityCode("");
		setPassword("");
		setConfirmPassword("");
		setShowGenerateCodeSuccess(false);
		setErrors([]);
		props.onClose();
	}

	function closeChangeSuccessModal(): void {
		setShowChangeSuccessModal(false);
		resetAndCloseHelper();
	}

	function securityCodeOnChange(e): void {
		setSecurityCode(e.target.value);
	}

	function passwordOnChange(e): void {
		setPassword(e.target.value);
	}

	function confirmPasswordOnChange(e): void {
		setConfirmPassword(e.target.value);
	}

	async function requestSecurityCode(): Promise<void> {
		setShowGenerateCodeSuccess(false);
		setErrors([]);
		props.dispatch(incrementLoading());

		const res = await initialAdapter.api.generateResetPasswordSecurityCode({oobCode: oobCode, apiKey: process.env.REACT_APP_RESET_PASSWORD_API_KEY_2}, tokenV2);

		if (!res.success) {
			props.dispatch(decrementLoading());
			setErrors(handleError(res));
			return;
		}

		setShowGenerateCodeSuccess(true);
		props.dispatch(decrementLoading());
	}

	async function requestPasswordChange(): Promise<void> {
		setErrors([]);

		if (password !== confirmPassword) {
			return;
		}

		if (password === "" && confirmPassword === "") {
			return;
		}

		props.dispatch(incrementLoading());

		const res = await initialAdapter.api.resetChangeUserPassword({securityCode: securityCode, newPassword: password, oobCode: oobCode, apiKey: process.env.REACT_APP_RESET_PASSWORD_API_KEY_2}, tokenV2);

		if (!res.success) {
			props.dispatch(decrementLoading());
			setErrors(handleError(res));
			return;
		}

		setShowChangeSuccessModal(true);

		props.dispatch(decrementLoading());
	}

	const changeButtonIsDisabled: boolean = (password === "" && confirmPassword === "") || password !== confirmPassword;

	return (
		<React.Fragment>
			<Modal
				isOpen={showChangeSuccessModal}
				fade={true}
				centered={true}
				className="htc-modal modal-max-400"
			>
				<HTCModalHeaderCloseButton onClick={closeChangeSuccessModal}/>
				<DashboardBackgroundCard background="bg-hBlue">
					<CardBody>
						<h1 className="text-white mb-3">Success!</h1>
						<Card>
							<CardBody>
								<p className="mb-0 text-center">
									Your password has been updated successfully. Please use it to log in from now on.
								</p>

								<div className="mt-4 mx-5">
									<Button color="hBlue" size="sm" className="w-100" onClick={closeChangeSuccessModal}>
										Dismiss
									</Button>
								</div>
							</CardBody>
						</Card>
					</CardBody>
				</DashboardBackgroundCard>
			</Modal>

			<Modal
				isOpen={isOpen}
				fade={true}
				centered={true}
				className="htc-modal modal-max-500"
			>
				<HTCModalHeaderCloseButton onClick={resetAndCloseHelper}/>

				<DashboardBackgroundCard background="bg-hBlue">
					<div className="px-4 py-3 custom-dashboard-card-child">
						<h1 className="text-white mb-3">Reset Password</h1>

						<Card>
							<CardBody>
								<div>
									<Label>1. Enter Security Code</Label>
									<Input placeholder="6 Digit Security Code" onChange={securityCodeOnChange} value={securityCode} className="number-display"/>
								</div>

								{showGenerateCodeSuccess && (
									<div className="text-success mt-1">
										Please check your mailbox for the code.
									</div>
								)}

								<div className="my-3 mx-5">
									<Button color="hBlue" size="sm" className="w-100" onClick={requestSecurityCode}>
										Send Security Code to Your Email
									</Button>
								</div>

								<div style={{lineHeight: "1.2rem"}}>
									Your password is case sensitive and must be between 8 and 30 characters. It must include letters and numbers and a special character.
								</div>

								<hr/>

								<div className="mb-3">
									<Label>2. Set New Password</Label>
									<Input type="password" placeholder="New Password..." onChange={passwordOnChange} value={password}/>
								</div>

								<div>
									<Label>2. Confirm New Password</Label>
									<Input type="password" placeholder="Confirm Password..." onChange={confirmPasswordOnChange} value={confirmPassword}/>
								</div>

								<div className="my-3 mx-5">
									<Button color="hBlue" size="sm" className="w-100" disabled={changeButtonIsDisabled} onClick={requestPasswordChange}>
										Change Password
									</Button>
								</div>

								{errors.length > 0 && (
									<div className="my-3">
										<ErrorList errors={errors}/>
									</div>
								)}
							</CardBody>
						</Card>
					</div>
				</DashboardBackgroundCard>
			</Modal>
		</React.Fragment>
	);
};

export default connect()(ResetForgotPasswordModal);
