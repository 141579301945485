import React from "react";
import {Container} from "reactstrap";
import CreatePurchaseOrderFormFull from "../components/CreatePurchaseOrderFormFull";
import {useHistory} from "react-router";
import {useQuery} from "../utils/useQuery";

const EditPOPage: React.FC = () => {

	const history = useHistory();
	const query = useQuery();
	const purchaseOrder: string = query.get("po");

	if (!purchaseOrder) {
		history.push("/po/create");
		return null;
	}

	return (
		<Container className="my-4 px-1 px-lg-4 htc-container">
			<CreatePurchaseOrderFormFull purchaseOrder={purchaseOrder}/>
		</Container>
	)
};

export default EditPOPage;
