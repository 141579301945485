import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import React, {useEffect, useState} from "react";
import {Button, Modal, ModalHeader} from "reactstrap";
import ErrorList from "./ErrorList";
import {decrementLoading, handleError, incrementLoading, setError} from "../redux/meta/MetaActions";
import {generic400, generic500} from "../utils/errors";
import {initialAdapter} from "adapter";

interface IProps {
	tokenV2?: any;
	dispatch?: any;
	isOpen: boolean;
	pdfType: string;
	api: "readInvPdf" | "readPOPdf" | "readCOGSPOPdf";
	docKey: string;

	onClose(): void;
}

const ClientViewPDFModal: React.FC<IProps> = (props: IProps) => {

	const {tokenV2, isOpen, pdfType, api, docKey} = props;
	const [pdf, setPDF] = useState<string | ArrayBuffer>();
	const [errors, setErrors] = useState([]);

	useEffect(() => {
		if (isOpen) {
			getPDFPreview().then().catch();
		}
	}, [isOpen]);

	async function getPDFPreview(): Promise<void> {
		setErrors([]);
		props.dispatch(incrementLoading());
		let res;
		switch (api) {
			case "readInvPdf":
				res = await initialAdapter.api.readInvPdf({invKeyURL: docKey}, tokenV2);
				break;
			case "readPOPdf":
				res = await initialAdapter.api.readPOPdf({poKeyURL: docKey}, tokenV2);
				break;
			case "readCOGSPOPdf":
				res = await initialAdapter.api.readCOGSPOPdf({poKeyURL: docKey}, tokenV2);
				break;
			default:
				props.dispatch(decrementLoading());
				closeHelper();
				return;
		}

		if (!res.success) {
			props.dispatch(decrementLoading());
			setErrors(handleError(res));
			return;
		}

		props.dispatch(decrementLoading());
		const reader = new FileReader();
		reader.readAsDataURL(res.data);
		reader.onloadend = function () {
			const base64data = reader.result;
			setPDF(base64data);
		};
	}

	function closeHelper(): void {
		setErrors([]);
		props.onClose();
		setTimeout(() => {
			setPDF(null);
		}, 250);
	}

	return (
		<Modal
			isOpen={isOpen}
			fade={true}
			centered={true}
			contentClassName="p-2"
			className="modal-max-1200"
			toggle={closeHelper}
		>
			<div className="px-3">
				<ModalHeader toggle={closeHelper} className="mb-3"><h4>{`${pdfType} PDF`}</h4></ModalHeader>
				<p>
					To print, right-click the preview and select Print. Or, click the Print icon if you see one below.
				</p>

				{pdf && (
					<div
						className="w-100 d-flex justify-content-center"
						style={{
							position: "relative",
							paddingBottom: "50%",
							paddingTop: "35px",
							height: 0,
							overflow: "hidden",
						}}
					>
						<iframe
							src={pdf.toString()}
							style={{
								position: "absolute",
								top: 0,
								left: 0,
								width: "100%",
								height: "100%",
							}}
						/>
					</div>
				)}

				{errors.length > 0 && (
					<div className="my-3">
						<ErrorList errors={errors}/>
					</div>
				)}

				<div className="d-flex justify-content-end mt-3">
					<Button color="link" onClick={closeHelper}>
						Close
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
		...props,
	}
})(ClientViewPDFModal);
