import React, {ReactNode} from "react";

interface ITabSelectorItem {
	title: string;
	value: any;
}

interface IProps {
	tabs: Array<ITabSelectorItem>;
	value: any;
	onClick(newFilter: any): void;
}

const TabSelector: React.FC<IProps> = (props: IProps) => {

	function createTabs(_tabs: Array<ITabSelectorItem> = []): ReactNode {
		return _tabs.map((_tab: ITabSelectorItem, i: number) => {

			function onClickHelper(): void {
				props.onClick(_tab.value);
			}

			return (
				<div className={"mx-2 tab-selector-item" + (_tab.value === props.value ? " tab-selector-item-active" : "")} onClick={onClickHelper}>
					{_tab.title}
				</div>
			);
		});
	}

	return (
		<div className="d-flex mx-4 overflow-x-scroller">
			{createTabs(props.tabs)}
		</div>
	);
};

export default TabSelector;
