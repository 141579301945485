import React, {ChangeEventHandler, MutableRefObject, useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import LoginForm from "../components/LoginForm";
import MobileHarborHeader from "../components/MobileHarborHeader";
import {initialAdapter} from "adapter";
import LoginAnnouncementsMobile from "../components/LoginAnnouncementsMobile";
import LoginFooterMobile from "../components/LoginFooterMobile";
import LoginCarousel from "../components/LoginCarousel";
import LoginCarouselDesktop from "../components/LoginCarouselDesktop";
import LoginAnnouncementsDesktop from "../components/LoginAnnouncementsDesktop";
import LoginFooterDesktop from "../components/LoginFooterDesktop";
import {
	decrementLoading,
	handleError,
	incrementLoading,
	loginV2, logout, saveDisplayName,
	setAccessTypes,
	setError, setUserType
} from "../redux/meta/MetaActions";
import {app} from "../index";
import {legacyIncludes} from "../utils/arrays";
import {useHistory} from "react-router-dom";
import {useQuery} from "../utils/useQuery";
import {IStore} from "../redux/defaultStore";
import ResetForgotPasswordModal from "../components/ResetForgotPasswordModal";
import SupplierSelfRegModal from "../components/SupplierSelfRegModal";

export const defaultCarouselImage: string = "https://cdn.harbortradecredit.com/static/assets/images/harbor-default-bkgrd-img.jpg";

interface IProps {
	tokenV2?: initialAdapter.IAuthInfo;
	dispatch?: any;
	accessTypeSelfReg: boolean;
}

const Login2: React.FC<IProps> = (props: IProps) => {

	const history = useHistory();
	const query = useQuery();
	const mode: string = query.get("mode");

	const [form, setForm] = useState<{loginID: string, password: string}>({loginID: "", password: ""});
	const [loginError, updateLoginError] = useState<string>();
	const [errors, setErrors] = useState([]);
	const [tempSelfRegToken, setTempSelfRegToken] = useState<{signToken: string}>();
	const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
	const [apiRes, setApiRes] = useState<initialAdapter.ILoginElementsMsg>();
	const [contentHeight, setContentHeight] = useState(0);
	const svgLayoutElement = useRef();

	useEffect(() => {
		handleError(null);

		if (props.tokenV2 && !props.accessTypeSelfReg) {
			history.replace("/home");
		} else if (mode === "resetPassword") {
			setShowResetPasswordModal(true);
		}

		readLoginInformation().then().catch();
		window.addEventListener("resize", onSVGLoad);
		return function cleanup() {
			window.removeEventListener("resize", onSVGLoad);
		};
	}, []);

	function handleFormOnChange(key: "loginID" | "password"): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		};
	}

	function onSVGLoad(): void {
		if (svgLayoutElement && svgLayoutElement.current) {
			setContentHeight((svgLayoutElement as MutableRefObject<HTMLDivElement>).current.clientHeight);
		}
	}

	async function readLoginInformation(): Promise<void> {
		const res = await initialAdapter.api.getLoginElements(undefined, undefined);

		if (res.success) {
			setApiRes(res.data);
		}
	}

	/**
	 * call sign in api, under normal circumstances, send user to dashboard,
	 * if accessTypes on the response object includes "selfreg", instead prompt with the self-reg modal
	 *
	 * @param e
	 */
	async function onLogIn(e?: any): Promise<void> {
		if (e) {
			e.preventDefault();
		}

		updateLoginError(undefined);
		setErrors([]);

		props.dispatch(incrementLoading());

		app.auth().signInWithEmailAndPassword(form.loginID, form.password).then(async () => {

			const idToken = await app.auth().currentUser.getIdToken(true);

			function loginError(): void {
				props.dispatch(decrementLoading());
				updateLoginError("There was an issue logging in, please try again.");
			}

			if (idToken) {
				const loginRes2 = await initialAdapter.api.investorSignInV2({
					signId: idToken,
					apiKey: "",
				});

				if (!loginRes2.success) {
					props.dispatch(decrementLoading());
					if (loginRes2.status === 503) {
						props.dispatch(setError(loginRes2.data.detail + " Error short code: " + (loginRes2 as any).errorShortCode));
					} else {
						setErrors(handleError(loginRes2));
					}
					return;
				} else {
					await props.dispatch(decrementLoading());
					if (legacyIncludes(loginRes2.data.accessTypes, "selfreg")) {
						setTempSelfRegToken({signToken: loginRes2.data.access_token});
					} else {
						await props.dispatch(loginV2({
							signToken: loginRes2.data.access_token,
						}));

						await props.dispatch(setAccessTypes(loginRes2.data.accessTypes));
						await props.dispatch(setUserType(loginRes2.data.userType));
						await props.dispatch(saveDisplayName(loginRes2.data.displayName));

						history.push("/home");
					}

				}

			} else {
				loginError();
			}

		}).catch((err) => {
			updateLoginError("Your Login ID or password are incorrect.");
			props.dispatch(decrementLoading());
			return;
		});
	}

	function handleFinishedSelfReg(): void {
		setTempSelfRegToken(null);
		onLogIn().then().catch();
	}

	function cancelSelfReg(): void {
		setTempSelfRegToken(null);
		props.dispatch(logout());
	}

	function closeResetForgotPasswordModal(): void {
		setShowResetPasswordModal(false);
	}

	return (
		<React.Fragment>
			<ResetForgotPasswordModal isOpen={showResetPasswordModal} onClose={closeResetForgotPasswordModal}/>

			<SupplierSelfRegModal tokenV2={tempSelfRegToken} onDone={handleFinishedSelfReg} onClose={cancelSelfReg}/>

			<div className="login-page-bg">
				<div className="login-display-mobile">
					<div className="p-4">
						<MobileHarborHeader/>
					</div>

					<LoginAnnouncementsMobile announcements={apiRes?.announcements}/>

					<div className="p-4 d-flex justify-content-center">
						<div className="login-form-mobile-container">
							<LoginForm
								values={form}
								onChange={handleFormOnChange}
								onLogIn={onLogIn}
								uniqueError={loginError}
								errors={errors}
							/>
						</div>
					</div>

					<LoginCarousel marketingItems={apiRes?.marketingItems}/>

					<LoginFooterMobile/>
				</div>

				<div className="position-relative">
					<div className="login-desktop-bottom-curtain" style={{top: contentHeight - 1}}/>
				</div>

				<div className="login-display-desktop">
					<div className="login-desktop-layout-manager" style={{height: contentHeight}}>

						<div className="p-4 position-absolute" style={{top: 0, left: 0, zIndex: 100}}>
							<MobileHarborHeader/>
						</div>

						<div className="login-main-bg-area">
							<LoginCarouselDesktop marketingItems={apiRes?.marketingItems} contentHeight={contentHeight}/>
						</div>

						<div className="login-svg-side" ref={svgLayoutElement}>
							<img
								src={process.env.PUBLIC_URL + "/login-svgs/1200dividerv2.svg"}
								onLoad={onSVGLoad}
							/>

							<div className="login-form-desktop">
								<LoginAnnouncementsDesktop announcements={apiRes?.announcements}/>
								<LoginForm
									values={form}
									onChange={handleFormOnChange}
									onLogIn={onLogIn}
									uniqueError={loginError}
									errors={errors}
								/>
							</div>

							<div className="login-desktop-footer" style={{top: contentHeight - 60}}>
								<LoginFooterDesktop/>
							</div>
						</div>

					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
		accessTypeSelfReg: store.metaStore.accessTypeSelfReg,
	}
})(Login2);
