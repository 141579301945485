import React, {ReactNode, useEffect, useState} from "react";
import {Button, Col, Container, Row} from "reactstrap";
import CustomerInfoCard from "./CustomerInfoCard";
import SupplierInfoCard from "./SupplierInfoCard";
import InvoiceSummaryCard from "./InvoiceSummaryCard";
import CreateInvoiceFromPOModal from "./CreateInvoiceFromPOModal";
import CreateInvoiceForm, {IInvoiceFormFields, IInvoiceItem} from "./CreateInvoiceForm";
import {initialAdapter} from "adapter";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {decrementLoading, handleError, incrementLoading, setError} from "../redux/meta/MetaActions";
import {calculateBaseOrderTotal} from "../utils/orderCalculations";
import {useHistory} from "react-router";
import {findJsonIndexInArray, legacyIncludes} from "../utils/arrays";
import PreviewAndSubmitInvoiceModal from "./PreviewAndSubmitInvoiceModal";
import {IPOEditingMeta} from "./CreatePurchaseOrderFormFull";
import ServiceCompanyInfoCard from "./ServiceCompanyInfoCard";
import {generic400, generic500} from "../utils/errors";
import InvoiceSummaryCard2 from "./InvoiceSummaryCard2";
import CustomerInfoCard2 from "./CustomerInfoCard2";
import ServiceCompanyInfoCard2 from "./ServiceCompanyInfoCard2";
import ErrorList from "./ErrorList";
import POSaveButtons from "./POSaveButtons";

export interface IInvoiceEditingMeta {
	timestamp: string;
	invoiceKeyURL: string;
}

interface ICreateInvoiceFormFullProps {
	tokenV2?: initialAdapter.IAuthInfo;
	dispatch?: any;
	invoice?: string;
}

const CreateInvoiceFormFull: React.FC<ICreateInvoiceFormFullProps> = (props: ICreateInvoiceFormFullProps) => {

	const {tokenV2, dispatch, invoice} = props;
	const history = useHistory();
	const [showCreateInvoiceFromPOModal, setShowCreateInvoiceFromPOModal] = useState(false);
	const [form, setForm] = useState<IInvoiceFormFields>({
		invoiceItems: [{}],
		paymentDue: -1,
		incoTerms: "",
		customer: "",
		invoiceNumber: "",
		POSO: "",
		invoiceDate: "",
		notes: "",
		receivedDeposit: "",
	});
	const [initDataRes, setInitDataRes] = useState<initialAdapter.IReadInvInitDataRes>();
	const [selectedBuyer, setSelectedBuyer] = useState<initialAdapter.IBuyerListForInv>();
	const [fullBuyerDetails, setFullBuyerDetails] = useState<any>(); // todo interface;
	const [fullServiceCompanyDetails, setFullServiceCompanyDetails] = useState<initialAdapter.IServiceCompany>();
	const [autoCompleteVal, setAutoCompleteVal] = useState("");
	const [editingMeta, setEditingMeta] = useState<IInvoiceEditingMeta>();
	const [newInvKey, setNewInvKey] = useState<string>();
	const [showSubmitPreview, setShowSubmitPreview] = useState(false);
	const [rawExistingInvoice, setRawExistingInvoice] = useState<any>();
	const [invoiceCreatedFromPO, setInvoiceCreatedFromPO] = useState(false);
	const [errors, setErrors] = useState([]);
	const [errorsForSubmitModal, setErrorsForSubmitModal] = useState([]);

	useEffect(() => {
		if (!invoice) {
			getInitialInvData().then().catch();
		} else {
			getExistingInvData(invoice).then().catch();
		}
	}, []);

	async function getInitialInvData(ignoreForm: boolean = false): Promise<initialAdapter.IReadInvInitDataRes> {
		setErrors([]);
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.readInvInitData(undefined, tokenV2);

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		setInitDataRes(res.data);
		if (!ignoreForm) {
			setForm({...form, invoiceNumber: res.data.invoiceNumber, invoiceDate: res.data.invoiceDate});
		}

		if (!invoice && !newInvKey) {
			setNewInvKey(res.data.newInvKeyURL);
		}

		props.dispatch(decrementLoading());

		return res.data;
	}

	async function getExistingInvData(key: string = ""): Promise<void> {
		const initData = await getInitialInvData(true);

		setErrors([]);
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.readDraftInv({invKeyURL: key}, tokenV2);

		if (!res.success) {
			props.dispatch(decrementLoading());
			setErrors(handleError(res));
			return;
		}

		// if poKeyURL exists on an invoice, this means it's an invoice
		// created from a customer PO, in which case we set a flag to indicate
		// that a few fields are readOnly -> Customer Name, Po Number, INCOTERM, & Payment Due
		if (res.data.poKeyURL.length > 0) {
			setInvoiceCreatedFromPO(true)
		}

		setEditingMeta({timestamp: res.data.timestamp, invoiceKeyURL: res.data.invKeyURL});

		let buyerRes;
		if (res.data.customerKeyURL) {
			buyerRes = await initialAdapter.api.readInvBuyerData(
				{buyerKeyURL: res.data.customerKeyURL, tradelink: res.data.tradelink},
				tokenV2
			);

			if (!buyerRes.success) {
				props.dispatch(decrementLoading());
				setErrors(handleError(buyerRes));
				return;
			}
			setFullBuyerDetails(buyerRes.data);
		}

		let serviceCompanyRes;
		if (res.data.htcPO && res.data.serviceCompanyKeyURL) {
			serviceCompanyRes = await initialAdapter.api.readServiceCompany({scKeyURL: res.data.serviceCompanyKeyURL}, tokenV2);

			if (!serviceCompanyRes.success) {
				props.dispatch(decrementLoading());
				setErrors(handleError(serviceCompanyRes));
				return;

			}

			setFullServiceCompanyDetails(serviceCompanyRes.data);
		}

		const files: Array<initialAdapter.IDocumentItemMsg> = await attachOrGetDocuments({
			timestamp: res.data.timestamp,
			invoiceKeyURL: res.data.invKeyURL
		});

		setAutoCompleteVal(res.data.customerName);
		setSelectedBuyer({
			buyerKeyURL: res.data.customerKeyURL,
			buyerName: res.data.customerName,
			tradelink: res.data.tradelink,
		});

		setRawExistingInvoice(res.data);

		const selectedPaymentDue: number = findJsonIndexInArray(initData.supplierPaymentDueList, "term", parseInt(res.data.paymentTerm));

		setForm({
			...form,
			customer: res.data.customerKeyURL,
			invoiceNumber: res.data.invoiceNumber,
			POSO: res.data.poNumber,
			invoiceDate: res.data.invoiceDate,
			incoTerms: res.data.shippingTerm,
			paymentDue: selectedPaymentDue,
			invoiceItems: res.data.orderItems.map((item) => {
				return {
					name: item.itemName,
					description: item.itemDesc,
					// quantity: parseFloat(item.itemQty),
					quantity: item.itemQty,
					// price: parseFloat(item.unitPrice),
					price: item.unitPrice,
				}
			}),
			notes: res.data.notes,
			files,
			receivedDeposit: res.data.receivedDeposit,
		});

		props.dispatch(decrementLoading());
	}

	async function finishRemovingDocumentHelper(newEditingMeta: IInvoiceEditingMeta): Promise<void> {
		await attachOrGetDocuments(newEditingMeta);
	}

	async function handleBuyerSelect(buyer: initialAdapter.IBuyerListForInv): Promise<void> {
		if (Object.keys(buyer).length < 1) {
			setSelectedBuyer(null);
			setFullBuyerDetails(null);
			setForm({
				...form,
				customer: "",
			}); // todo might have to reset more values based on response
		} else {
			setErrors([]);
			props.dispatch(incrementLoading());
			await getInitialInvData(true);

			const res = await initialAdapter.api.readInvBuyerData(
				{buyerKeyURL: buyer.buyerKeyURL, tradelink: buyer.tradelink},
				tokenV2
			);
			setAutoCompleteVal(buyer.buyerName);

			props.dispatch(decrementLoading());

			if (!res.success) {
				setErrors(handleError(res));
				return;
			}

			setSelectedBuyer(buyer);
			setFullBuyerDetails(res.data);
			setForm({
				...form,
				customer: buyer.buyerKeyURL,
			}); // todo set more values based on response? pending
		}
	}

	function toggleShowCreateFromPOModal(): void {
		setShowCreateInvoiceFromPOModal(!showCreateInvoiceFromPOModal);
	}

	async function attachOrGetDocuments(newEditingMeta: IInvoiceEditingMeta = editingMeta): Promise<Array<initialAdapter.IDocumentItemMsg>> {
		setErrors([]);
		props.dispatch(incrementLoading());
		setEditingMeta(newEditingMeta);
		const res = await initialAdapter.api.readInvDocs({invKeyURL: newEditingMeta.invoiceKeyURL}, tokenV2);
		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		setForm({
			...form,
			files: res.data.documentList,
		});

		return res.data.documentList;
	}

	async function handleSelectPO(po: any): Promise<void> {
		setErrors([]);
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.createDraftInvFromPO({poKeyURL: po.poKeyURL}, tokenV2);
		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			setShowCreateInvoiceFromPOModal(false);
			return;
		}

		history.push({pathname: "/invoice/edit", search: `?invoice=${res.data.data[1]}`});
	}

	function onFormChange(key: keyof IInvoiceFormFields, value: any): void {
		setForm({...form, [key]: value});
	}

	async function attachDocuments(): Promise<void> {
		// alert("todo! attach documents");
	}

	async function cogsPreview(): Promise<void> {
		// alert("todo! cogs invoice preview & submit");
	}

	async function showInvoicePreviewModal(): Promise<void> {
		const req: any = generateSaveReqObject(true);

		setErrors([]);
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.updateDraftInv(req, tokenV2);
		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		setEditingMeta({timestamp: res.data.data[0], invoiceKeyURL: res.data.data[1]});
		setShowSubmitPreview(true)
	}

	function hideInvoicePreviewModal(): void {
		setShowSubmitPreview(false);
	}

	async function sendInvoice(): Promise<void> {
		setErrorsForSubmitModal([]);
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.updateValidatedInvtoSubmitted(
			{invKeyURL: editingMeta.invoiceKeyURL, timestamp: editingMeta.timestamp},
			tokenV2
		);
		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		history.push("/home");
	}

	/**
	 * Creates the request object for use in the updateDraftInv api.
	 * needValidation = true when clicking "Preview & Submit" button,
	 * otherwise false when creating / standard editing of the invoice.
	 *
	 * @param needValidation
	 */
	function generateSaveReqObject(needValidation: boolean): any {
		return {
			needValidation,
			invoiceNumber: form.invoiceNumber,
			invoiceDate: form.invoiceDate,
			customerName: invoiceCreatedFromPO ? rawExistingInvoice.customerName : (selectedBuyer ? selectedBuyer.buyerName : ""),
			customerKeyURL: form.customer,
			tradelink: selectedBuyer ? selectedBuyer.tradelink : false,
			tradeCurrency: (fullBuyerDetails && fullBuyerDetails.tradeCurrency) ? fullBuyerDetails.tradeCurrency : (rawExistingInvoice && rawExistingInvoice.tradeCurrency ? rawExistingInvoice.tradeCurrency : ""),
			poNumber: invoiceCreatedFromPO ? rawExistingInvoice.poNumber : form.POSO,
			poKeyURL: invoiceCreatedFromPO ? rawExistingInvoice.poKeyURL : "",
			orderItems: form.invoiceItems.map((item) => {
				return {
					itemName: item.name ? item.name : "",
					itemDesc: item.description ? item.description : "",
					itemQty: item.quantity ? item.quantity.toString() : "",
					unitPrice: item.price ? item.price.toString() : "",
				}
			}).filter((filterItem) => {
				if (filterItem.itemName.length > 0 || filterItem.itemDesc.length > 0 || filterItem.itemQty.length > 0 || filterItem.unitPrice.length > 0) {
					return filterItem;
				}
			}),
			shippingTerm: invoiceCreatedFromPO ? rawExistingInvoice.shippingTerm : form.incoTerms,
			paymentTerm: invoiceCreatedFromPO ? rawExistingInvoice.paymentTerm : (form.paymentDue > -1 && initDataRes) ? initDataRes.supplierPaymentDueList[form.paymentDue].term.toString() : "",
			notes: form.notes,
			timestamp: (editingMeta && editingMeta.timestamp) ? editingMeta.timestamp : "",
			invKeyURL: (editingMeta && editingMeta.invoiceKeyURL) ? editingMeta.invoiceKeyURL : newInvKey,
			receivedDeposit: (rawExistingInvoice && rawExistingInvoice.poKeyURL.length > 0) ? (rawExistingInvoice.receivedDeposit ? rawExistingInvoice.receivedDeposit : "") : (form.receivedDeposit ? form.receivedDeposit : ""),
			htcPO: rawExistingInvoice ? rawExistingInvoice.htcPO : false,
			serviceCompanyKeyURL: rawExistingInvoice ? rawExistingInvoice.serviceCompanyKeyURL : "",
		}
	}

	async function saveDraft(): Promise<void> {
		const req: any = generateSaveReqObject(false);

		setErrors([]);
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.updateDraftInv(req, tokenV2);
		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		// Originally this checked if editingMeta exists, however,
		// even when creating a new invoice, editingMeta will exist after attaching a document,
		// so for now checking if this exists or not is the proper check. In the future
		// the way the editingMeta / newInvKey are handled on the frontend could be changed.
		// newInvKey is always returned when readInvInitData is called, but only saved
		// if no invKeyURL (invoice from url params) is present via props
		if (!newInvKey) {
			setEditingMeta({timestamp: res.data.data[0], invoiceKeyURL: res.data.data[1]});
			await getExistingInvData(res.data.data[1]);
		} else {
			history.push({pathname: "/invoice/edit", search: `?invoice=${res.data.data[1]}`});
		}
	}

	function calculateOrderAdjusted(): number {
		const itemTotal: number = calculateBaseOrderTotal(form.invoiceItems);
		return itemTotal;
	}

	function onAutoCompleteValueChange(val: string): void {
		setAutoCompleteVal(val);
	}

	const createFromPOButton: ReactNode = (initDataRes && initDataRes.actions && legacyIncludes(initDataRes.actions, "createinvfrompo") ? (
		<Button color="hPurple" size="sm" className="px-2" onClick={toggleShowCreateFromPOModal}>
			Create an invoice from customer's PO
		</Button>
	) : null);

	return (
		<React.Fragment>
			<CreateInvoiceFromPOModal
				isOpen={showCreateInvoiceFromPOModal}
				closeModal={toggleShowCreateFromPOModal}
				onSelect={handleSelectPO}
			/>

			<PreviewAndSubmitInvoiceModal
				isOpen={showSubmitPreview}
				cogs={rawExistingInvoice && rawExistingInvoice.htcPO}
				closeModal={hideInvoicePreviewModal}
				onConfirm={sendInvoice}
				invKey={editingMeta ? editingMeta.invoiceKeyURL : newInvKey}
				errorsForSubmitModal={errorsForSubmitModal}
			/>

			<div className="upload-po-or-invoice-button-container-a mb-3">
				{createFromPOButton}
			</div>

			<div className="po-invoice-form-container">
				<div className="po-invoice-main">
					<CreateInvoiceForm
						form={form}
						onChange={onFormChange}
						onAttachDocuments={attachOrGetDocuments}
						onCOGSPreview={cogsPreview}
						onInvoicePreview={showInvoicePreviewModal}
						onSaveDraft={saveDraft}
						buyerList={initDataRes ? initDataRes.buyerListForInvoice : []}
						onBuyerSelect={handleBuyerSelect}
						incoterms={initDataRes ? initDataRes.supplierIncotermList : []}
						paymentDueList={initDataRes ? initDataRes.supplierPaymentDueList : []}
						selectedBuyerIsEditable={selectedBuyer && selectedBuyer.tradelink === false}
						autoCompleteVal={autoCompleteVal}
						onAutoCompleteValueChange={onAutoCompleteValueChange}
						fullBuyerEditingDetails={fullBuyerDetails}
						selectedBuyer={selectedBuyer}
						editingMeta={editingMeta ? editingMeta : {invoiceKeyURL: newInvKey, timestamp: ""}}
						onRemoveDocument={finishRemovingDocumentHelper}
						invoiceCreatedFromPO={invoiceCreatedFromPO}
						serviceCompany={fullServiceCompanyDetails}
						errors={errors}
						actions={initDataRes ? initDataRes.actions : []}
					/>
				</div>

				<div className="po-invoice-sub">
					<div className="upload-po-or-invoice-button-container-b mb-3">
						{createFromPOButton}
					</div>

					{fullServiceCompanyDetails && (
						<div className="po-invoice-sub-card-container">
								<ServiceCompanyInfoCard2
									customer={fullServiceCompanyDetails ? fullServiceCompanyDetails.businessName : ""}
									address={fullServiceCompanyDetails ? fullServiceCompanyDetails.formattedAddress : ""}
									name={fullServiceCompanyDetails ? fullServiceCompanyDetails.contactName : ""}
									email={fullServiceCompanyDetails ? fullServiceCompanyDetails.contactEmail : ""}
									phone={fullServiceCompanyDetails ? fullServiceCompanyDetails.contactNumber : ""}
								/>
						</div>
					)}

					<div className={"po-invoice-sub-card-container " + (fullServiceCompanyDetails ? "po-invoice-sub-container-middle" : "po-invoice-sub-container-middle-pseudo")}>
						<CustomerInfoCard2
							customer={selectedBuyer ? selectedBuyer.buyerName : ""}
							address={fullBuyerDetails ? fullBuyerDetails.formattedAddress : ""}
							name={fullBuyerDetails ? fullBuyerDetails.contactName : ""}
							email={fullBuyerDetails ? fullBuyerDetails.contactEmail : ""}
							phone={fullBuyerDetails ? fullBuyerDetails.contactNumber : ""}
							serviceCompany={fullServiceCompanyDetails}
						/>
					</div>

					<div className="po-invoice-sub-card-container">
						<InvoiceSummaryCard2
							invoiceTotal={calculateOrderAdjusted()}
							currency={(fullBuyerDetails && fullBuyerDetails.tradeCurrency) ? fullBuyerDetails.tradeCurrency : (rawExistingInvoice && rawExistingInvoice.tradeCurrency ? rawExistingInvoice.tradeCurrency : "")}
						/>
					</div>
				</div>
			</div>

			{errors.length > 0 && (
				<div className="mb-4 px-3 po-invoice-error-helper">
					<div className="po-errors-exterior">
						<ErrorList errors={errors}/>
					</div>
				</div>
			)}

			<div className="mt-4 po-save-button-display-manager">
				<div className="po-buttons-exterior">
					<POSaveButtons
						showSaveDraftButton={(initDataRes && initDataRes.actions) && legacyIncludes(initDataRes.actions, "savedraft")}
						showPreviewButton={(initDataRes && initDataRes.actions) && legacyIncludes(initDataRes.actions, "submit")}
						onSaveDraft={saveDraft}
						onPOPreview={showInvoicePreviewModal}
					/>
				</div>
			</div>
		</React.Fragment>

	)
};

export default connect((store: IStore, props: ICreateInvoiceFormFullProps) => {
	return {
		tokenV2: store.metaStore.tokenV2
	}
})(CreateInvoiceFormFull);
