import React from "react";
import {Container} from "reactstrap";
import PurchaseTableWithController from "../components/PurchaseTableWithController";

const PurchasesPage: React.FC = () => {

	return (
		<Container className="my-4 px-1 px-lg-4 htc-container">
			<PurchaseTableWithController/>
		</Container>
	)
};

export default PurchasesPage;
