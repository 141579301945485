import React, {ReactNode, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {initialAdapter} from "adapter";
import {decrementLoading, handleError, incrementLoading} from "../redux/meta/MetaActions";
import ErrorList from "./ErrorList";
import {Button, Card, CardBody, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import BuyerSetupTradeLinkModal from "./BuyerSetupTradeLinkModal";
import HTCModalHeaderCloseButton from "./HTCModalHeaderCloseButton";
import DashboardBackgroundCard from "./dashboard/DashboardBackgroundCard";

interface IProps {
	tokenV2?: any;
	dispatch?: any;
	invite: initialAdapter.IBusinessLinkForTLCompany;
	onDone(): Promise<void>;
}

const PendingBuyerTradeLinkSetupCard: React.FC<IProps> = (props: IProps) => {

	const {invite} = props;
	const [showReject, setShowReject] = useState(false);
	const [showAccept, setShowAccept] = useState(false);
	const [errors, setErrors] = useState([]);

	/**
	 * factory for the labels & information
	 *
	 * @param label
	 * @param value
	 */
	function makeLabelValuePair(label: string, value: string): ReactNode {
		return (
			<div>
				<h6 className="text-secondary mb-0">
					{`${label}:`}
				</h6>
				<h5 className="text-dark mb-1">
					{value}
				</h5>
			</div>
		)
	}

	function toggleRejectModal(): void {
		setErrors([]);
		setShowReject(!showReject)
	}

	function toggleAcceptModal(): void {
		setErrors([]);
		setShowAccept(!showAccept);
	}

	/**
	 * call api to confirm the dismissal
	 *
	 */
	async function confirmReject(): Promise<void> {
		setErrors([]);
		setShowReject(false);
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.dismissTradeLinkSetup({keyURL: invite.businessLinkRequestKeyURL, timestamp: invite.timestamp}, props.tokenV2);

		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		props.onDone().then().catch();
	}

	const errorNode: ReactNode = errors.length > 0 ? (
		<div className="my-3">
			<ErrorList errors={errors}/>
		</div>
	) : null;

	function onDoneIntercept(): void {
		setShowAccept(false);
		props.onDone().then().catch();
	}

	return (
		<React.Fragment>
			<Modal
				isOpen={showReject}
				fade={true}
				centered={true}
				contentClassName=""
				className="htc-modal modal-max-500"
			>
				<HTCModalHeaderCloseButton onClick={toggleRejectModal}/>
				<DashboardBackgroundCard background="bg-hBlue">
					<CardBody>
						<h2 className="text-white mb-3">Confirm Dismissal</h2>
						<Card>
							<CardBody>
								<p>
									{`Are you sure you want to dismiss the pending TradeLink setup with ${invite.businessName}? You will be able to finish setting up the TradeLink at any point on the BizLink page under the Account tab.`}
								</p>
								{errorNode}

								<div className="mt-4 mx-5">
									<Button color="hPurple" size="sm" className="w-100" onClick={toggleRejectModal}>
										Cancel
									</Button>
									<Button color="hBlue" size="sm" className="w-100 mt-3" onClick={confirmReject}>
										Yes, Dismiss
									</Button>
								</div>
							</CardBody>
						</Card>
					</CardBody>
				</DashboardBackgroundCard>
			</Modal>

			<BuyerSetupTradeLinkModal
				isOpen={showAccept}
				keyURL={invite ? invite.clientKeyURL : ""}
				onClose={toggleAcceptModal}
				onDone={onDoneIntercept}
				supplierName={invite.businessName}
			/>

			<Card className="w-100 po-inv-card-base">
				<CardBody className="d-flex flex-column justify-content-between">
					<div>
						<Row className="mb-2">
							<Col xs={12}>
								<Label>Company:</Label>
							</Col>

							<Col xs={12}>
								<span>
									{invite.businessName}
								</span>
							</Col>
						</Row>

						<Row className="mb-2">
							<Col xs={12} sm={6}>
								<Label>Country:</Label>
							</Col>

							<Col xs={12} sm={6}>
								<span>
									{invite.registeredCountry}
								</span>
							</Col>
						</Row>

						<Row className="mb-2">
							<Col xs={12} sm={6}>
								<Label>Contact Person:</Label>
							</Col>

							<Col xs={12} sm={6}>
								<span>
									{invite.contactName}
								</span>
							</Col>
						</Row>

						<Row className="mb-2">
							<Col xs={12} sm={6}>
								<Label>Contact Job Title:</Label>
							</Col>

							<Col xs={12} sm={6}>
								<span>
									{invite.contactJobTitle}
								</span>
							</Col>
						</Row>

						<Row className="mb-2">
							<Col xs={12} sm={6}>
								<Label>Contact Number:</Label>
							</Col>

							<Col xs={12} sm={6}>
								<span className="number-display">
									{invite.contactNumber}
								</span>
							</Col>
						</Row>

						<Row className="mb-2">
							<Col xs={12}>
								<Label>Contact Email:</Label>
							</Col>

							<Col xs={12}>
								<span className="number-display">
									{invite.contactEmail}
								</span>
							</Col>
						</Row>
					</div>

					<div className="mt-3">
						<Button color="hPurple" size="sm" className="w-100 font-weight-bold" onClick={toggleRejectModal}>
							Dismiss
						</Button>

						<Button color="hBlue" size="sm" className="w-100 font-weight-bold mt-3" onClick={toggleAcceptModal}>
							Set up
						</Button>
					</div>
				</CardBody>
			</Card>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
		...props,
	}
})(PendingBuyerTradeLinkSetupCard);
