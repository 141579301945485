import React from "react";
import {Container} from "reactstrap";
import "../style/Header.scss";
import {useLocation} from "react-router-dom";

const Footer = () => {

	const location = useLocation();

	if (
		["/", "/login2"].includes(location.pathname)
	) {
		return null;
	}

	return (
		<Container className="p-3 px-5 border-top d-flex flex-wrap justify-content-end align-items-center mt-5" fluid>
			<h6 className="mb-0">
				Powered by
			</h6>
			<img
				src={process.env.PUBLIC_URL + "/logos/logo-only.png"}
				alt="Harbour Trade Credit Logo"
				style={{
					objectFit: "contain",
					maxHeight: 20
				}}
				className="ml-2"
			/>
		</Container>
	);
};

export default Footer;
