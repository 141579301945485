import React, {ChangeEventHandler, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Button, Card, CardBody, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {initialAdapter} from "adapter";
import {decrementLoading, handleError, incrementLoading, setError} from "../redux/meta/MetaActions";
import {generic400, generic500} from "../utils/errors";
import ErrorList from "./ErrorList";
import HTCModalHeaderCloseButton from "./HTCModalHeaderCloseButton";
import DashboardBackgroundCard from "./dashboard/DashboardBackgroundCard";

interface IProps {
	tokenV2?: any;
	dispatch?: any;
	isOpen: boolean;

	closeModal(): void;
}

const defaultForm: initialAdapter.IInviteeInfoMsg = {
	email: "",
	name: "",
	companyName: "",
};

const InviteSellerModal: React.FC<IProps> = (props: IProps) => {

	const {tokenV2, isOpen} = props;
	const [form, setForm] = useState<initialAdapter.IInviteeInfoMsg>(defaultForm);
	const [useFullForm, setUseFullForm] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	const [errors, setErrors] = useState([]);

	function resetAndClose(): void {
		setErrors([]);
		setUseFullForm(false);
		setForm(defaultForm);
		props.closeModal();
	}

	function createOnChange(key: keyof initialAdapter.IInviteeInfoMsg): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * Call the api to check if the email exists, if so the backend invites the supplier
	 * and the frontend will show a success modal
	 *
	 */
	async function submitCheckSellerEmail(e?): Promise<void> {
		if (e) {
			e.preventDefault()
		}
		setErrors([]);
		props.dispatch(incrementLoading());

		const res = await initialAdapter.api.inviteSupplierToConnect({email: form.email}, tokenV2);
		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			return;
		} else if (res && res.data && res.data.data && res.data.data.length === 0) {
			setUseFullForm(true);
		} else {
			setShowSuccess(true);
		}
	}

	/**
	 * Called on submit after no email is found,
	 * shows success modal on success
	 *
	 */
	async function submitSellerInvitation(e?): Promise<void> {
		if (e) {
			e.preventDefault()
		}
		setErrors([]);
		props.dispatch(incrementLoading());

		const res = await initialAdapter.api.inviteSupplier(form, tokenV2);
		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		setShowSuccess(true);
	}

	function closeSuccessModal(): void {
		resetAndClose();
		setShowSuccess(false);
	}

	return (
		<React.Fragment>
			<Modal
				isOpen={showSuccess}
				fade={true}
				centered={true}
				contentClassName=""
				className="htc-modal modal-max-400"
				toggle={closeSuccessModal}
			>
				<HTCModalHeaderCloseButton onClick={resetAndClose}/>
				<DashboardBackgroundCard background="bg-hBlue">
					<CardBody>
						<h2 className="text-white mb-3">Invitation Successful</h2>
						<Card>
							<CardBody>
								<p>
									Your invitation was successful.
								</p>

								<div className="d-flex justify-content-center">
									<Button color="hBlue" size="sm" className="px-4" onClick={closeSuccessModal}>
										Dismiss
									</Button>
								</div>
							</CardBody>
						</Card>
					</CardBody>
				</DashboardBackgroundCard>
			</Modal>

			<Modal
				isOpen={isOpen}
				fade={true}
				centered={true}
				contentClassName=""
				className="htc-modal modal-max-700"
			>
				<HTCModalHeaderCloseButton onClick={resetAndClose}/>
				<DashboardBackgroundCard background="bg-hBlue">
					<CardBody>
						<h2 className="text-white mb-3">Invite a Supplier</h2>
						<Card>
							<CardBody>
								<h4 className="text-hPurple">
									First, enter the company contact's email.
								</h4>
								<p className="font-weight-light form-tip">
									We'll run a check to see if they're already on our platform.
								</p>

								<form onSubmit={submitCheckSellerEmail}>
									<div>
										<Label>Supplier Company Email</Label>
										<Input type="text" name="supplierEmail" id="supplierEmail" placeholder="Email Address..." value={form.email} onChange={createOnChange("email")} disabled={useFullForm}/>
									</div>

									<Row className="mt-3">
										<Col xs={12} md={{size: 6, offset: 6}}>
											<Button color="hBlue" size="sm" className="w-100" disabled={!form.email || useFullForm} type="submit">
												Invite to Connect
											</Button>
										</Col>
									</Row>
								</form>

								{useFullForm && (
									<React.Fragment>
										<div className="my-4">
											<p className="font-weight-light form-tip">
												We couldn't find that email in our system. To complete this invitation, please enter
												the following details (or adjust the email address and click the "Check Email" button
												again).
											</p>
										</div>

										<form onSubmit={submitSellerInvitation}>
											<Row className="mb-3 mt-4">
												<Col xs={12} sm={6} className="mb-4 mb-sm-0">
													<Label for="sellerName">Company Name</Label>
													<Input type="text" name="sellerName" id="sellerName"
													       placeholder="Enter a name..." value={form.companyName}
													       onChange={createOnChange("companyName")}/>
												</Col>
												<Col xs={12} sm={6} className="mb-0">
													<Label for="contactName">Contact Name</Label>
													<Input type="text" name="contactName" id="contactName"
													       placeholder="Enter a name..." value={form.name}
													       onChange={createOnChange("name")}/>
												</Col>
											</Row>

											<Row>
												<Col xs={12} md={{size: 6, offset: 6}}>
													<Button color="hBlue" size="sm" className="w-100" disabled={!form.email || !form.companyName || !form.name} type="submit">
														Send Invite
													</Button>
												</Col>
											</Row>
										</form>
									</React.Fragment>
								)}

								{errors.length > 0 && (
									<div className="mt-4">
										<ErrorList errors={errors}/>
									</div>
								)}
							</CardBody>
						</Card>
					</CardBody>
				</DashboardBackgroundCard>
			</Modal>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		tokenV2: store.metaStore.tokenV2,
	}
})(InviteSellerModal);
