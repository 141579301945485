import React from "react";
import {Card} from "reactstrap";

interface IProps {
	title: string;
	value: string;
	className?: string;
	pClass?: string;
}

const AccountOverviewNormalTextDisplay: React.FC<IProps> = (props: IProps) => {

	return (
		<Card className={props.className}>
			<div className="py-2 px-3">
				<h5 className="text-hBlue font-weight-semi-bold">{props.title}</h5>
				<p className={"text-hPurple mb-0 white-space-break-spaces" + (props.pClass ? ` ${props.pClass}` : "")}>
					{props.value}
				</p>
			</div>
		</Card>
	);
};

AccountOverviewNormalTextDisplay.defaultProps = {
	className: "mb-4",
};

export default AccountOverviewNormalTextDisplay;
