import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import cloneDeep from "lodash.clonedeep";
import {decrementLoading, handleError, incrementLoading, setError} from "../redux/meta/MetaActions";
import {generic400, generic500} from "../utils/errors";
import {Button, Modal, ModalHeader} from "reactstrap";
import {initialAdapter} from "adapter";

interface IConfirmMarkInvoiceAsPaidModalProps {
	tokenV2?: any;
	dispatch?: any;
	isOpen: boolean;
	invoice: any;
	closeModal(): void;
	onDone(getNewData: boolean): void;
}

const ConfirmMarkInvoiceAsPaidModal: React.FC<IConfirmMarkInvoiceAsPaidModalProps> = (props: IConfirmMarkInvoiceAsPaidModalProps) => {

	const {tokenV2, isOpen, invoice} = props;
	const [dataClone, setDataClone] = useState<any>();
	const [errors, setErrors] = useState<string[]>([]);

	useEffect(() => {
		setDataClone(cloneDeep(invoice));
	}, [JSON.stringify(invoice)]);

	function cancelHelper(): void {
		props.closeModal();
	}

	async function confirmMarkAsPaid(): Promise<void> {
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.updateInvtoPaid({
			keyURL: invoice.invoiceKeyURL,
			timestamp: invoice.timestamp,
		}, tokenV2);

		props.dispatch(decrementLoading());
		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		props.onDone(true);
	}

	return (
		<Modal
			isOpen={isOpen}
			fade={true}
			centered={true}
			contentClassName="p-3"
		>
			{dataClone && (
				<React.Fragment>
					<ModalHeader toggle={cancelHelper} className="mb-3"><h4>Mark invoice as paid</h4></ModalHeader>

					<div className="px-3">
						<p className="mb-4 text-secondary">
							Are you sure you want to mark {dataClone.invoiceNumber} as paid?
						</p>

						<div className="d-flex justify-content-between">
							<Button onClick={cancelHelper} color="link">
								Cancel
							</Button>

							<Button
								onClick={confirmMarkAsPaid}
								color="primary"
							>
								Mark invoice as paid
							</Button>
						</div>
					</div>
				</React.Fragment>
			)}

		</Modal>
	);
};

export default connect((store: IStore, props: IConfirmMarkInvoiceAsPaidModalProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
		...props,
	}
})(ConfirmMarkInvoiceAsPaidModal);
