import React from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import SupplierSelfRegistrationForm from "./SupplierSelfRegistrationForm";
import HTCModalHeaderCloseButton from "./HTCModalHeaderCloseButton";

interface IProps {
	tokenV2?: any;
	dispatch?: any;
	onDone(): void;
	onClose(): void;
}

const SupplierSelfRegModal: React.FC<IProps> = (props: IProps) => {

	const {tokenV2} = props;

	return (
		<Modal
			isOpen={!!tokenV2}
			fade={true}
			centered={true}
			contentClassName=""
			className="htc-modal modal-max-900"
		>
			<HTCModalHeaderCloseButton onClick={props.onClose}/>
			<SupplierSelfRegistrationForm tokenV2={tokenV2} onDone={props.onDone}/>
		</Modal>
	)
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
	}
})(SupplierSelfRegModal)
