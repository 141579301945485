import React, {ChangeEventHandler, ReactNode, useState} from "react";
import AddCustomerModal from "./AddCustomerModal";
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Input, Label, Row} from "reactstrap";
import InvoiceItemsList from "./InvoiceItemsList";
import {initialAdapter} from "adapter";
import Select from "react-select";
import {reactSelectCustomStyles} from "./PurchaseOrderForm";
import SelectOptions from "./SelectOptions";
import AttachDocumentsToInvoiceModal from "./AttachDocumentsToInvoiceModal";
import {IInvoiceEditingMeta} from "./CreateInvoiceFormFull";
import DocumentListItem from "./DocumentListItem";
import NumberFormat from "react-number-format";
import {INumberFormat} from "./InvoiceItem";
import ErrorList from "./ErrorList";
import CustomDatePicker from "./CustomDatePicker";
import {legacyIncludes} from "../utils/arrays";
import POSaveButtons from "./POSaveButtons";

export interface IInvoiceItem {
	name?: string;
	description?: string;
	quantity?: any;
	price?: any;
	amount?: any;
}

export interface IInvoiceFormFields {
	customer?: string;
	invoiceNumber?: string;
	POSO?: string;
	invoiceDate?: any;
	incoTerms?: string;
	paymentDue?: number;
	invoiceItems?: IInvoiceItem[];
	notes?: string;
	files?: Array<initialAdapter.IDocumentItemMsg>;
	receivedDeposit: string;
}

interface ICreateInvoiceFormProps {
	form: IInvoiceFormFields;

	onChange(key: keyof IInvoiceFormFields, value: any): void;

	onAttachDocuments(editingMeta: IInvoiceEditingMeta): Promise<any>;

	onCOGSPreview(): void;

	onInvoicePreview(): void;

	onSaveDraft(): void;

	buyerList: Array<initialAdapter.IBuyerListForInv>;

	onBuyerSelect(buyer: initialAdapter.IBuyerListForInv): void;

	incoterms: string[];
	paymentDueList: Array<initialAdapter.IPaymentTermItemMsg>;
	selectedBuyerIsEditable: boolean;
	autoCompleteVal: string;

	onAutoCompleteValueChange(val: string): void;

	fullBuyerEditingDetails?: any; // todo
	selectedBuyer?: initialAdapter.IBuyerListForInv;
	editingMeta: IInvoiceEditingMeta;

	onRemoveDocument(newEditingMeta: IInvoiceEditingMeta): Promise<any>;

	invoiceCreatedFromPO: boolean;
	serviceCompany?: initialAdapter.IServiceCompany;
	errors: string[];
	actions: string[];
}

const CreateInvoiceForm: React.FC<ICreateInvoiceFormProps> = (props: ICreateInvoiceFormProps) => {

	const {
		form,
		onChange,
		onAttachDocuments,
		onCOGSPreview,
		onInvoicePreview,
		onSaveDraft,
		buyerList,
		onBuyerSelect,
		incoterms,
		paymentDueList,
		selectedBuyerIsEditable,
		autoCompleteVal,
		onAutoCompleteValueChange,
		fullBuyerEditingDetails,
		selectedBuyer,
		editingMeta,
		invoiceCreatedFromPO,
		serviceCompany,
		errors,
		actions,
	} = props;

	const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);
	const [showAttachDocumentsModal, setShowAttachDocumentsModal] = useState(false);
	const [invoiceItemsKey, setInvoiceItemsKey] = useState(1);

	function toggleAddCustomerModal(): void {
		setShowAddCustomerModal(!showAddCustomerModal);
	}

	function toggleAttachDocumentsModal(): void {
		setShowAttachDocumentsModal(!showAttachDocumentsModal);
	}

	function createOnChange(key: keyof IInvoiceFormFields): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			onChange(key, e.target.value);
		}
	}

	function createDateChange(key: keyof IInvoiceFormFields): any {
		return (d: string) => {
			onChange(key, d);
		}
	}

	function addNewInvoiceItem(): void {
		const items: IInvoiceItem[] = form.invoiceItems;
		items.push({});
		onChange("invoiceItems", items);
	}

	function removeInvoiceItem(i: number): void {
		onChange("invoiceItems", form.invoiceItems.filter((item, index) => {
			return i !== index
		}));
		setInvoiceItemsKey(invoiceItemsKey + 1);
	}

	function onInvoiceItemChange(i: number, key: keyof IInvoiceItem, value: any): void {
		const items: IInvoiceItem[] = form.invoiceItems;
		// @ts-ignore
		items[i][key] = value;
		onChange("invoiceItems", items)
	}

	function onBuyerSelectHelper(a): void {
		onBuyerSelect(a ? a.value : {});
	}

	function finishAddingBuyer(key: string, name: string): void {
		onBuyerSelect({
			buyerName: name,
			buyerKeyURL: key,
			tradelink: false,
		});
	}

	const buyers = buyerList.map((buyer: initialAdapter.IBuyerListForInv) => {
		return {
			value: buyer,
			label: buyer.buyerName,
		};
	});

	function onNumberFormatChange(key: keyof IInvoiceFormFields): any {
		return (e: INumberFormat) => {
			onChange(key, e.floatValue);
		}
	}

	function mapDocuments(files: Array<initialAdapter.IDocumentItemMsg> = []): ReactNode {
		if (files.length < 1) {
			return (
				<span className="text-hPurple font-italic">No documents have been uploaded yet.</span>
			);
		}

		return files.map((file: initialAdapter.IDocumentItemMsg, i: number) => {
			return (
				<div key={`document-list-item-${i}`} className="my-2">
					<DocumentListItem
						file={file}
						editingMeta={editingMeta}
						showConfirmation={true}
						type="invoice"
						onFinishDelete={props.onRemoveDocument}
						deleteIconPositionsLeft={false}
					/>
				</div>
			);
		})
	}

	return (
		<React.Fragment>
			<AddCustomerModal
				isOpen={showAddCustomerModal}
				closeModal={toggleAddCustomerModal}
				handleFinishAdding={finishAddingBuyer}
				fullEditingDetails={fullBuyerEditingDetails}
				selectedBuyer={selectedBuyer}
			/>

			<AttachDocumentsToInvoiceModal
				title="Attach Documents to Invoice"
				isOpen={showAttachDocumentsModal}
				closeModal={toggleAttachDocumentsModal}
				onUpload={onAttachDocuments}
				editingMeta={editingMeta}
			/>

			<Card>
				<CardHeader>Create Invoice</CardHeader>
				<CardBody>
					<Card className="mt-3 mb-4">
						<CardTitle>
							<h5 className="text-hPurple">Customer</h5>
							<hr/>
						</CardTitle>
						<CardBody className="pt-0">
							{serviceCompany && (
								<div className="mb-3">
									<Label for="billingCustomer">Billing Customer</Label>
									<Input type="text" name="billingCustomer" id="billingCustomer"
									       placeholder="Billing Customer" value={serviceCompany.businessName}
									       disabled={true} readOnly={true} className="input-max-width-600"/>
								</div>
							)}

							<div className="mb-3">
								<Label for="customer">Customer Name</Label>
								<div className="add-edit-supplier-customer-button-container">
									{invoiceCreatedFromPO ? (
										<Input type="text" name="customerName" id="customerName"
										       placeholder="Customer Name" value={autoCompleteVal}
										       disabled={true} readOnly={true}
										       className="input-max-width-300"
										/>
									) : (
										<div className="w-100 input-max-width-600">
											<Select
												options={buyers}
												styles={reactSelectCustomStyles}
												isClearable={true}
												onChange={onBuyerSelectHelper}
												onInputChange={onAutoCompleteValueChange}
												inputValue={autoCompleteVal}
											/>
										</div>
									)}

									<div>
										{!invoiceCreatedFromPO && (
											<React.Fragment>
												{form.customer ? (
													<div className="ae-sc-btn-inner">
														{selectedBuyerIsEditable && (
															<Button color="hBlue" size="sm" className="text-nowrap" onClick={toggleAddCustomerModal}>
																Edit Customer
															</Button>
														)}
													</div>
												) : (
													<div className="ae-sc-btn-inner">
														{actions && legacyIncludes(actions, "addcustomer") && (
															<Button color="hBlue" size="sm" className="text-nowrap" onClick={toggleAddCustomerModal}>
																Add a customer
															</Button>
														)}
													</div>
												)}
											</React.Fragment>
										)}
									</div>
								</div>
							</div>
						</CardBody>
					</Card>


					<Card className="mb-4">
						<CardTitle>
							<h5 className="text-hPurple">Invoice Info</h5>
							<hr/>
						</CardTitle>
						<CardBody className="pt-0">
							<div className="mb-3">
								<Label for="invoiceNumber">Invoice #</Label>
								<Input type="text" name="invoiceNumber" id="invoiceNumber"
								       placeholder="Invoice #..." value={form.invoiceNumber}
								       onChange={createOnChange("invoiceNumber")} className="input-max-width-300 number-display"/>
							</div>

							<div className="mb-3">
								<Label for="POSO">PO/SO #</Label>
								<Input type="text" name="POSO" id="POSO"
								       placeholder="PO/SO #..." value={form.POSO}
								       onChange={createOnChange("POSO")}
								       disabled={invoiceCreatedFromPO} readOnly={invoiceCreatedFromPO}
								       className="input-max-width-300 number-display"
								/>
							</div>

							<div className="mb-3">
								<Label for="invoiceDate">Invoice Date</Label>
								<div className="input-max-width-300">
									<CustomDatePicker
										inputProps={{
											type: "date",
											name: "invoiceDate",
											id: "invoiceDate",
											placeholder: "YYYY-MM-DD",
											value: form.invoiceDate,
											onChange: createOnChange("invoiceDate")
										}}
										date={form.invoiceDate}
										onDateChange={createDateChange("invoiceDate")}
									/>
								</div>
							</div>

							<div className="mb-3">
								<Label for="incoTerms">INCOTERMS</Label>
								<Input type="select" name="incoTerms" id="incoTerms"
								       placeholder="INCOTERMS" value={form.incoTerms}
								       onChange={createOnChange("incoTerms")}
								       disabled={invoiceCreatedFromPO} readOnly={invoiceCreatedFromPO}
								       className={"input-max-width-300" + (form.incoTerms === "" ? " un-selected-drop-down" : "")}
								>
									<option value="" selected className="first-select-option">Select Terms...</option>
									<hr/>
									<SelectOptions strings={incoterms}/>
								</Input>
							</div>

							<div className="mb-3">
								<Label for="paymentDue">Payment Due</Label>
								<Input type="select" name="paymentDue" id="paymentDue"
								       placeholder="Payment Due" value={form.paymentDue}
								       onChange={createOnChange("paymentDue")}
								       disabled={invoiceCreatedFromPO} readOnly={invoiceCreatedFromPO}
								       className={"input-max-width-300" + (form.paymentDue < 0 ? " un-selected-drop-down" : " number-display")}
								>
									<option value={-1} selected className="first-select-option">Select Terms...</option>
									<hr/>
									<SelectOptions fullOps={paymentDueList.map((item, i: number) => {
										return {
											value: i,
											text: item.termLabel,
										}
									})}/>
								</Input>
							</div>

							<div className="mb-3">
								<Label for="receivedDeposit">Received Deposit</Label>
								{invoiceCreatedFromPO ? (
									<Input type="text" name="receivedDeposit" id="receivedDeposit"
									       placeholder="Received Deposit..." value={form.receivedDeposit}
									       disabled={invoiceCreatedFromPO} readOnly={invoiceCreatedFromPO}
									       className="input-max-width-300"
									/>
								) : (
									<div className="input-max-width-300">
										<NumberFormat
											prefix="$"
											allowLeadingZeros={true}
											placeholder="Received Deposit"
											value={form.receivedDeposit}
											customInput={Input}
											thousandSeparator={true}
											decimalScale={2}
											onValueChange={onNumberFormatChange("receivedDeposit")}
										/>
									</div>
								)}
							</div>
						</CardBody>
					</Card>

					<Card className="mb-4">
						<CardTitle>
							<div className="d-flex align-items-center">
								<h5 className="text-hPurple d-inline mr-3 mb-0">Invoice Items</h5>
								<Button color="hBlue" size="sm" className="px-3 po-invoice-sub-header-button-a"
								        onClick={addNewInvoiceItem}>Add New Item</Button>
							</div>
							<hr/>
						</CardTitle>
						<CardBody className="pt-0 pb-0">
							<InvoiceItemsList items={form.invoiceItems}
							                  onDelete={removeInvoiceItem} onChange={onInvoiceItemChange}
							                  key={invoiceItemsKey}/>
							<div className="d-flex justify-content-center">
								<Button color="hBlue" size="sm" className="px-5 po-invoice-sub-header-button-b mb-4"
								        onClick={addNewInvoiceItem}>Add New Item</Button>
							</div>
						</CardBody>
					</Card>

					<Card className="mb-4">
						<CardTitle>
							<h5 className="text-hPurple">Notes</h5>
							<hr/>
						</CardTitle>
						<CardBody className="pt-0">
							<Input type="textarea" name="notes" id="notes"
							       placeholder="Notes..." value={form.notes}
							       onChange={createOnChange("notes")}
							/>
						</CardBody>
					</Card>

					{actions && legacyIncludes(actions, "attachdoc") && (
						<Card className="mb-4">
							<CardTitle>
								<div className="d-flex align-items-center">
									<h5 className="text-hPurple d-inline mr-3 mb-0">Attach Documents</h5>
									<Button color="hBlue" size="sm" className="px-3 po-invoice-sub-header-button-a"
									        onClick={toggleAttachDocumentsModal}>Upload a File</Button>
								</div>
								<hr/>
							</CardTitle>
							<CardBody className="pt-0">
								{mapDocuments(form.files)}

								<div className="d-flex justify-content-center">
									<Button color="hBlue" size="sm" className="mt-4 px-5 po-invoice-sub-header-button-b"
									        onClick={toggleAttachDocumentsModal}>Upload a File</Button>
								</div>
							</CardBody>
						</Card>
					)}

					{errors.length > 0 && (
						<div className="my-4 px-3">
							<ErrorList errors={errors}/>
						</div>
					)}

					<div className="mt-4 po-save-button-display-manager">
						<div className="po-buttons-interior">
							<POSaveButtons
								showSaveDraftButton={actions && legacyIncludes(actions, "savedraft")}
								showPreviewButton={actions && legacyIncludes(actions, "submit")}
								onSaveDraft={onSaveDraft}
								onPOPreview={onInvoicePreview}
							/>
						</div>
					</div>
				</CardBody>
			</Card>
		</React.Fragment>
	);
};

export default CreateInvoiceForm;
