import React from "react";

export function alignCellContentRight(additionalFormat?: (s: string) => string): (c) => JSX.Element {
	return (c: string) => {

		const c2: string = additionalFormat ? additionalFormat(c) : c;

		return (
			<p className="w-100 text-right mb-0">
				{c2}
			</p>
		)
	}
}
