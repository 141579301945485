import {Container} from "reactstrap";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {decrementLoading, handleError, incrementLoading} from "../redux/meta/MetaActions";
import {initialAdapter} from "adapter";
import BusinessLinkTable from "../components/BusinessLinkTable";
import TableFilterHelper, {defaultMainSubFilter, ITableFilterMainAndSub} from "../components/TableFilterHelper";
import ErrorList from "../components/ErrorList";
import TabSelector from "../components/TabSelector";

interface IProps {
	tokenV2?: any;
	dispatch?: any;
}

const defaultBusinessLinks: any = {};

const BusinessLinkPage: React.FC<IProps> = (props: IProps) => {

	const [businessLinks, setBusinessLinks] = useState<any>(defaultBusinessLinks);
	const [errors, setErrors] = useState([]);
	const [mainSubFilter, setMainSubFilter] = useState<ITableFilterMainAndSub>(defaultMainSubFilter);
	const [pseudoSelection, setPseudoSelection] = useState(null);

	useEffect(() => {
		setPseudoSelection(null);
		readBusinessLink().then().catch();
	}, [JSON.stringify(mainSubFilter)]);

	async function readBusinessLink(): Promise<void> {
		setErrors([]);
		props.dispatch(incrementLoading());

		const res = await initialAdapter.api.readBusinessLinkSummary({urlsafeNextCursor: "", ...mainSubFilter}, props.tokenV2);

		if (!res.success) {
			setErrors(handleError(res));
			setBusinessLinks([]);
			return;
		}

		props.dispatch(decrementLoading());

		if (!pseudoSelection && (res.data.mainFilters && res.data.mainFilters.length > 0)) {
			setPseudoSelection(res.data.mainFilters[0]);
		}

		setBusinessLinks(res.data);

	}

	return (
		<Container className="my-4 px-1 px-lg-4 htc-container">

			{errors.length > 0 && (
				<div className="my-3">
					<ErrorList errors={errors}/>
				</div>
			)}

			<TabSelector
				tabs={[{title: "BizLink", value: "BizLink",}]}
				value={"BizLink"}
				onClick={() => {}}
			/>

			{businessLinks && ((businessLinks.mainFilters && businessLinks.mainFilters.length > 0) || (businessLinks.subFilters && businessLinks.subFilters.length > 0)) && (
				<TableFilterHelper
					filterInformation={{
						mainFilters: businessLinks.mainFilters,
						subFilters: businessLinks.subFilters,
					}}
					activeFilters={mainSubFilter}
					pseudoSelection={pseudoSelection}
					onFilterChange={setMainSubFilter}
				/>
			)}

			<BusinessLinkTable data={businessLinks.items} onDone={readBusinessLink}/>

			{businessLinks.urlsafeNextCursor && (
				<p className="text-hPurple font-italic mt-3">
					{businessLinks.urlsafeNextCursor}
				</p>
			)}
		</Container>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
	}
})(BusinessLinkPage);
