import React, {useEffect, useState} from "react";
import {Modal, ModalHeader} from "reactstrap";
import SelectCustomerPOTableWithController from "./SelectCustomerPOTableWithController";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {decrementLoading, handleError, incrementLoading, setError} from "../redux/meta/MetaActions";
import {initialAdapter} from "adapter";
import {generic400, generic500} from "../utils/errors";
import ErrorList from "./ErrorList";

interface ICreateInvoiceFromPOModalProps {
	tokenV2?: any;
	dispatch?: any;
	isOpen: boolean;

	closeModal(): void;

	onSelect(po: any): void;
}

const CreateInvoiceFromPOModal: React.FC<ICreateInvoiceFromPOModalProps> = (props: ICreateInvoiceFromPOModalProps) => {

	const {tokenV2, dispatch, isOpen, closeModal, onSelect} = props;
	const [dataRes, setDataRes] = useState<initialAdapter.IBuyerPOListResMsg>();
	const [errors, setErrors] = useState([]);

	useEffect(() => {
		if (isOpen) {
			getCustomerPOS(initialAdapter.DocumentDayRange.LAST_30).then().catch();
		}
	}, [isOpen]);

	async function getCustomerPOS(newFilter: initialAdapter.DocumentDayRange = initialAdapter.DocumentDayRange.LAST_30): Promise<void> {
		setErrors([]);
		props.dispatch(incrementLoading());

		const req: initialAdapter.IBuyerPOListReqMsg = {
			urlsafeNextCursor: "",
			dayRange: newFilter,
		};

		const res = await initialAdapter.api.readBuyerPOList(req, tokenV2);
		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		setDataRes(res.data);
	}

	return (
		<Modal
			isOpen={isOpen}
			fade={true}
			centered={true}
			contentClassName="p-2"
			toggle={closeModal}
		>
			<div className="px-3">
				<ModalHeader toggle={closeModal} className="mb-3"><h4>Select a customer's PO</h4></ModalHeader>

				{errors.length > 0 && (
					<div className="my-3">
						<ErrorList errors={errors}/>
					</div>
				)}

				<SelectCustomerPOTableWithController
					items={dataRes ? dataRes.items : []}
					onFilterChange={getCustomerPOS}
					onSelect={onSelect}
				/>

			</div>
		</Modal>
	);
};

export default connect((store: IStore, props: ICreateInvoiceFromPOModalProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
		...props,
	}
})(CreateInvoiceFromPOModal);
