import React, {useState} from "react";
import {initialAdapter} from "adapter";
import {IPOEditingMeta} from "./CreatePurchaseOrderFormFull";
import {Button, Modal, ModalHeader} from "reactstrap";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {decrementLoading, handleError, incrementLoading, setError} from "../redux/meta/MetaActions";
import DocumentListItemWithIcon from "./DocumentListItemWithIcon";
import {IInvoiceEditingMeta} from "./CreateInvoiceFormFull";
import {IPendingInvoiceEditMeta} from "./AttachDocumentsToPendingInvoiceBuyerApprovalProcessModal";
import {generic400, generic500} from "../utils/errors";
import ErrorList from "./ErrorList";

interface IDocumentListItemProps {
	tokenV2?: any;
	dispatch?: any;
	file: initialAdapter.IDocumentItemMsg;
	editingMeta: IPOEditingMeta | IInvoiceEditingMeta | IPendingInvoiceEditMeta;
	showConfirmation: boolean;
	type: "po" | "invoice" | "pendingHTCInv";
	deleteIconPositionsLeft?: boolean;
	onFinishDelete(newEditingMeta?: any): Promise<void>;
}

const DocumentListItem: React.FC<IDocumentListItemProps> = (props: IDocumentListItemProps) => {

	const {tokenV2, dispatch, file, editingMeta, showConfirmation, type, deleteIconPositionsLeft} = props;
	const [isOpen, setIsOpen] = useState(false);
	const [errors, setErrors] = useState([]);

	function checkDelete(e: React.MouseEvent<HTMLAnchorElement>): void {
		e.preventDefault();
		if (showConfirmation) {
			setIsOpen(true);
		} else {
			attemptDelete().then().catch();
		}
	}

	async function attemptDelete(): Promise<void> {
		setErrors([]);
		props.dispatch(incrementLoading());
		let res;

		if (type === "po") {
			res = await initialAdapter.api.removeDocFromPO(
				{
					poKeyURL: (editingMeta as IPOEditingMeta).poKeyURL,
					timestamp: editingMeta.timestamp,
					documentID: file.documentID,
				},
				tokenV2,
			);
		} else if (type === "invoice") {
			res = await initialAdapter.api.removeDocFromInv(
				{
					invKeyURL: (editingMeta as IInvoiceEditingMeta).invoiceKeyURL,
					timestamp: editingMeta.timestamp,
					documentID: file.documentID,
				},
				tokenV2,
			);
		} else if (type === "pendingHTCInv") {
			res = await initialAdapter.api.removeDocFromHTCInv(
				{
					keyURL: (editingMeta as IPendingInvoiceEditMeta).keyURL,
					timestamp: editingMeta.timestamp,
					documentID: file.documentID,
				},
				tokenV2,
			);
		}
		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		const newEditingMeta: Partial<IPOEditingMeta | IInvoiceEditingMeta> = {timestamp: res.data.data[0]};
		if (type === "po") {
			(newEditingMeta as IPOEditingMeta).poKeyURL = res.data.data[1];
		} else if (type === "invoice") {
			(newEditingMeta as IInvoiceEditingMeta).invoiceKeyURL = res.data.data[1];
		} else if (type === "pendingHTCInv") {
			(newEditingMeta as IPendingInvoiceEditMeta).keyURL = res.data.data[1];
		}

		await props.onFinishDelete(newEditingMeta);
		cancelConfirmation();
	}

	function cancelConfirmation(): void {
		setIsOpen(false);
	}

	return (
		<React.Fragment>
			<Modal
				isOpen={isOpen}
				fade={true}
				centered={true}
				contentClassName="p-2"
				className="modal-max-500"
			>
				<div className="px-3">
					<ModalHeader
						toggle={cancelConfirmation}
						className="mb-3"
					>
						{`Remove file '${file.documentName}'?`}
					</ModalHeader>

					{errors.length > 0 && (
						<div className="my-3">
							<ErrorList errors={errors}/>
						</div>
					)}

					<div className="d-flex justify-content-between mt-3">
						<Button color="link" onClick={cancelConfirmation}>
							Cancel
						</Button>

						<Button color="link" onClick={attemptDelete}>
							Confirm
						</Button>
					</div>
				</div>
			</Modal>

			<DocumentListItemWithIcon
				url={file.documentURL}
				name={file.documentName}
				left={deleteIconPositionsLeft}
				onDelete={checkDelete}
			/>
		</React.Fragment>
	);
};

DocumentListItem.defaultProps = {
	deleteIconPositionsLeft: true,
};

export default connect((store: IStore, props: IDocumentListItemProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
		...props,
	}
})(DocumentListItem);
