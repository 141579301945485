import React from "react";
import {FiX} from "react-icons/all";

interface IProps {
	onClick(): void;
}

const HTCModalHeaderCloseButton: React.FC<IProps> = (props: IProps) => {

	return (
		<div className="w-100 position-relative" style={{zIndex: 100}} onClick={props.onClick}>
			<div className="htc-close-modal-icon-bg"/>
			<div className="htc-close-modal-icon-fg">
				<FiX className="text-white" size="1.25rem"/>
			</div>
		</div>
	);
};

export default HTCModalHeaderCloseButton
