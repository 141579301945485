import React, {useEffect, useState} from "react";
import {initialAdapter} from "adapter";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {decrementLoading, handleError, incrementLoading, logout, setError} from "../redux/meta/MetaActions";
import {generic400, generic500} from "../utils/errors";
import ErrorList from "./ErrorList";
import ClientTableFilter, {ClientTableFilters} from "./ClientsTableFilter";
import ClientsTable from "./ClientsTable";
import moment from "moment";
import {csvExport} from "../utils/csvExport";

interface IClientsTableWithControllerProps {
	onBuyerFunding(buyer: any): void;
	tokenV2?: initialAdapter.IAuthInfo;
	dispatch?: any;
}

const ClientsTableWithController: React.FC<IClientsTableWithControllerProps> = (props: IClientsTableWithControllerProps) => {

	const {onBuyerFunding, tokenV2, dispatch} = props;
	const [tableFilter, setTableFilter] = useState<ClientTableFilters>(ClientTableFilters.BUYER);
	const [clientData, setClientData] = useState<any[]>([]);
	const [searchVal, setSearchVal] = useState("");
	const [errors, setErrors] = useState([]);

	function handleTableFilterChange(newFilter: ClientTableFilters): void {
		setTableFilter(newFilter);
	}

	useEffect(() => {
		setSearchVal("");
		getClientData().then().catch();
	}, [tableFilter]);

	async function getClientData(search: string = ""): Promise<void> {
		props.onBuyerFunding(null);
		setErrors([]);
		props.dispatch(incrementLoading());

		const res = await initialAdapter.api.readClientAccountSummary(
			{
				urlsafeNextCursor: "",
				// requestType: tableFilter === ClientTableFilters.BUYER ? "buyer" : "seller",
				requestType: "buyer",
				searchKeyword: search,
			},
			tokenV2
		);

		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		let data = [];
		if (res.success && res.data && res.data.items && res.data.items.length > 0) {
			data = res.data.items.map((item: initialAdapter.IClientItemMessage) => {
				return {
					...item,
					type: tableFilter,
				};
			});
		}

		for (const item of data) {
			const actions: Array<[string, (row: any) => void]> = [];
			if (item.type === ClientTableFilters.BUYER) {
				actions.push(
					["View", funding],
					["Export CSV", exportToCsv],
				);
			}
			item.actions = actions;
		}

		setClientData(data);
	}

	async function readClientInvoiceHistory(client: { clientKeyURL: string }, filter: "open" | "paid" | "all") {
		props.dispatch(incrementLoading());

		const res = await initialAdapter.api.readHTCPOSummaryList({
			clientKeyURL: client.clientKeyURL,
			filterType: filter,
			urlsafeNextCursor: "",
		}, tokenV2);

		if (!res.success) {
			props.dispatch(decrementLoading());
			setErrors(handleError(res));
			return;
		}

		props.dispatch(decrementLoading());

		return res.data;

	}

	function handleApplySearch(): void {
		getClientData(searchVal).then().catch();
	}

	function funding(buyer: any): void {
		onBuyerFunding(buyer);
	}

	async function exportToCsv(buyer: any): Promise<void> {
		const headers = [
			"Invoice Number",
			"Status",
			"Invoice Date",
			"Term",
			"Invoice Amount",
			"Funded Amount",
			"Funded Date",
			"Amount Paid",
			"Due Date",
			"Date Paid",
			"Outstanding",
		];
		const filename: string = `invoices__${buyer.businessName.replace(/\s+/g, '-').toLowerCase()}__${moment().format("YYYY-MM-DD")}.csv`;

		const data = await readClientInvoiceHistory(buyer, "all");
		// console.log("export open", data);
		const formattedContent = data.items.map((item) => {
			// Returned price data sometimes has more than 2 decimals.
			// This causes problems with rounding, and problems with not rounding.
			// Also: https://stackoverflow.com/questions/10473994/javascript-adding-decimal-numbers-issue
			const invoiceCents: number = Math.round(parseFloat(item.invoiceAmount) * 100);
			const paidCents: number = Math.round(parseFloat(item.paidAmount) * 100);
			const fundedCents: number = Math.round(parseFloat(item.fundedAmount) * 100);
			const outstandingCents: number = invoiceCents - paidCents;
			// console.log(invoiceAmount, paidAmount, fundedAmount, outstandingAmount);
			return [
				item.invoiceNum,
				item.status,
				item.invoiceDate,
				item.term,
				invoiceCents / 100,
				fundedCents / 100,
				item.fundedDate,
				paidCents / 100,
				item.dueDate,
				item.paidDate,
				outstandingCents / 100,
			];
		});

		csvExport(formattedContent, headers, filename);
	}

	return (
		<div>
			{/*<ClientTableFilter*/}
			{/*	filter={tableFilter}*/}
			{/*	onFilterChange={handleTableFilterChange}*/}
			{/*	onSearchChange={setSearchVal}*/}
			{/*	search={searchVal}*/}
			{/*	handleSearch={handleApplySearch}*/}
			{/*/>*/}

			<div>
				<ClientsTable data={clientData} filter={tableFilter}/>
			</div>

			{errors.length > 0 && (
				<div className="my-3">
					<ErrorList errors={errors}/>
				</div>
			)}
		</div>
	)
};

export default connect((store: IStore, props: IClientsTableWithControllerProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
	}
})(ClientsTableWithController);
