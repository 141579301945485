import React, {Dispatch, SetStateAction, useState} from "react";
import BootstrapTable, {Column} from "react-bootstrap-table-next";
import styleHeaders from "../style/styleHeaders";
import {formatMoney, sortMoney} from "../utils/formatters";
import {Button} from "reactstrap";
import {initialAdapter} from "adapter";
import {alignCellContentRight} from "./AlignCellContentRight";
import {caretFormatter, headerTableFormatter, rowClassesHelper} from "../utils/headerTableFormatter";
import TableHamburgerIconButton, {ITableMenuButtonListItem} from "./TableHamburgerIconButton";
import ViewPOPDFModal2 from "./ViewPOPDFModal2";
import ViewInvoicePDFModal2 from "./ViewInvoicePDFModal2";
import ConfirmDeleteDraftPOModal from "./ConfirmDeleteDraftPOModal";
import ConfirmVoidPOModal from "./ConfirmVoidPOModal";
import ConfirmCompletedPOModal from "./ConfirmCompletedDraftPOModal";
import {useHistory} from "react-router";

interface IPurchasesTableProps {
	data: any[];
	filter: initialAdapter.PurchaseSummaryListFilter;
	onDoneAction(): Promise<void>;
}

const PurchasesTable: React.FC<IPurchasesTableProps> = (props: IPurchasesTableProps) => {

	const {data, filter} = props;

	function makeManageButton(cell: string[], row: any): JSX.Element {
		return (<ManagePOTableButton actions={cell} document={row} filter={filter} onDoneAction={props.onDoneAction}/>)
	}

	let longestName: number = 0;
	if (data && data.length > 0) {
		for (const d of data) {
			let l: number = d.supplierName.length;
			if (l > longestName) {
				longestName = l;
			}
		}
	}
	longestName = Math.round((longestName * 1.1) / 2);
	const customSupplierWidth: string = longestName + "ch";

	function getColumns(): any {
		const baseColumns: Array<Column> = styleHeaders(getTableColumns(filter));

		switch (filter) {
			case initialAdapter.PurchaseSummaryListFilter.PAST_DUE:
			case initialAdapter.PurchaseSummaryListFilter.CURRENT:
			case initialAdapter.PurchaseSummaryListFilter.PENDING:
			case initialAdapter.PurchaseSummaryListFilter.DRAFT:
			case initialAdapter.PurchaseSummaryListFilter.INVOICES:
			case initialAdapter.PurchaseSummaryListFilter.PURCHASE_ORDERS:
				baseColumns.push({
					dataField: "actions",
					text: "Actions",
					formatter: makeManageButton,
					headerFormatter: headerTableFormatter,
					sortCaret: caretFormatter,
					manualHeaderClasses: "justify-content-center",
				});
				break;
			case initialAdapter.PurchaseSummaryListFilter.PAID_VOIDED:
			case initialAdapter.PurchaseSummaryListFilter.TRANSACTION_HISTORY:
			default:
				break;
		}

		for (const c of baseColumns) {
			if (c.dataField === "supplierName") {
				c.formatter = addHTCIcon(customSupplierWidth);
			}
		}

		return baseColumns;
	}

	return (
		<div className="harbor-table-container box-shadow-helper">
			<BootstrapTable
				bootstrap4={true}
				striped={false}
				hover={true}
				condensed={true}
				bordered={false}
				wrapperClasses="table-responsive border"
				rowClasses={rowClassesHelper}
				rowStyle={{whiteSpace: "nowrap"}}
				columns={getColumns()}
				keyField={"keyField"}
				data={data !== undefined ? filterData(data, filter).map((d, i) => ({...d, keyField: i})) : []}
				noDataIndication={getEmptyMessage(filter, data)}
				defaultSorted={[{dataField: getDefaultSort(filter), order: "asc"}]}
			/>
		</div>
	);
};

/**
 * filter and only return the data that matches the filter
 *
 * @param data
 * @param filter
 */
function filterData(data: any[], filter: initialAdapter.PurchaseSummaryListFilter): any[] {

	switch (filter) {
		// case initialAdapter.PurchaseSummaryListFilter.PAST_DUE:
		// 	return data.filter((row) => {
		// 		return row.status === "overdue";
		// 	});
		// case initialAdapter.PurchaseSummaryListFilter.COMING_DUE:
		// 	return data.filter((row) => {
		// 		return row.status === "comingDue";
		// 	});
		// case initialAdapter.PurchaseSummaryListFilter.CURRENT:
		// 	return data.filter((row) => {
		// 		return row.status === "pending";
		// 	});
		// case initialAdapter.PurchaseSummaryListFilter.DRAFT:
		// 	return data.filter((row) => {
		// 		return row.status === "draft";
		// 	});
		// case initialAdapter.PurchaseSummaryListFilter.PAID_VOIDED:
		// 	return data.filter((row) => {
		// 		return row.status === "paidVoided";
		// 	});
		default:
			return data;
	}
}

/**
 * declaration of all the possible columns for this table, based on the filter, for use in "getTableColumns"
 */
const daysPastDueCol: Column = {
	dataField: "daysPastDue",
	text: "Days Past Due",
	sort: true,
	headerFormatter: headerTableFormatter,
	sortCaret: caretFormatter,
};

const dueInDaysPastDueCol: Column = {
	dataField: "dueInDays",
	text: "Due Date",
	sort: true,
	headerFormatter: headerTableFormatter,
	sortCaret: caretFormatter,
	manualHeaderClasses: "justify-content-center",
	classes: "text-center",
};

const orderNumberCol: Column = {
	dataField: "poNumber",
	text: "Order #",
	sort: true,
	headerFormatter: headerTableFormatter,
	sortCaret: caretFormatter,
};

const orderTotalCol: Column = {
	dataField: "orderTotal",
	text: "Order Total",
	sort: true,
	formatter: alignCellContentRight(formatMoney),
	sortFunc: sortMoney,
	headerFormatter: headerTableFormatter,
	sortCaret: caretFormatter,
	classes: "number-display",
	manualHeaderClasses: "justify-content-end",
};

const invoiceTotalCol: Column = {
	dataField: "invoiceTotal",
	text: "Invoice Total",
	sort: true,
	formatter: alignCellContentRight(formatMoney),
	sortFunc: sortMoney,
	headerAlign: "right",
	headerFormatter: headerTableFormatter,
	sortCaret: caretFormatter,
	classes: "number-display",
	manualHeaderClasses: "justify-content-end",
};

const supplierCol: Column = {
	dataField: "supplierName",
	text: "Supplier",
	sort: true,
	formatter: addHTCIcon("0ch"),
	headerFormatter: headerTableFormatter,
	sortCaret: caretFormatter,
};

const invoiceNumberCol: Column = {
	dataField: "invoiceNumber",
	text: "Invoice #",
	sort: true,
	headerFormatter: headerTableFormatter,
	sortCaret: caretFormatter,
};

const dueAmountCol: Column = {
	dataField: "dueAmount",
	text: "Due Amount",
	sort: true,
	formatter: alignCellContentRight(formatMoney),
	sortFunc: sortMoney,
	headerAlign: "right",
	headerFormatter: headerTableFormatter,
	sortCaret: caretFormatter,
	classes: "number-display",
	manualHeaderClasses: "justify-content-end",
};

const orderDateCol: Column = {
	dataField: "poDate",
	text: "Order Date",
	sort: true,
	headerFormatter: headerTableFormatter,
	sortCaret: caretFormatter,
	manualHeaderClasses: "justify-content-center",
	classes: "text-center",
};

const invoiceDateCol: Column = {
	dataField: "invoiceDate",
	text: "Invoice Date",
	sort: true,
	headerFormatter: headerTableFormatter,
	sortCaret: caretFormatter,
	manualHeaderClasses: "justify-content-center",
	classes: "text-center",
};

const shipDateCol: Column = {
	dataField: "shipByDate",
	text: "Ship Date",
	sort: true,
	headerFormatter: headerTableFormatter,
	sortCaret: caretFormatter,
	manualHeaderClasses: "justify-content-center",
	classes: "text-center",
};

const paymentDueCol: Column = {
	dataField: "paymentTerm",
	text: "Payment Due",
	sort: true,
	headerFormatter: headerTableFormatter,
	sortCaret: caretFormatter,
	manualHeaderClasses: "justify-content-center",
	classes: "text-center",
};

const statusCol: Column = {
	dataField: "status",
	text: "Status",
	sort: true,
	headerFormatter: headerTableFormatter,
	sortCaret: caretFormatter,
	manualHeaderClasses: "justify-content-center",
	classes: "text-center",
};

const lastUpdatedDateCol: Column = {
	dataField: "lastUpdated",
	text: "Last Updated Date",
	sort: true,
	headerFormatter: headerTableFormatter,
	sortCaret: caretFormatter,
};

const invoiceAmountCol: Column = {
	dataField: "invoiceAmount",
	text: "Invoice Amount",
	sort: true,
	sortFunc: sortMoney,
	headerAlign: "right",
	headerFormatter: headerTableFormatter,
	sortCaret: caretFormatter,
};

/**
 * return appropriate array of columns for the table based on the filter being applied
 *
 * @param filter
 */
function getTableColumns(filter: initialAdapter.PurchaseSummaryListFilter): Column[] {

	switch (filter) {
		case initialAdapter.PurchaseSummaryListFilter.PAST_DUE:
			return [daysPastDueCol, orderNumberCol, orderTotalCol, supplierCol, invoiceNumberCol, dueAmountCol];
		case initialAdapter.PurchaseSummaryListFilter.CURRENT:
			return [orderDateCol, orderNumberCol, orderTotalCol, supplierCol, invoiceNumberCol, dueAmountCol];
		case initialAdapter.PurchaseSummaryListFilter.PENDING:
			return [orderDateCol, orderNumberCol, orderTotalCol, shipDateCol, supplierCol, paymentDueCol];
		case initialAdapter.PurchaseSummaryListFilter.DRAFT:
			return [orderDateCol, orderNumberCol, orderTotalCol, shipDateCol, supplierCol, paymentDueCol];
		case initialAdapter.PurchaseSummaryListFilter.PAID_VOIDED:
			return [statusCol, lastUpdatedDateCol, orderDateCol, orderNumberCol, supplierCol, invoiceNumberCol, invoiceAmountCol];

		case initialAdapter.PurchaseSummaryListFilter.INVOICES:
			return [invoiceDateCol, dueInDaysPastDueCol, orderNumberCol, statusCol, supplierCol, invoiceNumberCol, dueAmountCol];
		case initialAdapter.PurchaseSummaryListFilter.PURCHASE_ORDERS:
			// return [orderDateCol, orderNumberCol, statusCol, orderTotalCol, shipDateCol, supplierCol, paymentDueCol];
			return [orderDateCol, orderNumberCol, statusCol, orderTotalCol, shipDateCol, supplierCol];
		case initialAdapter.PurchaseSummaryListFilter.TRANSACTION_HISTORY:
			return [statusCol];
		default:
			return [statusCol];
	}
}

/**
 * determine which column to sort by initially, based on the applied filter
 *
 * @param filter
 */
function getDefaultSort(filter: initialAdapter.PurchaseSummaryListFilter): string {
	switch (filter) {
		case initialAdapter.PurchaseSummaryListFilter.PAST_DUE:
			return "daysPastDue";
		case initialAdapter.PurchaseSummaryListFilter.CURRENT:
			return "orderDate";
		case initialAdapter.PurchaseSummaryListFilter.PENDING:
			return "orderDate";
		case initialAdapter.PurchaseSummaryListFilter.DRAFT:
			return "orderDate";
		case initialAdapter.PurchaseSummaryListFilter.PAID_VOIDED:
			return "status";
		default:
			return "";
	}
}

/**
 * message shown when no data present in table, dependant on filter
 *
 * @param filter
 * @param data
 */
function getEmptyMessage(filter: initialAdapter.PurchaseSummaryListFilter, data: any[]): string {

	if (data === undefined) {
		return "Loading...";
	}

	switch (filter) {
		case initialAdapter.PurchaseSummaryListFilter.PAST_DUE:
			return "You currently have no overdue purchase orders";
		case initialAdapter.PurchaseSummaryListFilter.CURRENT:
			return "You currently have no coming due purchase orders";
		case initialAdapter.PurchaseSummaryListFilter.PENDING:
			return "You currently have no pending purchase orders";
		case initialAdapter.PurchaseSummaryListFilter.DRAFT:
			return "You currently have no draft purchase orders";
		case initialAdapter.PurchaseSummaryListFilter.PAID_VOIDED:
			return "You currently have no paid/voided purchase orders";
		default:
			return "You currently have no invoices";
	}
}

/**
 * if htc po, add the icon
 *
 * @param chMinWidth
 */
function addHTCIcon(chMinWidth: string): (cell: string, document: any) => JSX.Element {
	return (cell: string, document: any) => {
		return (
            <div className="d-flex align-items-center">
                <p className="mb-0" style={{width: chMinWidth, whiteSpace: "initial"}}>
					{cell}
                </p>

	            <div style={{width: 20}}>
					{document.htcPO && (
						<img
							src={process.env.PUBLIC_URL + "/logos/harbor-o.png"}
							style={{height: 20, width: 20}}
							className="ml-2"
						/>
					)}
	            </div>
            </div>
		);
	}
}

interface IManagePOTableButtonProps {
	actions: string[];
	document: any;
	filter: initialAdapter.PurchaseSummaryListFilter;
	onDoneAction(): Promise<void>;
}

const ManagePOTableButton: React.FC<IManagePOTableButtonProps> = (props: IManagePOTableButtonProps) => {

	const history = useHistory();
	const {actions, document, filter} = props;
	const [showViewPO, setShowViewPO] = useState(false); // purchase order
	const [showViewInvoice, setShowViewInvoice] = useState(false); // invoice
	const [showDeleteDraft, setShowDeleteDraft] = useState(false); // purchase order
	const [showVoidPO, setShowVoidPO] = useState(false); // purchase order
	const [showPOToComplete, setShowPOToComplete] = useState(false); // purchase order

	function openModalHelper(toggle: Dispatch<SetStateAction<boolean>>): () => void {
		return () => {
			toggle(true);
		}
	}

	function closeModalHelper(toggle: Dispatch<SetStateAction<boolean>>): () => void {
		return () => {
			toggle(false);
		}
	}

	async function doneDelete(): Promise<void> {
		setShowDeleteDraft(false);
		await props.onDoneAction()
	}

	async function doneVoid(): Promise<void> {
		setShowVoidPO(false);
		await props.onDoneAction();
	}

	async function doneComplete(): Promise<void> {
		setShowPOToComplete(false);
		await props.onDoneAction();
	}

	function editDraftPurchaseOrder(): void {
		history.push({pathname: "/po/edit", search: `?po=${document.poKeyURL}`});
	}

	function pair(action: string): ITableMenuButtonListItem {
		if (filter === initialAdapter.PurchaseSummaryListFilter.INVOICES) {
			switch (action) {
				case "view":
					return {label: "View", callback: openModalHelper(setShowViewInvoice)};
			}
		} else if (filter === initialAdapter.PurchaseSummaryListFilter.PURCHASE_ORDERS) {
			switch (action) {
				case "view":
					return {label: "View", callback: openModalHelper(setShowViewPO)};
				case "edit":
					return {label: "Edit", callback: editDraftPurchaseOrder};
				case "delete":
					return {label: "Delete", callback: openModalHelper(setShowDeleteDraft)};
				case "markAsVoid":
					return {label: "Mark as Void", callback: openModalHelper(setShowVoidPO)};
				case "markAsCompleted":
					return {label: "Mark as Completed", callback: openModalHelper(setShowPOToComplete)};
			}
		}
	}

	if (actions.length < 1) {return null}

	const defaultAction: ITableMenuButtonListItem = pair(actions[0]);

	return (
		<React.Fragment>
			<ViewPOPDFModal2
				isOpen={showViewPO}
				poKey={document && document.poKeyURL}
				closeModal={closeModalHelper(setShowViewPO)}
			/>

			<ViewInvoicePDFModal2
				isOpen={showViewInvoice}
				invoiceKey={document && document.invoiceKeyURL}
				closeModal={closeModalHelper(setShowViewInvoice)}
			/>

			<ConfirmDeleteDraftPOModal
				isOpen={showDeleteDraft}
				po={document}
				closeModal={closeModalHelper(setShowDeleteDraft)}
				finishDelete={doneDelete}
			/>

			<ConfirmVoidPOModal
				isOpen={showVoidPO}
				po={document}
				closeModal={closeModalHelper(setShowVoidPO)}
				finishDelete={doneVoid}
			/>

			<ConfirmCompletedPOModal
				isOpen={showPOToComplete}
				po={document}
				closeModal={closeModalHelper(setShowPOToComplete)}
				finish={doneComplete}
			/>

			<div className="d-flex">
				<Button color="hBlue" className="text-nowrap button-width-140" onClick={defaultAction ? defaultAction.callback : () => {}}>
					{defaultAction ? defaultAction.label : ""}
				</Button>

				{actions.length > 1 && (
					<TableHamburgerIconButton
						buttons={actions.slice(1).map(pair)}
					/>
				)}
			</div>
		</React.Fragment>
	);
};

export default PurchasesTable;
