import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Button, Col, Container, Row} from "reactstrap";
import {useHistory} from "react-router";
import {initialAdapter} from "adapter";
import {Link} from "react-router-dom";
import {decrementLoading, handleError, incrementLoading} from "../redux/meta/MetaActions";
import BuyerInvitationForSellerConfirmationCard from "../components/BuyerInvitationForSellerConfirmationCard";
import ErrorList from "../components/ErrorList";

interface IProps {
	tokenV2?: any;
	dispatch?: any;
}

const BuyerInvitesPage: React.FC<IProps> = (props: IProps) => {

	const [list, setList] = useState<Array<initialAdapter.IPendingBuyerConnectMsg>>([]);
	const [enableEmptyMessage, setEnableEmptyMessage] = useState(false);
	const [errors, setErrors] = useState([]);

	useEffect(() => {
		readBuyerInvites().then().catch();
	}, []);

	async function readBuyerInvites(): Promise<void> {
		setErrors([]);
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.readPendingBuyerConnectList(undefined, props.tokenV2);
		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		setEnableEmptyMessage(true);
		setList(res.data.items);
	}

	function makeCards(invites: Array<initialAdapter.IPendingBuyerConnectMsg> = []): ReactNode {
		return invites.map((invite: initialAdapter.IPendingBuyerConnectMsg, i: number) => {
			return (
				<Col xs={12} sm={6} md={6} lg={4} className="mb-5 d-flex" key={`pendingBuyerInviteForSupplierConfirmation-${i}`}>
					<BuyerInvitationForSellerConfirmationCard invite={invite} onDone={readBuyerInvites}/>
				</Col>
			);
		});
	}

	return (
		<Container className="my-4 px-2 px-lg-5" fluid>
			{(list.length < 1 && enableEmptyMessage) && (
				<div>
					<h5 className="my-3 text-center">
						There are no more invitations from buyers that require review.
					</h5>

					<div className="d-flex justify-content-center my-4">
						<Link to="/home">
							<Button
								component={Link}
								color="primary"
							>
								Home
							</Button>
						</Link>
					</div>
				</div>
			)}

			{errors.length > 0 && (
				<div className="my-3">
					<ErrorList errors={errors}/>
				</div>
			)}

			<Row className="d-flex justify-content-centers">
				{makeCards(list)}
			</Row>
		</Container>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
	}
})(BuyerInvitesPage);
