import InitialMetaStore, {IMetaStore} from "./InitialMetaStore";
import {IAction} from "../index";
import cloneDeep from "lodash.clonedeep";
import {legacyIncludes} from "../../utils/arrays";

export enum MetaType {
	LOGIN = "LOGIN",
	LOGIN_V2 = "LOGIN_V2",
	LOGOUT = "LOGOUT",
	LOADING = "LOADING",
	SET_ERROR = "SET_ERROR",
	LOGO= "LOGO",
	ACCESS_TYPES = "ACCESS_TYPES",
	USER_TYPE = "USER_TYPE",
	SAVE_DISPLAY_NAME = "SAVE_DISPLAY_NAME",
}

export default function(store: IMetaStore = InitialMetaStore, action: IAction<MetaType, any>): IMetaStore {

	let newStore: IMetaStore = cloneDeep(store);

	switch(action.type) {
		case(MetaType.LOGO):
			newStore.logoURL = action.payload;
			break;
		case(MetaType.LOGIN):
			newStore.token = action.payload;
			break;
		case(MetaType.LOGIN_V2):
			newStore.tokenV2 = action.payload;
			break;
		case(MetaType.LOGOUT):
			delete newStore.token;
			delete newStore.tokenV2;
			newStore = cloneDeep(InitialMetaStore);
			break;
		case(MetaType.LOADING):
			if ((newStore.loadingCount + action.payload) >= 0) {
				newStore.loadingCount += action.payload;
			} else {
				newStore.loadingCount = 0;
			}
			break;
		case(MetaType.SET_ERROR):
			newStore.error = action.payload;
			break;
		case(MetaType.ACCESS_TYPES):
			if (legacyIncludes(action.payload, "buyer")) {
				newStore.accessTypeBuyer = true;
			}
			if (legacyIncludes(action.payload, "seller")) {
				newStore.accessTypeSeller = true;
			}
			if (legacyIncludes(action.payload, "investor")) {
				newStore.accessTypeInvestor = true;
			}
			if (legacyIncludes(action.payload, "account")) {
				newStore.accessTypeAccount = true;
			}
			if (legacyIncludes(action.payload, "selfreg")) {
				newStore.accessTypeSelfReg = true;
			}
			if (legacyIncludes(action.payload, "invitesupplier")) {
				newStore.accessTypeInviteSupplier = true;
			}
			break;
		case(MetaType.USER_TYPE):
			newStore.userType = action.payload;
			break;
		case(MetaType.SAVE_DISPLAY_NAME):
			newStore.displayName = action.payload;
			break;
	}

	return newStore;

}
