import React, {ReactNode, useEffect, useState} from "react";
import {Button, Card, CardBody, Col, Label, Row} from "reactstrap";
import {formatMoney} from "../utils/formatters";
import AttachDocumentsToPendingInvoiceBuyerApprovalProcessModal  from "./AttachDocumentsToPendingInvoiceBuyerApprovalProcessModal";
import DocumentListItem from "./DocumentListItem";
import {initialAdapter} from "adapter";
import "./UploadLogo.scss";
import ClientViewPDFModal from "./ClientViewPDFModal";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {decrementLoading, handleError, incrementLoading} from "../redux/meta/MetaActions";
import ErrorList from "./ErrorList";

interface IPendingInvoiceForBuyerApprovalCardProps {
	tokenV2?: any;
	dispatch?: any;

	invoiceNumber: string;
	invoiceDate: string;
	endSupplier: string;
	invoiceAmount: string;
	paymentTerm: string;
	attachments: Array<initialAdapter.IDocumentItemMsg>;
	legalText: string;
	htcInvKeyURL: string;
	invKeyURL: string;
	poKeyURL: string;
	timestamp: string;

	onAttach(): Promise<void>;

	onAccept(invoiceNumber): void;
}

const PendingInvoiceForBuyerApprovalCard: React.FC<IPendingInvoiceForBuyerApprovalCardProps> = (props: IPendingInvoiceForBuyerApprovalCardProps) => {

	const {invoiceNumber, invoiceDate, endSupplier, invoiceAmount, paymentTerm, attachments, legalText, htcInvKeyURL, timestamp, invKeyURL, poKeyURL, tokenV2} = props;
	const [PODocs, setPODocs] = useState<Array<initialAdapter.IDocumentItemMsg>>([]);
	const [invDocs, setInvDocs] = useState<Array<initialAdapter.IDocumentItemMsg>>([]);
	const [showAttachModal, setShowAttachModal] = useState(false);
	const [showPDFModal, setShowPDFModal] = useState(false);
	const [errors, setErrors] = useState([]);

	function toggleShowAttachModal(): void {
		setShowAttachModal(!showAttachModal);
	}

	function toggleShowPDFModal(): void {
		setShowPDFModal(!showPDFModal);
	}

	useEffect(() => {
		readPoDocuments(poKeyURL).then().catch();
		return () => {
			setPODocs([]);
		}
	},[poKeyURL]);

	useEffect(() => {
		readInvoiceDocuments(invKeyURL).then().catch();
		return () => {
			setInvDocs([]);
		}
	},[invKeyURL])

	async function readInvoiceDocuments(invKeyURL: string): Promise<void> {
		if (!invKeyURL) return;
		props.dispatch(incrementLoading());
		const invDocsRes = await initialAdapter.api.readInvDocs({invKeyURL: invKeyURL}, tokenV2);
		props.dispatch(decrementLoading());
		if (!invDocsRes.success) {
			setErrors(handleError(invDocsRes));
			return;
		}
		setInvDocs(invDocsRes.data.documentList)
	}

	async function readPoDocuments(poKeyURL: string): Promise<void> {
		if (!poKeyURL) return;
		props.dispatch(incrementLoading());
		const poDocsRes = await initialAdapter.api.readPODocs({poKeyURL: poKeyURL}, tokenV2);
		props.dispatch(decrementLoading());
		if (!poDocsRes.success) {
			setErrors(handleError(poDocsRes));
			return;
		}
		setPODocs(poDocsRes.data.documentList);
	}

	function mapFilesStatic(files: Array<initialAdapter.IDocumentItemMsg> = []): ReactNode {
		if (files.length < 1) {
			return (
				<li className="font-italic mb-1 font-weight-light">
					No Attachments.
				</li>
			);
		}

		return files.map((file: initialAdapter.IDocumentItemMsg, i: number) => {
			return (
				<li key={`mapped-file-${i}`}>
						<a href={file.documentURL} target="_blank" className="text-hPurple font-italic">
							{file.documentName}
						</a>
				</li>
			);
		});
	}

	function mapFiles(files: Array<initialAdapter.IDocumentItemMsg> = []): ReactNode {
		if (files.length < 1) {
			return (
				<p className="font-italic mb-1 font-weight-light">
					No Attachments.
				</p>
			);
		}

		return files.map((file: initialAdapter.IDocumentItemMsg, i: number) => {
			return (
				<div className="mb-1" key={`mapped-file-${i}`}>
					<DocumentListItem
						file={file}
						showConfirmation={true}
						type="pendingHTCInv"
						onFinishDelete={props.onAttach}
						editingMeta={{
							keyURL: htcInvKeyURL,
							timestamp: timestamp,
						}}
					/>
				</div>
			);
		});
	}

	async function onAccept(): Promise<void> {
		setErrors([]);
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.updatePendingHTCInvtoSubmitted({keyURL: htcInvKeyURL, timestamp: timestamp}, tokenV2);
		props.dispatch(decrementLoading());

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		props.onAccept(invoiceNumber);
	}

	return (
		<React.Fragment>
			<ClientViewPDFModal
				isOpen={showPDFModal}
				pdfType="Invoice"
				api="readInvPdf"
				docKey={htcInvKeyURL}
				onClose={toggleShowPDFModal}
			/>

			<AttachDocumentsToPendingInvoiceBuyerApprovalProcessModal
				isOpen={showAttachModal}
				editingMeta={{
					keyURL: htcInvKeyURL,
					timestamp: timestamp,
				}}
				closeModal={toggleShowAttachModal}
				onUpload={props.onAttach}
			/>

			<Card className="w-100 po-inv-card-base">
				<CardBody className="d-flex flex-column justify-content-between">
					<div>
						<Row className="mb-2">
							<Col xs={12} sm={6}>
								<Label>Inv #:</Label>
							</Col>

							<Col xs={12} sm={6}>
								<a className="text-hPurple number-display" onClick={toggleShowPDFModal} href="#">
									{invoiceNumber}
								</a>
							</Col>
						</Row>

						<Row className="mb-2">
							<Col xs={12} sm={6}>
								<Label>Invoice Date:</Label>
							</Col>

							<Col xs={12} sm={6}>
								<span className="number-display">
									{invoiceDate}
								</span>
							</Col>
						</Row>

						<Row className="mb-2">
							<Col xs={12} sm={12} md={12} lg={12}>
								<Label>End Supplier:</Label>
							</Col>

							<Col xs={12} sm={12} md={12} lg={12}>
								<span>
									{endSupplier}
								</span>
							</Col>
						</Row>

						<Row className="mb-2">
							<Col xs={12} sm={6}>
								<Label>Inv Amount:</Label>
							</Col>

							<Col xs={12} sm={6}>
								<span className="number-display">
									{formatMoney(invoiceAmount)}
								</span>
							</Col>
						</Row>

						<Row className="mb-2">
							<Col xs={12} sm={6}>
								<Label>Payment Term:</Label>
							</Col>

							<Col xs={12} sm={6}>
								<span className="number-display">
									{paymentTerm}
								</span>
							</Col>
						</Row>

						<Row className="mb-2">
							<Col xs={12}>
								<Label>Documents:</Label>
							</Col>

							<Col xs={12}>
								<p>Buyer's Purchase Order Documents</p>
								<ul>
									{mapFilesStatic(PODocs)}
								</ul>
							</Col>
							<Col xs={12}>
								<p>Supplier's Invoice Documents</p>
								<ul>
									{mapFilesStatic(invDocs)}
								</ul>
							</Col>
						</Row>

						<Row className="mb-2">
							<Col xs={12}>
								<Label>Attachments:</Label>
							</Col>

							<Col xs={12}>
								<span className="number-display">
									{mapFiles(attachments)}
								</span>
							</Col>

							<div className="d-flex justify-content-end w-100">
								<Button
									onClick={toggleShowAttachModal}
									color="link"
									className="text-hBlue"
								>
									Attach Shipping Documents
								</Button>
							</div>
						</Row>

						<p>
							{legalText}
						</p>
					</div>

					{errors.length > 0 && (
						<div className="my-3">
							<ErrorList errors={errors}/>
						</div>
					)}

					<div className="mt-3">
						<Button
							color="hBlue"
							size="sm"
							className="w-100 font-weight-bold"
							onClick={onAccept}
						>
							Accept
						</Button>
					</div>
				</CardBody>
			</Card>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IPendingInvoiceForBuyerApprovalCardProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
		...props,
	}
})(PendingInvoiceForBuyerApprovalCard);
