import React from "react";
import {Container} from "reactstrap";

const LoginFooterDesktop: React.FC = () => {

	return (
		<div className="p-4 d-flex justify-content-end">
			<div className="d-flex">
				<p className="mb-0 text-white font-weight-light" style={{fontSize: "0.8rem"}}>
					Powered by
				</p>
				<img
					src={process.env.PUBLIC_URL + "/logos/logo-only-white.png"}
					alt="Harbour Trade Credit Logo"
					style={{
						objectFit: "contain",
						maxHeight: 15
					}}
					className="ml-2"
				/>
			</div>
		</div>
	);
};

export default LoginFooterDesktop;
