import React from "react";
import {Button, Card, CardBody} from "reactstrap";
import CardIconHeader from "../CardIconHeader";
import {FiCheckCircle} from "react-icons/all";
import {useHistory} from "react-router";

interface IProps {
	quantity: number;
}

const BuyersDashboardInvoiceAlert: React.FC<IProps> = (props: IProps) => {

	const {quantity} = props;
	const history = useHistory();

	function onViewAndApprove(): void {
		history.push("/invoice-approval");
	}

	const i: string = quantity > 1 ? "invoices" : "invoice";
	const r: string = quantity > 1 ? "require" : "requires";

	return (
		<Card className="dashboard-card">
			<CardBody>
				<CardIconHeader icon={<FiCheckCircle/>} title="Payable(s) for Approval"/>

				<Card className="mb-4">
					<CardBody>
						<p className="mb-0">
							<span className="text-hPurple font-weight-semi-bold">{`${quantity} ${i} `}</span>
							{`${r} your approval.`}
						</p>
					</CardBody>
				</Card>

				<div className="mx-3">
					<Button color="hBlue" block size="sm" onClick={onViewAndApprove}>
						View & Approve
					</Button>
				</div>
			</CardBody>
		</Card>
	);
};

export default BuyersDashboardInvoiceAlert;
