import React from "react";

const MobileHarborHeader: React.FC = () => {

	return (
		<div className="">
			<img
				src={process.env.PUBLIC_URL + "/logos/logo-only-clear.png"}
				alt="Harbour Trade Credit Logo"
				className="header-logo"
			/>
		</div>
	);
};

export default MobileHarborHeader;
