import React from "react";
import {Nav, NavItem, NavLink} from "reactstrap";
import classnames from "classnames";
import {initialAdapter} from "adapter";

interface ISelectCustomerPOTableTabsProps {
	filter: initialAdapter.DocumentDayRange;
	onFilterChange?(filter: initialAdapter.DocumentDayRange): void;
}

const SelectCustomerPOTableTabs: React.FC<ISelectCustomerPOTableTabsProps> = (props: ISelectCustomerPOTableTabsProps) => {

	const {filter, onFilterChange} = props;

	function filterChangeHelper(newFilter: initialAdapter.DocumentDayRange): () => void {
		return () => {
			onFilterChange(newFilter);
		}
	}

	return (
		<div>
			<Nav tabs className="w-100 border-bottom-0 tab-table-fix tab-scroller">
				<NavItem style={{minWidth: "140px"}}>
					<NavLink
						className={classnames({active: filter === initialAdapter.DocumentDayRange.LAST_30}, "cursor-pointer")}
						onClick={filterChangeHelper(initialAdapter.DocumentDayRange.LAST_30)}
					>
						Last 30 days
					</NavLink>
				</NavItem>
				<NavItem style={{minWidth: "140px"}}>
					<NavLink
						className={classnames({active: filter === initialAdapter.DocumentDayRange.DAYS_30_TO_60}, "cursor-pointer")}
						onClick={filterChangeHelper(initialAdapter.DocumentDayRange.DAYS_30_TO_60)}
					>
						30-60 days
					</NavLink>
				</NavItem>
				<NavItem style={{minWidth: "140px"}}>
					<NavLink
						className={classnames({active: filter === initialAdapter.DocumentDayRange.DAYS_60_TO_180}, "cursor-pointer")}
						onClick={filterChangeHelper(initialAdapter.DocumentDayRange.DAYS_60_TO_180)}
					>
						60-180 days
					</NavLink>
				</NavItem>
			</Nav>
		</div>
	)
};

export default SelectCustomerPOTableTabs;
