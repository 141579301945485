import React, {useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import ClientsTableWithController from "./ClientsTableWithController";
import ClientInvoiceHistory from "./ClientInvoiceHistory";
import {Container} from "reactstrap";

interface IInvestorUserClientPortalDashboardProps {
	tokenV2?: any;
	dispatch?: any;
	userType?: string;
}

const InvestorUserClientPortalDashboard: React.FC<IInvestorUserClientPortalDashboardProps> = (props: IInvestorUserClientPortalDashboardProps) => {

	const {tokenV2, userType} = props;
	const [selectedBuyerForFunding, setSelectedBuyerForFunding] = useState<any>();

	function onBuyerFunding(buyer: any): void {
		setSelectedBuyerForFunding(buyer);
	}

	return (
		<div>
			<div className="my-5">
				<ClientsTableWithController
					onBuyerFunding={onBuyerFunding}
				/>
			</div>

			{selectedBuyerForFunding && (
				<div className="my-5">
					<h5>
						Invoice History for {" " + selectedBuyerForFunding?.businessName}
					</h5>

					<ClientInvoiceHistory
						client={selectedBuyerForFunding}
					/>
				</div>
			)}
		</div>
	);
};

export default connect((store: IStore, props: IInvestorUserClientPortalDashboardProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
		userType: store.metaStore.userType,
		...props,
	}
})(InvestorUserClientPortalDashboard);
