import React, {ReactNode, useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {decrementLoading, handleError, incrementLoading, setError} from "../redux/meta/MetaActions";
import {initialAdapter} from "adapter";
import {generic400, generic500} from "../utils/errors";
import cloneDeep from "lodash.clonedeep";
import {Col, Row} from "reactstrap";
import FileThumbnail from "./FileThumbnail";
import InvoiceTableTabs, {InvoiceTableFilters} from "./InvoiceTableTabs";
import InvoiceTable, {IInvoiceTableRow} from "./InvoiceTable";

interface IClientInvoiceHistoryProps {
	tokenV2?: any;
	dispatch?: any;
	client: { clientKeyURL: string }
}

export const scrollToRef = (ref: any) => window.scrollTo({left: 0, top: ref.current.offsetTop, behavior: "smooth"});

const ClientInvoiceHistory: React.FC<IClientInvoiceHistoryProps> = (props: IClientInvoiceHistoryProps) => {

	const {tokenV2, client} = props;
	const [filter, setFilter] = useState<InvoiceTableFilters>(InvoiceTableFilters.OPEN);
	const [errors, setErrors] = useState([]);
	const [invoiceHistory, setInvoiceHistory] = useState<Array<IInvoiceTableRow>>([]);
	const [selectedInvoice, setSelectedInvoice] = useState<initialAdapter.IReadHTCPOSummaryListItem>();
	const [PODocs, setPODocs] = useState<Array<initialAdapter.IDocumentItemMsg>>([]);
	const [invDocs, setInvDocs] = useState<Array<initialAdapter.IDocumentItemMsg>>([]);
	const [htcInvDocs, setHtcInvDocs] = useState<Array<initialAdapter.IDocumentItemMsg>>([]);
	const scrollHelper = useRef(null);
	const executeScroll = () => scrollToRef(scrollHelper);

	useEffect(() => {
		if (client && Object.keys(client).length > 0) {
			readClientInvoiceHistory().then().catch();
		}
	}, [JSON.stringify(client), filter]);

	useEffect(() => {
		if (selectedInvoice && Object.keys(selectedInvoice).length > 0) {
			readDocumentList().then().catch();
		}
	}, [JSON.stringify(selectedInvoice)]);

	function toggleFilter(f: InvoiceTableFilters): void {
		setSelectedInvoice(null);
		setPODocs([]);
		setInvDocs([]);
		setHtcInvDocs([]);
		setFilter(f);
	}

	async function readClientInvoiceHistory(): Promise<void> {
		props.dispatch(incrementLoading());

		const res = await initialAdapter.api.readHTCPOSummaryList({
			clientKeyURL: client.clientKeyURL,
			filterType: filter,
			urlsafeNextCursor: "",
		}, tokenV2);

		if (!res.success) {
			props.dispatch(decrementLoading());
			setErrors(handleError(res));
			return;
		}

		const data = cloneDeep(res.data.items);

		const invHist: Array<IInvoiceTableRow> = data.map((item: initialAdapter.IReadHTCPOSummaryListItem): IInvoiceTableRow => {
			const actions: Array<[string, (row: any) => void]> = [];
			actions.push(["View", selectInvoiceToView]);

			return {
				...item,
				raw: item,
				actions,
			};
		});

		setInvoiceHistory(invHist);

		props.dispatch(decrementLoading());
	}

	function selectInvoiceToView(invoice: IInvoiceTableRow): void {
		setPODocs([]);
		setInvDocs([]);
		setHtcInvDocs([]);
		setSelectedInvoice(invoice.raw);
	}

	async function readInvoiceDocuments(invKeyURL: string): Promise<void> {
		if (!invKeyURL) return;
		props.dispatch(incrementLoading());
		const invDocsRes = await initialAdapter.api.readInvDocs({invKeyURL: invKeyURL}, tokenV2);
		props.dispatch(decrementLoading());
		if (!invDocsRes.success) {
			setErrors(handleError(invDocsRes));
			return;
		}
		setInvDocs(invDocsRes.data.documentList)
	}

	async function readHTCInvoiceDocuments(htcInvKeyURL: string): Promise<void> {
		if (!htcInvKeyURL) return;
		props.dispatch(incrementLoading());
		const htcInvDocsRes = await initialAdapter.api.readHTCInvDocs({keyURL: htcInvKeyURL}, tokenV2);
		props.dispatch(decrementLoading());
		if (!htcInvDocsRes.success) {
			setErrors(handleError(htcInvDocsRes));
			// return;
		}
		setHtcInvDocs(htcInvDocsRes.data.documentList)
	}

	async function readPoDocuments(poKeyURL: string): Promise<void> {
		if (!poKeyURL) return;
		props.dispatch(incrementLoading());
		const poDocsRes = await initialAdapter.api.readPODocs({poKeyURL: poKeyURL}, tokenV2);
		props.dispatch(decrementLoading());
		if (!poDocsRes.success) {
			setErrors(handleError(poDocsRes));
			return;
		}
		setPODocs(poDocsRes.data.documentList);
	}


	async function readDocumentList(): Promise<void> {

		await readPoDocuments(selectedInvoice.poKeyURL);
		await readHTCInvoiceDocuments(selectedInvoice.invoiceTransactionKeyURL);
		await readInvoiceDocuments(selectedInvoice.invoiceKeyURL);

		executeScroll();

	}

	function mapDocuments(docs: Array<initialAdapter.IDocumentItemMsg> = []): ReactNode {
		if (docs.length < 1) {
			return (
				<div className="w-100">
					<p className="my-3 font-italic text-center text-muted">
						No Documents for this invoice yet.
					</p>
				</div>
			);
		}

		return docs.map((doc: initialAdapter.IDocumentItemMsg, i: number) => {
			return (
				<Col xs={12} sm={6} md={4} lg={3} key={"document_" + i}>
					<FileThumbnail document={doc} invoiceItem={selectedInvoice}/>
				</Col>
			);
		});
	}

	return (
		<div>
			<InvoiceTableTabs filter={filter} onFilterChange={toggleFilter}/>

			<InvoiceTable data={invoiceHistory} isOpen={filter === InvoiceTableFilters.OPEN}/>

			<div ref={scrollHelper}>
				{selectedInvoice && (
					<div>
						<h5 className="my-5">
							{`Buyer's Purchase Order Documents for Invoice ${selectedInvoice.invoiceNum}`}
						</h5>

						<Row>
							{mapDocuments(PODocs)}
						</Row>
						<h5 className="my-5">
							{`Supplier's Invoice Documents for Invoice ${selectedInvoice.invoiceNum}`}
						</h5>

						<Row>
							{mapDocuments(invDocs)}
						</Row>
						<h5 className="my-5">
							{`Buyer's Proof of Delivery Documents for Invoice ${selectedInvoice.invoiceNum}`}
						</h5>

						<Row>
							{mapDocuments(htcInvDocs)}
						</Row>
					</div>
				)}
			</div>
		</div>
	);
};

export default connect((store: IStore, props: IClientInvoiceHistoryProps) => {
	return {
		tokenV2: store.metaStore.tokenV2,
		...props,
	}
})(ClientInvoiceHistory);
