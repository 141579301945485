import React from "react";
import {FiCheck} from "react-icons/all";

interface IProps {
	checked: boolean;
	label: string;
	className?: string;
	onToggle(): void;
}

const HTCCheckbox: React.FC<IProps> = (props: IProps) => {

	return (
		<div className={props.className}>
			<div className="htc-checkbox-container" onClick={props.onToggle}>

				<div className={"htc-checkbox " + (props.checked ? "htc-checkbox-checked" : "htc-checkbox-unchecked")}>
					{props.checked && (
						<FiCheck color="white" size="1.25rem"/>
					)}
				</div>

				<span className="text-hPurple htc-checkbox-label">
					<u>
						{props.label}
					</u>
				</span>
			</div>
		</div>
	);
};

export default HTCCheckbox;
