import React, {ReactNode, useState} from "react";
import {initialAdapter} from "adapter";
import BootstrapTable from "react-bootstrap-table-next";
import {Button, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import BuyerSetupTradeLinkModal from "./BuyerSetupTradeLinkModal";
import {FiChevronDown} from "react-icons/all";
import ViewSupplierInfoModal from "./ViewSupplierInfoModal";
import {legacyIncludes} from "../utils/arrays";
import {caretFormatter, headerTableFormatter, rowClassesHelper} from "../utils/headerTableFormatter";
import TableHamburgerIconButton from "./TableHamburgerIconButton";

interface IProps {
	data: Array<initialAdapter.IBusinessLinkCompany>;

	onDone(): Promise<void>;
}

const BusinessLinkTable: React.FC<IProps> = (props: IProps) => {

	function makeManageButton(cell: string, row: initialAdapter.IBusinessLinkCompany): JSX.Element {
		return (<ManageTradeLinkButton company={row} onDone={props.onDone}/>);
	}

	return (
		<div className="harbor-table-container box-shadow-helper">
			<BootstrapTable
				bootstrap4={true}
				striped={false}
				hover={true}
				condensed={true}
				bordered={false}
				wrapperClasses="table-responsive border"
				rowClasses={rowClassesHelper}
				keyField={"keyField"}
				data={props.data ? props.data.map((d, i) => ({...d, keyField: i})) : []}
				columns={[
					{
						dataField: "businessName",
						text: "Business Name",
						sort: true,
						classes: "",
						headerFormatter: headerTableFormatter,
						sortCaret: caretFormatter,
					},
					{
						dataField: "registeredCountry",
						text: "Country",
						sort: true,
						classes: "",
						headerFormatter: headerTableFormatter,
						sortCaret: caretFormatter,
					},
					{
						dataField: "contactName",
						text: "Contact Person",
						sort: true,
						classes: "text-nowrap",
						headerFormatter: headerTableFormatter,
						sortCaret: caretFormatter,
					},
					{
						dataField: "contactEmail",
						text: "Contact Email",
						sort: true,
						classes: "text-nowrap",
						headerFormatter: headerTableFormatter,
						sortCaret: caretFormatter,
					},
					// {
					// 	dataField: "contactNumber",
					// 	text: "Contact Number",
					// 	sort: true,
					// 	classes: "text-nowrap",
					// 	headerFormatter: headerTableFormatter,
					// 	sortCaret: caretFormatter,
					// },
					{
						dataField: "",
						text: "Actions",
						sort: false,
						formatter: makeManageButton,
						headerFormatter: headerTableFormatter,
						sortCaret: caretFormatter,
						manualHeaderClasses: "justify-content-center",
					}
				]}
				noDataIndication={props.data === undefined ? "Loading..." : "No BizLinks yet."}
				defaultSorted={[{
					dataField: "businessName",
					order: "asc",
				}]}
			/>
		</div>
	);
};

interface IManageTradeLinkButtonProps {
	company: initialAdapter.IBusinessLinkCompany;

	onDone(): Promise<void>;
}

const ManageTradeLinkButton: React.FC<IManageTradeLinkButtonProps> = (props: IManageTradeLinkButtonProps) => {
	const [showModal, setShowModal] = useState(false);
	const [showInfo, setShowInfo] = useState(false);

	function toggleShowModal(): void {
		setShowModal(!showModal);
	}

	function done(): void {
		setShowModal(false);
		props.onDone().then().catch();
	}

	function toggleSupplierInfoModal(): void {
		setShowInfo(!showInfo);
	}

	const tlSetup: boolean = legacyIncludes(props.company.actions, "tradelinksetup");
	const view: boolean = legacyIncludes(props.company.actions, "viewaccount");

	const setUpTradeLinkLabel: string = "TradeLink";
	const ViewInfoLabel: string = "Business Info";

	return (
		<div className="d-flex">
			<BuyerSetupTradeLinkModal
				isOpen={showModal}
				keyURL={props.company.clientKeyURL}
				onClose={toggleShowModal}
				onDone={done}
				supplierName={props.company.businessName}
			/>

			<ViewSupplierInfoModal
				isOpen={showInfo}
				keyURL={props.company.clientKeyURL}
				onClose={toggleSupplierInfoModal}
			/>

			{(tlSetup && view) ? (
				<React.Fragment>
					<Button color="hBlue" className="text-nowrap button-width-140" onClick={toggleShowModal}>
						{setUpTradeLinkLabel}
					</Button>

					<TableHamburgerIconButton
						buttons={[
							{label: ViewInfoLabel, callback: toggleSupplierInfoModal},
						]}
					/>
				</React.Fragment>
			) : (
				<React.Fragment>
					{tlSetup && (
						<Button color="hBlue" className="text-nowrap button-width-140" onClick={toggleShowModal}>
							{setUpTradeLinkLabel}
						</Button>
					)}

					{view && (
						<Button color="hBlue" className="text-nowrap button-width-140" onClick={toggleSupplierInfoModal}>
							{ViewInfoLabel}
						</Button>
					)}
				</React.Fragment>
			)}
		</div>
	);
};

export default BusinessLinkTable;
