import React from "react";
import {CardBody} from "reactstrap";
import AccountOverviewNormalTextDisplay from "./AccountOverviewNormalTextDisplay";
import DashboardBackgroundCard from "./dashboard/DashboardBackgroundCard";

interface IProps {
	customer: string;
	address: string;
	name: string;
	email: string;
	phone: string;
}

const ServiceCompanyInfoCard2: React.FC<IProps> = (props: IProps) => {

	const {customer, address, name, email, phone} = props;

	const contactString: string = (name || email || phone) ? name + "\n" + email + "\n" + phone : "";

	return (
		<DashboardBackgroundCard background="bg-hBlue">
			<CardBody>
				<h2 className="text-white text-center mb-3">Billing Info</h2>

				<AccountOverviewNormalTextDisplay title="Billing Customer" value={customer ? customer : "-"}/>
				<AccountOverviewNormalTextDisplay title="Address" value={address ? address : "-"}/>
				<AccountOverviewNormalTextDisplay title="Contact Info" value={contactString ? contactString : "-"} className="mb-0"/>
			</CardBody>
		</DashboardBackgroundCard>
	);
};

export default ServiceCompanyInfoCard2;
