import React from "react";
import {useHistory} from "react-router";
import {Button, Card, CardBody} from "reactstrap";
import CardIconHeader from "../CardIconHeader";
import {FiCheckCircle} from "react-icons/all";

interface IProps {
	quantity: number;
}

const SuppliersDashboardPurchaseAlert: React.FC<IProps> = (props: IProps) => {

	const {quantity} = props;
	const history = useHistory();

	function onViewAndApprove(): void {
		history.push("/po-invoice-creation");
	}

	const i: string = quantity > 1 ? "purchases" : "purchase";

	return (
		<Card className="dashboard-card">
			<CardBody>
				<CardIconHeader icon={<FiCheckCircle/>} title="Purchase Order(s) for Acceptance"/>

				<Card className="mb-4">
					<CardBody>
						<p className="mb-0">
							<span className="text-hPurple font-weight-semi-bold">{`${quantity} ${i} `}</span>
							for fulfillment.
						</p>
					</CardBody>
				</Card>

				<div className="mx-3">
					<Button color="hBlue" block size="sm" onClick={onViewAndApprove}>
						View & Fulfill
					</Button>
				</div>
			</CardBody>
		</Card>
	);
};

export default SuppliersDashboardPurchaseAlert;
