import React, {ReactNode} from "react";
import {Button, ButtonGroup} from "reactstrap";

export interface ITableFilterMainAndSubLists {
	mainFilters: string[];
	subFilters: string[];
}

export interface ITableFilterMainAndSub {
	mainFilter: string;
	subFilter: string;
}

export const defaultMainSubFilter: ITableFilterMainAndSub = {
	mainFilter: "",
	subFilter: "",
};

interface IProps {
	filterInformation: ITableFilterMainAndSubLists;
	activeFilters: ITableFilterMainAndSub;
	pseudoSelection?: string;
	onFilterChange: any;
}

/**
 * To utilize this component in its intended way, parent component should have a state variable ("activeFilters"), controlled by the "onFilterChange" function.
 * "filterInformation" comes from the api responses where filtering is utilized, then the useEffect should be listening for JSON.stringify(theStateVariableName)
 * represented by "activeFilters".
 *
 * Also, if the table using this filter component has filters on multiple "tabs", make sure to clear the filter when switching tabs. You can use the "defaultMainSubFilter"
 * const above, also good for setting the default filter value.
 *
 * @param props
 * @constructor
 */
const TableFilterHelper: React.FC<IProps> = (props: IProps) => {

	const {filterInformation, activeFilters, pseudoSelection} = props;

	function mapFilters(options: string[] = [], propCheck: keyof ITableFilterMainAndSub): ReactNode {
		return options.map((option:string, i: number) => {

			/**
			 * check if main or sub; if main we reset sub to empty string,
			 * else if sub, maintain the main filter and update sub filter
			 *
			 */
			function onClickHelper(): void {
				if (propCheck === "mainFilter") {
					props.onFilterChange({
						mainFilter: option,
						subFilter: ""
					});
				} else {
					props.onFilterChange({
						...props.activeFilters,
						subFilter: option,
					});
				}
			}

			return (
				<Button
					key={`filter-${i}-${option}`}
					onClick={onClickHelper}
					color="link"
					className={"text-capitalize mr-3 p-0 text-decoration-none text-hPurple" + ((activeFilters[propCheck] === option || pseudoSelection === option) ? " font-weight-bold" : "")}
					style={{fontSize: propCheck === "mainFilter" ? "0.9rem" : "0.8rem"}}
				>
					{option}
				</Button>
			);
		});
	}

	return (
		<div className="filter-container px-4 py-3">
			{(filterInformation.mainFilters && filterInformation.mainFilters.length > 0) && (
				<div className="overflow-x-scroller">
					{mapFilters(filterInformation.mainFilters, "mainFilter")}
				</div>
			)}

			{(filterInformation.subFilters && filterInformation.subFilters.length > 0) && (
				<div className="mt-3 overflow-x-scroller">
					{mapFilters(filterInformation.subFilters, "subFilter")}
				</div>
			)}

		</div>
	)
};

export default TableFilterHelper;
