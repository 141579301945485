import React, {ChangeEventHandler, ReactNode, useEffect, useState} from "react";
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Input, Label, Row} from "reactstrap";
import {InputProps, InputType} from "reactstrap/lib/Input";
import {initialAdapter} from "adapter";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {decrementLoading, handleError, incrementLoading} from "../redux/meta/MetaActions";
import {FiUpload, FiUploadCloud} from "react-icons/fi";
import DocumentListItemWithIcon from "./DocumentListItemWithIcon";
import SelectOptions from "./SelectOptions";
import {getFileType} from "../utils/files";
import ErrorList from "./ErrorList";

interface IProps {
	tokenV2?: any;
	dispatch?: any;

	onDone(): void;
}

const defaultSelfRegForm: initialAdapter.ISupplierSelfRegFormMsg = {
	businessName: "",
	contactName: "",
	contactJobTitle: "",
	contactEmail: "",
	contactNumber: "",
	registrationNumber: "",
	establishedInYear: "",
	companyOfficer1: "",
	companyOfficer1Email: "",
	companyOfficer2: "",
	companyOfficer2Email: "",
	registeredAddress: {
		country: "",
		addressLine1: "",
		addressLine2: "",
		city: "",
		stateRegion: "",
		zipCode: "",
	},
	clientBankAccount: {
		beneficiaryName: "",
		beneficiaryAddress: "",
		beneficiaryBank: "",
		accountNumber: "",
		swiftCode: "",
		routingNumber: "",
		intermediaryBankName: "",
		intermediaryBankSwiftCode: "",
	}
};

const SupplierSelfRegistrationForm: React.FC<IProps> = (props: IProps) => {

	const {tokenV2} = props;
	const [form, setForm] = useState<initialAdapter.ISupplierSelfRegFormMsg>(defaultSelfRegForm);
	const [file, setFile] = useState<File>();
	const [countryList, setCountryList] = useState([]);
	const [errors, setErrors] = useState([]);

	useEffect(() => {
		readSelfRegInitData().then().catch();
	}, []);

	/**
	 * Call api and get the country list as well as the fields that were entered by the buyer
	 * who invited this supplier
	 *
	 */
	async function readSelfRegInitData(): Promise<void> {
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.readSupplierSelfRegInitData(undefined, tokenV2);

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}
		setForm({
			...form,
			businessName: res.data.companyName,
			contactEmail: res.data.contactEmail,
			contactName: res.data.contactName,
		});

		setCountryList(res.data.countryList);

		props.dispatch(decrementLoading());
	}

	function baseOnChange(key: keyof initialAdapter.ISupplierSelfRegFormMsg): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			})
		}
	}

	function addressOnChange(key: keyof initialAdapter.IAddressMsg): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				registeredAddress: {
					...form.registeredAddress,
					[key]: e.target.value,
				}
			})
		}
	}

	function bankInfoOnChange(key: keyof initialAdapter.ISupplierSelfRegBankAccountMsg): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				clientBankAccount: {
					...form.clientBankAccount,
					[key]: e.target.value,
				}
			})
		}
	}

	/**
	 * custom onChange to handle the document upload
	 *
	 * @param e
	 */
	function onFileChange(e: any): void {
		props.dispatch(incrementLoading());
		if (!e.target.files || (e.target.files && e.target.files.length < 1)) {
			props.dispatch(decrementLoading());
			return;
		}
		setFile(e.target.files[0]);
		const fr = new FileReader();
		fr.onload = () => {
			props.dispatch(decrementLoading());
		};
		fr.readAsDataURL(e.target.files[0]);
		e.target.value = null;
	}

	function clearFile(): void {
		setFile(null);
	}

	/**
	 * factory for creating normal text inputs in the form
	 *
	 * @param label
	 * @param name
	 * @param placeholder
	 * @param value
	 * @param onChange
	 * @param inputType
	 * @param selectConfig
	 * @param className
	 */
	function makeInputPair(
		label: string,
		name: keyof initialAdapter.ISupplierSelfRegFormMsg | keyof initialAdapter.IAddressMsg | keyof initialAdapter.ISupplierSelfRegBankAccountMsg,
		placeholder: string,
		value: string,
		onChange: (key: any) => ChangeEventHandler<HTMLInputElement>,
		inputType: InputType = "text",
		selectConfig: { defaultLabel?: string, strings?: string[] } = {},
		className?: string,
	): ReactNode {
		const inputProps: InputProps = {
			type: inputType,
			name: name as string,
			id: name as string,
			placeholder,
			value,
			onChange: onChange(name),
		};

		return (
			<div className="mb-3">
				<Label>
					{label}
				</Label>
				<div>
					{(inputType === "select" && Object.keys(selectConfig).length > 0) ? (
						<Input {...inputProps} className={className ? className : ""}>
							<option value="" selected
							        className="first-select-option">{selectConfig.defaultLabel}</option>
							<hr/>
							<SelectOptions strings={selectConfig.strings}/>
						</Input>
					) : (
						<Input {...inputProps} className={className ? className : ""}/>
					)}
				</div>
			</div>
		)
	}

	/**
	 * call api to submit form, on success call props.onDone()
	 *
	 */
	async function saveSelfReg(e?): Promise<void> {
		if (e) {
			e.preventDefault();
		}

		setErrors([]);
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.submitSupplierSelfRegForm(form, tokenV2);

		if (!res.success) {
			setErrors(handleError(res));
			return;
		}

		if (file) {
			const fileType: string = getFileType(file);

			const getURLRes = await initialAdapter.api.getClientDocumentUploadURL({fileType}, tokenV2);
			if (!getURLRes.success) {
				// setErrors(handleError(getURLRes));
				// return;
			}

			const uploadRes = await initialAdapter.api.uploadToSignedURL(getURLRes.data.signedURL, file);
			if (!uploadRes.success) {
				// setErrors(handleError(uploadRes));
				// return;
			}

			const attachRes = await initialAdapter.api.attachDocToCompanyAccount({
				documentType: fileType,
				documentName: file.name,
				documentID: getURLRes.data.fileid,
			}, tokenV2);
			if (!attachRes.success) {
				// setErrors(handleError(attachRes));
				// return;
			}
		}

		props.dispatch(decrementLoading());
		props.onDone();
	}

	return (
		<div>
			<CardHeader>Registration Form</CardHeader>
			<CardBody>
				<h1 className="text-hPurple font-weight-bold mb-3">
					{`Welcome${form.businessName ? (" " + form.businessName) : ""}!`}
				</h1>

				<form>
					<Card className="mt-3 mb-4">
						<CardTitle>
							<h5 className="text-hPurple">Company Legal Information</h5>
							<hr/>
						</CardTitle>
						<CardBody>
							<Row>
								<Col xs={12} lg={4}>
									<p className="text-hPurple">Company information must be identical to the way it is written in the legal documents.</p>
								</Col>
								<Col xs={12} lg={8}>
									{makeInputPair("Company Legal Name*", "businessName", "", form.businessName, baseOnChange)}
									{makeInputPair("Registration Number*", "registrationNumber", "", form.registrationNumber, baseOnChange, "text", undefined, "number-display")}
									{makeInputPair("Established in Year*", "establishedInYear", "", form.establishedInYear, baseOnChange, "text", undefined, "number-display")}
									{makeInputPair("Company Officer #1*", "companyOfficer1", "", form.companyOfficer1, baseOnChange)}
									{makeInputPair("Officer #1 Email*", "companyOfficer1Email", "", form.companyOfficer1Email, baseOnChange, "email")}
									{makeInputPair("Company Officer #2", "companyOfficer2", "", form.companyOfficer2, baseOnChange)}
									{makeInputPair("Officer #2 Email", "companyOfficer2Email", "", form.companyOfficer2Email, baseOnChange, "email")}
								</Col>
							</Row>
						</CardBody>
					</Card>

					<Card className="mb-4">
						<CardTitle>
							<h5 className="text-hPurple">Primary Contact</h5>
							<hr/>
						</CardTitle>
						<CardBody>
							<Row>
								<Col xs={12} lg={4}>
									<p className="text-hPurple">This will be the primary user / administrator of this account.</p>
								</Col>
								<Col xs={12} lg={8}>
									{makeInputPair("Primary Contact Name*", "contactName", "", form.contactName, baseOnChange)}
									{makeInputPair("Contact Person Job Title*", "contactJobTitle", "", form.contactJobTitle, baseOnChange)}
									{makeInputPair("Contact Number*", "contactNumber", "", form.contactNumber, baseOnChange, "text", undefined, "number-display")}
									{makeInputPair("Contact Email*", "contactEmail", "", form.contactEmail, baseOnChange, "email")}
								</Col>
							</Row>
						</CardBody>
					</Card>

					<Card className="mb-4">
						<CardTitle>
							<h5 className="text-hPurple">Registration Address</h5>
							<hr/>
						</CardTitle>
						<CardBody>
							<Row>
								<Col xs={12} lg={4}>
									<p className="text-hPurple">Address must be identical for bank payment.</p>
								</Col>
								<Col xs={12} lg={8}>
									<div className={(!form.registeredAddress || !form.registeredAddress.country || form.registeredAddress.country === "") ? "un-selected-drop-down-parent" : "un-selected-drop-down-parent"}>
										{makeInputPair("Country / Region*", "country", "", form.registeredAddress.country, addressOnChange, "select", {
											defaultLabel: "Select Your Country/Region...",
											strings: countryList
										})}
									</div>
									{makeInputPair("Address Line 1*", "addressLine1", "", form.registeredAddress.addressLine1, addressOnChange)}
									{makeInputPair("Address Line 2", "addressLine2", "", form.registeredAddress.addressLine2, addressOnChange)}
									{makeInputPair("City*", "city", "", form.registeredAddress.city, addressOnChange)}
									{makeInputPair("State / Province / Region*", "stateRegion", "", form.registeredAddress.stateRegion, addressOnChange)}
									{makeInputPair("Postal / ZIP Code*", "zipCode", "", form.registeredAddress.zipCode, addressOnChange)}
								</Col>
							</Row>
						</CardBody>
					</Card>

					<Card className="mb-4">
						<CardTitle>
							<h5 className="text-hPurple">Company Registration Document</h5>
							<hr/>
						</CardTitle>
						<CardBody>
							<Row>
								<Col xs={12} lg={4}>
									<p className="text-hPurple">Legal document from government authority. ie: articles of association and annual filing.</p>
								</Col>
								<Col xs={12} lg={8}>
									<Card>
										<div className="upload-btn-wrapper my-3 cursor-pointer">
											<div className="position-relative overflow-hidden w-100 d-inline-flex flex-column align-items-center upload-btn-wrapper-inner p-3">
												<FiUpload size="3em" className="text-hPurple" style={{opacity: 0.6}}/>
												<input
													type="file"
													name="myfile"
													className="cursor-pointer"
													onChange={onFileChange}
												/>
												<span className="mt-3 text-center font-weight-bold text-hPurple">
													Browse or drop your image here.
												</span>
												<span className="text-muted text-center">
													Maximum 5MB in size.<br/>
													JPG, PNG, or GIF formats.<br/>
													Recommended size: 200 x 300 pixels.
												</span>
											</div>
										</div>
									</Card>

									{file && (
										<div className="mt-3 px-3">
											<DocumentListItemWithIcon
												name={file.name}
												onDelete={clearFile}
											/>
										</div>
									)}
								</Col>
							</Row>
						</CardBody>
					</Card>

					<Card className="mb-4">
						<CardTitle>
							<h5 className="text-hPurple">Bank Account Details</h5>
							<hr/>
						</CardTitle>
						<CardBody>
							<Row className="mb-3">
								<Col xs={12} lg={4}>
									<p className="text-hPurple">Beneficiary Information must match exactly as it appears on your account at your bank.</p>
								</Col>
								<Col xs={12} lg={8}>
									{makeInputPair("Beneficiary Name*", "beneficiaryName", "", form.clientBankAccount.beneficiaryName, bankInfoOnChange)}
									{makeInputPair("Beneficiary's Address*", "beneficiaryAddress", "", form.clientBankAccount.beneficiaryAddress, bankInfoOnChange)}
									{makeInputPair("Beneficiary's Bank*", "beneficiaryBank", "", form.clientBankAccount.beneficiaryBank, bankInfoOnChange)}
									{makeInputPair("Account Number / IBAN*", "accountNumber", "", form.clientBankAccount.accountNumber, bankInfoOnChange, "text", undefined, "number-display")}
									{makeInputPair("SWIFT*", "swiftCode", "", form.clientBankAccount.swiftCode, bankInfoOnChange, "text", undefined, "number-display")}
									{makeInputPair("Routing Number", "routingNumber", "", form.clientBankAccount.routingNumber, bankInfoOnChange, "text", undefined, "number-display")}
								</Col>
							</Row>

							<hr/>

							<Row className="mt-3">
								<Col xs={12} lg={4}>
									<p className="text-hPurple">Field required only if an intermediary bank is utilized for transferring funds to the beneficiary bank.</p>
								</Col>
								<Col xs={12} lg={8}>
									{makeInputPair("Intermediary Bank Name", "intermediaryBankName", "", form.clientBankAccount.intermediaryBankName, bankInfoOnChange)}
									{makeInputPair("SWIFT", "intermediaryBankSwiftCode", "", form.clientBankAccount.intermediaryBankSwiftCode, bankInfoOnChange, "text", undefined, "number-display")}
								</Col>
							</Row>
						</CardBody>
					</Card>

					<div className="d-flex justify-content-center justify-content-sm-end mt-4">
						<Button color="hBlue" className="button-width-280" onClick={saveSelfReg} type="submit">
							Submit
						</Button>
					</div>

				</form>
				{errors.length > 0 && (
					<div className="my-3">
						<ErrorList errors={errors}/>
					</div>
				)}
			</CardBody>
		</div>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
	}
})(SupplierSelfRegistrationForm);
