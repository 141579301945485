import React from "react";
import {Button, Card, CardBody} from "reactstrap";
import CardIconHeader from "../CardIconHeader";
import {FiLink} from "react-icons/all";
import {useHistory} from "react-router";

interface IProps {
	quantity: number;
}

const BusinessLinkSetup: React.FC<IProps> = (props: IProps) => {

	const {quantity} = props;
	const history = useHistory();

	function onViewAndApprove(): void {
		history.push("/buyer-invites");
	}

	const s: string = quantity > 1 ? "buyers" : "buyer";
	const h: string = quantity > 1 ? "have" : "has";

	return (
		<Card className="dashboard-card">
			<CardBody>
				<CardIconHeader icon={<FiLink/>} title="BizLink"/>

				<Card className="mb-4">
					<CardBody>
						<p className="mb-0">
							<span className="text-hPurple font-weight-semi-bold">{`${quantity} ${s} `}</span>
							{`${h} invited you to connect.`}
						</p>
					</CardBody>
				</Card>

				<div className="mx-3">
					<Button color="hBlue" block size="sm" onClick={onViewAndApprove}>
						View & Approve
					</Button>
				</div>
			</CardBody>
		</Card>
	);
};

export default BusinessLinkSetup;
