import React, {useEffect, useState} from "react";
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import Login from "./pages/Login";
import AuthenticatedRoute from "./pages/AuthenticatedRoute";
import Buyer from "./pages/Buyer";
import {PersistGate} from "redux-persist/integration/react";
import {persistor} from "./redux";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ResetPassword from "./pages/ResetPassword";
import Loader from "./components/Loader";
import ErrorModal from "./components/ErrorModal";
import PurchasesPage from "./pages/PurchasesPage";
import SalesPage from "./pages/SalesPage";
import Dashboard from "./pages/Dashboard";
import CreateInvoiceFormPage from "./components/CreateInvoiceFormFull";
import SandBoxPage from "./pages/SandBoxPage";
import CreatePOPage from "./pages/CreatePOPage";
import EditPOPage from "./pages/EditPOPage";
import CreateInvPage from "./pages/CreateInvPage";
import EditInvPage from "./pages/EditInvPage";
import TokenManagement from "./components/TokenManagement";
import ViewAndApproveInvoicesPage from "./pages/ViewAndApproveInvoicesPage";
import ViewPOsAndCreateInvoicesPage from "./pages/ViewPOsAndCreateInvoicesPage";
import AccountInfoPage from "./pages/AccountInfoPage";
import BusinessLinkPage from "./pages/BusinessLinkPage";
import BuyerInvitesPage from "./pages/BuyerInvitesPage";
import {connect} from "react-redux";
import {IStore} from "./redux/defaultStore";
import BuyerTradeLinkSetup from "./pages/BuyerTradeLinkSetup";
import Login2 from "./pages/Login2";

interface IProps {
	accessTypeSelfReg?: boolean;
}

const App: React.FC<IProps> = (props: IProps) => {

	return (
		<PersistGate persistor={persistor} loading={null}>
			<BrowserRouter>
				<TokenManagement/>

				<Header/>

				<Loader/>
				<ErrorModal/>

				<main>
					<Switch>

						{/*<Route path="/" exact component={Login}/>*/}
						<Route path="/" exact component={Login2}/>
						{/*<Route path="/login2" exact component={Login2}/>*/}
						<Route path="/_/auth/action" exact component={Login2}/>
						<Route path="/reset-password" exact component={ResetPassword}/>

						<AuthenticatedRoute path="/home" exact component={Dashboard}/>
						<AuthenticatedRoute path="/purchases" exact component={PurchasesPage} accessRestriction={["buyer"]}/>
						<AuthenticatedRoute path="/sales" exact component={SalesPage} accessRestriction={["seller"]}/>

						<AuthenticatedRoute path="/po/create" exact component={CreatePOPage} accessRestriction={["buyer"]}/>
						<AuthenticatedRoute path="/po/edit" exact component={EditPOPage} accessRestriction={["buyer"]}/>
						<AuthenticatedRoute path="/invoice-approval" exact component={ViewAndApproveInvoicesPage} accessRestriction={["buyer"]}/>
						<Redirect from="/po" to="/po/create"/>

						<AuthenticatedRoute path="/invoice/create" exact component={CreateInvPage} accessRestriction={["seller"]}/>
						<AuthenticatedRoute path="/invoice/edit" exact component={EditInvPage} accessRestriction={["seller"]}/>
						<AuthenticatedRoute path="/po-invoice-creation" exact component={ViewPOsAndCreateInvoicesPage} accessRestriction={["seller"]}/>
						<Redirect from="/invoice" to="/invoice/create"/>

						<AuthenticatedRoute path="/account/account-info" exact component={AccountInfoPage} accessRestriction={["account"]}/>
						<AuthenticatedRoute path="/account/business-link" exact component={BusinessLinkPage} accessRestriction={["account", "buyer"]}/>
						<Redirect from="/account" to="/account/account-info"/>

						<AuthenticatedRoute path="/buyer-invites" exact component={BuyerInvitesPage} accessRestriction={["account"]}/>
						<AuthenticatedRoute path="/tradelink-setup" exact component={BuyerTradeLinkSetup} accessRestriction={["account", "buyer"]}/>

						<Redirect to="/"/>

						{/*<AuthenticatedRoute path="/test-page" exact component={SandBoxPage}/>*/}
					</Switch>
				</main>

				<Footer/>
			</BrowserRouter>
		</PersistGate>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		accessTypeSelfReg: store.metaStore.accessTypeSelfReg,
	}
})(App);
