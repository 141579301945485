import React from "react";
import BootstrapTable, {Column} from 'react-bootstrap-table-next';
import {formatDate, formatMoney, sortMoney} from "../utils/formatters";
import {Button, ButtonGroup} from "@material-ui/core";
import styleHeaders from "../style/styleHeaders";
import {IBuyerRow} from "./BuyersTable";
import {initialAdapter} from "adapter";
import {alignCellContentRight} from "./AlignCellContentRight";

export interface IInvoiceTableRow extends initialAdapter.IReadHTCPOSummaryListItem {
	actions: Array<[string, (row: IBuyerRow) => void]>;
	raw?: any;
}

interface IProps {
	data: IInvoiceTableRow[];
	isOpen: boolean;
}

const InvoiceTable: React.FC<IProps> = ({data, isOpen}) => {

	let columns: Column[] = [
		{
			dataField: "invoiceNum",
			text: "Invoice #",
			sort: true,
		},
		{
			dataField: "status",
			text: "Status",
			sort: true,
			formatter: mapStatus,
		},
		{
			dataField: "invoiceDate",
			text: "Invoice Date",
			sort: true,
			formatter: formatDate
		},
		{
			dataField: "term",
			text: "Term",
			sort: true,
		},
		{
			dataField: "invoiceAmount",
			text: "Invoice Amount",
			formatter: alignCellContentRight(formatMoney),
			sortFunc: sortMoney,
			headerAlign: "right",
			sort: true,
		},
		{
			dataField: "fundedAmount",
			text: "Funded Amount",
			formatter: alignCellContentRight(formatMoney),
			sortFunc: sortMoney,
			headerAlign: "right",
			sort: true,
		},
		{
			dataField: "paidAmount",
			text: "Amount Paid",
			formatter: alignCellContentRight(formatMoney),
			sortFunc: sortMoney,
			headerAlign: "right",
			sort: true,
		},
		{
			dataField: "dueDate",
			text: "Due Date",
			formatter: formatDate,
			sort: true,
		},
		{
			dataField: "actions",
			text: "Actions",
			formatter: mapButton,
		},
	];

	if (isOpen) {
		columns.splice(6, 0, {
			dataField: "dueAmount",
			text: "Amount Due",
			formatter: alignCellContentRight(formatMoney),
			sortFunc: sortMoney,
			headerAlign: "right",
			sort: true,
		})
	}

	return (
		<BootstrapTable
			bootstrap4={true}
			striped={false}
			hover={true}
			condensed={true}
			bordered={false}
			wrapperClasses="table-responsive border"
			rowStyle={{whiteSpace: "nowrap"}}
			columns={styleHeaders(columns)}
			keyField={"keyField"}
			data={data.map((d, i) => ({...d, keyField: i}))}
			noDataIndication="You currently have no invoices."
			defaultSorted={[{
				dataField: 'invoiceNumber',
				order: 'asc'
			}]}
		/>
	);
};

function mapButton(cell: Array<[string, (row: IInvoiceTableRow) => void]>, row: IInvoiceTableRow, i: number): JSX.Element {
	if (cell.length < 1) {return null}
	function map([name, func]: [string, (row: IInvoiceTableRow) => void], k: number): JSX.Element {

		function onClick() {
			func(row);
		}

		return (
			<Button key={"invoice_"+i+"_"+k} className="text-primary border-primary" onClick={onClick}>{name}</Button>
		)
	}

	return (
		<ButtonGroup key={"invoice_"+i} size="small" variant="outlined" >
			{cell.map(map)}
		</ButtonGroup>
	);
}

function mapStatus(cell: string, row: IInvoiceTableRow, i: number): JSX.Element {

	const className = cell === "overdue" || cell === "pending" ? "text-danger border-danger" : "text-success border-success";

	return (
		<Button disabled={true} size="small" variant="outlined"  key={"status_"+i} className={className}>
			{cell}
		</Button>
	);
}

export default InvoiceTable;
