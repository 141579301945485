import React from "react";
import {Button, Card, CardBody} from "reactstrap";
import CardIconHeader from "../CardIconHeader";
import {FiLink} from "react-icons/all";
import {useHistory} from "react-router";

interface IProps {
	quantity: number;
}

const TradeLinkSetup: React.FC<IProps> = (props: IProps) => {

	const {quantity} = props;
	const history = useHistory();

	function onViewAndSetUp(): void {
		history.push("/tradelink-setup");
	}

	const s: string = quantity > 1 ? "suppliers" : "supplier";
	const h: string = quantity > 1 ? "have" : "has";
	const i: string = quantity > 1 ? "invitations" : "invitation";
	const a: string = quantity > 1 ? "are" : "is";

	return (
		<Card className="dashboard-card">
			<CardBody>
				<CardIconHeader icon={<FiLink/>} title="Tradelink Setup"/>

				<Card className="mb-4">
					<CardBody>
						<p className="mb-0">
							<span className="text-hPurple font-weight-semi-bold">{`${quantity} ${s} `}</span>
							{`${h} accepted your ${i} and ${a} pending TradeLink setup.`}
						</p>
					</CardBody>
				</Card>

				<div className="mx-3">
					<Button color="hBlue" block size="sm" onClick={onViewAndSetUp}>
						View and Set Up
					</Button>
				</div>
			</CardBody>
		</Card>
	);
};

export default TradeLinkSetup;
