import React, {ReactNode} from "react";

interface IErrorListProps {
	errors?: string | string[];
}

const ErrorList: React.FC<IErrorListProps> = (props: IErrorListProps) => {

	function makeErrors(error: string): ReactNode {
		return (
			<p className="form-error-message">
				*{error}
			</p>
		)
	}

	return (
		<div>
			{props.errors ? (typeof props.errors === "string" ? [props.errors].map(makeErrors) : props.errors.map(makeErrors)) : [].map(makeErrors)}
		</div>
	);
};

ErrorList.defaultProps = {
	errors: [],
};

export default ErrorList;
