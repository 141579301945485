import React from "react";
import {Container} from "reactstrap";

const LoginFooterMobile: React.FC = () => {

	return (
		<div className="p-4 bg-hPurple d-flex justify-content-end">
			<div>
				<div className="d-flex">
					<p className="mb-0 text-white font-weight-light">
						Powered by
					</p>
					<img
						src={process.env.PUBLIC_URL + "/logos/logo-only-white.png"}
						alt="Harbour Trade Credit Logo"
						style={{
							objectFit: "contain",
							maxHeight: 20
						}}
						className="ml-2"
					/>
				</div>
			</div>
		</div>
	);
};

export default LoginFooterMobile;
