import React from "react";
import BootstrapTable, {Column} from "react-bootstrap-table-next";
import {ClientTableFilters} from "./ClientsTableFilter";
import styleHeaders from "../style/styleHeaders";
import {Button, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {FiChevronDown} from "react-icons/all";
import {formatMoney, sortMoney} from "../utils/formatters";
import {useHistory} from "react-router";
import {alignCellContentRight} from "./AlignCellContentRight";

interface IClientsTableProps {
	data: any[];
	filter: ClientTableFilters;
}

const ClientsTable: React.FC<IClientsTableProps> = (props: IClientsTableProps) => {

	const {data, filter} = props;

	return (
		<BootstrapTable
			bootstrap4={true}
			striped={false}
			hover={true}
			condensed={true}
			bordered={false}
			wrapperClasses="table-responsive border"
			rowStyle={{whiteSpace: "nowrap"}}
			columns={styleHeaders(getTableColumns(filter))}
			keyField={"keyField"}
			data={filterData(data, filter).map((d, i) => ({...d, keyField: i}))}
			noDataIndication={getEmptyMessage(filter)}
			defaultSorted={[{dataField: "status", order: "asc"}]}
		/>
	);
};

/**
 * filter and only return the data that matches the filter
 *
 * @param data
 * @param filter
 */
function filterData(data: any[], filter: ClientTableFilters): any[] {

	switch (filter) {
		case ClientTableFilters.BUYER:
			return data.filter((row) => {
				return row.type === ClientTableFilters.BUYER;
			});
		case ClientTableFilters.SELLER:
			return data.filter((row) => {
				return row.type === ClientTableFilters.SELLER;
			});
		default:
			return data;
	}
}

/**
 * declaration of all the possible columns for this table, based on the filter, for use in "getTableColumns"
 */
const statusCol: Column = {dataField: "status", text: "Status", sort: true};
const clientCol: Column = {dataField: "businessName", text: "Client", sort: true};
// const creditLimitCol: Column = {
// 	dataField: "creditLimitAmount",
// 	text: "Credit Limit",
// 	sort: true,
// 	formatter: alignCellContentRight(formatMoney),
// 	sortFunc: sortMoney,
// 	headerAlign: "right",
// };
// const balanceCol: Column = {
// 	dataField: "balanceAmount",
// 	text: "Balance",
// 	sort: true,
// 	formatter: alignCellContentRight(formatMoney),
// 	sortFunc: sortMoney,
// 	headerAlign: "right",
// };
// const availableCreditCol: Column = {
// 	dataField: "availableCreditAmount",
// 	text: "Available Credit",
// 	sort: true,
// 	formatter: alignCellContentRight(formatMoney),
// 	sortFunc: sortMoney,
// 	headerAlign: "right",
// };
const actionsCol: Column = {dataField: "actions", text: "Actions", formatter: mapButton};
const countryCol: Column = {dataField: "country", text: "Country", sort: true};
const contactNameCol: Column = {dataField: "contactName", text: "Contact Name", sort: true};
const contactEmailCol: Column = {dataField: "contactEmail", text: "Contact Email", sort: true};

/**
 * return appropriate array of columns for the table based on the filter being applied
 *
 * @param filter
 */
function getTableColumns(filter: ClientTableFilters): Column[] {

	switch (filter) {
		case ClientTableFilters.BUYER:
			return [statusCol, clientCol, actionsCol];
		case ClientTableFilters.SELLER:
			return [statusCol, clientCol, countryCol, contactNameCol, contactEmailCol];
		default:
			return [];
	}
}

/**
 * message shown when no data present in table, dependant on filter
 *
 * @param filter
 */
function getEmptyMessage(filter: ClientTableFilters): string {
	switch (filter) {
		case ClientTableFilters.BUYER:
			return "There are currently no buyers";
		case ClientTableFilters.SELLER:
			return "There are currently no suppliers";
		default:
			return "There are currently no clients";
	}
}

function mapButton(cell: Array<[string, (row: any) => void]>, row: any, i: number): JSX.Element {
	if (cell.length < 1) {
		return null
	}

	function map([name, func]: [string, (row: any) => void], k: number): JSX.Element {

		function onClick() {
			func(row);
		}

		return (
			<DropdownItem key={"buyer_" + i + "_" + k} className="text-primary border-primary"
			              onClick={onClick}>{name}</DropdownItem>
		)
	}

	function mainButtonOnClick(): void {
		cell[0][1](row);
	}

	const style1 = cell.length > 1 ? {borderTopRightRadius: 0, borderBottomRightRadius: 0} : {};

	return (
		<ButtonGroup key={"buyer_" + i} size="small">
			<Button onClick={mainButtonOnClick} outline color="primary" style={style1}>
				{cell[0][0]}
			</Button>
			{cell.length > 1 && (
				<UncontrolledDropdown>
					<DropdownToggle outline color="primary"
					                style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeftWidth: 0}}>
						<FiChevronDown/>
					</DropdownToggle>
					<DropdownMenu>
						{cell.map(map)}
					</DropdownMenu>
				</UncontrolledDropdown>
			)}
		</ButtonGroup>
	);
}

export default ClientsTable;
