import React, {ReactNode} from "react";
import {FiChevronDown, FiChevronUp} from "react-icons/all";

export function headerTableFormatter(column, colIndex, elements): ReactNode {

	return (
		<div className={"d-flex align-items-center py-2 h-100 " + column.manualHeaderClasses}>
			<h6 className="text-white font-weight-normal mb-0">
				{column.text}
			</h6>
			<div className="harbor-table-sorter d-flex align-items-center ml-2" style={{height: 32}}>
				{elements.sortElement}
			</div>
		</div>
	);
}

export function caretFormatter(order, column): ReactNode {
	const upClassName: string = order === "asc" ? "selected-sort" : "";
	const downClassName: string = order === "desc" ? "selected-sort" : "";

	return (
		<div>
			<FiChevronUp className={upClassName}/>
			<FiChevronDown className={downClassName}/>
		</div>
	);
}

export function rowClassesHelper(row, rowIndex: number): string {
	return "border-0 " + (rowIndex % 2 === 0 ? "bg-white" : "bg-hGray");
}
