import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Button, Card, CardBody, Col, Input, Label, Modal, ModalHeader, Row} from "reactstrap";
import {decrementLoading, handleError, incrementLoading, setError} from "../redux/meta/MetaActions";
import {initialAdapter} from "adapter";
import {generic400, generic500} from "../utils/errors";
import ErrorList from "./ErrorList";
import {formatMoney} from "../utils/formatters";
import downloadIFrameGenerator from "../utils/downloadIFrameGenerator";
import ClientViewPDFModal from "./ClientViewPDFModal";
import HTCModalHeaderCloseButton from "./HTCModalHeaderCloseButton";
import DashboardBackgroundCard from "./dashboard/DashboardBackgroundCard";

interface IViewPOPDFModal2Props {
	tokenV2?: any;
	dispatch?: any;
	isOpen: boolean;
	// htc: boolean;
	poKey: string;

	closeModal(): void;
}

const ViewPOPDFModal2: React.FC<IViewPOPDFModal2Props> = (props: IViewPOPDFModal2Props) => {

	const {tokenV2, dispatch, isOpen, poKey, closeModal} = props;
	const [data, setData] = useState<initialAdapter.IPODetailsMsg>();
	const [errors, setErrors] = useState([]);
	const [showPDFModal, setShowPDFModal] = useState(false);

	useEffect(() => {
		if (isOpen) {
			// getPDF().then().catch();
			getPODetails().then().catch();
		} else {
			setTimeout(() => {
				setData(null);
			}, 250)
		}
	}, [isOpen]);

	async function getPODetails(): Promise<void> {
		setErrors([]);
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.readPODetails({poKeyURL: poKey}, tokenV2);

		if (!res.success) {
			props.dispatch(decrementLoading());
			setErrors(handleError(res));
			return;
		}

		props.dispatch(decrementLoading());
		setData(res.data);
	}

	async function viewPDF(e): Promise<void> {
		e.preventDefault();
		setErrors([]);
		props.dispatch(incrementLoading());
		const res = await initialAdapter.api.readPOPdf({poKeyURL: poKey}, tokenV2);

		if (!res.success) {
			props.dispatch(decrementLoading());
			setErrors(handleError(res));
			return;
		}

		props.dispatch(decrementLoading());
		const reader = new FileReader();
		reader.readAsDataURL(res.data);
		reader.onloadend = function () {
			const base64data = reader.result;
			const win = window.open();
			win.document.open();
			win.document.write(downloadIFrameGenerator(data.poNumber, base64data));
			win.document.close();
		};
	}

	function close(): void {
		// setPDF(null);
		closeModal();
	}

	function mapDocuments(files: Array<initialAdapter.IDocumentItemMsg> = []): ReactNode {
		if (files.length < 1) {
			return (
				<p className="font-italic my-2 text-muted mb-0">
					No Attachments.
				</p>
			);
		}

		return files.map((file: initialAdapter.IDocumentItemMsg, i: number) => {
			return (
				<div key={`document-list-item-${i}`} className="my-2">
					<a href={file.documentURL} target="_blank" className="text-hPurple font-italic">
						{file.documentName}
					</a>
				</div>
			);
		})
	}

	function togglePDFModal(): void {
		setShowPDFModal(!showPDFModal);
	}

	return (
		<React.Fragment>
			<ClientViewPDFModal
				isOpen={showPDFModal}
				pdfType="Purchase Order"
				api="readPOPdf"
				docKey={poKey}
				onClose={togglePDFModal}
			/>

			<Modal
				isOpen={isOpen}
				fade={true}
				centered={true}
				className="htc-modal modal-max-800"
			>
				<HTCModalHeaderCloseButton onClick={close}/>
				<DashboardBackgroundCard background="bg-hBlue">
					<CardBody>
						<h2 className="text-white mb-3">Purchase Order Details</h2>
						<Card>
							<CardBody>
								{data && (
									<React.Fragment>
										<Row className="mb-3">
											<Col xs={12} sm={4} md={3}>
												<Label>PO Number:</Label>
											</Col>

											<Col xs={12} sm={8} md={9} className="number-display">
												{data.poNumber}
											</Col>
										</Row>

										<Row className="mb-3">
											<Col xs={12} sm={4} md={3}>
												<Label>PO Date:</Label>
											</Col>

											<Col xs={12} sm={8} md={9} className="number-display">
												{data.poDate}
											</Col>
										</Row>

										<Row className="mb-3">
											<Col xs={12} sm={4} md={3}>
												<Label>Supplier:</Label>
											</Col>

											<Col xs={12} sm={8} md={9}>
												{data.supplierName}
											</Col>
										</Row>

										<Row className="mb-3">
											<Col xs={12} sm={4} md={3}>
												<Label>Order Total:</Label>
											</Col>

											<Col xs={12} sm={8} md={9} className="number-display">
												{formatMoney(data.orderTotal)}
											</Col>
										</Row>

										<Row className="mb-3">
											<Col xs={12} sm={4} md={3}>
												<Label>Status:</Label>
											</Col>

											<Col xs={12} sm={8} md={9} className="text-capitalize">
												{data.status}
											</Col>
										</Row>

										<hr/>

										<Row>
											<Col xs={12}>
												<Label>Attachments</Label>
											</Col>

											<Col xs={12}>
												{mapDocuments(data.documentList)}
											</Col>
										</Row>
									</React.Fragment>
								)}
							</CardBody>
						</Card>

						{data && (
							<div className="mt-4 d-flex justify-content-center">
								<Button color="hBlue" className="px-3 px-sm-5" onClick={togglePDFModal}>
									View PO PDF
								</Button>
							</div>
						)}
					</CardBody>
				</DashboardBackgroundCard>
			</Modal>
		</React.Fragment>
	)
};

export default connect((store: IStore, props: IViewPOPDFModal2Props) => {
	return {
		tokenV2: store.metaStore.tokenV2,
		...props,
	}
})(ViewPOPDFModal2)
