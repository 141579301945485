import React, {useEffect, useState} from "react";
import SelectCustomerPOTableTabs from "./SelectCustomerPOTableTabs";
import SelectCustomerPOTable from "./SelectCustomerPOTable";
import {initialAdapter} from "adapter";

interface ISelectCustomerPOTableWithControllerProps {
	items: Array<initialAdapter.IBuyerPOItems>;
	onFilterChange(newFilter: initialAdapter.DocumentDayRange): Promise<void>;
	onSelect(po: any): void;
}

const SelectCustomerPOTableWithController: React.FC<ISelectCustomerPOTableWithControllerProps> = (props: ISelectCustomerPOTableWithControllerProps) => {

	const {items} = props;
	const [tabFilter, setTabFilter] = useState(initialAdapter.DocumentDayRange.LAST_30);
	const [poData, setPOData] = useState<any[]>([]);

	function handleTableFilterChange(newFilter: initialAdapter.DocumentDayRange): void {
		setTabFilter(newFilter);
		props.onFilterChange(newFilter).then().catch();
	}

	useEffect(() => {
		if (items) {
			getPOTableData(items);
		}
	}, [JSON.stringify(items)]);

	function getPOTableData(data: any[] = []): void {
		// todo api call for real data

		for (const item of data) {
			item.actions = [["Select", selectPO]];
		}

		setPOData(data);
	}

	function selectPO(po: any): void {
		props.onSelect(po);
	}

	return (
		<div>
			<SelectCustomerPOTableTabs filter={tabFilter} onFilterChange={handleTableFilterChange}/>
			<hr className="mt-0"/>

			<div>
				<SelectCustomerPOTable data={poData} filter={tabFilter}/>
			</div>
		</div>
	);
};

export default SelectCustomerPOTableWithController;
