import React, {ChangeEventHandler} from "react";
import {Col, Input, Row} from "reactstrap";
import {IInvoiceItem} from "./CreateInvoiceForm";
import {FiTrash2, FiXCircle} from "react-icons/all";
import NumberFormat from "react-number-format";
import {formatMoney} from "../utils/formatters";
import * as math from "mathjs";

export interface INumberFormat {
	formattedValue: string;
	value: string;
	floatValue: string;
}

interface IInvoiceItemProps extends IInvoiceItem {
	onDelete(): void;
	onChange(key: keyof IInvoiceItem, value: any): void;
}

const InvoiceItem: React.FC<IInvoiceItemProps> = (props: IInvoiceItemProps) => {

	const {name, description, quantity, price, amount, onDelete, onChange} = props;

	function onDeleteIntercept(e: React.MouseEvent<HTMLAnchorElement>): void {
		e.preventDefault();
		onDelete()
	}

	function onChangeIntercept(key: keyof IInvoiceItem): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			onChange(key, e.target.value);
		}
	}

	function onNumberFormatChange(key: keyof IInvoiceItem): any {
		return (e: INumberFormat) => {
			// onChange(key, e.floatValue);
			onChange(key, e.value);
		}
	}

	return (
		<div className="invoice-items">
			<div className="invoice-item-name">
				<Input
					type="text"
					name="name"
					placeholder="Item Name..."
					value={name}
					onChange={onChangeIntercept("name")}
				/>
			</div>

			<div className="invoice-item-description">
				<Input
					type="text"
					name="description"
					placeholder="Item Description..."
					value={description}
					onChange={onChangeIntercept("description")}
				/>
			</div>

			<div className="invoice-item-quantity">
				<NumberFormat
					placeholder="Quantity..."
					value={quantity}
					customInput={Input}
					thousandSeparator={true}
					onValueChange={onNumberFormatChange("quantity")}
					className={(quantity !== undefined && quantity.toString().length > 0) ? "number-display" : ""}
				/>
			</div>

			<div className="invoice-item-price-per">
				<NumberFormat
					placeholder="Price..."
					customInput={Input}
					value={price}
					thousandSeparator={true}
					decimalScale={5}
					prefix="$"
					onValueChange={onNumberFormatChange("price")}
					className={(quantity !== undefined && quantity.toString().length > 0) ? "number-display" : ""}
				/>
			</div>

			<div className="invoice-item-total number-display text-hPurple">
				{quantity && price && (
					<React.Fragment>
						{formatMoney(math.multiply(quantity, price))}
					</React.Fragment>
				)}
			</div>

			<div className="invoice-item-delete-icon">
				<a href="#" className="text-hPurple" onClick={onDeleteIntercept}>
					<img
						src={process.env.PUBLIC_URL + "/svg_icons/small_x_button.svg"}
						className="invoice-list-item-delete-icon-svg"
					/>
				</a>
			</div>
		</div>
	);
};

export default InvoiceItem;
